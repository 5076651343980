import { ReactNode, useState } from 'react'
import { isEqual } from 'lodash'
import { BEMGenerator, useBoolean, useMultiState, useRCComputed } from '@oahz/neact-utils'
import { Alert, Button, Drawer, Link, Modal, Tag, Tooltip, Typography } from '@bedrock/components'
import { ChevronThinDown, ChevronThinUp, InfoCircle, Mall } from '@bedrock/icons-react'
import I18N from '@feature/i18n'
import { formatDateTime, formatStartEndDate } from '@feature/shared'
import { AppTable, createTableColumns } from '@feature/views'
import { DataAttachments, DataBlock, DataItem, DataMoney, DataPanel } from '@/components/data-view'
import type { DataAttachmentsProps, DateItemProps, FilePreviewAndDownloadServices } from '@/components/data-view'
import { apiService, bizEnums, defs } from '@/services'
import './style.less'

const bem = BEMGenerator('sp-show')

export const SupplierTags = (props: { className?: string; value?: defs.MdmTagDto[] }) => {
  const { className, value } = props
  const tags = value?.filter(Boolean)
  return (
    <div className={bem('tags', [className])}>
      {tags?.map(v => (
        <span key={v.tagId} className={bem('tag', `tag-${v.tagLevel}`)}>
          {v.label}
        </span>
      ))}
    </div>
  )
}

export const SupplierBlockTitle = (props: { title: string; tip?: ReactNode; required?: boolean }) => {
  const { title, tip, required } = props
  return (
    <div className={bem('blockTitle')}>
      {title}
      {tip && (
        <Tooltip className={bem('blockTip')} overlayClassName={bem('blockTip-overlay')} title={tip}>
          <InfoCircle />
        </Tooltip>
      )}
      {required && <Tag className={bem('blockTag')} size="small" color="light-red" text={I18N.auto.biTian} />}
    </div>
  )
}

export type SpHeaderData = {
  supplierId?: string
  supplierName?: string
  supplierType?: defs.SupplierType
  supplierMail?: string
  countryName?: string
  tags?: defs.MdmTagDto[]
  onboardProgress?: defs.SpOnboardProgress
  modifiedOaFlowStatus?: defs.SpOaFlowStatus
}

export const pickHeaderDataFromDetailDto = (
  data: {
    id?: string | number
    supplierId?: string
    baseInfo?: { supplierName?: string; userName?: string; mail?: string }
    countryName?: string
  } & Pick<SpHeaderData, 'supplierType' | 'tags' | 'onboardProgress' | 'modifiedOaFlowStatus'>
): SpHeaderData => {
  return {
    // 优先取supplierId, 再id
    supplierId: data.supplierId || data.id + '',
    // 默认取值supplierName、个人使取值userName
    supplierName: data.baseInfo?.supplierName || data.baseInfo?.userName,
    supplierType: data.supplierType,
    supplierMail: data.baseInfo?.mail,
    countryName: data.countryName,
    tags: data.tags,
    onboardProgress: data.onboardProgress,
    modifiedOaFlowStatus: data.modifiedOaFlowStatus,
  }
}

export const SupplierCooperateStatus = (props: { className?: string; value?: defs.SpCooperateStatus }) => {
  const { className, value } = props
  if (!value) return null
  return (
    <div className={bem('coop-status', { [value]: true }, [className])}>
      {bizEnums.SpCooperateStatusEnum.pick(value)?.name}
    </div>
  )
}

export const SupplierHeader = (props: { data: SpHeaderData; action?: ReactNode; children?: ReactNode }) => {
  const { data, action, children } = props

  const renderItem = (v: { label: string; value?: string }) => {
    return (
      <div className={bem('sh-item')}>
        <div className={bem('sh-label')}>{v.label}</div>
        <div className={bem('sh-value')} title={v.value}>
          {v.value}
        </div>
      </div>
    )
  }

  return (
    <header className={bem('sh')}>
      <div className={bem('sh-logo')}>
        <Mall size={60} />
      </div>
      <div className={bem('sh-content')}>
        <div className={bem('sh-row')}>
          <div className={bem('sh-brief')}>
            <div className={bem('sh-title')}>{data.supplierName}</div>
            <SupplierTags className={bem('sh-tags')} value={data.tags} />
            {data.onboardProgress && (
              <div className={bem('onboard-status', { [data.onboardProgress]: true })}>
                {bizEnums.SpOnboardProgressEnum.pick(data.onboardProgress)?.name}
              </div>
            )}
            {data.modifiedOaFlowStatus && (
              <div className={bem('oaflow-status', { [data.modifiedOaFlowStatus]: true })}>
                {bizEnums.SpOaFlowStatusEnum.pick(data.modifiedOaFlowStatus)?.name}
              </div>
            )}
          </div>
          <div className={bem('sh-action')}>{action}</div>
        </div>
        <div className={bem('sh-info')}>
          {renderItem({ label: I18N.auto.gongYingShangID_2, value: data.supplierId })}
          {renderItem({ label: I18N.auto.gongYingShangYouXiang_2, value: data.supplierMail })}
          {renderItem({ label: I18N.auto.zhuCeGuoJiaDi_2, value: data.countryName })}
          {renderItem({
            label: I18N.auto.gongYingShangLeiXing_3,
            value: bizEnums.SupplierTypeEnum.pick(data.supplierType!)?.name,
          })}
        </div>
        {children}
      </div>
    </header>
  )
}

export const supplierFilePreviewAndDownloadServices: FilePreviewAndDownloadServices = {
  getPreviewUrl: v => apiService.identityEnterpriseBoardedGetAttachmentPreviewUrl({ fileId: v.fileId }),
  getDownloadUrl: v => apiService.identityEnterpriseBoardedGetAttachmentDownloadUrl({ fileId: v.fileId }),
}

export const SupplierAttachments = (props: Omit<DataAttachmentsProps, 'dataId'>) => {
  return (
    <DataAttachments className={bem('attachment')} dataId="" {...props} {...supplierFilePreviewAndDownloadServices} />
  )
}

export const SupplierTable: typeof AppTable = props => {
  return <AppTable className={bem('st')} hideOnSinglePage scroll={{ x: true }} {...props} />
}

export function SupplierDataItem(props: DateItemProps & { diff?: boolean }) {
  const { className, diff = false, ...rest } = props

  return <DataItem className={bem('data-item', { diff }, [className])} {...rest} />
}

function conputeDiffDataValue<T, V>(opts: {
  data: { current: T; prev?: T }
  value: (v: T) => V
  render?: (v: V, record: T) => ReactNode
}) {
  const { data, value, render } = opts
  const curValue = value(data.current)
  const doRender = render || ((v: typeof curValue) => v as ReactNode)
  const renderValue = doRender(curValue, data.current)

  if (!data.prev) return { value: renderValue, prevValue: null, diff: false }

  const prevValue = value(data.prev)
  const diff = !isEqual(curValue, prevValue)

  if (diff) {
    const prevRenderValue = doRender(prevValue, data.prev)
    return { value: renderValue, prevValue: prevRenderValue, diff: true }
  } else {
    return { value: renderValue, prevValue: null, diff: false }
  }
}

type NonBoolable<T extends Array<any>, V = T[number]> = (V extends boolean ? never : V)[]
// TODO: 这里每个字段上Value和Render怎么类型推到一下呢?
function computeDiffDataFields<T>(opts: {
  data: { current: T; prev?: T }
  fields: (
    | (Pick<DateItemProps, 'label' | 'w100' | 'w500' | 'w667'> & {
        value: (v: T) => any
        render?: (v: any) => ReactNode
      })
    | boolean
  )[]
}) {
  const { data, fields } = opts

  return (fields.filter(Boolean) as NonBoolable<typeof fields>).map(({ value, render, ...rest }) => {
    const result = conputeDiffDataValue({ data, value, render })
    return { ...result, ...rest }
  })
}

function createDiffDataRender<T>(data: { current: T; prev?: T; add?: boolean; del?: boolean }) {
  return (opts: { value: (v: T) => any; render?: (v: any, record: T) => ReactNode; paragraph?: boolean }) => {
    const { paragraph, ...rest } = opts

    const { value, diff } = conputeDiffDataValue({ data, ...rest })
    const result = value ?? '-'

    return (
      <span className={bem('diff-cell', { diff, add: !!data.add, del: !!data.del })}>
        {opts.paragraph ? (
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              showTooltip: true,
              cssEllipsis: true,
            }}>
            {result}
          </Typography.Paragraph>
        ) : (
          result
        )}
      </span>
    )
  }
}

type DiffDataRecord = Record<string, ReactNode>
type DiffDataRecordsMapping<T> = (data: Parameters<typeof createDiffDataRender<T>>[0]) => DiffDataRecord
function computeDiffDataRecords<T>(opts: {
  data: T[]
  prevData?: T[]
  mapping: DiffDataRecordsMapping<T>
  predicate: (a: T, b: T) => boolean
}) {
  const { data, prevData, mapping, predicate } = opts

  const diff = prevData && !isEqual(data, prevData)

  const vdata = data.map(v => {
    const t = prevData?.find(t => predicate(t, v))
    return mapping({ current: v, prev: t, add: diff && !t })
  })
  const vPrevData = prevData?.map(v => mapping({ current: v }))

  if (!diff) return { data: vdata, diff: false }

  const diffData = vdata.slice()
  for (let i = 0, j = 0, v = prevData[0]; i < prevData.length; i++) {
    v = prevData[i]
    // 如果之前数组中的元素在当前的数组中没有找到，则将其添加到当前数组中，并标记为“删除”
    if (!data.find(t => predicate(t, v))) {
      // 之前的元素添加到当前数组中时，需要以之前的下标值插入
      diffData.splice(i + j, 0, mapping({ current: v, del: true }))
      // 这里j值，表示前面已经添加过元素的数量
      j++
    }
  }

  return { data: vdata, prevData: vPrevData, diff, diffData }
}

const DiffBlockTitle = (props: {
  title: string
  tip?: ReactNode
  current: ReactNode
  prev: ReactNode
  forTable?: boolean
}) => {
  const { title, tip, current, prev, forTable = false } = props
  const [visible, [show, hide]] = useBoolean(false)

  return (
    <div className={bem('diff-blocktitle')}>
      <SupplierBlockTitle title={title} tip={tip} />
      <div className={bem('diff-btns')}>
        <Tag color="light-orange" text={I18N.auto.bianGeng} size="small" />
        <Button type="text" onClick={show}>
          {I18N.auto.chaKanBianGeng}
        </Button>
      </div>
      <Drawer
        className={bem('diff-drawer', { forTable })}
        title={I18N.template(I18N.auto.chaKanBianGeng_2, { val1: title })}
        onClose={hide}
        visible={visible}>
        <div className={bem('diff-item')}>
          <div className={bem('diff-title')}>{I18N.auto.bianGengHou}</div>
          {current}
        </div>
        <div className={bem('diff-item')}>
          <div className={bem('diff-title')}>{I18N.auto.bianGengQian}</div>
          {prev}
        </div>
      </Drawer>
    </div>
  )
}
const DiffBlockContent = (props: { children?: ReactNode }) => {
  const { children } = props
  return <div className={bem('diff-content')}>{children}</div>
}

const DiffDataTitle = (props: { title: string; tip?: ReactNode; data?: ReturnType<typeof computeDiffDataFields> }) => {
  const { title, tip, data } = props

  const diffDatas = useRCComputed(() => data?.filter(v => v.diff), [data])

  if (!diffDatas?.length) return <SupplierBlockTitle title={title} tip={tip} />

  return (
    <DiffBlockTitle
      title={title}
      current={
        <DiffBlockContent>
          {diffDatas?.map((v, i) => (
            <div className={bem('diff-row')} key={i}>
              <div className={bem('diff-label')}>{v.label}:</div>
              <div className={bem('diff-value')}>{v.value || '-'}</div>
            </div>
          ))}
        </DiffBlockContent>
      }
      prev={
        <DiffBlockContent>
          {diffDatas?.map((v, i) => (
            <div className={bem('diff-row')} key={i}>
              <div className={bem('diff-label')}>{v.label}:</div>
              <div className={bem('diff-value')}>{v.prevValue || '-'}</div>
            </div>
          ))}
        </DiffBlockContent>
      }
    />
  )
}

const DiffDataContent = (props: { data: ReturnType<typeof computeDiffDataFields> }) => {
  const { data } = props
  return <>{data?.map((v, i) => <SupplierDataItem key={i} {...v} />)}</>
}

export type SpBaseData<T = Record<string, any>> = {
  supplierType?: defs.SupplierType
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  baseInfo?: T
}

export type SpContentData<T = 'Enterprise' | 'Person', V = Record<string, any>> = SpBaseData<V> & {
  /** 合作状态 */
  cooperateStatus?: defs.SpCooperateStatus
  /** 准入详情中有这字段 */
  onboardingInfo?: defs.SpOnboardingInfoDto
  /** 登记信息 */
  checkIn?: T extends 'Enterprise' ? defs.IdEnterpriseCheckInDto : defs.PersonCheckInDto
  /** 服务品类 */
  serviceCategories?: defs.ServiceCategoryDto[]
  /** 联系人信息 */
  contacts?: T extends 'Enterprise' ? defs.EnterpriseContactDto[] : defs.PersonContactDto[]
  /** 银行信息 */
  bankInfos?: T extends 'Enterprise' ? defs.IdEnterpriseBankInfoDto[] : defs.PersonBankInfoDto[]
  /** 税务信息 */
  taxInfos?: defs.EnterpriseTaxInfoDto[]
} & (T extends 'Enterprise'
    ? {
        /** 资质信息 */
        credentials?: defs.EnterpriseCredentialDto[]
        /** 董监高信息 */
        supervisorInfos?: defs.EnterpriseSupervisorInfoDto
        /** 财务信息 */
        financeInfos?: defs.EnterpriseFinanceInfoDto
        /** 成功案例 */
        successCases?: defs.EnterpriseSuccessCaseDto[]
        /** 利益关系 */
        interestRelations?: defs.EnterpriseInterestRelationDto[]
      }
    : {})
export type SpContentProps<T = 'Enterprise' | 'Person'> = { data: SpContentData<T>; prevData?: SpContentData<T> }

export const isChinaCountryCode = (code?: string) => code?.toUpperCase() === 'CN'

export const isChinaEnterprise = (
  v: SpContentData
): v is SpContentData<'Enterprise', defs.ChinaEnterpriseBaseInfoDto> => {
  return v.supplierType === bizEnums.SupplierTypeEnum.ENTERPRISE && isChinaCountryCode(v.countryCode)
}

export const isChinaPerson = (v: SpContentData): v is SpContentData<'Person', defs.PersonBaseInfoDto> => {
  return v.supplierType === bizEnums.SupplierTypeEnum.PERSON && isChinaCountryCode(v.countryCode)
}

export const isOverseaEnterprise = (
  v: SpContentData
): v is SpContentData<'Enterprise', defs.OverseaEnterpriseBaseInfoDto> => {
  return v.supplierType === bizEnums.SupplierTypeEnum.ENTERPRISE && !isChinaCountryCode(v.countryCode)
}

export const isOverseaPerson = (v: SpContentData): v is SpContentData<'Person', defs.PersonBaseInfoDto> => {
  return v.supplierType === bizEnums.SupplierTypeEnum.PERSON && !isChinaCountryCode(v.countryCode)
}

export const isDJGRequired = (v: SpContentData) => {
  const cooperateStatus = v.cooperateStatus || v.onboardingInfo?.cooperateStatus
  return (
    isChinaCountryCode(v.countryCode) &&
    (cooperateStatus === bizEnums.SpCooperateStatusEnum.POTENTIAL_REGULAR ||
      cooperateStatus === bizEnums.SpCooperateStatusEnum.COOPERATION_REGULAR)
  )
}

export const SupplierContentInfos = (props: SpContentProps) => {
  const { data, prevData } = props

  const isEnterprise = isChinaEnterprise(data) || isOverseaEnterprise(data)
  const isOverase = isOverseaPerson(data) || isOverseaEnterprise(data)

  return (
    <>
      <CheckInInfo {...props} />
      <ServiceCategoriesInfo {...props} />
      {isEnterprise && !!data.credentials?.length && (
        <EnterpriseCredentialsInfo data={data.credentials} prevData={(prevData as typeof data)?.credentials} />
      )}

      <ContactsInfo {...props} />
      {isEnterprise && (
        <>
          {(!!data.supervisorInfos?.supervisors?.length || data.supervisorInfos?.absenceReason) && (
            <SupervisorInfo data={data.supervisorInfos} prevData={(prevData as typeof data)?.supervisorInfos} />
          )}
        </>
      )}
      <BankInfo {...props} />
      {isEnterprise && (
        <>
          {(!!data.financeInfos?.financeInfos?.length || data.financeInfos?.financeAbsenceReason) && (
            <FinancenInfo data={data.financeInfos} prevData={(prevData as typeof data)?.financeInfos} />
          )}
        </>
      )}
      {isOverase && (
        <>
          {!!data.taxInfos?.length && <TaxInfo data={data.taxInfos} prevData={(prevData as typeof data)?.taxInfos} />}
        </>
      )}
      {isEnterprise && (
        <>
          {!!data.successCases?.length && (
            <SuccessCasesInfo data={data.successCases} prevData={(prevData as typeof data)?.successCases} />
          )}
          {!!data.interestRelations?.length && (
            <InterestRelationsInfo
              data={data.interestRelations}
              prevData={(prevData as typeof data)?.interestRelations}
            />
          )}
        </>
      )}
    </>
  )
}

const CheckInInfo = (props: SpContentProps) => {
  const { data, prevData } = props

  const genEnterpriseRenderData = (
    data: { current: SpContentData<'Enterprise'>['checkIn']; prev?: (typeof data)['current'] },
    isChina: boolean
  ) => {
    return computeDiffDataFields({
      data,
      fields: [
        { label: I18N.auto.qiYeMingCheng, value: v => v?.enterpriseName },
        isChina
          ? { label: I18N.auto.tongYiSheHuiXin, value: v => v?.creditCode }
          : { label: 'TIN', value: v => v?.tin },
        !isChina && { label: I18N.auto.dengBaiShiBianMa, value: v => v?.duns },
        { label: I18N.auto.gongYingShangJianCheng, value: v => v?.enterpriseAliasName },
        isChina && { label: I18N.auto.faDingDaiBiaoRen, value: v => v?.legalRepresentative },
        {
          label: I18N.auto.zhuCeZiBenWan,
          value: v => v?.registerCapital,
          render: v => v && <DataMoney size="xsmall" value={v} highlight={false} />,
        },
        { label: I18N.auto.gongSiZhuYeWang, value: v => v?.homeSite },
        { label: I18N.auto.yingYeQiXian, value: v => formatStartEndDate(v?.startAt, v?.endAt) },
        { label: I18N.auto.chengLiRiQi, value: v => formatDateTime(v?.establishTime) },
        {
          label: I18N.auto.naShuiRenZiZhi,
          value: v => bizEnums.TaxpayerQualificationEnum.pick(v?.taxpayerQualification!)?.name,
        },
        { label: I18N.auto.shengShi, value: v => v?.provinceName && `${v?.provinceName}/${v.city ?? '-'}` },
        { label: I18N.auto.quXian, value: v => v?.district },
        { label: I18N.auto.xiangXiDiZhi, value: v => v?.address },
        !isChina && { label: '邮编', value: v => v?.postCode },
        { label: I18N.auto.jingYingFanWei, value: v => v?.businessScope, w100: true },
        {
          label: I18N.auto.yingYeZhiZhaoFu,
          value: v => v?.licenseFile,
          render: v => v && <SupplierAttachments attachments={[v]} />,
        },
        {
          label: I18N.auto.heFaZhuCeZheng,
          value: v => v?.legalRegistrationLicenseFile,
          render: v => v && <SupplierAttachments attachments={[v]} />,
        },
        {
          label: I18N.auto.zhunRuZiLiaoShang,
          value: v => v?.admittanceFile,
          render: v => v && <SupplierAttachments attachments={[v]} />,
        },
      ],
    })
  }

  const genPersonRenderData = (
    data: { current: SpContentData<'Person'>['checkIn']; prev?: (typeof data)['current'] },
    isChina: boolean
  ) => {
    return computeDiffDataFields({
      data,
      fields: [
        { label: I18N.auto.xingMing, value: v => v?.userName },
        { label: I18N.auto.zhengJianLeiXing, value: v => bizEnums.PersonIdTypeEnum.pick(v?.personIdType!)?.name },
        { label: I18N.auto.zhengJianHaoMa, value: v => v?.personId },
        !isChina && { label: '邮编', value: v => v?.postCode },
      ],
    })
  }

  const genRenderData = () => {
    if (isChinaEnterprise(data))
      return genEnterpriseRenderData({ current: data.checkIn, prev: (prevData as typeof data)?.checkIn }, true)
    if (isOverseaEnterprise(data))
      return genEnterpriseRenderData({ current: data.checkIn, prev: (prevData as typeof data)?.checkIn }, false)
    if (isChinaPerson(data))
      return genPersonRenderData({ current: data.checkIn, prev: (prevData as typeof data)?.checkIn }, true)
    if (isOverseaPerson(data))
      return genPersonRenderData({ current: data.checkIn, prev: (prevData as typeof data)?.checkIn }, false)
  }

  const renderDatas = useRCComputed(() => genRenderData(), [data])

  return (
    <DataPanel>
      <DataBlock title={<CheckInDiffTitle title={I18N.auto.dengJiXinXi_2} data={renderDatas} />}>
        {renderDatas?.map((v, i) => <SupplierDataItem key={i} {...v} />)}
      </DataBlock>
    </DataPanel>
  )
}

const CheckInDiffTitle = (props: { title: string; data?: ReturnType<typeof computeDiffDataFields> }) => {
  const { title, data } = props

  const diffDatas = useRCComputed(() => data?.filter(v => v.diff), [data])

  if (!diffDatas?.length) return <>{title}</>

  return (
    <DiffBlockTitle
      title={title}
      current={
        <DiffBlockContent>
          {diffDatas?.map((v, i) => (
            <div className={bem('diff-row')} key={i}>
              <div className={bem('diff-label')}>{v.label}:</div>
              <div className={bem('diff-value')}>{v.value || '-'}</div>
            </div>
          ))}
        </DiffBlockContent>
      }
      prev={
        <DiffBlockContent>
          {diffDatas?.map((v, i) => (
            <div className={bem('diff-row')} key={i}>
              <div className={bem('diff-label')}>{v.label}:</div>
              <div className={bem('diff-value')}>{v.prevValue || '-'}</div>
            </div>
          ))}
        </DiffBlockContent>
      }
    />
  )
}

const ServiceCategoriesInfo = (props: SpContentProps) => {
  const { data, prevData } = props

  const serviceCategories = useRCComputed(() => {
    const { serviceCategories = [] } = data
    const { serviceCategories: prevServiceCategories = [] } = prevData || {}

    const diff = prevData && !isEqual(serviceCategories, prevServiceCategories)
    if (!diff) return { data: serviceCategories, diff: false }

    const diffData = serviceCategories.map(v => ({
      ...v,
      // 当前数组中的元素，在之前的数组中找不到，标记为“新增”
      add: !prevServiceCategories.find(t => t.categoryId === v.categoryId),
      del: false,
    }))

    for (let i = 0, j = 0, v = prevServiceCategories[0]; i < prevServiceCategories.length; i++) {
      v = prevServiceCategories[i]
      // 如果之前数组中的元素在当前的数组中没有找到，则将其添加到当前数组中，并标记为“删除”
      if (!serviceCategories.find(t => t.categoryId === v.categoryId)) {
        // 之前的元素添加到当前数组中时，需要以之前的下标值插入
        diffData.splice(i + j, 0, { ...v, add: false, del: true })
        // 这里j值，表示前面已经添加过元素的数量
        j++
      }
    }

    return { data: serviceCategories, prevData: prevServiceCategories, diff, diffData }
  }, [data])

  return (
    <DataPanel>
      <DataBlock title={<ServiceCategoriesDiffTitle title={I18N.auto.fuWuPinLei} {...serviceCategories} />}>
        <DataItem w100 label={I18N.auto.fuWuPinLeiNei}>
          <ServiceCategoriesList {...serviceCategories} />
        </DataItem>
      </DataBlock>
    </DataPanel>
  )
}

type ServiceCategoriesDiffData = (NonNullable<SpContentData['serviceCategories']>[number] & {
  add?: boolean
  del?: boolean
})[]
const ServiceCategoriesList = (props: { data: ServiceCategoriesDiffData; diff?: boolean }) => {
  const { data, diff = false } = props
  return (
    <div className={bem('cate-list')}>
      {data.map(v => (
        <span className={bem('cate-item', { diff, add: !!v.add, del: !!v.del })} key={v.categoryId}>
          {v.categoryNames?.join('/')}
        </span>
      ))}
    </div>
  )
}

const ServiceCategoriesDiffTitle = (props: {
  title: string
  diff: boolean
  diffData?: ServiceCategoriesDiffData
  prevData?: ServiceCategoriesDiffData
}) => {
  const { title, diff, diffData = [], prevData = [] } = props

  if (!diff) return <>{title}</>

  return (
    <DiffBlockTitle
      title={title}
      current={<ServiceCategoriesList data={diffData} />}
      prev={<ServiceCategoriesList data={prevData} />}
    />
  )
}

type CredentialsDto = NonNullable<SpContentData<'Enterprise'>['credentials']>[number]
const EnterpriseCredentialsInfo = (props: { data: CredentialsDto[]; prevData?: CredentialsDto[] }) => {
  const { data, prevData } = props

  const credentials = useRCComputed(() => {
    return computeDiffDataRecords({
      data,
      prevData,
      mapping: data => {
        const renderValue = createDiffDataRender(data)
        return {
          categoryName: renderValue({ value: v => v.categoryName }),
          credentialName: renderValue({ value: v => v.credentialName }),
          credentialFile: renderValue({
            value: v => v.credentialFile,
            render: v => v && <SupplierAttachments attachments={[v]} />,
          }),
          startAt_endAt: renderValue({ value: v => formatStartEndDate(v.startAt, v.endAt) }),
          remark: renderValue({ value: v => v.remark, paragraph: true }),
        }
      },
      predicate: (a, b) => a.categoryId === b.categoryId && a.credentialName === b.credentialName,
    })
  }, [data])

  return (
    <DataPanel>
      <DataBlock
        bodyClassName={bem('dbBodyForTable')}
        title={<EnterpriseCredentialsDiffTitle title={I18N.auto.ziZhiXinXi} {...credentials} />}>
        <EnterpriseCredentialsTableList data={credentials.data} />
      </DataBlock>
    </DataPanel>
  )
}

const EnterpriseCredentialsTableList = (props: { data: DiffDataRecord[] }) => {
  const { data } = props

  const columns = createTableColumns<DiffDataRecord>([
    {
      title: I18N.auto.pinLei,
      dataIndex: 'categoryName',
      width: 240,
      fixed: 'left',
    },
    {
      title: I18N.auto.ziZhiMingCheng,
      dataIndex: 'credentialName',
      width: 223,
    },
    {
      title: I18N.auto.ziZhiWenJian,
      dataIndex: 'credentialFile',
      width: 194,
    },
    {
      title: I18N.auto.ziZhiYouXiaoQi,
      dataIndex: 'startAt_endAt',
      width: 248,
    },
    {
      title: I18N.auto.beiZhu,
      dataIndex: 'remark',
      width: 214,
    },
  ])

  return <SupplierTable columns={columns} data={data} />
}

const EnterpriseCredentialsDiffTitle = (props: {
  title: string
  diff: boolean
  diffData?: DiffDataRecord[]
  prevData?: DiffDataRecord[]
}) => {
  const { title, diff, diffData = [], prevData = [] } = props

  if (!diff) return <>{title}</>

  return (
    <DiffBlockTitle
      title={title}
      current={<EnterpriseCredentialsTableList data={diffData} />}
      prev={<EnterpriseCredentialsTableList data={prevData} />}
      forTable
    />
  )
}

const ContactsInfo = (props: SpContentProps) => {
  const { data, prevData } = props
  const isEnterprise = isChinaEnterprise(data) || isOverseaEnterprise(data)

  const contacts = useRCComputed(() => {
    return computeDiffDataRecords({
      data: data.contacts || [],
      prevData: prevData?.contacts,
      mapping: data => {
        const renderValue = createDiffDataRender(data)
        return {
          name: renderValue({ value: v => v.name }),
          mail: renderValue({ value: v => v.mail }),
          phone: renderValue({ value: v => v.phone }),
          remark: renderValue({ value: v => v.remark, paragraph: true }),
          ...(isEnterprise
            ? {
                contactType: renderValue({
                  value: (v: defs.EnterpriseContactDto) => bizEnums.ContactTypeEnum.pick(v.contactType!)?.name,
                }),
                post: renderValue({ value: (v: defs.EnterpriseContactDto) => v.post }),
              }
            : { sns: renderValue({ value: (v: defs.PersonContactDto) => v.sns }) }),
        }
      },
      predicate: (a, b) => a.id === b.id,
    })
  }, [data])

  return (
    <DataPanel>
      <DataBlock
        bodyClassName={bem('dbBodyForTable')}
        title={<ContactsDiffTitle title={I18N.auto.lianXiRenXinXi} isEnterprise={isEnterprise} {...contacts} />}>
        <ContactsTableList isEnterprise={isEnterprise} {...contacts} />
      </DataBlock>
    </DataPanel>
  )
}

const ContactsTableList = (props: { data: DiffDataRecord[]; isEnterprise: boolean }) => {
  const { data, isEnterprise } = props

  const columns = createTableColumns<DiffDataRecord>([
    {
      title: I18N.auto.lianXiRenXingMing,
      dataIndex: 'name',
      width: 131,
      fixed: 'left',
    },
    ...(isEnterprise
      ? [
          {
            title: I18N.auto.lianXiRenLeiXing,
            dataIndex: 'contactType',
            width: 174,
          },
          {
            title: I18N.auto.zhiWu,
            dataIndex: 'post',
            width: 156,
          },
        ]
      : []),
    {
      title: I18N.auto.youXiang,
      dataIndex: 'mail',
      width: 169,
    },
    {
      title: I18N.auto.shouJiHao,
      dataIndex: 'phone',
      width: 173,
    },
    ...(!isEnterprise
      ? [
          {
            title: 'SNS',
            dataIndex: 'sns',
            width: 146,
          },
        ]
      : []),
    {
      title: I18N.auto.beiZhu,
      dataIndex: 'remark',
      width: 173,
    },
  ])

  return <SupplierTable columns={columns} data={data} />
}

const ContactsDiffTitle = (props: {
  title: string
  diff: boolean
  isEnterprise: boolean
  diffData?: DiffDataRecord[]
  prevData?: DiffDataRecord[]
}) => {
  const { title, diff, isEnterprise, diffData = [], prevData = [] } = props

  if (!diff) return <>{title}</>

  return (
    <DiffBlockTitle
      title={title}
      current={<ContactsTableList data={diffData} isEnterprise={isEnterprise} />}
      prev={<ContactsTableList data={prevData} isEnterprise={isEnterprise} />}
      forTable
    />
  )
}

export const SupervisorDecription = (props: { collapsible?: boolean }) => {
  const { collapsible } = props
  const [showMore, setshowMore] = useState<boolean>(true)

  return (
    <div className={bem('alert-content')}>
      <div className={bem('alert-content-title')}>
        {'请根据以下要求完整填写董事、监事及高级管理人员信息，公司将对信息进行合规审核，如有不实将影响供应商合作进程；'}
      </div>
      <div>{' 1、填写范围：董事、监事、高级管理人员（如总经理、财务负责人等关键管理岗位）；'}</div>
      <div>{'2、信息查询指引：'}</div>

      {showMore && (
        <ul className={bem('alert-content-ul')}>
          <li>
            {'优先通过'}
            <Link type="primary" target="_blank" href={'https://shiming.gsxt.gov.cn'}>
              {'【国家企业信用信息公示系统】'}
            </Link>
            {'→【基础信息】→【主要人员信息】中披露的人员信息填写；'}
          </li>
          <li>{'若实际人员信息与公示系统不一致（如人员变更未更新公示），请以最新有效信息为准。'}</li>
        </ul>
      )}
      {collapsible && (
        <Button
          type="link"
          suffixIcon={showMore ? <ChevronThinUp /> : <ChevronThinDown />}
          onClick={() => {
            setshowMore(pre => !pre)
          }}>
          {showMore ? '收起' : '展开'}
        </Button>
      )}
    </div>
  )
}

type SupervisornfoDto = NonNullable<SpContentData<'Enterprise'>['supervisorInfos']>
const SupervisorInfo = (props: { data: SupervisornfoDto; prevData?: SupervisornfoDto }) => {
  const { data, prevData } = props

  const supervisorChecked = useRCComputed(() => {
    // 如果没有董监高信息缺失原因，就默认需要上传该信息
    return !data.absenceReason ? bizEnums.StatusBooleanEnum.YES : bizEnums.StatusBooleanEnum.NO
  }, [data])

  type T = NonNullable<SupervisornfoDto['supervisors']>
  function computeSupervisors(data: T = [], prevData?: T) {
    return computeDiffDataRecords({
      data,
      prevData,
      mapping: data => {
        const renderValue = createDiffDataRender(data)
        return {
          name: renderValue({ value: v => v.name }),
          positions: renderValue({
            value: v => v.positions?.map(t => bizEnums.SpSupervisorPositionEnum.pick(t)?.name).join('、'),
          }),
          personIdType: renderValue({ value: v => bizEnums.PersonIdTypeEnum.pick(v.personIdType!)?.name }),
          personId: renderValue({ value: v => v.personId }),
          remark: renderValue({ value: v => v.remark, paragraph: true }),
        }
      },
      predicate: (a, b) => a.name === b.name && a.personIdType === b.personIdType && a.personId === b.personId,
    })
  }
  const supervisors = useRCComputed(() => {
    return computeSupervisors(data.supervisors, prevData?.supervisors)
  }, [data])

  const computeAbsenceReason = (current: typeof data, prev?: typeof data) => {
    return computeDiffDataFields({
      data: { current, prev },
      fields: [{ label: '董监高缺失原因', value: v => v.absenceReason, w100: true }],
    })
  }

  const supervisorAbsenceReason = useRCComputed(() => {
    return computeAbsenceReason(data, prevData)
  }, [data])

  const renderDiffTitle = () => {
    const diffTitleProps = { title: '董监高信息', tip: <SupervisorDecription /> }
    if (!prevData) return <SupplierBlockTitle {...diffTitleProps} />

    const prevSupervisorChecked = !prevData?.absenceReason
      ? bizEnums.StatusBooleanEnum.YES
      : bizEnums.StatusBooleanEnum.NO

    // 如果前后两次都是同样的数据类型，则按照各自的类型展示
    if (supervisorChecked === prevSupervisorChecked) {
      if (supervisorChecked === bizEnums.StatusBooleanEnum.YES) {
        return <SupervisorDiffTitle {...diffTitleProps} {...supervisors} />
      } else {
        return <DiffDataTitle {...diffTitleProps} data={supervisorAbsenceReason} />
      }
    } else {
      // 如果前后两次不是同样的数据类型，则各展示各的
      if (data.supervisors) {
        return (
          <DiffBlockTitle
            {...diffTitleProps}
            current={<SupervisorTableList data={supervisors.data} />}
            prev={<DiffDataContent data={computeAbsenceReason(prevData)} />}
            forTable
          />
        )
      }
      if (data.absenceReason) {
        return (
          <DiffBlockTitle
            {...diffTitleProps}
            current={<DiffDataContent data={supervisorAbsenceReason} />}
            prev={<SupervisorTableList data={computeSupervisors(prevData.supervisors).data} />}
            forTable
          />
        )
      }
    }
  }

  return (
    <DataPanel>
      <DataBlock bodyClassName={bem('dbBodyForTable')} title={renderDiffTitle()}>
        {supervisorChecked === bizEnums.StatusBooleanEnum.YES ? (
          <SupervisorTableList {...supervisors} />
        ) : (
          <div className={bem('dbBodyForTableChild')}>
            <DiffDataContent data={supervisorAbsenceReason} />
          </div>
        )}
      </DataBlock>
    </DataPanel>
  )
}

const SupervisorTableList = (props: { data: DiffDataRecord[] }) => {
  const { data } = props

  const columns = createTableColumns<DiffDataRecord>([
    {
      title: '姓名',
      dataIndex: 'name',
      width: 194,
      fixed: 'left',
    },
    {
      title: '职务',
      dataIndex: 'positions',
      width: 231,
    },
    {
      title: '证件类型',
      dataIndex: 'personIdType',
      width: 245,
    },
    {
      title: '证件号码',
      dataIndex: 'personId',
      width: 190,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 261,
    },
  ])

  return <SupplierTable columns={columns} data={data} />
}

const SupervisorDiffTitle = (props: {
  title: string
  tip?: ReactNode
  diff: boolean
  diffData?: DiffDataRecord[]
  prevData?: DiffDataRecord[]
}) => {
  const { title, tip, diff, diffData = [], prevData = [] } = props

  if (!diff) return <SupplierBlockTitle title={title} tip={tip} />

  return (
    <DiffBlockTitle
      title={title}
      tip={tip}
      current={<SupervisorTableList data={diffData} />}
      prev={<SupervisorTableList data={prevData} />}
      forTable
    />
  )
}

export const BankAccount = (props: {
  data: { account?: string; status?: defs.StatusEnum; primary?: defs.StatusEnum }
}) => {
  const { data } = props
  return (
    <div className={bem('bank-item')}>
      <div className={bem('bank-account')}>{data.account}</div>
      <span className={bem('bank-tag', 'bank-status', { [data.status!]: true })}>
        {bizEnums.StatusInUseEnum.pick(data.status!)?.name}
      </span>
      {data.primary === bizEnums.StatusBooleanEnum.YES && (
        <span className={bem('bank-tag')}>{I18N.auto.moRenZhangHao}</span>
      )}
    </div>
  )
}

export const BankTip = (props: { please?: boolean }) => (
  <>
    {props.please ? '请' : ''}
    提供开户许可证或其他盖有公章的银行信息证明资料，并确保跟填写的银行信息一致
  </>
)

type BankInfoDto =
  | NonNullable<SpContentData<'Enterprise'>['bankInfos']>[number]
  | NonNullable<SpContentData<'Person'>['bankInfos']>[number]
const BankInfo = (props: SpContentProps) => {
  const { data, prevData } = props
  const [viewState, setViewState] = useMultiState({ visible: false, curData: {} as BankInfoDto })
  const isEnterprise = isChinaEnterprise(data) || isOverseaEnterprise(data)
  const { visible, curData } = viewState

  const bank = useRCComputed(() => {
    return computeDiffDataRecords({
      data: data.bankInfos || [],
      prevData: prevData?.bankInfos,
      mapping: data => {
        const renderValue = createDiffDataRender(data)
        return {
          account: renderValue({ value: v => v.account, render: (_, record) => <BankAccount data={record} /> }),
          accountName: renderValue({ value: v => v.accountName }),
          currency: renderValue({ value: v => v.currency }),
          countryName: renderValue({ value: v => v.countryName }),
          swiftCode: renderValue({ value: v => v.swiftCode }),
          ...(isEnterprise
            ? {
                cnaps: renderValue({ value: (v: defs.IdEnterpriseBankInfoDto) => v.cnaps }),
                openProvinceName_openCity: renderValue({
                  value: (v: defs.IdEnterpriseBankInfoDto) =>
                    v.openProvinceName && `${v.openProvinceName}/${v.openCity ?? '-'}`,
                }),
                preferredPaymentMethod: renderValue({
                  value: (v: defs.IdEnterpriseBankInfoDto) =>
                    bizEnums.SpPreferredPaymentMethodEnum.pick(v.preferredPaymentMethod!)?.name,
                }),
                iban: renderValue({ value: (v: defs.IdEnterpriseBankInfoDto) => v.iban }),
                accountType: renderValue({
                  value: (v: defs.IdEnterpriseBankInfoDto) => bizEnums.SpBankAccountTypeEnum.pick(v.accountType!)?.name,
                }),
                abaRoutingNumber: renderValue({ value: (v: defs.IdEnterpriseBankInfoDto) => v.abaRoutingNumber }),
                sortCode: renderValue({ value: (v: defs.IdEnterpriseBankInfoDto) => v.sortCode }),
                bankId: renderValue({ value: (v: defs.IdEnterpriseBankInfoDto) => v.bankId }),
                branchId: renderValue({ value: (v: defs.IdEnterpriseBankInfoDto) => v.branchId }),
              }
            : {}),
          openBankName: renderValue({ value: v => v.openBankName }),
          branchName: renderValue({ value: v => v.branchName }),
          ...(isEnterprise
            ? {
                address: renderValue({ value: (v: defs.IdEnterpriseBankInfoDto) => v.address }),
                handlingFeeType: renderValue({
                  value: (v: defs.IdEnterpriseBankInfoDto) =>
                    bizEnums.HandlingFeeTypeEnum.pick(v.handlingFeeType!)?.name,
                }),
                accountPeriod: renderValue({ value: (v: defs.IdEnterpriseBankInfoDto) => v.accountPeriod }),
              }
            : {}),
          remark: renderValue({ value: v => v.remark, paragraph: true }),
          ...(isEnterprise
            ? {
                createPermitFile: renderValue({
                  value: (v: defs.IdEnterpriseBankInfoDto) => v.createPermitFile,
                  render: v => v && <SupplierAttachments attachments={[v]} />,
                }),
              }
            : {}),
        }
      },
      predicate: (a, b) => a.id === b.id,
    })
  }, [data])

  const curRenderData = useRCComputed(() => {
    return computeDiffDataFields({
      data: { current: curData, prev: prevData?.bankInfos?.find(v => v.id === curData?.id) },
      fields: [
        { label: I18N.auto.yinHangZhangHao, value: v => v?.account, w500: true },
        { label: I18N.auto.zhangHaoMingCheng, value: v => v?.accountName, w500: true },
        { label: I18N.auto.jieSuanBiZhong, value: v => v?.currency, w500: true },
        { label: I18N.auto.guoJiaDiQu_2, value: v => v.countryName, w500: true },
        ...(isEnterprise
          ? [
              ...(isChinaCountryCode((curData as defs.IdEnterpriseBankInfoDto).countryCode)
                ? [
                    { label: I18N.auto.lianHangHao, value: (v: defs.IdEnterpriseBankInfoDto) => v?.cnaps, w500: true },
                    {
                      label: I18N.auto.kaiHuHangShengShi,
                      value: (v: defs.IdEnterpriseBankInfoDto) =>
                        v.openProvinceName && `${v.openProvinceName}/${v.openCity ?? '-'}`,
                      w500: true,
                    },
                  ]
                : [
                    {
                      label: '首选付款方式',
                      value: (v: defs.IdEnterpriseBankInfoDto) =>
                        bizEnums.SpPreferredPaymentMethodEnum.pick(v.preferredPaymentMethod!)?.name,
                      w500: true,
                    },
                    { label: 'SWIFT CODE', value: (v: defs.IdEnterpriseBankInfoDto) => v?.swiftCode, w500: true },
                    { label: 'IBAN', value: (v: defs.IdEnterpriseBankInfoDto) => v?.iban, w500: true },
                    {
                      label: 'Account Type',
                      value: (v: defs.IdEnterpriseBankInfoDto) =>
                        bizEnums.SpBankAccountTypeEnum.pick(v.accountType!)?.name,
                      w500: true,
                    },
                    {
                      label: 'ABA/Routing number',
                      value: (v: defs.IdEnterpriseBankInfoDto) => v?.abaRoutingNumber,
                      w500: true,
                    },
                    { label: 'Sort Code', value: (v: defs.IdEnterpriseBankInfoDto) => v?.sortCode, w500: true },
                    { label: '银行ID', value: (v: defs.IdEnterpriseBankInfoDto) => v?.bankId, w500: true },
                    { label: '支行ID', value: (v: defs.IdEnterpriseBankInfoDto) => v?.branchId, w500: true },
                  ]),
            ]
          : [
              !isChinaCountryCode((curData as defs.PersonBankInfoDto).countryCode) && {
                label: 'SWIFT CODE',
                value: (v: defs.PersonBankInfoDto) => v?.swiftCode,
                w500: true,
              },
            ]),
        { label: I18N.auto.kaiHuHangMingCheng_2, value: v => v?.openBankName, w500: true },
        { label: I18N.auto.zhiHangMingCheng, value: v => v?.branchName, w500: true },
        ...(isEnterprise
          ? [
              { label: I18N.auto.yinHangDiZhi, value: (v: defs.IdEnterpriseBankInfoDto) => v?.address, w100: true },
              {
                label: I18N.auto.shouXuFeiChengDan,
                value: (v: defs.IdEnterpriseBankInfoDto) => bizEnums.HandlingFeeTypeEnum.pick(v.handlingFeeType!)?.name,
                w500: true,
              },
              { label: I18N.auto.zhangQi, value: (v: defs.IdEnterpriseBankInfoDto) => v?.accountPeriod, w500: true },
            ]
          : []),
        { label: I18N.auto.shiFouWeiMoRen, value: v => bizEnums.StatusBooleanEnum.pick(v.primary!)?.name, w500: true },
        { label: I18N.auto.zhuangTai, value: v => bizEnums.StatusEnum.pick(v.status!)?.name, w500: true },
        { label: I18N.auto.beiZhu, value: v => v.remark, w100: true },
        isEnterprise && {
          label: I18N.auto.kaiHuXuKeZheng,
          value: v => (v as defs.IdEnterpriseBankInfoDto)?.createPermitFile,
          render: v => v && <SupplierAttachments attachments={[v]} />,
          w100: true,
        },
      ],
    })
  }, [curData])

  // 这里就是为了TS类型推导一下
  // const renderEnterprise = (jsx: (v: defs.EnterpriseBankInfoDto) => ReactNode) => isEnterprise && jsx(curData)

  return (
    <DataPanel>
      <DataBlock
        bodyClassName={bem('dbBodyForTable')}
        title={
          <BankDiffTitle title={I18N.auto.yinHangZhangHaoXin} tip={<BankTip />} isEnterprise={isEnterprise} {...bank} />
        }>
        <BankTableList
          data={bank.data}
          isEnterprise={isEnterprise}
          onClickView={idx => setViewState({ visible: true, curData: data.bankInfos?.[idx] })}
        />
      </DataBlock>
      <Modal
        className={bem('modal', 'modal-bank')}
        title={I18N.auto.yinHangXinXi}
        visible={visible}
        onCancel={() => setViewState({ visible: false })}
        footer={false}>
        <DataBlock>
          {curRenderData.map((v, i) => (
            <SupplierDataItem key={i} {...v} />
          ))}
        </DataBlock>
      </Modal>
    </DataPanel>
  )
}

const BankTableList = (props: {
  data: DiffDataRecord[]
  isEnterprise: boolean
  forDrawer?: boolean
  onClickView?: (idx: number) => void
}) => {
  const { data, isEnterprise, forDrawer, onClickView } = props

  type T = Parameters<typeof createTableColumns<DiffDataRecord>>[0]
  const columns = createTableColumns<DiffDataRecord>([
    {
      title: I18N.auto.yinHangZhangHao,
      dataIndex: 'account',
      width: 200,
      fixed: 'left',
    },
    {
      title: I18N.auto.zhangHaoMingCheng,
      dataIndex: 'accountName',
      width: 123,
      fixed: 'left',
    },
    {
      title: I18N.auto.jieSuanBiZhong,
      dataIndex: 'currency',
      width: 157,
    },
    ...(isEnterprise
      ? [
          {
            title: I18N.auto.guoJiaDiQu_2,
            dataIndex: 'countryName',
            width: 150,
          },
          ...((forDrawer
            ? [
                {
                  title: I18N.auto.lianHangHao,
                  dataIndex: 'cnaps',
                  width: 135,
                },
                {
                  title: I18N.auto.kaiHuHangShengShi,
                  dataIndex: 'openProvinceName_openCity',
                  width: 135,
                },
                {
                  title: '首选付款方式',
                  dataIndex: 'preferredPaymentMethod',
                  width: 135,
                },
                {
                  title: 'SWIFT CODE',
                  dataIndex: 'swiftCode',
                  width: 135,
                },
                {
                  title: 'IBAN',
                  dataIndex: 'iban',
                  width: 135,
                },
                {
                  title: 'Account Type',
                  dataIndex: 'accountType',
                  width: 160,
                },
                {
                  title: 'ABA/Routing number',
                  dataIndex: 'abaRoutingNumber',
                  width: 150,
                },
                {
                  title: 'Sort Code',
                  dataIndex: 'sortCode',
                  width: 135,
                },
                {
                  title: '银行ID',
                  dataIndex: 'bankId',
                  width: 135,
                },
                {
                  title: '支行ID',
                  dataIndex: 'branchId',
                  width: 135,
                },
              ]
            : [
                {
                  title: I18N.auto.kaiHuHangShengShi,
                  dataIndex: 'openProvinceName_openCity',
                  width: 135,
                },
              ]) as T),
        ]
      : [
          ...(forDrawer
            ? [
                {
                  title: 'SWIFT CODE',
                  dataIndex: 'swiftCode',
                  width: 135,
                },
              ]
            : []),
        ]),
    {
      title: I18N.auto.kaiHuHangMingCheng_2,
      dataIndex: 'openBankName',
      width: 141,
    },
    {
      title: I18N.auto.zhiHangMingCheng,
      dataIndex: 'branchName',
      width: 135,
    },
    ...((forDrawer
      ? [
          ...(isEnterprise
            ? ([
                {
                  title: I18N.auto.yinHangDiZhi,
                  dataIndex: 'address',
                  width: 150,
                },
                {
                  title: I18N.auto.shouXuFeiChengDan,
                  dataIndex: 'handlingFeeType',
                  width: 135,
                },
                {
                  title: I18N.auto.zhangQi,
                  dataIndex: 'accountPeriod',
                  width: 100,
                },
              ] as T)
            : []),
          {
            title: I18N.auto.beiZhu,
            dataIndex: 'remark',
            width: 144,
          },
          ...(isEnterprise
            ? [
                {
                  title: I18N.auto.kaiHuXuKeZheng,
                  dataIndex: 'createPermitFile',
                  width: 187,
                },
              ]
            : []),
        ]
      : [
          {
            title: I18N.auto.beiZhu,
            dataIndex: 'remark',
            width: 144,
          },
          {
            title: I18N.auto.caoZuo,
            dataIndex: 'operate',
            width: 70,
            fixed: 'right',
            render: (_, record, idx) => (
              <Button type="text" onClick={() => onClickView?.(idx)}>
                {I18N.auto.chaKan}
              </Button>
            ),
          },
        ]) as T),
  ])

  return <SupplierTable columns={columns} data={data} />
}

const BankDiffTitle = (props: {
  title: string
  tip?: ReactNode
  diff: boolean
  isEnterprise: boolean
  diffData?: DiffDataRecord[]
  prevData?: DiffDataRecord[]
}) => {
  const { title, tip, diff, isEnterprise, diffData = [], prevData = [] } = props

  if (!diff) return <SupplierBlockTitle title={title} tip={tip} />

  return (
    <DiffBlockTitle
      title={title}
      tip={tip}
      current={<BankTableList data={diffData} isEnterprise={isEnterprise} forDrawer />}
      prev={<BankTableList data={prevData} isEnterprise={isEnterprise} forDrawer />}
      forTable
    />
  )
}

export const FinanceDescription = (props: { collapsible?: boolean }) => {
  const { collapsible } = props
  const [showMore, setshowMore] = useState<boolean>(true)
  return (
    <div className={bem('alert-content')}>
      <div className={bem('alert-content-title')}>
        按集团制度要求，需请供应商提供：最近两年的完整财务报告（需包含资产负债表、利润表、现金流量表）。
      </div>
      <div>1、若财务报告经审计，请直接提交审计报告（需附会计师事务所盖章）；</div>
      <div>2、若报告未经审计，请提供财务报表原件并加盖公司公章；</div>
      {showMore && (
        <>
          <div>
            3、请按以下要求提供数据，并确保与财务报表完全一致，若以上数据与财务报表不一致，需供应商书面说明差异原因并加盖公章；
          </div>
          <ul className={bem('alert-content-ul')}>
            <li>营业收入：指利润表中年度“营业收入”栏目；请直接提取利润表列示的金额，无需调整；</li>
            <li>利润总额：指利润表中“利润总额”栏目；一般直接提取利润表列示的金额（税前利润）；</li>
            <li>
              实缴资本：指股东实际缴纳到公司的出资总额（非“注册资本”）；请参考资产负债表“实收资本”栏目或验资报告中的实缴金额；
            </li>
            <li>总资产：指资产负债表中“资产总计”栏目；请直接提取资产负债表列示的金额，无需调整</li>
          </ul>
        </>
      )}
      {collapsible && (
        <Button
          type="link"
          suffixIcon={showMore ? <ChevronThinUp /> : <ChevronThinDown />}
          onClick={() => {
            setshowMore(pre => !pre)
          }}>
          {showMore ? '收起' : '展开'}
        </Button>
      )}
    </div>
  )
}

type FinanceInfoDto = NonNullable<SpContentData<'Enterprise'>['financeInfos']>
const FinancenInfo = (props: { data: FinanceInfoDto; prevData?: FinanceInfoDto }) => {
  const { data, prevData } = props

  const financeChecked = useRCComputed(() => {
    // 如果没有财务状况缺失原因，就默认需要上传该信息
    return !data.financeAbsenceReason ? bizEnums.StatusBooleanEnum.YES : bizEnums.StatusBooleanEnum.NO
  }, [data])

  type T = NonNullable<FinanceInfoDto['financeInfos']>
  const computeFinance = (data: T = [], prevData?: T) => {
    return computeDiffDataRecords({
      data,
      prevData,
      mapping: data => {
        const renderValue = createDiffDataRender(data)
        return {
          year: renderValue({ value: v => v.year }),
          income: renderValue({ value: v => (v.income !== undefined ? `${v.currency} ${v.income}` : '-') }),
          profit: renderValue({ value: v => (v.profit !== undefined ? `${v.currency} ${v.profit}` : '-') }),
          paidUpCapital: renderValue({
            value: v => (v.paidUpCapital !== undefined ? `${v.currency} ${v.paidUpCapital}` : '-'),
          }),
          totalAssets: renderValue({
            value: v => (v.totalAssets !== undefined ? `${v.currency} ${v.totalAssets}` : '-'),
          }),
          annualAuditReportFile: renderValue({
            value: v => v.annualAuditReportFile,
            render: v => v && <SupplierAttachments attachments={[v]} />,
          }),
          financialStatementFile: renderValue({
            value: v => v.financialStatementFile,
            render: v => v && <SupplierAttachments attachments={[v]} />,
          }),
          remark: renderValue({ value: v => v.remark, paragraph: true }),
        }
      },
      predicate: (a, b) => a.id === b.id,
    })
  }

  const financen = useRCComputed(() => {
    return computeFinance(data.financeInfos, prevData?.financeInfos)
  }, [data])

  const computeFinanceAbsenceReason = (current: typeof data, prev?: typeof data) => {
    return computeDiffDataFields({
      data: { current, prev },
      fields: [{ label: '财务状况缺失原因', value: v => v.financeAbsenceReason, w100: true }],
    })
  }

  const financeAbsenceReason = useRCComputed(() => {
    return computeFinanceAbsenceReason(data, prevData)
  }, [data])

  const renderDiffTitle = () => {
    const diffTitleProps = { title: I18N.auto.caiWuZhuangKuang, tip: <FinanceDescription /> }
    if (!prevData) return <SupplierBlockTitle {...diffTitleProps} />

    const prevFinanceChecked = !prevData?.financeAbsenceReason
      ? bizEnums.StatusBooleanEnum.YES
      : bizEnums.StatusBooleanEnum.NO

    // 如果前后两次都是同样的数据类型，则按照各自的类型展示
    if (financeChecked === prevFinanceChecked) {
      if (financeChecked === bizEnums.StatusBooleanEnum.YES) {
        return <FinancenDiffTitle {...diffTitleProps} {...financen} />
      } else {
        return <DiffDataTitle {...diffTitleProps} data={financeAbsenceReason} />
      }
    } else {
      // 如果前后两次不是同样的数据类型，则各展示各的
      if (data.financeInfos) {
        return (
          <DiffBlockTitle
            {...diffTitleProps}
            current={<FinancenTableList data={financen.data} />}
            prev={<DiffDataContent data={computeFinanceAbsenceReason(prevData)} />}
            forTable
          />
        )
      }
      if (data.financeAbsenceReason) {
        return (
          <DiffBlockTitle
            {...diffTitleProps}
            current={<DiffDataContent data={financeAbsenceReason} />}
            prev={<FinancenTableList data={computeFinance(prevData.financeInfos).data} />}
            forTable
          />
        )
      }
    }
  }

  return (
    <DataPanel>
      <DataBlock bodyClassName={bem('dbBodyForTable')} title={renderDiffTitle()}>
        {financeChecked === bizEnums.StatusBooleanEnum.YES ? (
          <FinancenTableList data={financen.data} />
        ) : (
          <div className={bem('dbBodyForTableChild')}>
            <DiffDataContent data={financeAbsenceReason} />
          </div>
        )}
      </DataBlock>
    </DataPanel>
  )
}

const FinancenTableList = (props: { data: DiffDataRecord[] }) => {
  const { data } = props

  const columns = createTableColumns<DiffDataRecord>([
    {
      title: I18N.auto.nianFen,
      dataIndex: 'year',
      width: 100,
      fixed: 'left',
    },
    {
      title: I18N.auto.yingYeShouRuWan,
      dataIndex: 'income',
      width: 144,
      align: 'right',
    },
    {
      title: I18N.auto.liRunZongEWan,
      dataIndex: 'profit',
      width: 144,
      align: 'right',
    },
    {
      title: I18N.auto.shiJiaoZiBenWan,
      dataIndex: 'paidUpCapital',
      width: 144,
      align: 'right',
    },
    {
      title: '总资产（万）',
      dataIndex: 'totalAssets',
      width: 144,
      align: 'right',
    },
    {
      title: I18N.auto.nianDuShenJiBao,
      dataIndex: 'annualAuditReportFile',
      width: 187,
    },
    {
      title: I18N.auto.caiWuBaoBiao,
      dataIndex: 'financialStatementFile',
      width: 187,
    },
    {
      title: I18N.auto.beiZhu,
      dataIndex: 'remark',
      width: 144,
    },
  ])

  return <SupplierTable columns={columns} data={data} />
}

const FinancenDiffTitle = (props: {
  title: string
  tip?: ReactNode
  diff: boolean
  diffData?: DiffDataRecord[]
  prevData?: DiffDataRecord[]
}) => {
  const { title, tip, diff, diffData = [], prevData = [] } = props

  if (!diff) return <SupplierBlockTitle title={title} tip={tip} />

  return (
    <DiffBlockTitle
      title={title}
      tip={tip}
      current={<FinancenTableList data={diffData} />}
      prev={<FinancenTableList data={prevData} />}
      forTable
    />
  )
}

type TaxInfoDto = NonNullable<SpContentData<'Enterprise'>['taxInfos']>[number]
const TaxInfo = (props: { data: TaxInfoDto[]; prevData?: TaxInfoDto[] }) => {
  const { data, prevData } = props

  const tax = useRCComputed(() => {
    return computeDiffDataRecords({
      data,
      prevData,
      mapping: data => {
        const renderValue = createDiffDataRender(data)
        return {
          taxType: renderValue({ value: v => bizEnums.SpTaxTypeEnum.pick(v.taxType!)?.name || '-' }),
          taxPayer: renderValue({ value: v => bizEnums.SpTaxPayerEnum.pick(v.taxPayer!)?.name || '-' }),
          taxAttachment: renderValue({
            value: v => v.taxAttachment,
            render: v => v && <SupplierAttachments attachments={[v]} />,
          }),
          taxRemark: renderValue({ value: v => v.taxRemark, paragraph: true }),
        }
      },
      predicate: (a, b) => a.taxType === b.taxType,
    })
  }, [data])

  return (
    <DataPanel>
      <DataBlock bodyClassName={bem('dbBodyForTable')} title={<TaxDiffTitle title={'税务信息'} {...tax} />}>
        <TaxTableList data={tax.data} />
      </DataBlock>
    </DataPanel>
  )
}

const TaxTableList = (props: { data: DiffDataRecord[] }) => {
  const { data } = props

  type T = Parameters<typeof createTableColumns<DiffDataRecord>>[0]
  const columns = createTableColumns<DiffDataRecord>([
    {
      title: '税表类型',
      dataIndex: 'taxType',
      width: 260,
      fixed: 'left',
    },
    {
      title: '纳税主体',
      dataIndex: 'taxPayer',
      width: 260,
    },
    {
      title: '附件',
      dataIndex: 'taxAttachment',
      width: 196,
    },
    {
      title: '备注',
      dataIndex: 'taxRemark',
      width: 402,
    },
  ])

  return <SupplierTable columns={columns} data={data} />
}

const TaxDiffTitle = (props: {
  title: string
  diff: boolean
  diffData?: DiffDataRecord[]
  prevData?: DiffDataRecord[]
}) => {
  const { title, diff, diffData = [], prevData = [] } = props

  if (!diff) return <>{title}</>

  return (
    <DiffBlockTitle
      title={title}
      current={<TaxTableList data={diffData} />}
      prev={<TaxTableList data={prevData} />}
      forTable
    />
  )
}

type SuccessCaseDto = NonNullable<SpContentData<'Enterprise'>['successCases']>[number]
const SuccessCasesInfo = (props: { data: SuccessCaseDto[]; prevData?: SuccessCaseDto[] }) => {
  const { data, prevData } = props
  const [viewState, setViewState] = useMultiState({ visible: false, curData: {} as SuccessCaseDto })
  const { visible, curData } = viewState

  const successCase = useRCComputed(() => {
    return computeDiffDataRecords({
      data,
      prevData,
      mapping: data => {
        const renderValue = createDiffDataRender(data)
        return {
          name: renderValue({ value: v => v.name }),
          customerName: renderValue({ value: v => v.customerName }),
          serviceScope: renderValue({ value: v => v.serviceScope, paragraph: true }),
          projectCapitalAmount: renderValue({ value: v => v.projectCapitalAmount }),
          projectTime: renderValue({ value: v => formatDateTime(v.projectTime, 'YYYY-MM-DD') }),
          introduction: renderValue({ value: v => v.introduction, paragraph: true }),
          achievement: renderValue({ value: v => v.achievement, paragraph: true }),
          contactName: renderValue({ value: v => v.contactName }),
          contactPost: renderValue({ value: v => v.contactPost }),
          contact: renderValue({ value: v => v.contact }),
          remark: renderValue({ value: v => v.remark, paragraph: true }),
          recommendationFile: renderValue({
            value: v => v.recommendationFile,
            render: v => v && <SupplierAttachments attachments={[v]} />,
          }),
          projectFile: renderValue({
            value: v => v.projectFile,
            render: v => v && <SupplierAttachments attachments={[v]} />,
          }),
        }
      },
      predicate: (a, b) => a.id === b.id,
    })
  }, [data])

  const curRenderData = useRCComputed(() => {
    return computeDiffDataFields({
      data: { current: curData, prev: prevData?.find(v => v.id === curData?.id) },
      fields: [
        { label: I18N.auto.anLiMingCheng, value: v => v?.name, w500: true },
        { label: I18N.auto.keHuMingCheng, value: v => v?.customerName, w500: true },
        { label: I18N.auto.xiangMuShiJian, value: v => formatDateTime(v?.projectTime, 'YYYY-MM-DD'), w500: true },
        { label: I18N.auto.xiangMuGuiMoWan, value: v => v?.projectCapitalAmount, w500: true },
        { label: I18N.auto.xiangMuJianJie, value: v => v?.introduction, w100: true },
        { label: I18N.auto.xiangMuChengGuo, value: v => v?.achievement, w100: true },
        { label: I18N.auto.lianXiRenXingMing, value: v => v?.contactName, w500: true },
        { label: I18N.auto.lianXiRenZhiWei, value: v => v?.contactPost, w500: true },
        { label: I18N.auto.lianXiFangShi, value: v => v?.contact, w500: true },
        { label: I18N.auto.beiZhu, value: v => v?.remark, w100: true },
        {
          label: I18N.auto.keHuPingJiaTui,
          value: v => v?.recommendationFile,
          render: v => v && <SupplierAttachments attachments={[v]} />,
          w100: true,
        },
        {
          label: I18N.auto.heTongZhengMingHuo,
          value: v => v?.projectFile,
          render: v => v && <SupplierAttachments attachments={[v]} />,
          w100: true,
        },
      ],
    })
  }, [curData])

  return (
    <DataPanel>
      <DataBlock
        bodyClassName={bem('dbBodyForTable')}
        title={<SuccessCaseDiffTitle title={I18N.auto.chengGongAnLi} {...successCase} />}>
        <SuccessCaseTableList
          data={successCase.data}
          onClickView={idx => setViewState({ visible: true, curData: data[idx] })}
        />
      </DataBlock>
      <Modal
        className={bem('modal', 'modal-successCases')}
        title={I18N.auto.chengGongAnLi}
        visible={visible}
        onCancel={() => setViewState({ visible: false })}
        footer={false}>
        <DataBlock>
          {curRenderData.map((v, i) => (
            <SupplierDataItem key={i} {...v} />
          ))}
        </DataBlock>
      </Modal>
    </DataPanel>
  )
}

const SuccessCaseTableList = (props: {
  data: DiffDataRecord[]
  forDrawer?: boolean
  onClickView?: (idx: number) => void
}) => {
  const { data, forDrawer, onClickView } = props

  type T = Parameters<typeof createTableColumns<DiffDataRecord>>[0]
  const columns = createTableColumns<DiffDataRecord>([
    {
      title: I18N.auto.anLiMingCheng,
      dataIndex: 'name',
      width: 180,
      fixed: 'left',
    },
    {
      title: I18N.auto.keHuMingCheng,
      dataIndex: 'customerName',
      width: 123,
    },
    {
      title: I18N.auto.xiangMuGuiMoWan,
      dataIndex: 'projectCapitalAmount',
      width: 150,
      align: 'right',
    },
    {
      title: I18N.auto.xiangMuShiJian,
      dataIndex: 'projectTime',
      width: 135,
    },
    {
      title: I18N.auto.xiangMuJianJie,
      dataIndex: 'introduction',
      width: 157,
    },
    {
      title: I18N.auto.xiangMuChengGuo,
      dataIndex: 'achievement',
      width: 141,
    },
    ...((forDrawer
      ? [
          {
            title: I18N.auto.lianXiRenXingMing,
            dataIndex: 'contactName',
            width: 123,
          },
          {
            title: I18N.auto.lianXiRenZhiWei,
            dataIndex: 'contactPost',
            width: 123,
          },
          {
            title: I18N.auto.lianXiFangShi,
            dataIndex: 'contact',
            width: 123,
          },
          {
            title: I18N.auto.beiZhu,
            dataIndex: 'remark',
            width: 144,
          },
          {
            title: I18N.auto.keHuPingJiaTui,
            dataIndex: 'recommendationFile',
            width: 187,
          },
          {
            title: I18N.auto.heTongZhengMingHuo,
            dataIndex: 'projectFile',
            width: 187,
          },
        ]
      : [
          {
            title: I18N.auto.caoZuo,
            dataIndex: 'operate',
            width: 60,
            fixed: 'right',
            render: (_, record, idx) => (
              <Button type="text" onClick={() => onClickView?.(idx)}>
                {I18N.auto.chaKan}
              </Button>
            ),
          },
        ]) as T),
  ])

  return <SupplierTable columns={columns} data={data} />
}

const SuccessCaseDiffTitle = (props: {
  title: string
  diff: boolean
  diffData?: DiffDataRecord[]
  prevData?: DiffDataRecord[]
}) => {
  const { title, diff, diffData = [], prevData = [] } = props

  if (!diff) return <>{title}</>

  return (
    <DiffBlockTitle
      title={title}
      current={<SuccessCaseTableList data={diffData} forDrawer />}
      prev={<SuccessCaseTableList data={prevData} forDrawer />}
      forTable
    />
  )
}

type InterestRelationDto = NonNullable<SpContentData<'Enterprise'>['interestRelations']>[number]
const InterestRelationsInfo = (props: { data: InterestRelationDto[]; prevData?: InterestRelationDto[] }) => {
  const { data, prevData } = props

  const relations = useRCComputed(() => {
    return computeDiffDataRecords({
      data,
      prevData,
      mapping: data => {
        const renderValue = createDiffDataRender(data)
        return {
          name: renderValue({ value: v => v.name }),
          company: renderValue({ value: v => v.company }),
          jobNumber: renderValue({ value: v => v.jobNumber }),
          mail: renderValue({ value: v => v.mail }),
          relation: renderValue({ value: v => v.relation }),
          remark: renderValue({ value: v => v.remark, paragraph: true }),
        }
      },
      predicate: (a, b) => a.id === b.id,
    })
  }, [data])

  return (
    <DataPanel>
      <DataBlock
        bodyClassName={bem('dbBodyForTable')}
        title={<InterestRelationsDiffTitle title={I18N.auto.liYiGuanXiShen} {...relations} />}>
        <InterestRelationsTableList data={relations.data} />
      </DataBlock>
    </DataPanel>
  )
}

const InterestRelationsTableList = (props: { data: DiffDataRecord[] }) => {
  const { data } = props

  const columns = createTableColumns<DiffDataRecord>([
    {
      title: I18N.auto.yuanGongXingMing,
      dataIndex: 'name',
      width: 128,
      fixed: 'left',
    },
    {
      title: I18N.auto.yuanGongSuoZaiGong,
      dataIndex: 'company',
      width: 241,
    },
    {
      title: I18N.auto.gongHao,
      dataIndex: 'jobNumber',
      width: 144,
    },
    {
      title: I18N.auto.youXiang,
      dataIndex: 'mail',
      width: 238,
    },
    {
      title: I18N.auto.guanXi,
      dataIndex: 'relation',
      width: 202,
    },
    {
      title: I18N.auto.beiZhu,
      dataIndex: 'remark',
      width: 171,
    },
  ])

  return <SupplierTable columns={columns} data={data} />
}

const InterestRelationsDiffTitle = (props: {
  title: string
  diff: boolean
  diffData?: DiffDataRecord[]
  prevData?: DiffDataRecord[]
}) => {
  const { title, diff, diffData = [], prevData = [] } = props

  if (!diff) return <>{title}</>

  return (
    <DiffBlockTitle
      title={title}
      current={<InterestRelationsTableList data={diffData} />}
      prev={<InterestRelationsTableList data={prevData} />}
      forTable
    />
  )
}
