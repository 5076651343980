export default {
  weiGuanLianZhangDan: 'Without linked bills',
  yiGuanLianZhangDan: 'With linked bills',
  dingDanFaHuo: 'PO Shippment',
  xuQiuDan: 'Requisition',
  ziCaiYuanGongCe: 'Self-Procurement Employee Portal ASN Detail Extension',
  guoNeiBuMenZi: 'Department Self-Procurement Request (Domestic)',
  xiangMuFenBao: 'Project Subcontracting',
  fanHuiXiuGai: 'Edit',
  yiXiaLIN: 'Some line items have incomplete award statuses. Please review before proceeding.',
  jiaoFuXinXi: 'Order Delivery ',
  shiFouGuanLianZhang: 'Link to Bills',
  guanLianZhangDan: 'Linked Bills',
  weiQuanBuDingBiao: 'Reason for Incomplete Award',
  caoZuoShiJian_2: 'Action Time:',
  keDiShuiJinE_2: 'Deductible Tax Amount cannot exceed Tax Payable',
  shuiEBuKeDa: 'Tax Amount cannot exceed Tax-Exclusive Amount',
  pingMuSuoFangShe: 'Display Scaling',
  shiFouDiKouShui: 'Apply Tax Deduction?',
  hanShuiJinEBu: 'Amount (Tax Incl.) = Amount excluding tax + Tax + Shipping + Other expenses',
  gaiJinEJiangZi: 'Auto-calculated',
  keDiShuiJinE: 'Deductible Tax Amount',
  qiTaFeiYong: 'Other Expenses',
  tiJiaoShiBai: 'Submission Failed',
  yiXiaGongYingShang:
    'Following vendors meet either "Total awarded amount (past 12 months) exceeding CNY 500,000" or "Current awarded amount exceeding CNY 500,000". Please provide vendor financial status.',
  jinYiNianLeiJi: 'Awarded Bid Amount (Past 12M)',
  qianWangTianJia: 'Add Now ',
  qianWangShengJi: 'Upgrade ',
  gongYingShangShangWei: "Vendor's director/supervisor/executive information and financial status are empty",
  shenPiXinXi: 'Request Info',
  shenPiShiFouTong: 'Approve Vendor Admission ',
  qingXuanZeHeZuo: 'Please select cooperation status',
  liYiChongTuShen: 'Conflict of Interest Review',
  guanLianLiuCheng: 'Related Request',
  zhunRuYuanYin: 'Reasons for Admission ',
  liChongGuanXi: 'Conflict of Interest Detected',
  dongJianGaoXinXi:
    "Potential conflict of interest (COI) detected in vendor's leadership profiles. A COI review will be initiated. Partnership is contingent on approval; failure will result in collaboration disqualification.",
  qingRenZhenTianXie:
    'Please provide complete and accurate details. This field will be presented to PCC & Business Group Head during COI review.',
  jiXuFaQiShen: 'Summit Request',
  qingShuRuWeiShu: 'Enter 18-digit number or 17 digits + X',
  gouXuanHouYiTian: 'Check to clear all entered data',
  dongJianGaoXinXi_2: 'Director/Supervisor/Executive Info',
  shiFouShangChuanDong: 'Upload Director/Supervisor/Executive Info',
  dongJianGaoQueShi: 'Reason for Omission',
  yiCunZaiFaDing: 'Duplicate legal representative. Please update existing entry before submission.',
  yiCunZaiXiangTong_8: 'Duplicate profile detected. Please update existing entry before submission.',
  tianJiaRenYuanXin: 'Add Vendor Leadership Info',
  vALRenYuan: '{val1} Vendor Leadership Info',
  shiFouShangChuanCai: 'Upload Financial Status',
  caiWuZhuangKuangQue: 'Reason for Omission',
  zongZiChanWan: 'Total Assets (10K)',
  qingGenJuYiXia:
    'Please complete the information of directors, supervisors, and executives according to the following requirements. The company will conduct compliance review and any inaccurate details may impact vendor partnership eligibility.',
  tianXieFanWeiDong: '1. Required positions: Directors, supervisors, and executives (e.g. CEO, CFO, COO) ;',
  xinXiChaXunZhi: '2. Verification steps:',
  youXianTongGuo: 'Preferred method: ',
  guoJiaQiYeXin: 'Access [National Enterprise Credit Information Publicity System] ',
  jiChuXinXiZhu: '> [Basic Information] > [Key Personnel Information], then fill in disclosed information ',
  ruoShiJiRenYuan:
    'If registry data is outdated (e.g. recent personnel changes), please submit the latest information.',
  laiYuanFang: 'Data Source',
  shiBieDaoYouWei:
    'Unreported director/supervisor/executive information detected. Please verify with vendor to complete filing.',
  anJiTuanZhiDu:
    'In accordance with the stipulations set forth by Group policy, vendors are required to submit comprehensive financial documentation for the preceding two fiscal years, including the balance sheet, income statement, and cash flow statement.',
  ruoCaiWuBaoGao: "1. Submit full audit report (affix CPA firm's stamp);",
  ruoBaoGaoWeiJing: '2. For unaudited statements, please provide original, unaltered copies (affix corporate seal);',
  qingAnYiXiaYao:
    '3. Please provide the following data in strict compliance with financial statements. Any discrepancies require explanation letter with corporate seal; ',
  yingYeShouRuZhi:
    'Revenue: Refers to the "Revenue" line in the annual Income Statement. Directly apply the amount stated in the said Statement without amendments. ',
  liRunZongEZhi:
    'Total Profit: Refers to the "Total Profit" line (tax included profit). Generally apply the amount stated in the said Statement without amendments. ',
  shiJiaoZiBenZhi:
    'Paid-in Capital: Refers to the total capital actually contributed by shareholders to the company (not "Registered Capital"); please refer to the "Paid-in Capital" line in the Balance Sheet or the actual paid amount in the capital verification report.  ',
  zongZiChanZhiZi:
    'Total Assets: Refers to the "Total Assets" item in the Balance Sheet. Directly apply the amount stated in the Balance Sheet without amendments. ',
  shiBieDaoYouWei_2: 'Unreported financial status detected. Please verify with vendor to complete filing.',
  gongYingShangLiYi: 'Vendor Conflict of Interest',
  dongShi: 'Director',
  zongJingLi: '(General) Manager ',
  jianShi: 'Supervisor ',
  caiWuFuZeRen: 'CFO',
  shiFouJiXuFu: 'Continue payment',
  shengYu: 'Bal.',
  qingTianXieXuQiu: 'Brief request Dept. & content',
  baoJiaDanJiaHan: 'Quotation unit price (tax incl.)',
  cangKuMingCheng: 'Warehouse',
  cangKuCOD: 'Warehouse Code',
  tianJiaShouHuoRen: 'Add Recipients',
  souSuoShouHuoRen: 'Search recipient / warehouse',
  yiXiaXiaoJinE_3:
    'Following small-sum vendors meet either "Total awarded amount (past 6 months) nearing CNY 100,000" or "Total awarded amount (past 6 months) exceeding CNY 100,000". Submit required materials to upgrade vendor status.',
  gongYingShangMingCheng_2: 'Vendor name or abbreviation / Unified Social Credit Code / TIN / VMDM ID',
  shouXuanFuKuanFang: 'Preferred Payment Type',
  yinHangID: 'Bank ID',
  zhiHangID: 'Branch ID',
  shuiWuXinXi: 'Tax Info',
  shuiBiaoLeiXing: 'Tax Form Type',
  naShuiZhuTi: 'Tax Entity',
  yiCunZaiXiangTong_7: 'Duplicate tax information found. Please revise and resubmit.',
  tianJiaShuiWuXin: 'Add Tax Info',
  vALShuiWu: '{val1} Tax Info',
  yiZhanShangPei: 'Yi Zhan (dormitory)/Non-canteen catering coupon',
  buMenYongChe: 'Department Vehicle ',
  buMenYongCheCai: 'Department Vehicle Purchase',
  shiWu: 'Physical',
  gongYingShangShengJi: 'Vendor Upgrade Reminder',
  yiXiaXiaoJinE:
    'Following small-sum vendors meet either "Total awarded amount (past 6 months) exceeding CNY 100,000" or "Current awarded amount exceeding CNY 50,000". Submit required materials to upgrade vendor status.',
  jinBanNianLeiJi: 'Total Awarded Amount (Past 6 Months)',
  benCiDingBiaoJin: 'Current Awarded Amount (This Time)',
  shengJi: 'Upgrade',
  yiXiaXiaoJinE_2:
    '`Following small-sum vendors meet either "Total awarded amount (past 6 months) nearing CNY 100,000" or "Total awarded amount (past 6 months) exceeding CNY 100,000". Submit required materials to upgrade vendor status.`',
  jiXuFaSongXun: 'Send Inquiry',
  jiXuFaSongZhao: 'Send Tender',
  huiZongJinEHan_2: 'Sum (Tax Incl.):',
  huiZongJinEHan: 'Sum (Tax Incl.) ',
  heTongYuEBu_2: 'The contract balance is insufficient. Selection is not allowed.',
  qiTaGongYingShang: 'Additional Vendor(s)',
  fangQiBaoJia: 'Withdraw quotation',
  tiJiaoHouJiangJin_3: 'It will proceed to the award approval phase upon submission',
  xuQiuXingYiBei: 'PR line has been occupied by different RFx. Please check.',
  pinLeiWei: 'Category: {val1}',
  canKaoJiaHeJi_3: 'Total Ref. Price: {val1} {val2}',
  xiaDanFangShiWei: 'Order Type: {val1}',
  fenPeiJiCaiGou: 'Assign to buyer',
  congCaiGouYuan: 'From buyer',
  qingXianXuanZeXia: 'Please select the category',
  qingShuRuDingDan_2: 'PO No./PR No./Item Name',
  mingCheng: 'Name:',
  oADanHaoWu_2: 'PR No./Delivery No./Shippment No./Item Name',
  oADanHaoShou_2: 'PR No./Receipt No./PO No./Accrual No./Item Name',
  fenPei: 'Allocation',
  yanShouShangPinFu: 'Receipt Goods/Services:',
  yanShouDian: 'Receipt Line Items',
  yanShouYaoQiuMing: 'Detailed Receipt Requirements ({val1})',
  piLiangYanShouQue: 'Confirm Selected',
  queRenXiangGuanYan: 'Confirmed. Receipt documents uploaded (if any)',
  yanShouYaoQiuMing_2: 'Detailed Receipt Requirements ({val1}/{val2})',
  zaiYouCeGouXuan: 'Please check "Confirm Receipt" on the right to approve, leave it unchecked to disapprove.',
  weiWanZhengYanShou: 'Reason for Incomplete Receipt',
  ruWeiQuanBuQue: 'Please enter the reason for incomplete receipt',
  yanShouQueRenJie: 'Receipt Confirmation',
  beiZhuXinXiDui: 'Description (Internal)',
  dingDanHangBeiZhu: 'Line Note (Send to vendors)',
  beiZhuXinXiFa: 'Description (Send to vendors)',
  dingDanHangFuJian: 'Line Attachments (Send to vendors)',
  fuJianDuiNei: 'Attachments (Internal)',
  fuJianFaGeiGong: 'Attachments (Send to vendors)',
  yanShouPingZheng: 'Proof of Receipt',
  heTongYiDaoQi: 'The contract has expired. Please confirm with the vendor about order fulfillment.',
  jiXuTiJiao: 'Submit ',
  beiZhuDuiNei: 'Note (Internal)',
  beiZhuFaGeiGong: 'Note (Send to vendors)',
  qingShuRuJieSuan_2: 'Settlement Doc No. / PO No. / PR No. / Payment Doc No. ',
  yiQueRen: 'Confirmed',
  bangZhuFanKui: 'Help\nCenter',
  bangZhunZhongXin: 'Help Center',
  dianShangZiDingDan: 'Punchout PO Line',
  dianShangFuDingDan: 'Punchout PO',
  boHuiXinXi: 'Rejection Details',
  jiangTongBuZhiO: "The content will be synchronized with the OA workflow for the approvers' reference.",
  qing: 'Please',
  tiGongKaiHuXu:
    'provide the account opening certificate or other bank account documentation bearing the official seal. Please ensure that it is consistent with the bank information provided. ',
  tiGongZuiJinLiang:
    'provide financial statements and reports for the past two years (audited or reliable). If the reports are unaudited, they must be stamped with the official seal.',
  tiGongDeCaiWu:
    'The provided financial statements must align with the financial reports, where revenue equals the "Revenue" on the income statement, and total profit equals "Total Profit" on the income statement. Kindly note that paid-in capital (distinct from registered capital) refers to the total amount of contributions received from shareholders.',
  zhaoBiaoTouFuJian: 'Bidding Attachments (Send to vendor)',
  pingFenShiJian: 'Scoring Time',
  zhengDanZhongBiaoBu:
    'Bid splitting is not allowed in full award and vendors are not allowed to abandon bids by lines',
  qingShuRuYongHu: 'Enter user name/email address',
  zhiShaoTianJiaYi_2: 'Please add at least one evaluator',
  xuQiuShuLiang_2: 'Request Qty.:',
  pingFenMuBan: 'Scoring Template',
  cunZaiLIN:
    'Line {val1} regarding [{val2}] did not invite the vendor for bidding. Please either remove Line {val3} or send an invitation to the vendor and resubmit.  ',
  gongYingShangWuYou: 'Line item under vendor [{val1}] is empty. Please make necessary adjustments and resubmit.',
  jinRuShangWuPing: 'Commercial Eval.',
  pingGuWeiQuanBu: 'The evaluation has not been fully completed.',
  queDingYaoJinRu_2: 'Enter the commercial evaluation now?',
  queRenHouJiShu: 'Once confirmed, the technical bid will be sealed and the bid will enter commercial evaluation',
  pingGuJieShuTi: 'Evaluation completed. It will proceed to the pricing stage upon submission',
  xuXianWanChengJi: 'Please complete technical bid evaluation first.',
  jiShuBiaoKaiBiao_2: 'Time of Technical Bid Opening',
  shangWuBiaoKaiBiao_2: 'Time of Commercial Bid Opening',
  tianJiaChengGong: 'Added',
  tianJiaPingGuCheng: 'Add Evaluator',
  pingGuChengYuan: 'Evaluator',
  tianJiaYuanYin: 'Reason for adding',
  yiChuChengGong: 'Removed',
  yiChuPingGuCheng: 'Remove Evaluator',
  yiChuYuanYin: 'Reason for removal',
  caoZuoNeiRong: 'Operations',
  caoZuoYuanYin: 'Reasons',
  bianGengLiShi: 'Change History',
  zanWeiTianJiaPing_2: 'Evaluation committee members have not been added yet',
  quXiaoYaoBiao: 'Cancel tender',
  huiFu: 'Recover',
  jiShuBiaoXiaoZu: 'The evaluation committee members of technical bid have not been set yet',
  shangWuBiaoXiaoZu: 'The evaluation committee members of commercial bid have not been set yet',
  queDingJinRuPing: 'Enter the bid evaluation phase now?',
  jinRuPingBiaoHou: 'The evaluation team will receive an invitation email after entering the bid evaluation phase',
  kaiShiShangWuPing: 'Start Commercial Eval.',
  kaiShiJiShuPing: 'Start Technical Eval.',
  pingGuZhong: 'Evaluating',
  pRShenPiZhong_3: 'PR Approving/Evaluating',
  pRYiShenPi_3: 'PR Approved/Evaluating',
  weiKaiShiPingGu: 'Awaiting Evaluation',
  jiShuBiaoPingGu: 'Under Technical Bid Evaluation',
  jiShuMiFengZhong: 'Technical Bid Sealed  ',
  shangWuBiaoPingGu: 'Under Commercial Bid Evaluation',
  xunYuanLeiXing: 'Sourcing Type',
  gengDuo: 'More',
  jieShouRen: 'Recipient',
  yiWanChengYanShou: 'Receipt complete, you may review the receipt records',
  xunYuanDanHao: 'Sourcing Doc No.',
  xunYuanDanChuangJian: 'Created Time',
  qingShuRuXunYuan: 'Sourcing Doc No./PO No.',
  qingShuRuXunYuan_2: 'Sourcing Doc No./PO No./Item Name',
  xunBiJiaJingJia: 'RFQ - Competitive Bidding',
  zhaoTouBiaoJingJia: 'Bidding and Tendering - Competitive Bidding',
  congTiaoZhengWei: 'is adjusted from {val1} to {val2}',
  chengQingDaoJiShi: 'Countdown of Clarification:',
  queDingJieShuCheng: 'Confirm to terminate the clarification?',
  jieShuChengQingHou:
    'Once the clarification period terminates, the vendor will be unable to submit any further quotations.',
  jieShuChengQing: 'Terminate',
  baoJiaZhuangTai: 'Quotation',
  chengQingZhuangTai: 'Clarification',
  xinZengChengQingGong: 'Vendor for Clarification',
  baoJiaChengQing: 'Clarification',
  xuanZeChengQingGong: 'Select vendors for clarification',
  faSongChengQing: 'Send',
  chengQingQueRen: 'Confirmation',
  tiJiaoHouJiangXiang: 'An invitation email will be sent to the vendor upon submission',
  chengQingJieZhiShi: 'Deadline of clarification',
  qingXuanZeRiQi: 'Select the date and time',
  chengQingYuanYin: 'Reason for clarification',
  xiangQing: 'Details',
  baoJiaChengQingJi: 'Clarification Records',
  chengQingFaQiShi: 'Clarification initiated on: {val1}',
  chengQingXiangQing: 'Clarification Details',
  weiChengQing: 'Unclarified',
  zhouShengShi: 'State/Province/City',
  bianWei: '{val1} {val2} → {val3}',
  weiShuiBaoJia: 'Quotation (Tax Excl.):',
  hanShuiBaoJia: 'Quotation (Tax Incl.):',
  faPiaoLeiXing_2: 'Invoice Type:',
  bianWei_2: '→',
  shuiLu_2: 'Tax Rate:',
  daiShenPi: 'Pending',
  queDingTiJiaoBao: 'Confirm to save and submit?',
  yiXiaYongHuWei: 'These users have not completed procurement compliance certification:',
  shuRuYouXiangHou: 'Auto retrieve upon email entry',
  zhengZaiHuoQu: 'Retrieving',
  yiRenZheng: 'Certified   ',
  weiRenZheng: 'Uncertified',
  gaiYongHuWeiWan: 'This user has not completed procurement compliance certification',
  heGuiRenZhengZhuang: 'Compliance certification status',
  queRenChongXinFa: 'Confirm to resend the notification?',
  yanShouTongZhi: 'Receipt Notification',
  gongYingShangZhaoHui: 'Recreate Admin Account',
  boHuiZhuCe: 'Reject Registration',
  boHuiYuanYin: 'Reason',
  qingShuRuBoHui: 'Enter the reason for rejection',
  queDingShenPiTong: 'Confirm the approval?',
  shenPiTongGuoHou:
    "Upon approval, the vendor's admin account will be updated to the current account, while the original admin account will be downgraded to a sub account and deactivated.",
  yaoQingXinXi: 'Invitation Info',
  xinGuanLiYuanYou: 'New admin email',
  yaoQingShiJian: 'Invitation time',
  zhuCeXinXi: 'Registration Info',
  zhuCeYouXiang: 'Registered email',
  wangYiCeDuiJie: "NetEase buyer's name",
  wangYiCeDuiJie_2: "NetEase buyer's email",
  zhuCeShuoMing: 'Description',
  gongYingShangChuangJian: 'Create Vendor Account',
  zuHuPeiZhi: 'Tenant Settings',
  zuHu: 'Tenants',
  xiTongSheZhi: 'Settings',
  chaKanCaoZuoJi: 'Action Records',
  gengGaiJiLu: 'Change Records',
  gengGai: 'Changed',
  zhiShaoTianJiaYi: 'Add at least one admin user',
  bianJiChengGong: 'Saved',
  xinZengZuHu: 'Add Tenant',
  bianJiZuHu: 'Edit Tenant',
  zuHuMingCheng: 'Tenant Name',
  zuHuCOD: 'Tenant Code',
  oALaiYuan: 'OA Data Source',
  zuHuShuoMing: 'Description',
  zuHuGuanLiYuan: 'Tenant Admin.',
  qingShuRuZuHu: 'Enter the tenant name/tenant code',
  qingNinBuChongYin: 'Please provide the province and city information in the bank account details',
  ziGuanRuKu: 'Inbound - Asset Manager',
  yanShouShenPi: 'Receipt Approval',
  yuanGongYanShou: 'Receipt - End User',
  jinZhanXiangQing: 'Progress Details',
  yanShouJinZhanXiang: 'Receipt Progress',
  daiYanShouJinE_5: 'Item Value Pending Receipt (Tax Incl.)',
  ziGuan: 'Asset Manager',
  daiZhiXingRen: 'Performer',
  yanShouJinZhan: 'Progress',
  jiZhunJia: 'Standard Price',
  weiShuiJinE: 'Amount(Tax Excl.)',
  keFaHuoJinE_2: 'Shipable Amount(Tax Excl.)',
  keFaHuoJinE_3: 'Shipable Amount(Tax Incl.)',
  benCiFaHuoJin_2: 'Shipping Amount(Tax Excl.)',
  benCiFaHuoJin_3: 'Shipping Amount(Tax Incl.)',
  xuQiuJiZhunJia: 'Standard Price of PR',
  dingBiaoZongEWei: 'Awarded Amount (Tax Excl.)',
  dingBiaoZongEHan: 'Awarded Amount (Tax Incl.)',
  daiYanShouJinE_3: 'Item Value Pending Receipt (Tax Excl.)',
  daiYanShouJinE_4: 'Item Value Pending Receipt (Tax Incl.)',
  benCiYanShouJin_4: 'Receiving Amount (Tax Excl.)',
  benCiYanShouJin_5: 'Receiving Amount (Tax Incl.)',
  weiShui_2: '(Tax Excl.)',
  hanShui_2: '(Tax Incl.)',
  benCiYanShouJin_6: 'Receiving Amount(Tax Excl.):',
  weiShui_3: '{val1}(Tax Excl.)',
  hanShui_3: '{val1}(Tax Incl.)',
  xuQiuDanXiaHai: 'Below items can be received together',
  xiaDanJinEWei: 'Ordered Amount(Tax Excl.)',
  xiaDanJinEHan: 'Ordered Amount(Tax Incl.)',
  daiKaiBiao: 'Award Pending',
  yanShouJinEWei: 'Receipt Amount(Tax Excl.)',
  hanShuiDanJia: 'Unit Price(Tax Incl.)',
  guanLianZongJinE_2: 'Linked total amount(Tax Incl.)',
  shengYuKeKaiPiao_2: 'Pending invoice amount(Tax Incl.)',
  guanLianJinEHan: 'Linked amount(Tax Incl.)',
  buTongJiZhunJia: 'Can not link with different standard price',
  oaLineNoBuYiZhiBuKe: 'Can not create for dfiferent OA source',
  pingFenManFenYiBai: 'Score (out of 100)',
  queDingYaoFangQi: 'Confirm to exit the evaluation?',
  fangQiHouBuKe: "Once exited, you can't evaluate again.",
  wuLiHaiGuanXi: 'Declaration of No Conflict of Interest',
  zhaoBiaoPingShiXiao: '(Commitment Letter of Bidding Evaluation Committee)',
  benRen: 'I, the undersigned',
  zuoWeiWangYiJi: 'as an employee of NetEase Group,',
  biaoShuBianHaoZhao:
    'understand my role as a member of the bidding evaluation for tender No. [{val1}] related to the [{val2}] project,',
  zhaoTouBiaoXiaoZu: 'and I hereby make this declaration in good faith.',
  yiZunShouZhongHua:
    "I. The bid evaluation activities shall comply with the provisions of the Government Procurement Law of the People's Republic of China",
  wuLiHaiGuanXi_2: 'and  I affirm that I have no conflicts of interest with the vendors involved in this project.  ',
  erZaiPingShenGuo: 'II. Throughout the evaluation process, all bid evaluation activities shall comply with the',
  zhongHuaRenMinGong_6: "Law of the People's Republic of China on Bid Invitation and Bidding,",
  zhongHuaRenMinGong_7: "Government Procurement Law of the People's Republic of China",
  jiYouGuanFaLu:
    'and relevant regulations. I shall uphold the principles of fairness, justness, scientificity and selecting the best qualified, performing my duties earnestly, fairly and honestly, adhering to work discipline and confidentiality protocols, ensuring to carry out duties with the highest degree of objectivity.',
  yYYYNian: 'MMMM/DD/YYYY',
  queRenQianShu: 'Confirm and Sign',
  fangQiPingGu: 'Exit Evaluation',
  zaiCiFaSong: 'Resend',
  zaiCiFaSong_2: 'Resend ({val1})',
  qingShuRuKaiBiao: 'Enter DSC password',
  queRenKaiBiao: 'Confirm Bid Opening',
  weiShouDaoYanZheng: "Didn't receive a code?",
  jiShuBiaoMiFeng: 'Technical Bid Sealed  ',
  shangWuBiaoMiFeng: 'Commercial Bid Sealed  ',
  huoQuKaiBiaoMi: 'Obtain DSC password',
  dianJiHouKaiBiao:
    'After clicking, the password will be sent to your email automatically and will remain valid for 5 minutes.  ',
  kaiBiaoHouJiangZi:
    'Evaluation notifications will be automatically sent to the following member(s) after the bid opening.',
  pingBiaoZuYuan: 'Evaluation Committee Member(s)',
  jiShuPingGuZhong: 'In Technical Evaluation',
  zhaoBiaoShiJianHao: 'Tender Event No.',
  pinPai_3: 'Brand:',
  peiZhi_3: 'Configuration:',
  beiZhu_2: 'Note: {val1}',
  beiZhu_3: 'Note:',
  shangPinFuWuMing_2: 'Item / Service Name',
  xuQiuXingFuJian: 'PR Line Attachments',
  jiShuBiaoFuJian: 'Technical Bid Attachments',
  shangWuBiaoFuJian: 'Commercial Bid Attachments',
  baoJiaShiJianU: 'Quotation Time (UTC+08:00)',
  touBiaoLieBiao: 'Bid Lists  ',
  zhaoBiaoXuQiuFu: 'Tender Attachments',
  pingBiaoZuZhang: 'Evaluation Committee Leader  ',
  heJiaXinXi: 'Pricing Information',
  jiangBenJinE: 'Cost Reduction',
  dingBiaoFuJian: 'Awarding Attachment',
  feiBiao: 'Nullified',
  pingFenZuiYou: 'Highest Score',
  paiMing: 'Ranking',
  zongHePingFen: 'Overall Score',
  baoJiaZongJinE: 'Total Quotation (Tax Incl.)',
  pingFenRen: 'Evaluator   ',
  pingFen: 'Score',
  pingFenFuJian: 'Scoring Attachments',
  jiShuBiao: 'Technical Bid',
  shangWuBiao: 'Commercial Bid',
  pingFenQuanZhong: 'Weight of Score:',
  mingXi: 'Details',
  pingFenMingXi: 'Scoring Details',
  zongHeFenShu: 'Overall Score',
  baoJiaWeiShui: 'Quotation (Tax Excl.)',
  baoJiaMingXi: 'Quotation Details',
  touBiaoXiangQing: 'Bid Details  ',
  weiPingFen: 'Unscored',
  yiFangQi: 'Exited',
  yiPingFen: 'Scored',
  daiChengQing: 'Unclarified',
  yiChengQing: 'Clarified',
  pingGuGuanLi: 'Evaluation Overview',
  jiShuPingFenQuan: 'Weight of Technical Score',
  shangWuPingFenQuan: 'Weight of Commercial Score',
  jiShuBiaoPingFen: 'Scoring Template for Technical Bid',
  xiaoZuChengYuan: 'Committee member(s)',
  shangWuBiaoPingFen: 'Scoring Template for Commercial Bid',
  pingBiaoChengYuanQian: 'Require all to sign the Declaration of No Conflict of Interest',
  zuoFeiZhaoBiao: 'Cancel',
  zhaoBiaoLiuChengJie: 'The tendering process is closed; vendors may no longer submit bids.',
  zuoFeiChengGong: 'Canceled',
  feiBiaoYuanYin: 'Reason for Nullified',
  zhaoTouBiaoShiJian: 'Tender Event Name  ',
  zhaoTouBiao: 'Bidding and Tendering',
  yuFuKuan: 'Pre-payment',
  heJiaTiJiaoShen: 'Pricing Submission Time',
  dingBiaoZongJinE_2: 'Total awarded amount',
  pingFenBuNengWei: 'Score cannot be empty',
  shangWuPingGuZhong: 'In Commercial Evaluation',
  touBiaoKaiShiShi: 'Bid Start Time  ',
  touBiaoJieShuShi: 'Bid End Time  ',
  jiShuBiaoKaiBiao: 'Technical Bid Opener',
  shangWuBiaoKaiBiao: 'Commercial Bid Opener',
  jiShuPingGu: 'Technical Evaluation',
  shangWuPingGu: 'Commercial Evaluation',
  jiShuPingFenQuan_2: 'Weight of Technical Score:',
  shangWuPingFenQuan_2: 'Weight of Commercial Score:',
  queDingYaoFaSong: 'Send the evaluation now?',
  pingGuTiJiaoHou: 'It will proceed to the awarding stage and cannot be modified upon submission',
  pingGu: 'Evaluation',
  faSongPingGu: 'Send',
  zanWuQuanXian: 'No Permission',
  dingBiaoZhuangTai: 'Award Status',
  caoZuoRen_4: 'User:',
  shiJian_2: 'Time:',
  yuanYin: 'Reason:',
  dingBiaoShenPiShi: 'Award Approval Time',
  shiFouFeiBiao: 'Reject',
  baoJiaZongEHan: 'Total Quotation (Tax Incl.)',
  queDingYaoJinRu: 'Enter the pricing stage now?  ',
  dangQianCunZaiWei: '{val1} member(s) have not finished scoring',
  tiJiaoHouJiangJin: 'It will proceed to the pricing stage upon submission',
  zhaoBiaoXiangQing: 'Tender Details {val1}',
  jinRuHeJia: 'Enter Pricing',
  jiShuKaiBiaoRen: 'Technical Bid Opener',
  shangWuKaiBiaoRen: 'Commercial Bid Opener',
  baoJiaJinEWei_2: 'Quotation Amount (Tax Excl.)',
  zhaoBiaoXuQiuFu_2: 'Tender Attachments (Send to vendor)',
  yingBiaoQueRenHan: 'Tender Response Letter',
  baoMiXieYi: 'Non-disclosure Agreement',
  gongYingShangXiangGuan: 'Vendor Attachments',
  shenFen: 'Role',
  pingFenZhuangTai: 'Scoring Status',
  wuFaTiJiao: 'Submission failed',
  zanWeiTianJiaPing: 'Please add a leader for the bid evaluation committee',
  queDingYaoTiJiao_2: 'Submit the tender now?',
  tiJiaoHouJiangJin_2: 'It will proceed to the bidding stage upon submission',
  chuangJianZhaoBiao: 'Create Tender',
  faSongZhaoBiao: 'Send Tender',
  zhaoBiaoXuQiuFu_3: 'Tender Attachments (Send to vendor)',
  shiJianSheZhi: 'Event Setup',
  shiQu_2: 'Time Zone',
  faSongYingBiaoQue: 'Send Tender Response Letter',
  queRenHanMuBan: 'Response Letter Template',
  faSongBaoMiXie: 'Send NDA',
  baoMiXieYiMo: 'NDA Template',
  zhongBiaoGongGaoFan: 'Bid Announcement Visibility',
  tiJiaoHouKaiShi: 'Start upon submission',
  dingBiaoTiJiaoHou_2: 'It will proceed to the approval stage upon award submission',
  dingBiaoShenQingShi: 'Award Request Time',
  shiFouDingBiao_2: 'Award',
  pingBiaoRen: 'Evaluator',
  jiShuPingBiaoZu: 'Technical Bid Evaluation Leader',
  shangWuPingBiaoZu: 'Commercial Bid Evaluation Leader',
  jinRuPingBiaoShi: 'Failed to enter evaluation',
  jiShuPingBiaoZu_2: 'Please set up the technical bid evaluation leader',
  shangWuPingBiaoZu_2: 'Please set up the commercial bid evaluation leader',
  jinRuPingBiao: 'Enter Evaluation',
  jiangYaoQingPingBiao: 'The following leaders of evaluation committee will be invited to initiate the evaluation',
  xuQiuLieBiao: 'Procurement Items',
  daiYingBiao: 'Responding',
  daiTouBiao: 'Bid Pending',
  yiTouBiao: 'Bid Submitted',
  yiQiBiao: 'Exit',
  wenTiBiaoTi: 'Question Title',
  tiChuGongYingShang: 'Vendors',
  wenTiChuangJianRen: 'Submitted by',
  wenTiTiJiaoShi: 'Submitted on',
  chengQingGongGaoBiao: 'Bidding Clarification Title',
  chengQingRen: 'Clarified by',
  chengQingGongGao: 'Bidding Clarification',
  zhaoBiao: 'Tendering',
  touBiao: 'Bidding',
  heJia: 'Pricing',
  touBiaoZhong: 'Bidding',
  yiKaiBiao: 'Opened',
  pRShenPiZhong: 'PR Approving/Bidding',
  pRYiShenPi: 'PR Approved/Bidding',
  pRShenPiZhong_2: 'PR Approving/Opened',
  pRYiShenPi_2: 'PR Approved/Opened',
  wuGongGao: 'No Announcement',
  canYuCaiGouShang: 'Participating Vendors Only',
  gongYingShangMenHu: 'All Registered Vendors',
  zuZhangFangQiPing: 'Leader exit evaluation',
  zuZhangWeiQianShu: 'The leader has not signed the Declaration of No Conflict of Interest',
  zuZhangWeiKaiBiao: 'The leader has not opened the bid',
  zuChangYiKaiBiao: 'The leader has opened the bid',
  zuYuanWeiQianShu: 'The member has not signed the Declaration of No Conflict of Interest',
  zuYuanYiQianShu: 'The member has signed the Declaration of No Conflict of Interest',
  zuYuanYiFangQi: 'The member has exited evaluation',
  zuZhang: 'Leader',
  zuYuan: 'Member',
  weiQianShuLiHai: 'Declaration of No Conflict of Interest has not been signed',
  qingShuRuBiaoTi: 'Please enter title',
  qingShuRuZiXun: 'Please enter your question',
  chengQingHuiFu: 'Clarification Reply',
  weiTianXieNeiRong: 'Please fill in the content field',
  huiFuZiXun: 'Reply to Questions',
  chengQingZiXun: 'Clarification Questions',
  ziXunXiangQing: 'Question Details',
  jiaZaiZhong: 'Loading...',
  sheZhiYunFei: 'Set freight',
  dianShangDiZhi: 'E-commerce Address',
  zhouShengDiQu: 'State / Province',
  chengShi: 'City',
  qingXuanZeRenYuan: 'Select the user',
  qingXianSouSuoZai: 'Please search before selecting',
  xinJianDianShangDi: 'Add E-commerce Address',
  bianJiDianShangDi: 'Edit E-commerce Address',
  xinJianDiZhiCheng: 'E-commerce address has been added',
  bianJiDianShangDi_2: 'E-commerce address has been saved',
  xinJianDiZhi: 'Add Address',
  dianShangPingTai: 'Platform',
  dianShangPingTaiBu: 'Platform is required',
  diZhiBianMa: 'Address Code',
  diZhiBianMaBu: 'Address code is required',
  shouHuoRenBuNeng: 'Recipient is required',
  qingShuRuZhengQue_4: 'Please enter the contact info correctly',
  fenPeiLeiXing: 'Accessible to',
  shouHuoRenXingMing: 'Recipient',
  qingShuRuDiZhi: 'Enter address code / recipient',
  yaMaXun: 'Amazon',
  daiEr: 'Dell',
  shiTaiBo: 'Staples',
  jingDong: 'JD.com',
  wuRenYuanShiYong: 'None',
  quanYuanShiYong: 'All Users',
  buFenYuanGong: 'Selected Users',
  zuiDuoShuRuGe: 'Enter up to 20 characters',
  yanShouJianJie: 'Receipt Summary',
  yanShouJinEChao:
    'If the receipt amount exceeds the limit, CEO approval is required. The [Receipt Summary, Receipt  Instruction, Receipt Voucher] will be sent for approval via POPO',
  tiJiaoZhong: 'Submitting',
  tiJiaoShenPi: 'Submit ',
  benCiYanShouJin_3: 'Receipt Amount(Tax Incl.)',
  benCiYanShouJin_2: 'Receive Amount / Pending Receipt Amount\n',
  benCiYanShouShu_2: 'Receipt Quantity / Pending Receipt ',
  qiPaiJiaWeiShuiDanJia: 'Starting price (Tax Exc.)',
  yuFuJinEXuDaYu: 'Pre-payment amount should larger than 0.',
  zhiFuFangShiWeiYuFu: 'Contract is mandatory for pre-payment.',
  tiaoZhengBuFuDanJia: 'Price Adjustment (unit price)',
  xinZengBaoJia_2: 'First Quote: {val1}',
  diYiMing: 'Top 1 ',
  diErMing: 'Top 2 ',
  diSanMing: 'Top 3 ',
  anJinE: 'By amount',
  anBiLi: 'By percentage',
  buXian: 'No limit',
  qiPaiJia: 'Starting Price',
  diYiMingJiaGe: 'Top 1 Price',
  jianGe: 'Interval ',
  fenZhong: 'min(s)',
  zuiHou: 'In the last ',
  fenZhongNeiChuXian: 'min(s), if a new leading quote appears, the deadline will be extended by ',
  yanShi: 'delay',
  wuYanShi: 'No delay',
  kaiQiZaiXianJing: 'Start Online Bidding',
  shiFouZhengDanZhong: 'Full Award',
  jingJiaMoShi: 'Bidding Model',
  tiaoJiaBuFuZheng: 'Price Adjustment (full bid)',
  tiaoJiaBuFuB: 'Price Adjustment (by line)',
  jingJiaGuiZe: 'Bidding Rules',
  baoJiaJianGeShi: 'Interval of Quotation',
  ziDongYanShiShi: 'Auto-On Delay Timer',
  gongYingShangBaoJia_2: 'Show Quotation in',
  anJinE_2: 'By amount ({val1} {val2})',
  anBiLi_2: 'By percentage ({val1} %)',
  fenZhongNeiChuXian_2: '{val1} min(s), if a new leading quote appears, the deadline will be extended by {val2} min(s)',
  baoJiaShiJian: 'Time of Quotation',
  jiaGe: 'Price ',
  gengGaiBaoJiaBian: 'Adjusted: {val1} to {val2}',
  caoZuoXing: 'Line',
  weiCanYu: 'Not joined',
  baoJiaJinEHan_2: 'Quotation Amount (Tax Incl.):',
  shiFouDingBiao: 'Award:',
  wuBaoJiaJinE: 'No Quotation ',
  zanWuGongYingShang: 'Currently no vendor ranking available',
  baoJiaLiShi: 'Quotation Records',
  gongYingShangPaiMing: 'Vendor Ranking',
  canYuGongYingShang_2: 'Vendors engaged are insufficient',
  kaiQiZaiXianJing_2: 'After starting the online bidding, there should be a minimum of two participating vendors.',
  yingShiBaoJiaFan: 'Reverse Auction',
  qianSanMingBuYun: 'The top three quotes must differ',
  suoYouBaoJiaXu: 'All quotes must exceed the leading one',
  suoYouPaiMingBu: 'All quotes in the rankings must differ',
  juJieShuHaiSheng: 'Time Remaining ',
  tiaoZhengHeTongJieSuan: 'Changing Legal Entity, Vendor, Currency, the price list will be deleted, please confirm.',
  chaiFenDingBiaoMing: 'Split Award Details',
  xingVALDing_2: 'Line {val1}: The total amount of the split award details does not equal to the award amount',
  duiWaiLiPin: 'Gifts to external party',
  banGongYongFeiI: 'Office facilities (Non IT-controlled hardware & software)',
  zhuanShou: 'Resell',
  banShuSongShen: 'Submission for ISBN approval',
  banGongYongIT: 'Office supplies - IT shared',
  banGongYongYeWu: 'Office supplies - business side shared',
  guoNeiITRuan: 'IT Hardware & Software Request and Procurement (Domestic)',
  zhuCeDiWei: 'The registration location of {val1} is {val2}',
  cunZaiDuoZhong: 'There are multiple types, {val1}',
  cunZaiDuoKaHao: '{val1} (multiple card numbers, {val2})',
  yuFuJinE_2: '{val1}(Pre-Payment Amount:',
  zhuangTaiWeiBianHua: 'Status Unchanged',
  zhuangTaiXiuGaiCheng: 'Status Changed',
  xiuGaiZhuangTai: 'Change Status',
  guanLianWangYiJi: 'Link All Companies in NetEase Group',
  yiGuanLian: 'Linked:',
  guanLianGongSi: 'Linked Companies',
  rFXXingBao: 'RFx - Line No. - Quotation',
  peiZhiGuiGe: 'Configuration / Specification',
  xuanZeRFX: 'Select RFx',
  qingShuRuRF: 'Please enter RFx number',
  ruoTianLeShuLiang:
    'If quantity is entered, the amount including tax (quantity*unit price tax incl.) will be automatically calculated. If only {val1} amount (tax incl.) is entered, the quantity will not be calculated.',
  xingHao_2: 'Model',
  shuLiangBuKeWei: 'Quantity cannot be 0',
  bianJiJiaGeQing: 'Edit Price List',
  tianJiaJiaGeQing: 'Add Price Lists',
  laiYuanXinXi: 'Source',
  mingXiXinXi: 'Details',
  jiaGeYouXiaoQi: 'Price Validity',
  xuJieChuDuiYing: 'Please unlink the related documents before editing',
  xuJieChuDuiYing_2: 'Please unlink the related documents before deleting  ',
  jiaGeQingDan: 'Price List',
  zanWuNeiRong: "It's empty",
  cRPHeTong: 'CRP Contract',
  nETBU: 'Netbuy Contract',
  heTongLeiXing: 'Contract Type',
  heTongYuE: 'Contract Balance',
  qiShiRiQi: 'Start Date',
  zhongZhiRiQi: 'End Date',
  tianJiaHeTong: 'Add Contract',
  woFangZhuTi: 'Internal Entity',
  qingShuRuHeTong: 'Please enter contract number / contract title',
  shengXiaoRiQi: 'Effective Date',
  qingXuanZeShengXiao: 'Please select effective date',
  heTongZhuangTaiFei: 'The contract status is non-effective and cannot be selected',
  biZhongBuYiZhi: 'The currency does not match and cannot be selected',
  heTongYuEBu: 'The contract balance is insufficient, please check before submitting.  ',
  dingDanHanShuiJin: 'Order Total Amount (Tax Incl.):  ',
  heTong: 'Contract',
  dangQianKeYongYu: 'Available Balance:',
  qingJianChaHouZai: 'Please check before submitting',
  jiaGeKuangJiaHe: 'Framework Agreement',
  xuXuanZeYouJia:
    'Please choose a contract that is currently effective, possesses a price list, and has a balance exceeding 0',
  xuanZeJiaGeQing: 'Select Price List',
  xiaDanFangShiLei: 'Order type does not match, please check before submitting',
  baoJiaQingDan: 'Quotation List',
  dingBiaoShuLiangBi: 'Awarded quantity must be greater than 0',
  dingBiaoJinEBi: 'Awarded amount must be greater than 0',
  shangPinFuWuMing: 'Item / Service Name',
  yiYouYunFeiBu: 'Cannot be added if freight already exists',
  chuangJian: 'Create',
  gaiHeTongYiCun: 'This contract already exists. Would you like to navigate to the details page for editing?  ',
  qieHuanHeTongBi: 'Switching the contract currency will delete the price list. Confirm the deletion?  ',
  jiaGeQingDanBu: 'Price list cannot be empty',
  chuangJianHeTong: 'Create Contract',
  qingXuanZeZhengQue:
    'Please select the internal entity/vendor/contract correctly. Modifications will clear the content below.  ',
  daoQiCaoZuo: 'Upon Expiration',
  xuQianTiXing: 'Reminder for renewal',
  buXuQian: 'No renewal',
  woFangZhuTiJie: 'Internal Entity (Legal Entity)',
  faBuRiQi: 'Issue Date',
  youXiaoQi: 'Validity',
  danJuLeiXing: 'Document Type  ',
  guanLianShiJian: 'Linked Time',
  guanLianJiaGeQing: 'Linked Price List - No.',
  guanLianJiaGeQing_2: 'Linked Price List - Item Name',
  heTongXiangQing: 'Contract Details - {val1}',
  faBuShiJian: 'Issue Time',
  heTongYuEHe: 'Contract Balance = Total contract amount - Total awarded amount  ',
  xuQian: 'Renewal',
  guanLianRFX: 'Linked RFx / PO',
  rFXShenPi_3: 'RFx Reviewing + Ordered + Uncanceled PO',
  heTongBianJi: 'Contract Editing - {val1}',
  dingDanJinEHan_2: 'PO Amount (Freight Incl.)',
  shangPin_2: 'Item: {val1}',
  jiaGeQingDanDe: 'Total awarded amount in the price list exceeds the final awarded amount',
  rFXDingBiao: 'RFx Awarded Amount:',
  dangQianKeYongYu_2: 'Available Balance',
  yiYouJiaGeQing: 'Cannot be added if price list already exists',
  kuangJiaXiaDan: 'Framework Agreement',
  daiShengXiao: 'Not in force',
  yiGuoQi: 'Expired',
  kuangJiaHeTong: 'Framework Agreement',
  danXiangMuHeTong: 'Single Project Contract   ',
  yiBanHeTong: 'General Contract',
  shouGong: 'Manual Input',
  oADanHaoDing_2: 'PR No./PO No./Delivery No./Shippment No.',
  zhiFuJinE: 'Amount Paid',
  oADanHaoFa: 'PR No./Shippment No./Receipt No./PO No./Accrual No.',
  zhiFuZhong: 'Paying ',
  yuGuJinE: 'Estimated Amount',
  shenPiJuJueYuan: 'Reasons for Rejection',
  shenQingFuKuanJin: 'There is a difference between the requested payment amount and the invoice amount',
  shenQingFuKuanJin_2: 'There is no difference between the requested payment amount and the invoice amount',
  shenQingFuKuanJin_3: 'Requested Payment Amount (Tax Incl.)',
  faPiaoJinEHan: 'Invoice Amount (Tax Incl.)',
  chaYiJinEHan: 'Difference (Tax Incl.)',
  jinEWeiShui: 'Amount (Tax Excl.)',
  faPiaoXinXi: 'Invoice Info.',
  benCiShenQingJin: 'Requested Amount (Tax Incl.)',
  fuKuanXinXi: 'Payment Info.',
  lianXingHangHao: 'CNAPS code',
  benCiShiJiFu: 'Total Paid Amount\n(Tax Incl.)',
  qiTaFuJian: 'Attachments',
  daiJiTiRuZhang: 'Not Accounted for Accruals',
  yiGuiDang: 'Archived',
  yiCheHui: 'Withdrawn',
  fuKuanDan: 'Payment Doc',
  gongYingShangBaoJia: 'Vendor quotation',
  qingShuRuShangPin: 'Please enter item name',
  heJiaCeLue: 'Pricing strategy',
  fuKuanShenQing: 'Payment Request',
  guanLianXinXi: 'Linked Info.',
  shiFouXuYongHu: 'Confirmed by the end user?',
  xuanZeShiJiangYou: 'If "yes" is selected, the end user will confirm invoice and receipt information on OA platform.',
  dingDanHaoHangHao: 'PO No. - Line No.',
  oCRShiBie: 'OCR recognition failed',
  oCRShiBie_2: 'In OCR recognition...',
  haiWaiFaPiao: 'Overseas Invoice',
  queRenHouJiangJie:
    'Delete connection will release the PO information linked to the invoice and remove the current connection. Users can re-linked the connection based on the invoice.',
  jieChuGuanLian: 'Delete Connection',
  yanZhenZhuangTai: 'Verification State',
  chuangJianFang: 'Created by',
  qingShuRuFaPiao_2: 'Please enter invoice No./PO No./Vendor',
  shangPinMingChengHuo: 'Item Name/Service Name',
  guiGeXingHao: 'Specifications',
  danWei: 'Unit',
  xinZengXing: 'Insert a line',
  shanChuXing: 'Delete the line',
  faPiaoXing: 'Invoice Lines',
  zhiHangMingCheng_2: 'Branch Name:',
  lianXingHangHao_2: 'CNAPS Code:',
  zhangHuMingCheng_2: 'Account Name:',
  yinHangZhangHao_2: 'Account No.:',
  shouXuFeiChengDan_2: 'Responsible party for handling fees:',
  xuanZeKaiHuHang: 'Select the bank where the account was opened',
  xuanZeYinHangXin: 'Select the bank account',
  chuangJianFuKuanShen: 'Create Payment Request',
  baoXiaoRen: 'Requester',
  shenQingFuKuanShuo: 'Description of Payment Request',
  danGeWenJianXiao: 'Each file should be less than 100M',
  fuKuanShenQingXiang: 'Details of Payment Request: {val1}',
  jieSuanGongSiGong:
    'Docs with different entity, vendor, contract No. and currency cannot be combined. Please confirm.',
  qingShuRuFaPiao_3: 'Please enter Invoice No./PO No.',
  jieSuanDanHao: 'Payment Doc No.',
  fuKuanJinEHan: 'Requested Amount (Tax Incl.)',
  fuKuanDanHao: 'Payment Doc No.',
  zhiFuJinEHan: 'Amount Paid  (Tax Incl.)',
  qingShuRuJieSuan: 'Please enter Settlement Doc No./PO No./PR No.',
  tian_8: '0 day(s)',
  caiGouFang: 'Buyer',
  weiYanZhen: 'Unverified',
  yanZhenChengGong: 'Verified',
  yanZhenShiBai: 'Failed',
  zengZhiShuiZhuanYong: 'VAT Special Invoice',
  zengZhiShuiDianZi: 'VAT Special E-invoice',
  zengZhiShuiPuTong: 'VAT Ordinary Invoice',
  zengZhiShuiDianZi_2: 'VAT Ordinary E-Invoice',
  zengZhiShuiDianZi_3: 'VAT Ordinary E-Invoice (Highway Tolls)',
  quKuaiLianDianZi: 'Blockchain E-Invoice',
  zengZhiShuiPuTong_2: 'VAT Ordinary Invoice (Folding)',
  jiDongCheXiaoShou: 'Uniform Invoice for Motor Vehicle Sales',
  tongYongJiDaFa: 'Machine-printed Invoice (Paper)',
  tongYongJiDaFa_2: 'Machine-printed Invoice (Electronic)',
  hangKongXingChengDan: 'Flight Itinerary',
  huoChePiao: 'Train Ticket',
  keYunQiChePiao: 'Bus Ticket',
  chuanPiao: 'Ferry Ticket',
  chuZuChePiao: 'Taxi Receipt',
  dingEFaPiao: 'Quota Invoice',
  diDiXingChengDan: 'DiDi Itinerary',
  haiWaiINV: 'Overseas Invoice',
  shi_2: 'hr',
  fen: 'min ',
  miao: 'sec',
  jieSuanGongSiZhu: 'Legal Entity Registered Country Code:',
  gongYingShangZhuCe_2: 'Vendor Company Registered Country Code:',
  wuYunFei: 'No freight',
  wangYiGongSiBan: 'Copyright ©️1997-{val1} NetEase ',
  oADanHao_2: 'PR No.:   ',
  xunJiaBeiZhu: 'RFx remarks',
  faGeiGongYingShang: '(Send to vendors)',
  xunJiaFuJian: 'RFx attachments',
  dingBiaoJinEChao: 'Awarded amount exceed Estimate Amount by',
  dingBiaoJinEDi: 'Awarded amount is lower than Estimate Amount by',
  xunJiaShuLiang: 'RFx Qty.',
  gongJiJiaBaoJia: '{val1} quotations',
  shouQiQuanBu: 'Collapse all',
  zhanKaiQuanBu: 'Expand all',
  gongYingShangZhuCe: 'Registered Country code is {val1}',
  jinEBuWanZheng: 'Amount mandatory',
  buTongOADan: 'PR number should be the same',
  qingShuRuXingMing_2: 'Please enter full name/employee ID/email',
  daiHeJia: 'Pending Pricing',
  xunJiaGongYingShang: 'RFx vendor',
  kaiBiaoHeJia: 'Open and Pricing',
  pinPai_2: 'Brand:',
  peiZhi_2: 'Configuration:',
  shiFouZhongBiao: 'Awarded',
  shiFouZuiYouJia: 'Best price',
  zhongBiaoShuLiang: 'Awarded quantity',
  zhongBiaoJinEHan: 'Awarded amount (tax incl.)',
  baoJiaDanJiaWei: 'Quotation unit price (tax excl.)',
  weiBaoQiYue: 'Warranty period (month)',
  shouQuanQiRuanJian: 'License period (software/month)',
  jiaoFuZhouQiZi: 'Delivery cycle (calendar days)  ',
  canKaoDanJiaHan: 'Ref. unit price (tax incl.)',
  canKaoZongJiaHan: 'Total ref. price amount (tax incl.)',
  shenPiJieDian: 'Approval node',
  shenPiRen: 'Approver',
  caiGouXuQiuShen: 'Purchase Requisition Info',
  xuQiuShenQingRen: 'Requested by',
  xuQiuShenQingRen_2: 'Department',
  xuQiuShenQingYuan: 'Reason for purchase request',
  xuQiuShenPiFu: 'Attachments of purchase request',
  xuQiuYiShenPi: 'PR Approval Details',
  dingJiaDingBiaoShen: 'Pricing/Award Request',
  xunJiaDingBiaoHao_2: 'RFx No.',
  dingBiaoShenPiBiao: 'Award Approval Title',
  canKaoJiaJinE: 'Ref. price amount',
  dingBiaoZongJinE: 'Total awarded amount (tax incl.)',
  dingBiaoShuoMing: 'Award description',
  dingJiaDingBiaoMing: 'Pricing/Award Details',
  dingJiaDingBiaoFu: 'Pricing/Award Attachments',
  qingZaiDingBiaoMing: 'Please refer to award details for further information',
  dingBiaoShenQing: 'Award Request - {val1}',
  xunYuanFangShi: 'Sourcing strategy',
  dingBiaoCeLue: 'Award strategy',
  heJiaFuJian: 'Attachment',
  heJiaShenQingTi: 'Submitted at',
  canKaoJiaHeJi_2: 'Total ref. amount (tax incl.)',
  qingZaiBaoJiaXin: 'Please refer to quotation details for further information',
  renYuanXinXi: 'User Info',
  shiYongRenYouXiang: "User's email",
  shiYongRenSuoShu: "User's department",
  shouHuoRenYouBian: 'Postal code',
  shouQuanQi: 'License period',
  qiWangJiaoHuoRi: 'Need by',
  caiGouXinXi: 'Procurement Info',
  baoJiaXinXi: 'Quotation Info',
  fuJianXinXi: 'Additional Info',
  baoJiaYouXiaoQi: 'Validity of quotation',
  gongHuoZhouQiZi: 'Supply cycle (calendar days)',
  dingDanSheZhi: 'Order Settings',
  faSongDingDanZhi: 'Send orders to vendors',
  ziDongFaSongDing_2: 'PO Auto sending',
  baoJiaHeJia: 'Quotation & Pricing\n',
  chuangJianBaoJiaDan: 'Create Quotation',
  xunYuanCeLue: 'Sourcing Type',
  dingJiaCeLue: 'Pricing strategy',
  tiJiaoHeJiaHou: 'Display after submit',
  queDingYaoShanChu_2: 'Are you sure to delete line item?',
  zanWeiXuanZeGong: 'Please select vendor',
  dingBiaoShenQingTi: 'Award request submitted at',
  kaiBiaoShiJian: 'Biding open at',
  heJiaRen: 'Evaluated by',
  baoJia_3: 'Quotation',
  faSongXunJia: 'Send Inquiry',
  queRenYaoFaSong: 'Send the inquiry to vendor?',
  baoJiaJieZhiShi: 'Deadline of quotation',
  shiQu: 'Time zone:',
  xunJiaShiJian: 'Inquiry sent at',
  faChuXunJiaHou: 'Display after submit',
  canYuGongYingShang: 'Vendors List',
  lianXiRen_3: 'Contact person: {val1}',
  xunJiaShuLiang_2: 'Quantity:',
  xunJiaXiangQing: 'Inquiry Details {val1}',
  baoJiaXiangQing_2: 'Quotation Details {val1}',
  quXiaoXunJia: 'Cancel Inquiry',
  quXiaoChengGong: 'Cancel successfully',
  xunJiaFaQiRen: 'Inquiry submitted by',
  tiaoZhengBaoJiaShi: 'Adjust Deadline',
  tiaoZhengHouKeNeng: "Adjust Deadline may affect vendor's quotation",
  dangQianYiYouJia: 'Currently, {val1} vendors have submitted the quotation',
  tiaoZhengShiJian: 'Adjust Deadline',
  queDingTiJiaoKai: 'Open biding?',
  cunZaiBuFenBao: 'Some quotations have not been received yet. Are you sure you want to open the bid?',
  baoJiaTiJiaoHou:
    'After open biding, the process advance to the pricing stage, and vendors will no longer be able to submit quotation.',
  baoJiaDaoJiShi: 'Countdown of Quotation:',
  xunJiaJieZhiShi: 'Deadline of inquiry',
  yiBaoJiaGongYing: 'Quotations received from vendors: {val1}',
  weiBaoJia: 'Not submitted  ',
  yiBaoJia: 'Submitted',
  buCanYu: 'Quit',
  caiGouFangShi: 'Procurement method',
  zhiDingCaiGouLei: 'Designatedprocurement type',
  yuanYinShuoMing: 'Reason',
  juJue: 'Rejected',
  tongYi: 'Approve',
  shenHeXinXi: 'Approval information',
  shenHeShuoMing: 'Description',
  caiGouYuanDaiLi: 'Registered by the buyer',
  gongYingShangZiZhu: 'Registered by the vendor',
  faSongYaoQingCheng: 'Send invitation successfully',
  yaoQingGongYingShang: 'Invite vendor for registration',
  faQiYaoQing: 'Send an invitation',
  tiJiaoChengGongHou: 'Trigger an email notification to the vendor after submission',
  qingXuanZeFuWu: 'Please select the category',
  zhangHaoGuanLi: 'Account Management',
  xianXiaBaoJia: 'Offline quotation',
  chuangJianXunJia: 'Online inquiry',
  yiZhongBiao: 'Awarded',
  weiZhongBiao: 'Non-awarded',
  xianXiaBaoJiaHe: 'Offline - Quotation & Bidding Evaluation',
  xianXiaDingBiao: 'Offline - Award',
  xianShangXunJia: 'Online - Inquiry',
  xianShangBaoJia: 'Online - Quotation',
  xianShangHeJia: 'Online - Pricing',
  xianShangDingBiao: 'Online - Award',
  xunBiJia: 'RFQ',
  zhiJieCaiGou: 'Direct Procurement ',
  zhiDingCaiGou: 'Designated',
  zuiDiJiaFa: 'Lowest Price',
  zongHePingFenFa: 'Composite Score',
  zuiGaoJiaFa: 'Highest price',
  dingBiaoShenPiZhong: 'Award under approval',
  baoJiaZhong: 'Under quotation',
  heJiaZhong: 'Under Pricing',
  shenPiZhongBaoJia: 'PR Approving/RFx Quoting',
  yiShenPiBaoJia: 'PR Approved/RFx Quoting',
  shenPiZhongHeJia: 'PR Approving/RFx Pricing',
  yiShenPiHeJia: 'PR Approved/RFx Pricing',
  feiITWuZi: 'Non-IT supplies',
  xingZhengZhuanYongGong: 'Administrative use - public procurement',
  weiXiuYongPin: 'Maintenance supplies',
  yuanGongFuLi: 'Employee benefits',
  qingJieLei: 'Cleaning supplies',
  shiTangBaoXiang: 'Dining room supplies',
  canYinShangPei: 'Non-canteen catering coupon',
  changGuiCaiGou: 'Regular',
  gongYingShangXuYue: 'Vendor renewal',
  cunZaiJiShuBi:
    "In situations where technical requirements arise, such as maintaining consistency in existing procurement projects and services, meeting continuity of technology requirements, or the alternatives for products or services do not exist, the technical person in charge of relevant department (at least Lvl-2 Dept. Head or the CTO of relevant department) shall confirm the necessity of this technical requirements. If conflicts arise with the procurement department's opinions, it is suggested to organize an expert review before making a purchase",
  youTeShuBaoMi: 'When there are specific confidentiality or service restrictions',
  keHuZhiDingDe:
    'For vendor designated by the client, it is required to obtain clear designated proofs from the client (such as written documents or chat history, etc.),  as well as confirmation from the Lvl-1 Dept. Head of relevant department',
  shouDaoCaiGouShi:
    'Due to procurement time constraints or regional limitations, there exists only one qualified vendor for the products or services to be purchased',
  zhengFuHangYeZhun:
    'Vendors designated by the government, industry standards, qualification management agencies, or laws and regulations',
  shuDianFaPiaoZhuan: 'Electric invoice (Chinese special invoice)',
  shuDianFaPiaoPu: 'Electric invoice (Chinese general invoice)',
  haiWaiCaiGou: 'Purchase Request (Overseas)',
  guoNeiZongHeCai: 'Integrated Purchase Request (China)',
  xingJinErBuHanShui: 'Line Amount (Tax Excl.)',
  jinEHanBuShui: 'Amount (Tax Excl.)',
  yiJuJue: 'Rejected',
  qingShuRu: 'Please enter',
  qingShuRuFaPiaoJinE: 'Please enter invoice amount',
  chuangJianLuYueJi: 'Create Delivery Schedule',
  meiYue: 'Each month',
  ri: 'Day',
  geYue_2: 'month(s)',
  faPiaoHaoMa: 'Invoice Number',
  faPiaoDaiMa: 'Invoice Code',
  jiaoYanMa: 'Verification Code',
  kaiPiaoRiQi: 'Invoice Date',
  zhangQiTian: 'Due Days',
  daoQiRi: 'Due Date',
  shuiE: 'Tax Amount',
  buHanShuiJinE: 'Amount (Tax Excl.)',
  faPiaoBiZhong: 'Invoice Currency',
  yanCongXiang: '闫从翔',
  weiZhiFu: 'Unpaid',
  yiZhiFu: 'Paid',
  buFenZhiFu: 'Partially paid',
  jieSuan: 'Payment',
  jieSuanGuanLi: 'Payment Management',
  faPiao: 'Invoice',
  shangChuanFaPiao: 'Upload Invoices',
  luYueXinXi: 'Delivery Details',
  jiaoFuFangShi: 'Delivery Types',
  luYueRiQi: 'Date of Delivery',
  jiaoFuZhouQiMei: 'Delivery period (monthly)',
  luYueJiHuaXiang: 'Delivery Schedules{val1}',
  meiYueRi: 'on the {val1}th of each month',
  geYueMeiYueRi: '{val1} month(s), on the {val2}th of each month',
  guanLianDingDanZheng: 'Linked order (order overview)',
  guanLianDingDanMing: 'Linked order (details)',
  guanLianYanShouDan: 'Linked receipt document',
  gaiFaPiaoZanWei:
    'The invoice is not currently linked to any orders. If linking is required, please select a linking method:',
  guanLianZongJinE: 'Total linked amount (Tax Excl.)',
  jinEHanShui: 'Amount (Tax Incl.)',
  shengYuKeKaiPiao: 'Remaining available invoicing amount (Tax Incl.)',
  guanLianJinEBu: 'Linked amount (Tax Excl.)',
  xingJinE: 'Line amount',
  yanShouDingDan: 'Receipt Orders',
  guanLian: 'Link',
  queDing: 'Confirm',
  qingJianChaBiZhong:
    'Please check whether the fields such as currency, paying company and vendor are entered correctly.',
  qingShuRuCaiGou: 'Enter PO ID/RP No.',
  faPiaoFuJian: 'Invoice attachment',
  zhiChiPNG: 'Supports PNG, JPG, JPEG, PDF, and OFD files',
  daoQiRiBuKe: 'Invoice due date cannot be earlier than invoicing date, please check again!',
  buHanShuiJinE_2: 'The amount excluding tax cannot be greater than the amount including tax.',
  guanLianJinEBu_2: 'The sum of the linked amount (tax excl.) must match the invoice amount (tax excl.)',
  shanChuHouBuKe: 'Irrecoverable after deletion',
  faPiao_2: '{val1} invoices',
  shangChuan: 'Upload',
  faPiaoXiangQing: 'Invoice Details',
  guanLianDanJu: 'Linked Documents',
  zhiFuZhuangTai: 'Payment Status',
  guanLianFuKuanDan: 'Linked Payment Documents',
  faPiaoDaoQiRi: 'Invoice Due Date',
  faPiaoID: 'Invoice ID',
  shangChuanShiJian: 'Uploaded Time',
  qingShuRuFaPiao: 'Please enter invoice reference number and vendor',
  fuWuLeiJiaoFu: 'Service',
  zhongGuoDaLuFa: 'Invoices (Chinese mainland) ',
  feiZhongGuoDaLu: 'Invoices (Non-Chinese mainland) ',
  yiCiXingJiaoFu: 'One-time delivery',
  meiYueGuDingJiao: 'fixed monthly delivery',
  meiYueAnXuJiao: 'on-demand monthly delivery',
  shiJiLvYueJinE: 'Amount delivered',
  shiJiLvYueShuLiang: 'Quantity delivered',
  lvYueDanHangHao: 'Delivery Doc - Line',
  lvYueXinXi: 'Delivery Details ({val1})',
  buTongShouHuoQu: 'Different delivery region PR cannot mix in 1 RFx.',
  zhiFuFangShiCunZaiYu: 'The payment method is prepaid and cannot be combined to create an order',
  tuiJianRenXingMing: 'Referrer (Full Name + Email)',
  guanLiYuanShiZhi: '"Administrator" refers to the employee who moderates the vendor portal inside the vendor company',
  gongYingShangLaiYuan: 'Vendor Source',
  caiGouXunYuan: 'Sourcing',
  qiTaRenTuiJian: 'Referred',
  yiTiJiao: 'Submitted',
  dingBiaoJinEWeiLing: "Awarded amount shouldn't be 0",
  shuRuYouWu: 'Input error',
  biTian: 'Required',
  biZhongBuYiZhiBuKe: 'Currency can not mix in 1 RFx.',
  gaiShuJuBuCunZai: "The data doesn't exist",
  caiGouFuJian: 'Procurement Attachment',
  xunJiaDanShenPiZhong: 'RFx Approving',
  ziDongChuangJianDing: 'PO Auto Issue',
  baoJiaJinEHan: 'Quotation Amount (Tax Incl.)',
  dingDanJinEWeiShui: 'Order Amount(Tax Exc.)',
  weiBaoQiXianYue_2: 'Warranty(Month)',
  shouQuanQiXianYue_2: 'License Period(Month)',
  xuQiuBeiZhu: 'PR comments',
  xuQiuFuJian: 'PR Attachment',
  daiShouHuo: 'Pending Receipt',
  xianShiLiuChengTu: 'Display Flow Chart',
  shenPiBuTongGuo: 'Rejected',
  jinDuFanKui: 'Feed Back',
  zheSuanRenMinBi: 'CNY Amount(Tax Inc.)',
  xuQiuYuYuanYin: 'Purchase Reason',
  zhiFuFangShiBu: 'Different payment method can not mix.',
  qingShuRuZhengQue_3: 'Please enter a valid email address.',
  caiGouXuQiuXiang: 'PR Details {val1}',
  kaPian: 'Grid',
  lieBiao: 'List',
  dengDaiShiChangChao: 'Pending over 3 days',
  qingShuRuDingDan: 'PO or PR number',
  dingDanXiangQing: 'Order Details',
  baoJiaHangHao: 'RFx Line No.',
  ziDongFaSongDing: 'PO send to vendor',
  bianGeng: 'Update',
  chaKanBianGeng: 'View update',
  chaKanBianGeng_2: 'View update - {val1}',
  fuWuLei_2: 'Service',
  shenQingJieShuRi: 'Expiry Date of Request',
  yongJiuYouXiao: 'Permanent',
  shiQuShuoMing: 'About time zones',
  gaiShiJianShiAn: 'The displayed time is adjusted to your local time zone.',
  fuZhiChengGong: 'Copied',
  queDingLiKaiYe: 'Do you want to leave this page?',
  xunJiaDanChuangJian: 'RFx Created at',
  caiGouPinLei: 'Procument Category',
  buTongXuQiuBan: 'Different version PR cannot mix in 1 RFx.',
  shouHuoDiQu: 'Region',
  xuQiuRen: 'End User',
  heZuoZhuangTai: 'Cooperation Status',
  heZuoZhuangTaiShuo: 'About cooperation status',
  chaKanXiangQing: 'View details',
  caiGouYuanKeDui:
    'Buyers can manage the vendor status data by marking vendors as registered, potential, cooperating, eliminated, or blacklisted.',
  wangQi: '王琦',
  shangHaiXingZhengZong: 'Comprehensive administrative service requirements in Shanghai',
  wangYunHui: '王运辉',
  jiTuanRenLiGong:
    'Human resources except for Global HRO Center,Global Studios BP Center, Global Total Rewards,Mutual Entertainment Pay Centre comprehensive service Requirements',
  liuLingYan: '刘灵燕',
  gLOBA:
    "Human Resources Global HRO Center, Global Studios BP Center,Global Total Rewards' comprehensive service Requirements",
  linYang: '林杨',
  jiTuanRenLiRen:
    'Group Human Resources Operation Center/Talent Development Center/Organizational Development and Cultural Center/EHR Product Center Comprehensive Service Requirements',
  liuFang: '刘芳',
  yunFuWuDC:
    'Cloud services, DC hardware; procurement requirements for comprehensive services other than Shanghai、Beijing administration and HR departments；Hangzhou、Beijing Park Renovation Projects',
  guoXia: '郭霞',
  iTRuanYingJian: 'IT software and hardware, other IDC resources、text message',
  shiMengQing: '史梦清',
  qingPuSanYaMeng: 'Xuhui、Qingpu, Sanya, Montreal renovation project',
  xuHuiBeiJingYuan: 'Comprehensive administrative service requirements in Shanghai；Xuhui Park Renovation Projects',
  dengWenJing: '邓文婧',
  hangSanGuiAnHang:
    "Hangzhou Third, Gui'an, Guangzhou Park Infrastructure Projects and Weak current transformation；Comprehensive administrative service requirements in Beijing",
  duiJieRen: 'Contact',
  duiJieYeWu: 'Business in Charge',
  yeWuXianShuoMing: 'About business area',
  yeWuXian: 'Business Area',
  zhuanJiao: 'Transfer',
  suoShuGongYingShang: 'Affiliated vendors',
  zhiFuBaoZhongGuo: 'Alipay (China) Network Technology Co., Ltd.',
  zhuanJiaoYouXiang: 'Recipient Email',
  zhuCe: 'Registered',
  qianZaiXiaoJinE: 'Potential / Small-amount',
  qianZaiChangGui: 'Potential / Conventional',
  heZuoChangGui: 'In cooperation / Conventional',
  heZuoXiaoJinE: 'In cooperation / Small-amount',
  taoTai: 'Eliminated',
  heiMingDan: 'Blacklisted',
  shenPiChengGong: 'Review completed',
  chengGong: 'done',
  shiBai: 'failed',
  xunYuan: 'Sourcing',
  dingDan: 'Order',
  yanShou: 'Receipt',
  gongYingShangLieBiao: 'Vendors',
  gongYingShangZhangHao: 'Vendor Account',
  xuQiuLaiYuan: 'Source From',
  xiaDanGuiZe: 'Order Type',
  jinE: 'Amount',
  bianGengJiLu_2: 'Record -- {val1}',
  caoZuoRen_3: 'Operator',
  chuangJianDingDan: 'Order creation',
  gongYingShangYiRu: 'List of Joined Vendors',
  xinXiBianGeng: 'Information update',
  heZuoZhuangTaiBian: 'Update of cooperation status',
  gongYingShangWeiRu: 'List of Not Joining Vendors',
  chaKanJiLu: 'View',
  chongXinZhunRu: 'Re-admssion',
  bianGengJiLuDai: 'Record -- Items pending review',
  shenHe: 'Review',
  bianGengJiLuXin: 'Record -- Information update',
  bianGengJiLuHe: 'Update - Update of cooperation status',
  bianGengJiLuZhun: 'Record -- Re-admission request',
  jueSeBianMa: 'Role Code',
  jueSeBianMaBu: 'Role code required',
  zhouRi: 'Sunday',
  zhouYi: 'Monday',
  zhouEr: 'Tuesday',
  zhouSan: 'Wednesday',
  zhouSi: 'Thursday',
  zhouWu: 'Friday',
  zhouLiu: 'Saturday',
  benCiYanShouShu: 'Receipt Quantity',
  benCiYanShouJin: 'Receipt Amount',
  yanShouShenPiJie: 'GR Approval Flow Details',
  yanShouXinXi_2: 'Receipt Details',
  yanShouFuJian: 'Receipt Attachments',
  faHuoShuoMing: 'About advance shipping notice: ',
  chaKanYiYanShou: 'View receipt record',
  yanShouJiLu: 'Receipt record',
  daiYanShouJinE: 'Item Value Pending Receipt',
  shiWuLei: 'Physical Items: ',
  qingQueBaoShouDao:
    'Please ensure that the goods received meet the provision of the contract/order, (including quantity, model, specification, quality, and delivery time) and meet the specific acceptance standard of the end user department / acceptance department.',
  fuWuLei: 'Services: ',
  qingQueBaoFuWu:
    'Please ensure that the service provided is delivered on time complied with the contract/order and meets the specific acceptance standard of the end user department / acceptance department.',
  ruanJianLei: 'Software: ',
  qingQueBaoNinYi:
    'Please ensure that you have received the software as provided in the contract/order (including the purchased/available quantity of licenses, number of licensed users, dongles, licensed period etc.).',
  yanShouYaoQiu: 'Receipt Requirements:',
  ruYouYiWenQing:
    'For any questions, please contact the buyer responsible for the order. Please ensure that the acceptance record aligns with the actual quantity of items received. Avoid splitting the acceptance into batches unless absolutely necessary.',
  caiGouBuGongGong: 'Authorized General Procurement Reviewer — Acceptance',
  qingAnShiJiLu:
    'Please ensure that the acceptance record aligns with the actual performance of the contract performance.',
  yanShouChengGong: 'Succeed',
  queRenShouHuo: 'Confirm to receive',
  zuiDuoShangChuanZhang: 'Maximum 3 uploads, no larger than 100MB',
  yanShouShuoMing: 'Receiving instruction',
  shangPinXinXi: 'Item Info.',
  xingBeiZhu: 'Line Note',
  xingFuJian: 'Line Attachments',
  shenQing: 'Request',
  tiJiaoDingDan: 'Submit',
  gongYingShangLuYue: 'Order Delivery',
  dengDaiShouHuo: 'Not received',
  wanCheng: 'Done',
  xinJianFaHuoDan: 'ASN Creation',
  woDeShenQing: 'My Requests: ',
  yinCangLiuChengTu: 'Hide flowchart',
  qingShouDaoHuoHou:
    'Please ensure the goods are delivered before the receipt confirmation. For any questions, please reach out the buyer in charge.',
  yuJiLuYueRi: 'Expected date of delivery',
  faHuoFuJian: 'Shipment Attachments',
  shouHuoXinXi_2: 'Recipient Details',
  lianXiFangShi: 'Contact Info',
  dingDanZongE: 'Order Amount',
  xiaDanShiJian: 'Order released at',
  jiaJi: 'Urgent',
  qingShuRuXunJia_2: 'Please enter RFx No./RP No.',
  oADanHaoCai: 'RP No./PO No.',
  xuQiuXinXiBu: 'Details Required',
  xunJiaCaoGao: 'RFx Drafts',
  daiXiaDan: 'Unreleased PO',
  heXiaoXinYongKa: 'Bill mapping',
  keXiaDanShuLiang: 'Quantity Available',
  keXiaDanJinE: 'Amount Available',
  xiaDanFangShi: 'Order Type',
  gongYingShangBuYi: 'Different vendors cannot be included in a single document',
  gongYingShangLianXi_3: 'Different vendor contact people cannot be included in a single document',
  gongYingShangLianXi_4: 'Different email addresses of vendor contact person cannot be included in a single document',
  shiFouFaSongDing: 'Inconsistent options for "Send order email" cannot be included in a single document',
  zhangQiBuYiZhi: 'Different accounting periods cannot be included in a single document',
  jieSuanGongSiBu: 'Different entities cannot be included in a single document',
  shouHuoRenBuYi: 'Different recipients cannot be included in a single document',
  shouHuoRenYouXiang_2: 'Different recipient email addresses cannot be included in a single document',
  shouHuoGuoJiaBu: 'Different countries cannot be included in a single document',
  shouHuoDiQuBu: 'Different regions cannot be included in a single document',
  shouHuoChengShiBu: 'Different cities cannot be included in a single document',
  shouHuoDiZhiBu: 'Different delivery addresses cannot be included in a single document',
  shouHuoYouBianBu: 'Different zip codes cannot be included in a single document',
  shouHuoRenLianXi_2: 'Different recipient contacts cannot be included in a single document',
  heTongHaoBuYi: 'Different contract numbers cannot be included in a single document',
  zhiFuXinYongKa: 'Different credit cards cannot be included in a single document',
  shiFouLaiZiDian: 'Different sources (via e-commerce platform or not) cannot be included in a single document',
  chuangJianRenBuYi: 'Different document creators cannot be included in a single document',
  chuangJianShiBai: 'Creation failed',
  gongYingShangLeiXing:
    'The vendor is classified as a "small-amount" type vendor, but the total amount of this order, ',
  chaoGuoXiaoJinE: ', has exceeded the per-order limit of',
  deXianZhiQingZuo: ' for this vendor type per-order. Please upgrade the vendor before releasing this order.',
  liJiShengJi: 'Upgrade vendor',
  dingDanJinEHan: 'Amount (tax incl.)',
  jiZhunTiaoKuan: 'Base terms',
  buChongHeTong: 'Add contract',
  oADan: 'PR No.',
  xingCanKaoZongJia_3: 'Line Total Ref. Amount(Tax Incl.)',
  shouHuoDiQuGuo: 'Region (Country/Region - State/Province)',
  qingShuRuOA: 'Please enter PR No. or Item name',
  oADanHaoXing: 'PR-Line',
  qingShuRuXunJia_3: 'Please enter RFx No./ PR No./Item name',
  dingDanChuangJianCheng: 'Order created',
  daYu_2: '(with a value greater than',
  xuQianShuHeTong:
    ') with the contract required. Please sign the contract promptly to prevent any delay in the order shipment. If a contract has already been signed for this purchase, please attach it to this document.',
  guanLianHeTong: 'Add contract',
  xinJianCaiGouDing: 'PO creation',
  shouHuoFangShi: 'Receipt method',
  xiaDanJinE: 'Order Amount',
  dingDanXingJinE: 'Line Amount',
  xinJianDingDan: 'PO Release',
  dingDanXingJinE_2: 'Line Amount (Tax Excl.)',
  dingDanHangJinE_2: 'Line Amount (Tax Incl.)',
  feiYongChengDanCheng: 'Cost Center',
  yanShouJinE: 'Receive Amount ',
  oADanHaoDing: 'PR No./PO No.',
  keFaHuoShuLiang: 'Available Quantity',
  keFaHuoJinE: 'Available Amount',
  benCiFaHuoShu: 'Qty for shipping',
  benCiFaHuoJin: 'Amount for shipping',
  faHuoXingBeiZhu: 'Line Note of Shippment',
  benDanLuYueJin: 'Amount Delivered (Tax Incl.)',
  faHuoXinXi: 'Shippment Info.',
  chuangJianRen: 'Created by',
  faHuoLeiXing: 'Shipment Type',
  faHuoBeiZhu: 'Shippment Notes',
  zuiDuoShangChuanZhang_2: 'Maximum 20 uploads, no larger than 100MB',
  mingXiXing: 'Detail ({val1} line)',
  heTongPDF: 'CONTRACT 2.pdf',
  yiZaiYanShouZhong: 'The ASN is under receipt and cannot be closed',
  faHuoDanGuanBi: 'Shippment cancelled',
  guanBiFaHuoDan: 'Cancel ASN',
  gaiFaHuoDanBao: 'The shippment contains',
  xingJiangQuanBuGuan: 'lines. Confirm to cancel?',
  guanBiYuanYin: 'Reason for cancel',
  faHuoDanXiangQing: 'ASN details {val1}',
  yiGuanBi: 'Cancelled',
  faHuoDanFuJian: 'ASN Attachments',
  faHuoXinXi_2: 'Shippment Info.({val1})',
  faHuoDanHangHao: 'Shippment-Line',
  shiJiFaHuoJin: 'Shippment Amount',
  danJiaHanShui: 'Unit Price (Tax Incl.)',
  yunFeiHanShui: 'Freight (Tax Incl.)',
  baoJiaBeiZhu: 'Quotation Note',
  gongYingShangLianXi_5: 'Email of Vendor Contact',
  gongYingShangLeiXing_2: 'Vendor Type',
  gongYingShangYouXiang: 'Vendor Email',
  dangQianHeZuoZhuang: 'Current Cooperation Status',
  bianGengXinXi: 'Change Info.',
  muBiaoHeZuoZhuang: 'Target Cooperation Status',
  bianGengShuoMing: 'About Changing',
  zhunRuXinXi: 'Admission Information',
  tuiJianRen: 'Referral Person Name',
  tuiJianLiYou: 'Reason for reference',
  zhunRuShuoMing: 'About admission',
  danJiaHanShui_2: 'Unit Price (Tax Incl.)',
  dingBiaoJinEWei: 'Awarded Amount (Tax Excl.)',
  ziDongZhuanPO: 'PO Auto Issue',
  dingBiaoXinXi: 'Award Info.',
  zhongBiao: 'Awarded',
  weiZhong: 'Not awarded',
  ruKuDanHangHao: 'Inbound Doc-Line',
  ruKuZiGuan: 'Warehouse Operator',
  jiaoYanJieGuo: 'Validation Result',
  shiBaiYuanYin: 'Error Reason',
  shouHuoDanXiangQing: 'Receipt Details',
  faHuoDanHangHao_2: 'Shippment-Line',
  weiShuiDanJia: 'Unit Price (Tax Excl.)',
  faPiaoLeiXing: 'Invoice Type',
  yanShouDanHangHao: 'Receipt No-Line',
  ruKuXinXi: 'Inbound Information',
  faHuoDan: 'Shippment',
  daiYanShouJinE_2: 'Item Value Pending Receipt (Tax Incl.)',
  faHuoShiJian: 'Shipped on',
  oADanHaoWu: 'PR No./Delivery Number/Shippment No.',
  dingDanHangHao_2: 'PO-Line',
  yanShouJinEHan_2: 'Received Amount(Tax Incl.)',
  shouHuoShiJian: 'Received on',
  oADanHaoShou: 'PR No./Receipt No./PO No./Accrual No.',
  biaoTi: 'Title',
  feiYongChengDanBu: 'Paying Department',
  shouHuoRenDianHua: 'Phone Number',
  queDingWanChengXun: 'Confirm to mark as quotation finished?',
  xunJiaYiWanCheng: 'RFx finished',
  wanChengXunJia: 'Finish RFx',
  xuQiuChi: 'Requisition Pool',
  xunJia: 'RFx',
  shenQingDanHao: 'Request No.',
  gongYingShangJianCheng: 'Short Name',
  shenPiZhuangTai: 'Approval Status',
  qingShuRuGongYing: 'Vendor name/Short Name /PR No.',
  heZuoZhuangTai_2: 'Cooperation Status: ',
  bianGengShuoMing_2: 'About alteration: ',
  shiJian: 'Time',
  dangQianShaiXuanTiao: 'Nothing found for the current filter. Click  here  ',
  qingKongShaiXuanTiao: 'to clear filter',
  neiRongBianGeng: 'Details change',
  bianGengDanHao: 'Changing request ID',
  bianGengNeiRong: 'Changing Detail',
  shenPiJieDianMing: 'Approval Details',
  queRenFanHuiShang: 'Are you sure to go back?',
  tianXieDeShuJu: 'All data will be lost.',
  shangYiBu: 'Back',
  xiaYiBu: 'Next',
  jiChuXinXi: 'Basic Info.',
  qingShuRuGongYing_2: 'Please enter the vendor name',
  tongYiSheHuiXin: 'Unified Social Credit Code',
  qingShuRuTongYi: 'Please enter the unified social credit code',
  qingShuRuTI: 'Please enter the TIN',
  guanLiYuanXingMing: 'Administrator Full Name',
  qingShuRuGuanLi: "Please enter the administrator's full name",
  guanLiYuanYouXiang: 'Administrator Email',
  qingShuRuGuanLi_2: "Please enter the administrator's email",
  qingShuRuXingMing: 'Please enter the full name',
  zhengJianLeiXing: 'Document Type',
  qingShuRuZhengJian: 'Please enter the document type name',
  zhengJianHaoMa: 'Document ID',
  qingShuRuZhengJian_2: 'Please enter the document ID',
  qingShuRuLianXi: 'Please enter the email',
  qiYeXinXi: 'Enterprise Information',
  dangAnJiLu: 'Archives record',
  dangQianZhanShiNei:
    'The information displayed currently is the original version. The changes you submitted are currently under review. Please wait for the review result.',
  daiShenHe: 'Pending review',
  zhunRuShenQing: 'Admission Application',
  qingShuRuGongYing_3: 'Please enter the vendor name or abbreviation / TIN / Unified Social Credit Code',
  fuWuPinLei: 'Category',
  zhunRuShiJian: 'Admitted on',
  yiRuKu: 'Joined',
  weiRuKu: 'Not joined',
  zhunRuShenPi: 'Admission Review',
  qingShuRuGongYing_4: 'Please enter the vendor name or abbreviation / application ID',
  caiGouYuanCaiGou: 'Buyer - Lvl-2 Procurement Supervisor',
  shouGongFaQi: 'Submitted manually',
  ruVMDM: 'If the vendor in blacklist (the source is ECC), the upgrade process cannot be initiated',
  caiGouYuanFaWu: 'Buyer - Legal BP - Finance BP - Procurement Level 2 Supervisor',
  ruVMDM_2: 'If the vendor in blacklist , the upgrade process cannot be initiated',
  zhongXuanBingKaiZhan: 'Select and cooperate to automatically upgrade',
  shouCiXiaDanChu: 'Order issue and trigger',
  nianNeiWeiKaiZhan: 'No cooperation within 2 years, automatic downgrade',
  youHeTongQuHe:
    'If there is a contract, the latest expiration time of the contract; if there is no contract and there is an order, the last order is placed; if there is no contract and no order, the time for approval',
  caiGouYuanCaiGou_2: 'Buyer - Purchasing Level 2 Supervisor - Procurement Level 1 Supervisor',
  caiGouYuanFaWu_2: 'Buyer - Legal BP - Finance BP - Procurement Level 2 Supervisor - Procurement Level 1 Supervisor',
  caiGouYuanCaiGou_3:
    'Buyer - Procurement Level 2 Supervisor - Procurement Level 1 Supervisor Approves, Copies Financial BP, Legal BP, and Internal Control BP',
  yiJinXingXinXi:
    '1. Vendors that have registered information but have not completed the join approval;\n2. Vendors that have joined more than 2 years but have no PO, and have not been recorded as “eliminated” or “blacklisted”.\n',
  yiTongGuoRuKu:
    'Vendors that have joined and not in“obsolete” or “blacklisted” The amount of cooperation is small, the risk is low, exempt from financial law approval, and quick admission to cooperative vendors (cumulative rolling cooperation within half a year must not exceed 100,000 yuan, or a single order must not exceed 50,000 yuan)',
  yiTongGuoRuKu_2: 'Vendors that have joined and not in“obsolete” or “blacklisted” ',
  zhiZaiJinNianNei:
    'Refers to vendors that have cooperated in the past 2 years and have not been recorded as “eliminated” or “blacklisted” (after cooperating with potential/small amounts, upgraded to cooperative/small amounts)',
  zhiZaiJinNianNei_2:
    'It refers to vendors that have cooperated within the past 2 years and have not been recorded as “eliminated” or “blacklisted”.',
  heZuoGuoChengZhong:
    'If there is an abnormal situation during the cooperation process, such as unsatisfactory assessment results or a risk of contract performance, the cooperative vendor should be suspended after evaluation by the Commercial Procurement Department.',
  yuanZeShangYingYong: 'In principle, cooperating vendors should be permanently discontinued.',
  buQuFen: 'No distinguish',
  muBiaoJiBie: 'Target level',
  shenPiLianLu: 'Approval path',
  shengJiangJiChuFa: 'Relegation trigger point',
  jieDuanShuoMingXiang: 'About cooperation status',
  shengMingZhouQiJi: 'Cooperation Status',
  shengMingZhouQiLiu: 'Cooperation Status rules',
  ziZhiPinLeiYu: 'The qualification category does not match the service category, please revise it first',
  tuiJianYuanYin: 'Reason for recommendation',
  faDingDaiBiaoRen: 'Legal Representative',
  gongSiZhuYeWang: 'Company homepage website',
  yingYeQiXian: 'Business License period',
  chengLiRiQi: 'Foundation Date',
  naShuiRenZiZhi: 'Taxpayer qualifications',
  shengShi: 'Province/City',
  quXian: 'District/County',
  yingYeZhiZhaoFu: 'Business License File',
  zhunRuZiLiao: 'Documents for Admission',
  dengJiXinXi: 'Register Information',
  fuWuPinLeiNei: 'Category scope',
  ziZhiXinXi: 'Qualification Documentation',
  ziZhiMingCheng: 'Qualification Name',
  ziZhiWenJian: 'Qualification Certificate Doc',
  ziZhiYouXiaoQi: 'Period of validity',
  yiCunZaiXiangTong: 'The same qualification information already exists, please revise and submit',
  tianJiaZiZhi: 'Add qualifications',
  ziZhiXinXi_2: '{val1} Qualification Information',
  tianJia: 'Add',
  lianXiRenXinXi: 'Contact Information',
  lianXiRenLeiXing: 'Type of Contact Person',
  zhiWu: 'Job Title',
  shouJiHao: 'Mobile Phone Number',
  yiCunZaiXiangTong_2: 'The same contact already exists, please revise and submit',
  tianJiaLianXiRen: 'Add contact',
  lianXiRen_2: '{val1} contact',
  yiCunZaiMoRen: 'A default account already exists. Do you want to set this account as the default account?',
  yinHangZhangHaoXin: 'Bank Account Details',
  zhangHaoMingCheng: 'Account Name',
  jieSuanBiZhong: 'Billing Currency',
  kaiHuHangMingCheng_2: 'Bank Name',
  zhiHangMingCheng: 'Branch Name',
  fuZhiXinZeng: 'Copy Add',
  yiCunZaiXiangTong_3: 'The same bank account already exists, please revise and submit',
  tianJiaYinHangXin: 'Add bank information',
  yinHangXinXi_2: '{val1} Bank Information',
  lianHangHao: 'CNAPS Code',
  kaiHuHangShengShi: 'Province and city of the bank where the account was opened',
  yinHangDiZhi: 'Bank address',
  shouXuFeiChengDan: 'Responsible party for handling fees',
  shiFouWeiMoRen: 'Set as default account',
  caiWuZhuangKuang: 'Financial status',
  nianFen: 'years',
  yingYeShouRuWan: 'Revenue (10K)',
  liRunZongEWan: 'Total profit  (10K)',
  shiJiaoZiBenWan: 'Paid-in Capital (10K)',
  nianDuShenJiBao: 'Annual Audit Report',
  caiWuBaoBiao: 'Financial Report',
  youXiaoQiXian: 'Valid To',
  yiCunZaiXiangTong_4: 'The same financial situation already exists, please revise and submit',
  tianJiaCaiWuZhuang: 'Add financial status',
  caiWuZhuangKuang_2: 'Financial status{val1}',
  chengGongAnLi: 'Success Case',
  anLiMingCheng: 'Case Name',
  keHuMingCheng: 'Customer name',
  fuWuChanPinFan: 'Services/Product Scorp',
  xiangMuGuiMoWan: 'Project Amount (10K yuan)',
  xiangMuShiJian: 'Project time',
  xiangMuJianJie: 'Project introduction',
  xiangMuChengGuo: 'Achievement',
  yiCunZaiXiangTong_5: 'The same case already exists, please revise and submit',
  tianJiaChengGongAn: 'Add a success case',
  chengGongAnLi_2: 'Success case{val1}',
  lianXiRenZhiWei: 'Contact person job title',
  keHuPingJiaTui: 'Customer Reviews/Recommendation',
  heTongZhengMingHuo: 'Proof of contract or project documentation',
  liYiGuanXiShen: 'Declaration of interests',
  yuanGongXingMing: 'Employee name',
  yuanGongSuoZaiGong: "The employee's company",
  guanXi: 'Relationship',
  yiCunZaiXiangTong_6: 'The same interests already exist, please revise and submit',
  tianJiaLiYiGuan: 'Add relationship',
  liYiGuanXi: '{val1} relationship',
  gongYingShangID_2: 'Vendor ID:',
  gongYingShangYouXiang_2: 'Vendor email:',
  zhuCeGuoJiaDi_2: 'Registered Country/Region',
  gongYingShangLeiXing_3: 'Vendor Type:',
  qiYeMingCheng: 'Company name',
  dengJiXinXi_2: 'Registration Information',
  moRenZhangHao: 'Default account',
  ziGuanDaiShou: 'Asset manager receive',
  yuanGongBenRenShou: 'Employee receive',
  yongHuGuanLi: 'User Management',
  xunYuanGang: 'Sourcing Manager',
  caiGouJingLi: 'Procurement Manager',
  caiGouBuMenFu: 'Head of procurement department',
  caiGouGuiZeGuan: 'Procurement rules management',
  caiWu: 'Finance',
  shenJi: 'Audit',
  neiKong: 'Internal Control',
  tian_7: '90 days',
  qiYe: 'Enterprise',
  geRen: 'Individual',
  juMinShenFenZheng: 'Resident ID',
  gangAoJuMinLai: 'Mainland Travel Permit for Hong Kong and Macau Residents',
  zhongHuaRenMinGong: "Residence permits for Hong Kong and Macau residents in the People's Republic of China",
  taiWanJuMinLai: 'Mainland Travel Permit for Taiwan Residents',
  zhongHuaRenMinGong_2: "Residence Permit for Taiwan Residents of the People's Republic of China",
  zhongGuoHuZhao: 'Chinese passport',
  waiGuoHuZhao: 'Foreign passport',
  waiGuoRenYongJiu: 'Foreigner Permanent Residence ID (Foreigner Permanent Residence Permit)',
  zhongHuaRenMinGong_3: "People's Republic of China Work Permit for Foreigners (Class A)",
  zhongHuaRenMinGong_4: "People's Republic of China Work Permit for Foreigners (Class B)",
  zhongHuaRenMinGong_5: "People's Republic of China Work Permit for Foreigners (Class C)",
  qiTaGeRenZheng: 'Other personal documents',
  yiBanNaShuiRen: 'General taxpayers',
  xiaoGuiMoNaShui: 'Small-scale taxpayers',
  feiZengZhiShuiNa: 'Non-VAT taxpayers',
  qiTa: 'Others (Non Chinese Invoice)',
  shangWuDuiJieRen: 'Sales',
  caiWuDuiJieRen: 'Finance',
  wangYiJiTuan: 'NetEase Group',
  shuangFang: 'Both Party',
  shengMingZhouQiBian: 'Lifecycle changes',
  zhuZhangHao: 'Master account',
  ziZhangHao: 'Sub account',
  wuLiuKuaiDi: 'Logistics express',
  feiWuLiuKuaiDi: 'Non-logistics express',
  wuXinXi: 'No information available',
  weiFaHuo: 'Unshipped',
  yiFaHuo: 'Shipped',
  yiQianShou: 'Received',
  yiTuiHuo: 'Returned',
  peiSongZhong: 'In transit',
  zengZhiShuiZhuanPiao: 'VAT special invoice',
  zengZhiShuiPuPiao: 'VAT ordinary invoice',
  dianZiFaPiaoZhuan: 'VAT special invoice(Electronic)',
  dianZiFaPiaoPu: 'VAT ordinary invoice(Electronic)',
  niMeiYouFangWen: 'Access denied',
  chongXinDengLu: 'Re-Log in',
  fanHuiShouYe: 'Back to homepage',
  ziChanXinXi: 'Asset Info.',
  ziChanBianHao: 'Asset ID',
  pODanHao: 'PO No.',
  qingShuRuWanZheng_2: 'Provide PO No.',
  daiXuQiuQueRen: 'Pending PR confirm',
  daiXunJia: 'Pending RFx',
  daiXunJiaQueRen: 'Draft',
  dingDanXinXiBu: 'Pending Order Info',
  daiBuChongHeTong_2: 'Pending Contract Info',
  zhengDanFaHuo: 'Ship by PO',
  dingDanHangFaHuo: 'Ship by PO line',
  guanLianXinYongKa_2: 'Map Credit Card Bill',
  qingShuRuWanZheng_3: 'Input PO No.',
  qingXianBuChongXu: 'Please add PR Info. at first.',
  queRenTongGuo: 'Confirm',
  xuQiuYiQueRen: 'PR confirmed',
  xingCanKaoZongJia_2: 'Line total Ref Amount',
  buKeQuXiao: 'Non-cancellable',
  gaiXuQiuGongXing: 'PR contains {val1} lines, cancel all?',
  xuQiuXingQueRen: 'PR line confirmed',
  buTongYiJiPin: "Different Lvl-1 category items can't mix in 1 RFx.",
  xuQiuQueRen: 'PR confirm',
  feiYongDaLei: 'Expense Category',
  feiYongXiaoLei: 'Business Category',
  xuQiuXinXiQue: 'Pending PR confirm',
  xuQiuXinXiQue_2: 'Wating for other PR line confirm',
  fangDiChanJiShe: 'Real Estate and Facilities',
  yuanGongFuLiQi: 'Other Employee Benefits',
  shiJiShiYongRen_10: "Different cost center can't mix in 1 RFx.",
  xunJiaBiaoTi: 'RFx event Title',
  wenXinTiShiShe: 'Please note that the [{val1}]  might displayed in POPO for CEO approval.',
  xunJiaBiaoTiHe: 'RFx Title, Pricing Results, and Quotation Documents',
  qingShuRuXunJia: 'RFx event title might displayed in POPO for CEO approval, if applicable.',
  qingShuRuHeJia: 'Provide the pricing result, might be displayed in the POPO for CEO approval, if applicable.',
  queDingJinYongYongHu: 'Confirm to disable the user?',
  queDingQiYongYongHu: 'Confirm enable the user?',
  jinYongYongHuHou: "After disable, the user can't log into NetBuy.",
  zuiHouGengXinRen: 'Last updated by',
  lianXiRenXingMing: 'Contact Person Name',
  banGongYeWuYong: 'Office/Business use',
  iTBuMenZhuan: 'For IT departments only - Spare parts',
  tian_2: 'day(s)',
  dianJiFaQiP: 'Chat in POPO',
  buTongJieSuanZhu: "Different legal entities can't mix in 1 RFx.",
  zhongZhi: 'Reset',
  huiLuQiJian: 'Exchange rate period',
  yuanBiZhong: 'Original Currency',
  zheSuanBiZhong: 'Target Currency',
  chaKanXuQiu: 'View PR',
  chaKanXunJiaDan: 'View RFx',
  shanChuXunJiaDan: 'Delete RFx',
  chaKanDingDan: 'View order',
  gengXinDingDan: 'Update order',
  xinZeng: 'Add',
  jieDanGuiZe: 'Buyer Distribute Rule',
  rFXShenPi_2: 'RFx Approval Rules',
  teDingBiaoQian: 'Tag',
  suoYouQuanXian: 'Full access',
  quanXianPeiZhi: 'Authorization setting',
  chuangJianJiaoSe: 'Create role',
  bianJiJueSe: 'Edit role',
  jueSeZhuangTai: 'Role Status',
  jueSeMingCheng: 'Role Nmae',
  jueSeMingChengBu: 'Please provide the role name',
  shanChuChengGong: 'Deleted',
  shanChuShiBai: 'Delete failed',
  bianJiGuiZe: 'Edit rule',
  guiZeMingCheng: 'Rule Name',
  guoJiaLeiXing: 'Country/Region',
  caiGouLeiXing: 'PR Type',
  shangPinMingChengPei: 'Item Name & Configuration & Brand',
  guoJiaDiQu_2: 'Country/Region',
  buMen: 'Department',
  shenQingRenJiBie: "Applicant's Job Level",
  shenQingRenXingMing: 'Requester',
  shiJiShiYongRen_5: "Actual User's Job Level",
  shiJiShiYongRen_6: 'Actual User Name',
  jieSuanZhuTi: 'Legal Entity',
  xinJianBiaoQian: 'Create tag',
  bianJiBiaoQian: 'Edit tag',
  biaoQianMing: 'Tag Name',
  biaoQianMingBuDe: 'Please provide the tag name',
  biaoQianYanSe: 'Tag Color',
  panDuanTiaoJian: 'Condition',
  xinZengTiaoJian: 'Add condition',
  xuHao: 'No.',
  guiZe: 'Rule',
  shenQingRenBuMen_2: "Requester's Department: {val1}",
  shenQingRenJiBie_2: "Requester's Job Level: {val1}",
  shiJiShiYongRen_7: "Actual User's Department: {val1}",
  shiJiShiYongRen_8: "Actual User's Job Level: {val1}",
  shiJiShiYongRen_9: 'Actual User: {val1}',
  jieSuanZhuTi_2: 'Legal Entity: {val1}',
  gongYingShang_2: 'Vendor: {val1}',
  yiXuanZeQuanBu: 'Selected: All employees',
  yiXuanZeQuanBu_2: 'Selected: All Legal Entity',
  dangQianMoRenXuan: 'The default selection is "All employees", and you can choose "Selected employees".',
  dangQianMoRenXuan_2: 'The default selection is "All Legal Entity", and you can choose "Selected paying companies".',
  xinZengCaiGouYuan: 'Add buyer',
  xinZengJieSuanZhu: 'Add Legal Entity',
  xingMing: 'Full Name',
  gongHao: 'Employee No.',
  jieSuanZhuTiMing: 'Legal Entity',
  jieSuanZhuTiI: 'Legal Entity ID',
  quanBuRenYuan: 'All employees',
  zhiDingRenYuan: 'Selected employees',
  yiChuCaiGouYuan: 'Remove buyer',
  quanBuJieSuanZhu: 'All Legal Entity',
  zhiDingJieSuanZhu: 'Selected Legal Entity',
  yiChuJieSuanZhu: 'Remove Legal Entity',
  tianJiaYongHu: 'Add user',
  bianJiYongHu: 'Edit user',
  qingZhengQueTianXie: 'Please provide correct data',
  fenPeiJueSe: 'Distribute Role',
  shuJuQuanXian: 'Data Access',
  xingMingGongHao: 'Name & Employee ID',
  xuanPinYiWanCheng: 'Goods select finished',
  qingFanHuiOA: 'Return to the OA request page to proceed',
  caiGouDingDanZheng: 'PO Overview',
  caiGouDingDanMing: 'PO Line items',
  xunJiaDanHao: 'RFx No.',
  tiJiaoRiQi: 'Submit Date',
  tiJiaoKaiShiRi: 'From date',
  dingDanZongEWei: 'Order Total Amount(Tax Excl.)',
  laiYuan: 'Source from',
  gengXinZhuTiHe: 'Modify entities, contracts, and notes',
  dingDanHangHao: 'PO Line',
  baoJiaJinEWei: 'Quotation Amount (Tax Excl.)',
  chuangJianRiQi: 'Creation Date',
  chuangJianKaiShiRi: 'Creation date',
  qiWangDaoHuoDi: 'Delivery Address',
  shouHuoLianXiRen: 'Recipient',
  gaiXingDuiYingDe: 'RFx will be deleted, Continue?',
  shanChuXunJiaDan_2: 'RFx deleted',
  zanWu: 'None',
  yanShouDanXiangQing: 'Receipt detail',
  yanShouDanHao: 'Receipt ID',
  yanShouRen: 'Acceptor',
  yanShouShiJian: 'Receipt Date',
  yanShouLeiXing: 'Receipt Type',
  yanShouXinXi: 'Receipt Details',
  oADanHaoXu: 'PR-Line',
  caiGouDingDanHao: 'PO-Line',
  jiTiDanHao: 'Accrual No.',
  jiTiZhuangTai: 'Accrual Status',
  shouQuanQiXianYue: 'License Period(Month)',
  yanShouJinEHan: 'Receipt Amount(Tax Incl.)',
  biZhong: 'Currency',
  shiFouZiChan: 'Asset or Not',
  caoGao: 'Drafts',
  tiJiaoJieShuRi: 'End date',
  daiYanShouShiChang: 'Receipt Duration',
  daiYanShouShuLiang: 'Pending Receipt',
  zuiXinWuLiuXin: 'Delivery Info',
  yiShouHuoShuLiang: 'Received Qty.',
  weiQueRen: 'Pending ',
  yiTuiHui: 'Returned',
  weiShenHe: 'Pending Approve',
  yiShenHe: 'Approve',
  yiRuZhang: 'Account post',
  yiChongXiao: 'Write off',
  caiGouDingDanHao_2: 'PO No.',
  yanShouRiQi: 'Receipt Date',
  yanShouKaiShiRi: 'Receipt start date',
  yanShouJieShuRi: 'Receipt end date',
  jiTiRiQi: 'Accrual Date',
  jiTiKaiShiRi: 'Accrual start date',
  jiTiJieShuRi: 'Accrual end date',
  caiGouDingDanHao_3: 'PO-Line',
  weiBaoQiXianYue: 'Warranty (Month)',
  yiWanCheng: 'Completed',
  qingShuRuWanZheng: 'PR No.',
  shenQingRiQi: 'Request Date',
  shenQingKaiShiRi: 'Request from',
  oADanHangHao_2: 'PR-Line',
  renMinBiJinE: 'CNY Amount (Tax Incl.)',
  shouHuoDianHua: 'Phone Number',
  shouJianRen: 'Recipient',
  yuanYinJiXuQiu: 'Reason for Purchase',
  chanPin: 'Game Project',
  chanPinChengBenZhong: 'Cost Center',
  buChongXingCanKao: 'Add the line ref. price & category',
  tingYong: 'Disabled',
  xingHao: 'Line',
  kaiHuHangMingCheng: 'Branch Name',
  zhangHuMingCheng: 'Account Name',
  yinHangZhangHao: 'Account No.',
  kaiHuXuKeZheng: 'Account Opening Certificate',
  tiJiaoChengGong: 'Submited',
  tiJiao: 'Submit',
  gongYingShangID: 'Vendor ID',
  gongYingShangMingCheng: 'Vendor Name',
  dengBaiShiBianMa: 'DUNS Number',
  zhuCeGuoJiaDi: 'Registered Country/Region',
  lianXiRen: 'Contact person',
  lianXiYouXiang: 'Email',
  chengLiShiJian: 'Established on',
  zhuCeZiBenWan: 'Registered Capital (10k)',
  gengXinShiJian: 'Updated at',
  gengXinRen: 'Updated by',
  xiangXiDiZhi: 'Address',
  jingYingFanWei: 'Business Scope',
  heFaZhuCeZheng: 'Business Registration Certificate',
  zhunRuZiLiaoShang: 'Documents for Admittance',
  yinHangXinXi: 'Bank Account',
  dengBaiShi: 'DUNS Number',
  faRenDaiBiaoRen: 'Legal Representative / Responsible Person',
  zuiHouGengXinShi: 'Last updated at',
  caoZuoShiBai: 'Failed',
  xinZengGongYingShang: 'Vendor Creation',
  zhiChiKuoZhanMing: 'Single file < 100M; acceptable formats: pdf, jpg, png.',
  shangChuanFuJian: 'Upload File',
  chuangJianChengGong: 'Created',
  qingShuRuZhengQue: 'Please enter a valid email address',
  qingShuRuZhengQue_2: 'Please enter a valid mobile phone number',
  zhuCeBiZhong: 'Currency',
  tian_3: '15 days',
  tian_4: '30 days',
  tian_5: '45 days',
  tian_6: '60 days',
  shouHuo: 'Receipt',
  tuiHuo: 'Return',
  zongYunFeiHanShui: 'Total Freight (Tax Incl.): {val1}',
  dingBiaoJinEHan: 'Awarded Amount (Tax & Allocated Freight Incl.)',
  dingBiaoJinEHan_2: 'Awarded Amount (Tax Incl.)',
  yunFei: '(Freight: {val1})',
  yunFei_2: 'Freight',
  zaiGaiBaoJiaDan: 'The total freight for [{val1}] within the RFx',
  zongYunFeiHanShui_2: 'Total Freight (Tax Incl.)',
  quXiaoXuQiu: 'PR Send Back',
  xuQiuYiQuXiao: 'PR Rejected',
  hanShui: 'Tax Incl.',
  weiShui: 'Tax Excl.',
  gongYingShangXiangQing: 'Vendor Details',
  caiGouDingDanXiang: 'Order Details {val1}',
  faSongDingDan: 'Send Order',
  faSongZhi: 'Send to',
  faSongChengGong: 'Sending succeed',
  quXiaoDingDan: 'Cancel order',
  caiGouDingDanYi: 'Order canceled',
  dingDanHao: 'PO No.',
  dingDanLaiYuan: 'Source',
  danJuBiaoQian: 'Tags',
  dingDanJinE: 'PO Amount',
  yuFuKuanShenQing: 'Pre-payment Request',
  xinYongKaZhangDan: 'Credit Card Bill',
  shouHuoXinXi: 'Receipt Info.',
  guoJiaDiQu: 'Country/Region',
  shouHuoLianXiDian: 'Phone Number',
  oADanHangHao: 'PR- Line',
  xunJiaDingBiaoXing: 'RFx Line',
  shouQuanQiXian: 'License Period',
  weiBaoQiXian: 'Warranty',
  xuQiuShuLiang: 'Request Qty',
  xiaDanShuLiang_2: 'Order Quantity',
  dingDanHangBiZhong: 'Line Currency',
  xingYunFeiHanShui: 'Line Freight (Tax Incl.)',
  dingDanHangJinE: 'Line Amount (Freight Excl.)',
  shouHuoShuLiang: 'Received Qty',
  shouHuoDanHao: 'Receipt No.',
  fuJiaXinXi: 'Additional Info.',
  chengBenZhongXin: 'Cost Center',
  chanPinDuan: 'Game Product Info',
  wuLiuXinXi: 'Logistics Information',
  zhangDanID: 'Bill ID',
  shangJiaMingCheng: 'Vendor Name',
  jiaoYiShiJian: 'Transaction Date',
  jiaoYiJinE: 'Amount',
  jiaoYiBiZhong: 'Currency',
  xinYongKaBenBi: 'Amount (Card Currency)',
  zhangDanXiangQing: 'Billing Details',
  guanLianXinYongKa: 'Linked Credit Card Bill ({val1})',
  beiZhuXinXi: 'Note',
  caoZuoShiJian: 'Time',
  feiKuaiDiWuLiu: 'Non-Express',
  wuLiuGongSi: 'Carrier',
  wuLiuDanHao: 'Delivery No.',
  shiJiFaHuoShu: 'Shipped Qty.',
  yuJiDaoHuoRi: 'Estimated Time of Arrival',
  faHuoRiQi: 'Shipping Date',
  queDingFaHuoMa: 'Confirm to ship?',
  faHuoShiBaiShi: 'Shipping failed, no enough Qty for shipping.',
  yiTiJiaoFaHuo: 'Shipped',
  faHuo: 'Shippment',
  tianJiaXing: 'Add line',
  mingXiHangHao: 'Line No.',
  daiFaHuoShuLiang: 'Unshipped/All',
  faHuoShiBaiShi_2: 'Shipping failed, shipping Qty should larger than 0.',
  piLiangFaSong: 'Batch sending',
  piLiangFaHuo: 'Batch shippment',
  queRenFaHuo: 'Confirm',
  shiJiFaHuoShu_2: 'Shipped Qty.: {val1}',
  caoZuoRen_2: 'Operator',
  bianGengWei: 'Updated to',
  xuQiuDanXiangQing: 'PR Details',
  zhiHangQingKuang: 'Document Flow',
  shenQingRen: 'Requester',
  shenQingRenYouXiang: 'Email',
  shenQingRenBuMen: 'Requester Department',
  shenQingSuoShuGong: 'Requester Company',
  shenQingRenChengBen: 'Cost Center',
  shenQingShiJian: 'Submit at',
  shiFouDianShang: 'From Punchout',
  shiFouBenRenShi: 'For self-use',
  shiJiShiYongRen_4: "Actual User's Cost Center",
  canKaoZongJinE: 'Ref. Amount',
  guoJia: 'Country',
  diQu: 'Region',
  shouHuoLianXiFang: 'Contact Information',
  qiWangDaoHuoRi: 'Expected Date of Delivery',
  xuQiuXinXi: 'Requisition Details',
  oAHangHao: 'PR-Line: {val1}',
  shuLiang: 'Quantity',
  xuQiuJiYuanYin: 'Reason for Purchase',
  yongTu: 'Purpose',
  dengDaiShiChang: 'Pending Buyer Action',
  xiaoShi: '{val1} H',
  xingCanKaoZongJia: 'Line Ref.  Total Amount (Tax Incl.)',
  xuQiuShenPiJie: 'PR Approval Detail',
  queRen: 'Confirm',
  jinDu: 'Progress',
  fanKuiShiJian: 'Feed back time',
  tiJiaoFanKui: 'Submit',
  tianJiaJinDu: 'Add Feed Back ',
  zhuanJiaoCaiGouYuan: 'Reassign',
  zhuanJiaoChengGong: 'Reassigned',
  dangQianCaiGouYuan: 'Current Buyer',
  xinCaiGouYuan: 'New Buyer',
  faQiXunJia: 'Create RFx',
  buChongCanKaoJia: 'You can create RFx after adding reference prices.',
  queDingYaoFaQi: 'Submit the RFx?',
  quanXuan: 'Select all',
  yiXuanXingCaiGou: '{val1} lines selected',
  oAHangHao_2: 'PR Line',
  shangPin: 'Item',
  xunJiaXinXi: 'RFx Info.',
  xunJiaDan: 'RFx',
  dingDanXinXi: 'Order Info.',
  dingDanZhuangTai: 'Order Status',
  yanShouShuLiang: 'Receipt Qty.',
  zhiXingMingXi: 'Progress Detail',
  daiBuChongCanKao: 'Pending Ref. Price',
  zhen: 'true',
  jia: 'false',
  buQiYong: 'Disabled',
  youXiao: 'Active',
  banGongYong: 'Office use',
  iTBeiJi: 'IT Backup',
  yuanGongFuLiNian: 'Employee benefits - Annual party gifts',
  yuanGongFuLiJie: 'Employee benefits - Festival gifts',
  huoDongJiangPin: 'Event gifts',
  daiBuChongHeTong: 'Pending Contract',
  daiFaHuo: 'Unshipped',
  daiYanShou: 'Pending receipt',
  buFenYanShou: 'Partially received',
  yiYanShou: 'Received',
  caiGouShenQing: 'Purchase Request',
  dianShang: 'PunchOut',
  shenPiZhong: 'Reviewing',
  shenPiJuJue: 'Reject',
  shenPiTongGuo: 'Approved',
  daiRenLingXinYong: 'Pending bill mapping',
  chaoGuoCanKaoJia: 'Over {val1}% that Ref. Price',
  wan: '10K',
  shiWan: '100K',
  baiWan: 'M',
  qianWan: '10M',
  yi: '100M',
  shiYi: 'B',
  baiYi: '10B',
  qianYi: '100B',
  weiBaoCunNeiRong: 'Unsaved data will be lost. Confirm to leave?',
  cheHuiYuanYin: 'Reason for revoke',
  cheHuiQingQiuYi: 'Revoke requested, please refresh and check the result',
  zhiDaoLe: 'Got it',
  quXiaoYuanYin: 'Reason for cancellation',
  xingVALDing: 'Line {val1}: The final amount can not be 0',
  jieDian: 'Node',
  shenPiShiJian: 'Approved Time',
  caoZuoRen: 'Approver',
  shenPiYiJian: 'Comments',
  rPXShenPi: 'RFx Approval Details',
  shenPiDanHao: 'OA workflow ID: ',
  shouQi: 'Hide',
  zhanKai: 'Show',
  dingBiaoShuLiang: 'Awarded Qty. : ',
  dingBiaoZongE: 'Awarded Amount: ',
  tiShiGaiXunJia: 'Note: The reference amount of this RFx over 20,000.00 CNY, at least 3 vendors needed.',
  dingBiao: 'Award',
  zuiYouJia: 'Best Price',
  zanWuBaoJiaXin: 'No quotes yet',
  qingDianJiXiaFang: 'Click the button below to enter quotes',
  liJiBaoJia: 'Add Quote',
  heTongMingXi: 'Contract Details',
  heTongBianHao: 'Contract No.',
  heTongMingCheng: 'Contract Title',
  qianDingRiQi: 'Signing Date',
  heTongKaiShiRi: 'Start Date',
  heTongZhongZhiRi: 'Termination Date',
  zongJinE: 'Total Amount',
  biaoQian: 'Tag',
  heJiaShuoMing: 'Pricing Result',
  baoJiaFuJian: 'Quota Doc',
  shiJiShiYongRen: 'Actual User',
  shiJiShiYongRen_2: "Actual User's Department",
  shiJiShiYongRen_3: "Actual User's Email",
  caiGouXuQiuFu: 'Requisition Attachment',
  shouHuoRenLianXi: 'Phone Number',
  caiGouYuanYin: 'Reason for Purchase',
  dingBiaoZongE_2: 'Total Awarded Amount',
  yiXunJiaZongShu: 'Quoted / All',
  ruanJian: 'Software',
  yingJian: 'Hardware',
  baoJia: 'Quote {val1}',
  dingBiaoJinE: 'Awarded Amount',
  gongYingShangLianXi: "Vendor's Contact Person",
  gongYingShangLianXi_2: 'Contact Info.',
  faSongDingDanTong: 'PO Template Auto Send',
  shi: 'Yes',
  fou: 'No',
  xiaZai: 'Download',
  queDingCheHuiShen: 'Confirm to reovke?',
  cheHuiShenPiCheng: 'Revoke succeed',
  cheHuiShenPi: 'Revoke Approval',
  queDingQuXiaoDing: 'Confirm to cancel the RFx?',
  quXiaoDingBiaoCheng: 'RFx cancelled succeed',
  quXiaoDingBiao: 'Cancel RFx',
  shouHuoRenYouXiang: "Recipient's Email",
  tiJiaoDingBiaoShi: 'Submit failed',
  dingBiaoShuLiangWei: 'Awarded Qty is 0, please check again.',
  woZhiDaoLe: 'Got it',
  xingZhongBiaoShuLiang: 'Line {val1}: Order Qty over pending quotation Qty, please check again.',
  xingYuFuJinE: 'Line {val1}: Please provide a valid prepayment amount',
  queDingYaoTiJiao: 'Submit the RFx?',
  dingBiaoTiJiaoHou: 'Once the RFx is approved, the order will be issued automatically.',
  bianJiXunJiaDan: 'Edit RFx',
  chuangJianXunJiaDan: 'Create RFx',
  tiJiaoDingBiao: 'Submit',
  xinZengBaoJia: 'Add quote',
  baoJia_2: 'Line {val1} - Quote {val2}',
  queDingYaoShanChu: 'Delete the quote?',
  danGeWenJianBu: 'File size should within 1GB',
  zhiZhiChiMYi: 'File size limit: {val1}',
  zhiNengShangChuanGe: 'Maximum of {val1} files can be uploaded',
  xuanZeHeTong: 'Select a contract',
  qingXuanZeHeTong: 'Please select a contract',
  shenPiZhongDaiXun_2: 'PR Approving/Pending RFx',
  yiShenPiDaiXun_2: 'PR Approved/Pending RFx',
  shenPiZhongYiXun_2: 'PR Approving/RFx Approved',
  shenPiZhongXunJia_2: 'PR Approving/RFx Approving',
  yiShenPiXunJia_2: 'PR Approved/RFx Approving',
  pUNCH: 'Punchout Whitelist & Alias',
  zhanDian: 'Website',
  yongHuLieBiao: 'Users List',
  baiMingDanGongNeng: 'Whitelist Status',
  queDingJinYongMa: 'Confirm to disable whitelist?',
  queDingQiYongMa: 'Confirm to enable whitelist?',
  yiJinYong: 'Disabled',
  yiQiYong: 'Enabled',
  qiYong: 'Active',
  jinYong: 'Inactive',
  yongHuYouXiang: "User's Email",
  huaMingYouXiang: 'Email Alias',
  qingTianXieYongHu: "Enter the user's email",
  xinZengYongHu: 'Add User',
  zanWuXianXiaBao: 'No quotation document',
  xianXiaBaoJiaFu: 'Quotation Document Upload',
  shenPiZhongXunJia: 'PR Approving/RFx Approving',
  yiShenPiXunJia: 'PR Approved/RFx Approving',
  queDingShanChuMa: 'Confirm to delete?',
  shanChu: 'Delete',
  liKaiYeMianDang: 'All data will be lost, if leave current page.',
  ciXiangBiTian: 'Mandatory',
  quXiao: 'Cancel',
  baoCun: 'Save',
  quanBuPinLei: 'All Categories',
  gengDuoShaiXuan: 'Other filter',
  yongHuPeiZhi: 'User Configuration',
  jueSe: 'Role',
  yongHu: 'User',
  yeWuPeiZhi: 'Business Configuration',
  huiLu: 'Exchange Rate',
  guiZePeiZhi: 'Rule Configuration',
  caiGouJieDanGui: 'Buyer Assignment',
  shenPiGuiZe: 'Approval Rules',
  teShuBiaoQian: 'Tags',
  gongZuoTai: 'Workbench',
  caiGouXuQiu: 'Requisitions',
  xunJiaDingBiao: 'Quotes',
  caiGouDingDan: 'Orders',
  shouHuoGuanLi: 'Receipt',
  gongYingShangGuanLi: 'Vendor Management',
  xiTongGuanLi: 'Settings',
  danHaoBaoKuoV: 'Search for PR No. include {val1}',
  danHaoBaoKuoV_2: 'Search for RFx No. include {val1}',
  danHaoBaoKuoV_3: 'Search for PO No. include {val1}',
  shangPinMingBaoKuo: 'Search for PO including the item name {val1}',
  qingSouSuoDanHao: 'Search Doc No./Item/Vendor Name',
  guanLiYuan: 'Administrator',
  tuiChuDengLu: 'Log out',
  guiZeZhuangTai: 'Status',
  manZuYiXiaTiao: 'The RFx will be reviewed when the following conditions are met',
  tiaoJian: 'Conditions',
  shanChuTiaoJian: 'Delete',
  tianJiaTiaoJian: 'Add',
  xinJianShenPiGui: 'Create Review Rules - RFx Review',
  bianJiShenPiGui: 'Edit Review Rules - RFx Review',
  baoCunChengGong: 'Saved',
  xinJianChengGong: 'Created',
  xinJianGuiZe: 'Create Rule',
  bianJi: 'Edit',
  shenPiGuiZeMing: 'Rule Name',
  youXianJi: 'Priority',
  oAShenPiLian: 'OA Workflow',
  bianGengQian: 'Before',
  bianGengHou: 'After',
  chaKan: 'View',
  bianGengJiLuR: 'Change Records - RFx Review - {val1}',
  zanWuBianGengJi: 'No change records',
  qingShuRuGuiZe: 'Search by rule name',
  leiXing: 'Type',
  zhuangTai: 'Status',
  bianGengJiLu: 'Change Log',
  caoZuo: 'Operate',
  queDingShiXiaoMa: 'Confirm to inactive?',
  queDingShengXiaoMa: 'Confirm to active?',
  shiXiao: 'Inactive',
  shengXiao: 'Active',
  yiShiXiao: 'Inactive',
  yiShengXiao: 'Active',
  zanWuShuJu: 'No data',
  rFXShenPi: 'RFx Review',
  daYu: '＞',
  xiaoYu: '＜',
  dengYu: '＝',
  daYuDengYu: '≥',
  xiaoYuDengYu: '≤',
  buDengYu: '≠',
  cunZaiYu: 'Contains',
  zhengDanJinE: 'Order Amount',
  tiJiaoRen: 'Buyer',
  jieSuanGongSi: 'Legal Entity',
  shouHuoGuoJiaDi: "Recipient's Country/Region",
  pinLei: 'Category',
  dingBiaoGongYingShang: 'Awarded Vendor',
  zhiFuFangShi: 'Payment Method',
  zhangQi: 'Payment Terms',
  gongYingShang: 'Vendor',
  weiBao: 'Warranty',
  danJiaWeiShui: 'Unit Price (Tax Excl.)',
  baoJiaBiZhong: 'Quotation Currency',
  shuiLu: 'Tax Rate',
  xiaDanShuLiang: 'Awarded Qty.',
  hanShuiJinE: 'Total Amount(Tax Incl.)',
  tian: '{val1} days',
  yuFuJinE: 'Pre-Payment Amount',
  xinYongKaHao: 'Credit Card No.',
  heTongHao: 'Contract No.',
  beiZhu: 'Notes',
  fuJian: 'Attachment',
  shouQuan: 'License Period',
  yongJiu: 'Permanent',
  geYue: '{val1} month(s)',
  baoJiaXiangQing: 'Quotes',
  oADanHao: 'PR No.',
  canKaoLianJie: 'Reference URL',
  shangPinMingCheng: 'Item Name',
  pinPai: 'Brand',
  peiZhi: 'Configuration',
  canKaoJia: 'Ref. Price (Tax Incl.)',
  canKaoBiZhong: 'Ref. Price Currency',
  shouHuoRen: 'Recipient',
  shouHuoDiZhi: 'Delivery Address',
  youBian: 'Zip Code',
  lianXiDianHua: 'Phone Number',
  youXiang: 'Email',
  daiXunJiaZongShu: 'Pending RFx / All',
  jiBenXinXi: 'Basic Information',
  xunJiaDingBiaoHao: 'RFx No.',
  chuangJianShiJian: 'Created Time',
  tiJiaoShiJian: 'Submitted Time',
  caiGouYuan: 'Buyer',
  caiGouYuanYouXiang: 'Email',
  canKaoJiaHeJi: 'Total Ref. Price Amout(Tax Incl.)',
  dingBiaoJinEHe: 'Total Awarded Amount (Tax Incl.)',
  jieSuanGongSiBi: 'Currency of Legal Entity',
  shenPiZhongDaiXun: 'PR Approving/Pending RFx',
  yiShenPiDaiXun: 'PR Approved/Pending RFx',
  shenPiZhongYiXun: 'PR Approving/RFx Approved',
  daiJiaGeHuiZong: 'Pending other RFx',
  yiXiaDan: 'Ordered',
  yiQuXiao: 'Canceled',
  zhuanZhang: 'Bank Transfer',
  xinYongKa: 'Credit Card',
  zhuanZhangYuFu: 'Pre-Payment',
  wangLuoYiChang: 'Network error',
}
