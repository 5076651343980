import { DataOfResponse, createUri, createEnum } from '@oahz/neact-openapi'
import request, { ReqOpts } from '@/shared/nxios'
export type StagingQuotationSaveCommand = {
  /** 报价ID */
  quotationId?: string
  /** 暂存信息 */
  stagingInfo?: string
}
export type ResponseVoid = {
  status?: number
  data?: object
  debugMessage?: string
  message?: string
}
export type ResponseOcrTaskId = {
  status?: number
  /** 数据 */
  data?: string
  message?: string
}
/**
 * 发票类型
 */
export type StInvoiceType = '01' | '02'
/**
 * 发票子分类
 */
export type StOcrInvoiceSubType =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
/**
 * 验真状态
 */
export type InvoiceCheckingStatus = '1' | '2' | '3'
/**
 * 发票附件
 */
export type StorageObjectCommand = {
  id: number
  name: string
  size: number
}
/**
 * 币种
 */
export type Currency =
  | 'CNY'
  | 'USD'
  | 'HKD'
  | 'EUR'
  | 'GBP'
  | 'KRW'
  | 'JPY'
  | 'AUD'
  | 'CAD'
  | 'TWY'
  | 'CHF'
  | 'DKK'
  | 'NZD'
  | 'PHP'
  | 'RUB'
  | 'SGD'
  | 'THB'
  | 'VND'
/**
 * 发票提交 - 基础信息
 */
export type InvoiceSubmitBaseInfoCommand = {
  /** 发票号码 */
  number?: string
  /** 发票代码 */
  code?: string
  /** 校验码 */
  checkSum?: string
  /** 开票日期 */
  openDate?: string
  /** 账期(天) */
  accountPeriod?: number
  /** 到期日 */
  expiredDate?: string
  /** 含税金额 */
  taxMoney?: number
  /** 税额 */
  tax?: number
  /** 不含税金额 */
  unTaxMoney?: number
  currency?: Currency
  /** 结算公司code */
  settlementCompanyCode?: string
  /** 备注 */
  remark?: string
}
/**
 * 发票提交行
 */
export type InvoiceSubmitLineCommand = {
  /** 商品或服务名称 */
  productName?: string
  /** 规格型号 */
  specification?: string
  /** 单位 */
  unit?: string
  /** 数量 */
  quantity?: string
  /** 未税单价 */
  unTaxUnitPrice?: number
  /** 未税金额 */
  unTaxMoney?: number
  /** 税率 */
  taxRate?: number
  /** 税额 */
  taxAmount?: number
}
/**
 * 金额
 */
export type MoneyCommand = {
  /** 数值 */
  amount?: number
  currency?: Currency
}
/**
 * 金额计算基准
 */
export type MoneyCalBenchmark = 'UNTAXED' | 'TAXED'
/**
 * 发票提交 - 匹配的 行数据
 */
export type InvoiceSubmitMatchGrCommand = {
  /** 订单id */
  orderId?: string
  /** 订单行id */
  orderLineId?: string
  /** 订单行 关联的oa单号 */
  oaNo?: string
  /** ASN id */
  asnId?: string
  /** asn 行ID */
  asnLineId?: string
  /** gr no */
  grId?: string
  /** 计提单号 */
  accrualNo?: string
  /** 税率 */
  taxRate?: number
  occupiedMoney?: MoneyCommand
  taxOccupiedMoney?: MoneyCommand
  moneyCalBenchmark?: MoneyCalBenchmark
}
/**
 * 发票提交 - 匹配的 行数据
 */
export type InvoiceSubmitMatchOrderLineCommand = {
  /** 订单id */
  orderId?: string
  /** 订单行id */
  orderLineId?: string
  /** 订单行 关联的oa单号 */
  oaNo?: string
  occupiedMoney?: MoneyCommand
  taxOccupiedMoney?: MoneyCommand
  /** 税率 */
  taxRate?: number
  moneyCalBenchmark?: MoneyCalBenchmark
  grList?: InvoiceSubmitMatchGrCommand[]
}
/**
 * 发票提交 - 匹配的 订单数据
 */
export type InvoiceSubmitMatchOrderCommand = {
  /** 订单id */
  orderId?: string
  taxOccupiedMoney?: MoneyCommand
  occupiedMoney?: MoneyCommand
  moneyCalBenchmark?: MoneyCalBenchmark
  /** 订单行 */
  lines?: InvoiceSubmitMatchOrderLineCommand[]
}
/**
 * 发票提交
 */
export type InvoiceSubmitCommand = {
  /** 发票id，编辑时必传 */
  id?: string
  type?: StInvoiceType
  subType?: StOcrInvoiceSubType
  truthCheckingStatus?: InvoiceCheckingStatus
  /** 税务银擎发票key */
  yqInvoiceKey?: string
  /** 税务银擎发票id */
  yqInvoiceId?: number
  attachment?: StorageObjectCommand
  baseInfo?: InvoiceSubmitBaseInfoCommand
  /** 发票行信息 */
  lines?: InvoiceSubmitLineCommand[]
  /** 关联的订单列表 */
  matchOrders?: InvoiceSubmitMatchOrderCommand[]
  /** 是否需要用户确认 */
  needUserConfirm?: boolean
}
export type ResponseStInvoiceId = {
  status?: number
  /** 数据 */
  data?: string
  message?: string
}
/**
 * 查询可匹配的订单
 */
export type InvoiceMatchDataQuery = {
  /** 发票ID，编辑时必传 */
  invoiceId?: string
  /** 订单ID 或 OA单号 */
  keyword?: string
  currency?: Currency
  /** 结算公司编码 */
  settlementCompanyCode?: string
  /** 供应商ID */
  supplierId?: string
}
/**
 * 价格
 */
export type MoneyDto = {
  /** 金额 */
  amount?: number
  currency?: Currency
  /** 币种符号 */
  currencySymbol?: string
}
/**
 * 操作人 DTO
 */
export type OperatorDto = {
  /** 用户id */
  id?: number
  /** 姓名 */
  name?: string
  /** 邮箱 */
  mail?: string
}
/**
 * 发票匹配的 gr 数据
 */
export type InvoiceMatchGrDto = {
  /** ASN id */
  asnId?: string
  /** asn 行ID */
  asnLineId?: string
  /** grId */
  grNo?: string
  /** 计提单号 */
  accrualNo?: string
  /** 订单行id */
  orderLineId?: string
  /** 订单号 */
  orderId?: string
  /** oa 单号 */
  oaNo?: string
  /** 商品名称 */
  goodsName?: string
  /** 商品数量 */
  quantity?: number
  taxMoney?: MoneyDto
  unTaxMoney?: MoneyDto
  /** 税额 */
  tax?: number
  /** 税率 */
  taxRate?: number
  moneyCalBenchmark?: MoneyCalBenchmark
  buyer?: OperatorDto
  /** 创建时间 */
  createdAt?: string
  remainedMoney?: MoneyDto
  unTaxRemainedMoney?: MoneyDto
  /** 是否已经被当前发票匹配 */
  hasMatched?: boolean
  occupiedMoney?: MoneyDto
}
/**
 * 发票匹配的 订单行 数据
 */
export type InvoiceMatchOrderLineDto = {
  /** 订单行号 */
  orderLineId?: string
  /** 订单行号 */
  orderLineIndex?: number
  /** oa 单号 */
  oaNo?: string
  /** 订单号 */
  orderId?: string
  /** 商品名称 */
  goodsName?: string
  /** 商品数量 */
  quantity?: number
  taxMoney?: MoneyDto
  unTaxMoney?: MoneyDto
  /** 税额 */
  tax?: number
  /** 税率 */
  taxRate?: number
  moneyCalBenchmark?: MoneyCalBenchmark
  buyer?: OperatorDto
  /** 创建时间 */
  createdAt?: string
  remainedMoney?: MoneyDto
  unTaxRemainedMoney?: MoneyDto
  /** 是否已经被当前发票匹配 */
  hasMatched?: boolean
  occupiedMoney?: MoneyDto
  /** 子级数据 */
  grList?: InvoiceMatchGrDto[]
}
/**
 * 发票匹配的 订单数据
 */
export type InvoiceMatchOrderDto = {
  /** 订单号 */
  orderId?: string
  /** oa 单号 */
  oaNo?: string[]
  /** 商品名称 */
  goodsName?: string[]
  /** 商品数量 */
  quantity?: number
  taxMoney?: MoneyDto
  unTaxMoney?: MoneyDto
  /** 税额 */
  tax?: number
  moneyCalBenchmark?: MoneyCalBenchmark
  buyer?: OperatorDto
  remainedMoney?: MoneyDto
  /** 是否已经被当前发票匹配 */
  hasMatched?: boolean
  occupiedMoney?: MoneyDto
  /** 子级数据 */
  lines?: InvoiceMatchOrderLineDto[]
  /** 创建时间 */
  createdAt?: string
}
export type ResponseListInvoiceMatchOrderDto = {
  status?: number
  /** 数据 */
  data?: InvoiceMatchOrderDto[]
  message?: string
}
export type ResponseListInvoiceMatchOrderLineDto = {
  status?: number
  /** 数据 */
  data?: InvoiceMatchOrderLineDto[]
  message?: string
}
export type ResponseListInvoiceMatchGrDto = {
  status?: number
  /** 数据 */
  data?: InvoiceMatchGrDto[]
  message?: string
}
/**
 * 发票支付状态
 */
export type PayStatus = '01' | '02' | '03'
/**
 * 发票列表查询
 */
export type InvoiceListQuery = {
  current?: number
  limit?: number
  /** 发票号码、供应商 */
  keyword?: string
  /** 发票支付状态 */
  payStatus?: PayStatus[]
  /** 发票类型 */
  invoiceTypes?: StInvoiceType[]
  /** 发票到期日-开始 */
  expiredDateStart?: string
  /** 发票到期日-结束 */
  expiredDateEnd?: string
  /** 结算公司 */
  settlementCompanyCode?: string[]
}
/**
 * 发票状态
 */
export type StInvoiceStatus = '01' | '02' | '04' | '05'
/**
 * 附件
 */
export type StorageObjectDto = {
  /** 文件id */
  id?: number
  /** 文件名称 */
  name?: string
  /** 文件大小 */
  size?: number
}
/**
 * 发票创建方
 */
export type InvoiceSourceParty = '0' | '1'
/**
 * 发票列表DTO
 */
export type InvoiceListDto = {
  /** 发票id */
  id?: string
  /** 发票号码 */
  number?: string
  status?: StInvoiceStatus
  payStatus?: PayStatus
  type?: StInvoiceType
  /** 付款单ID */
  paymentId?: string
  /** 结算公司code */
  settlementCompanyCode?: string
  /** 结算公司名称 */
  settlementCompanyName?: string
  /** 供应商ID */
  supplierId?: string
  /** 供应商名称 */
  supplierName?: string
  taxMoney?: MoneyDto
  tax?: MoneyDto
  attachment?: StorageObjectDto
  /** 关联的订单ID列表 */
  orderIds?: string[]
  /** 开票日期 */
  openDate?: string
  /** 发票到期日 */
  expiredDate?: string
  checkingState?: InvoiceCheckingStatus
  /** 采购员 */
  buyers?: OperatorDto[]
  /** 发票在银擎的发票ID */
  yqId?: string
  /** 上传时间 */
  createdAt?: string
  creator?: OperatorDto
  sourceParty?: InvoiceSourceParty
  /** 是否可以编辑 */
  canUpdate?: boolean
}
/**
 * 数据
 */
export type PageDtoInvoiceListDto = {
  total?: number
  records?: InvoiceListDto[]
}
export type ResponsePageDtoInvoiceListDto = {
  status?: number
  data?: PageDtoInvoiceListDto
  message?: string
}
/**
 * 转换成树形结构的关联信息
 */
export type InvoiceConvertMatchTreeCommand = {
  /** 订单号、订单行号、gr 号 */
  ids?: string[]
  /** 供应商ID */
  supplierId?: string
}
export type StFileRequestUploadCommand = {
  /** 文件名 */
  fileName?: string
  /** 文件大小 */
  fileSize?: number
}
/**
 * 数据
 */
export type FileRequestUploadDto = {
  /** 上传地址 */
  uploadUrl?: string
  /** 完成令牌 */
  token?: string
  /** 额外头部 */
  extraHeaders?: {
    [key: string]: any
  }
}
export type ResponseFileRequestUploadDto = {
  status?: number
  data?: FileRequestUploadDto
  message?: string
}
export type FileCompleteUploadCommand = {
  /** 令牌 */
  token?: string
}
/**
 * 数据
 */
export type FileCompleteUploadDto = {
  /** 文件地址 */
  url?: string
  /** 文件id */
  id?: number
  /** 文件名 */
  fileName?: string
  /** 文件大小 */
  fileSize?: number
}
export type ResponseFileCompleteUploadDto = {
  status?: number
  data?: FileCompleteUploadDto
  message?: string
}
export type QuotationTenderWorkbenchQuery = {
  current?: number
  limit?: number
  /** 单号/招标书标题 */
  keyword?: string
  /** 投标开始时间 */
  beginAt?: string
  /** 投标结束时间 */
  endAt?: string
}
/**
 * 状态
 */
export type QuotationStatus = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11'
export type QuotationTenderWorkbenchListDto = {
  /** id */
  id?: string
  /** 招标事件号 */
  rfxId?: string
  status?: QuotationStatus
  /** 招标书标题 */
  title?: string
  /** 时区 */
  timeZone?: string
  /** 投标开始时间 */
  beginAt?: string
  /** 投标结束时间 */
  endAt?: string
  /** 招标公司code */
  tenderCompanyCode?: string
  /** 招标公司 */
  tenderCompany?: string
  /** 是否已应标 */
  respondTender?: boolean
  /** 是否报价澄清 */
  isClarification?: boolean
  /** 报价澄清截止时间 */
  clarificationDeadlineAt?: string
}
export type PageDtoQuotationTenderWorkbenchListDto = {
  total?: number
  records?: QuotationTenderWorkbenchListDto[]
}
export type ResponsePageDtoQuotationTenderWorkbenchListDto = {
  status?: number
  data?: PageDtoQuotationTenderWorkbenchListDto
  debugMessage?: string
  message?: string
}
export type QuotationWorkbenchQuery = {
  current?: number
  limit?: number
  /** 截至开始时间 */
  deadlineStart?: string
  /** 截至结束时间 */
  deadlineEnd?: string
  /** 询价单号/商品名称 */
  keyword?: string
}
/**
 * 询价行
 */
export type QuotationLineWorkbenchListDto = {
  /** 报价行ID */
  id?: string
  /** 商品名称 */
  goodsName?: string
  /** 品牌 */
  goodsBrand?: string
  /** 配置 */
  goodsConfiguration?: string
  /** 数量 */
  quantity?: number
}
export type QuotationWorkbenchListDto = {
  /** 报价ID */
  id?: string
  /** 询价ID */
  rfxId?: string
  /** 结算公司code */
  settlementCompanyCode?: string
  /** 结算公司名称 */
  settlementCompanyName?: string
  /** 报价截止时间 */
  deadlineAt?: string
  /** 询价行 */
  lines?: QuotationLineWorkbenchListDto[]
}
export type PageDtoQuotationWorkbenchListDto = {
  total?: number
  records?: QuotationWorkbenchListDto[]
}
export type ResponsePageDtoQuotationWorkbenchListDto = {
  status?: number
  data?: PageDtoQuotationWorkbenchListDto
  debugMessage?: string
  message?: string
}
export type FmFulfillmentWorkbenchQuery = {
  current?: number
  limit?: number
  /** PO单号/商品名称 */
  keyword?: string
  /** 下单开始时间 */
  orderTimeStart?: string
  /** 下单结束时间 */
  orderTimeEnd?: string
}
/**
 * 收货信息
 */
export type FmReceivingInfoDto = {
  /** 收货人 */
  receiver?: string
  /** 联系方式 */
  contactWay?: string
  /** 收货地址 */
  address?: string
  /** 邮编 */
  postalCode?: string
  /** 收货人邮箱 */
  receiverEmail?: string
  /** 国家ID */
  countryId?: number
  /** 国家名称 */
  countryName?: string
  /** 省份ID */
  stateId?: number
  /** 省份名称 */
  stateName?: string
  /** 城市ID */
  cityId?: number
  /** 城市名称 */
  cityName?: string
  /** 州/省/市 */
  deliveryRegion?: string
}
/**
 * 状态
 */
export type FmOrderLineStatus = '1' | '2' | '3' | '4'
/**
 * 下单方式
 */
export type PlaceOrderMethod = '1' | '2'
/**
 * 发货信息
 */
export type FmDeliveryFulfillmentWaitListLineDto = {
  /** id */
  id?: string
  /** 行ID */
  lineNo?: string
  /** 商品名称 */
  goodsName?: string
  /** 品牌 */
  goodsBrand?: string
  /** 配置 */
  goodsConfiguration?: string
  status?: FmOrderLineStatus
  orderMethod?: PlaceOrderMethod
  shippingAmount?: MoneyDto
  /** 下单数量 */
  orderQuantity?: number
  orderAmount?: MoneyDto
  /** 可发货数量 */
  shippingQuantity?: number
}
export type FmDeliveryFulfillmentWaitListDto = {
  /** 订单ID */
  id?: string
  /** 订单号 */
  orderNo?: string
  /** 下单时间 */
  orderTime?: string
  receivingInfo?: FmReceivingInfoDto
  /** 发货信息 */
  lines?: FmDeliveryFulfillmentWaitListLineDto[]
}
export type PageDtoFmDeliveryFulfillmentWaitListDto = {
  total?: number
  records?: FmDeliveryFulfillmentWaitListDto[]
}
export type ResponsePageDtoFmDeliveryFulfillmentWaitListDto = {
  status?: number
  data?: PageDtoFmDeliveryFulfillmentWaitListDto
  debugMessage?: string
  message?: string
}
/**
 * 发票类型
 */
export type InvoiceType =
  | 'VAT_SPECIAL_INVOICE'
  | 'VAT_GENERAL_INVOICE'
  | 'E_INVOICE_SPECIAL'
  | 'E_INVOICE_GENERAL'
  | 'D_INVOICE_SPECIAL'
  | 'D_INVOICE_GENERAL'
  | 'OTHERS'
/**
 * 报价行
 */
export type UpdateQuotationLineCommand = {
  /** 报价行ID */
  id?: string
  /** rfx行ID */
  rfxLineId?: string
  moneyCalBenchmark?: MoneyCalBenchmark
  /** 税率 */
  taxRate?: number
  unitUnTaxMoney?: MoneyCommand
  unitMoney?: MoneyCommand
  /** 供货周期 */
  supplyCycle?: number
  invoiceType?: InvoiceType
  quotationUnTaxMoney?: MoneyCommand
  quotationMoney?: MoneyCommand
  freight?: MoneyCommand
  /** 报价有效期 */
  validityPeriod?: string
  /** 维保期限 */
  maintainPeriod?: number
  /** 报价备注 */
  quotationRemark?: string
  /** 是否招标弃标 */
  isTenderDiscarded?: boolean
  /** 附件 */
  quotationAttachments?: StorageObjectCommand[]
  /** 商务标附件 */
  commercialMarkAttachments?: StorageObjectCommand[]
  /** 技术标附件 */
  technicalMarkAttachments?: StorageObjectCommand[]
}
export type UpdateQuotationCommand = {
  /** 报价行 */
  lines?: UpdateQuotationLineCommand[]
}
/**
 * 查询类型
 */
export type QuotationQueryType = 'MY' | 'OTHER'
export type QuotationTenderQuestionListQuery = {
  /** rfxId */
  rfxId?: string
  type?: QuotationQueryType
}
/**
 * 澄清人
 */
export type PartnerOperator = {
  id?: number
  name?: string
  jobNumber?: string
  mail?: string
  system?: boolean
}
/**
 * 咨询
 */
export type QuotationTenderQuestionConsultationDto = {
  /** 咨询标题 */
  title?: string
  /** 咨询内容 */
  content?: string
  creator?: PartnerOperator
  /** 创建时间 */
  createdAt?: string
}
/**
 * 回复
 */
export type QuotationTenderQuestionReplyDto = {
  /** 回复标题 */
  title?: string
  /** 回复内容 */
  content?: string
  creator?: PartnerOperator
  /** 创建时间 */
  createdAt?: string
}
/**
 * 问题状态
 */
export type QuotationQuestionStatus = '0' | '1'
export type QuotationTenderQuestionDto = {
  consultation?: QuotationTenderQuestionConsultationDto
  reply?: QuotationTenderQuestionReplyDto
  questionStatus?: QuotationQuestionStatus
}
export type ResponseListQuotationTenderQuestionDto = {
  status?: number
  data?: QuotationTenderQuestionDto[]
  debugMessage?: string
  message?: string
}
export type ParticipateTenderCommand = {
  /** 是否同意应答条件 */
  isAgree?: boolean
  confirmationLetterAttachment?: StorageObjectCommand
  confidentialityAgreementAttachment?: StorageObjectCommand
}
/**
 * 状态
 */
export type QuotationDisplayTenderStatus = '8' | '9' | '10' | '3' | '4' | '5' | '6' | '7' | '11'
export type QuotationTenderQuery = {
  current?: number
  limit?: number
  /** 单号/招标书标题 */
  keyword?: string
  /** 投标开始时间 */
  beginAt?: string
  /** 投标结束时间 */
  endAt?: string
  /** 状态 */
  statusSet?: QuotationDisplayTenderStatus[]
}
export type QuotationTenderListDto = {
  /** id */
  id?: string
  /** 招标事件号 */
  rfxId?: string
  status?: QuotationStatus
  /** 招标书标题 */
  title?: string
  /** 时区 */
  timeZone?: string
  /** 投标开始时间 */
  beginAt?: string
  /** 投标结束时间 */
  endAt?: string
  /** 招标公司code */
  tenderCompanyCode?: string
  /** 招标公司 */
  tenderCompany?: string
  /** 是否已应标 */
  respondTender?: boolean
  /** 是否报价澄清 */
  isClarification?: boolean
  /** 报价澄清截止时间 */
  clarificationDeadlineAt?: string
}
export type PageDtoQuotationTenderListDto = {
  total?: number
  records?: QuotationTenderListDto[]
}
export type ResponsePageDtoQuotationTenderListDto = {
  status?: number
  data?: PageDtoQuotationTenderListDto
  debugMessage?: string
  message?: string
}
export type QuestionTenderConsultationCommand = {
  /** 咨询标题 */
  title?: string
  /** 咨询内容 */
  content?: string
}
export type FileRequestUploadCommand = {
  /** 文件名 */
  fileName?: string
  /** 文件大小 */
  fileSize?: number
}
/**
 * 报价状态
 */
export type QuotationDisplayStatus = '1' | '2' | '3' | '4' | '5' | '6' | '7'
export type QuotationQuery = {
  current?: number
  limit?: number
  /** 截至开始时间 */
  deadlineStart?: string
  /** 截至结束时间 */
  deadlineEnd?: string
  /** 询价单号/商品名称 */
  keyword?: string
  /** 报价状态 */
  statusSet?: QuotationDisplayStatus[]
}
/**
 * 行
 */
export type QuotationLineListDto = {
  /** 报价行ID */
  id?: string
  /** 询价单行id */
  rfxLineId?: string
  /** 商品名称 */
  goodsName?: string
  /** 品牌 */
  goodsBrand?: string
  /** 配置 */
  goodsConfiguration?: string
  /** 数量 */
  quantity?: number
  /** 结算公司名称 */
  settlementCompanyName?: string
  /** 报价截止时间 */
  deadlineAt?: string
  /** 是否报价澄清 */
  isClarification?: boolean
  /** 报价澄清截止时间 */
  clarificationDeadlineAt?: string
}
export type QuotationListDto = {
  /** 报价ID */
  id?: string
  /** 询价ID */
  rfxId?: string
  status?: QuotationStatus
  /** 行 */
  lines?: QuotationLineListDto[]
  /** 是否可以报价 */
  quotable?: boolean
}
export type PageDtoQuotationListDto = {
  total?: number
  records?: QuotationListDto[]
}
export type ResponsePageDtoQuotationListDto = {
  status?: number
  data?: PageDtoQuotationListDto
  debugMessage?: string
  message?: string
}
/**
 * 状态
 */
export type AccountStatus = '0' | '1'
/**
 * 账号类型
 */
export type AccountType = '1' | '0'
export type IdAccountListDto = {
  /** 账号id */
  id?: number
  /** 所属企业id */
  enterpriseId?: string
  status?: AccountStatus
  type?: AccountType
  /** 账号名称 */
  name?: string
  /** 邮箱 */
  mail?: string
  /** 账号电话 */
  tel?: string
  /** 账号区号 */
  telAreaCode?: string
}
export type PageDtoIdAccountListDto = {
  total?: number
  records?: IdAccountListDto[]
}
export type ResponsePageDtoIdAccountListDto = {
  status?: number
  data?: PageDtoIdAccountListDto
  debugMessage?: string
  message?: string
}
export type IdCreateAccountCommand = {
  /** 账号名称 */
  name: string
  /** 区号 */
  telAreaCode?: string
  /** 电话号码 */
  tel?: string
  /** 邮箱 */
  mail: string
  /** 密码 */
  password?: string
  status: AccountStatus
}
export type IdAccountDto = {
  /** id */
  id?: number
  /** 企业id */
  enterpriseId?: string
  type?: AccountType
  status?: AccountStatus
  /** 账号名称 */
  name?: string
  /** 邮箱 */
  mail?: string
  /** 账号电话 */
  tel?: string
  /** 账号区号 */
  telAreaCode?: string
}
export type ResponseIdAccountDto = {
  status?: number
  data?: IdAccountDto
  debugMessage?: string
  message?: string
}
export type IdUpdateAccountCommand = {
  /** 姓名 */
  name?: string
  /** 电话号码 */
  tel?: string
  /** 区号 */
  telAreaCode?: string
  status?: AccountStatus
}
export type IdTransferAccountCommand = {
  /** 转交的目标账号 */
  targetAccountId?: number
  /** 转交的源账号 */
  sourceAccountId?: number
}
export type ResponseBoolean = {
  status?: number
  data?: boolean
  debugMessage?: string
  message?: string
}
export type IdEnableAccountCommand = {
  /** 启用的账号 */
  accountId: number
}
export type IdDisableAccountCommand = {
  /** 禁用的账号 */
  accountId?: number
}
export type IdChangeAccountPasswordCommand = {
  /** 旧密码 */
  oldPassword: string
  /** 新密码 */
  newPassword: string
  /** 确认密码 */
  confirmPassword: string
}
/**
 * 企业类型
 */
export type EnterpriseType = '01' | '02'
/**
 * 证件类型
 */
export type PersonIdType = '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'
export type IdEnterpriseRegistrationCommand = {
  /** 邀请注册的Token */
  token: string
  type: EnterpriseType
  /** 国家/地区 */
  countryId: number
  /** 国家/地区Code */
  countryCode: string
  /** 企业名称 */
  name?: string
  /** 社会统一信用代码 */
  creditCode?: string
  /** 联系人邮箱 */
  contactMail?: string
  /** 联系人名称 */
  contactName?: string
  /** 税务登记id */
  tin?: string
  personIdType?: PersonIdType
  /** 个人证件号 */
  idNumber?: string
  /** 验证码 */
  verificationCode: string
  /** 密码 */
  password: string
  /** 确认密码 */
  confirmPassword: string
  uniqueKey?: string
}
export type ResponseString = {
  status?: number
  data?: string
  debugMessage?: string
  message?: string
}
export type IdSendRegistrationVerificationCodeCommand = {
  /** 邮箱 */
  mail: string
}
export type IdSupplierAdminRegistrationCommand = {
  /** 邀请注册的Token */
  token: string
  /** 供应商ID */
  supplierId: string
  /** 企业名称 */
  supplierName?: string
  /** 姓名 */
  username?: string
  /** 手机号 */
  phoneNumber?: string
  /** 注册邮箱 */
  registerMail?: string
  /** 验证码 */
  verificationCode: string
  /** 密码 */
  password: string
  /** 确认密码 */
  confirmPassword: string
  /** 对接采购员姓名 */
  businessName?: string
  /** 对接采购员邮箱 */
  businessMail?: string
  attachment?: StorageObjectCommand
  /** 注册说明 */
  description?: string
}
/**
 * 个人 基本信息
 */
export type PersonBaseInfoCommand = {
  /** 基础信息 姓名 */
  userName?: string
  personIdType?: PersonIdType
  /** 基础信息 证件号码 */
  personId?: string
  /** 基础信息 联系邮箱 */
  mail?: string
}
/**
 * 供应商合作状态
 */
export type SpCooperateStatus = '01' | '02' | '03' | '04' | '05' | '06' | '07'
/**
 * 准入信息
 */
export type SupplierOnboardingInfoCommand = {
  cooperateStatus?: SpCooperateStatus
  /** 准入信息 业务线ID */
  businessId?: string[]
  /** 准入信息 推荐人姓名 */
  recommenderName?: string
  /** 准入信息 推荐人邮箱 */
  recommenderMail?: string
  /** 准入信息 推荐原因 */
  recommendReason?: string
  /** 准入信息 准入说明 */
  remark?: string
}
/**
 * 个人 登记信息
 */
export type PersonCheckInCommand = {
  /** 姓名 */
  userName?: string
  personIdType?: PersonIdType
  /** 证件号码 */
  personId?: string
  /** 邮编 */
  postCode?: string
}
/**
 * 服务品类
 */
export type ServiceCategoryCommand = {
  /** 最末级 品类ID */
  categoryIds?: number[]
}
/**
 * 个人 联系人信息
 */
export type PersonContactCommand = {
  /** id,更新时回传 */
  id?: number
  /** 联系人姓名 */
  name?: string
  /** 联系人邮箱 */
  mail?: string
  /** 联系人手机 */
  phone?: string
  /** sns */
  sns?: string
  /** 联系人备注 */
  remark?: string
}
/**
 * 是否是默认账号
 */
export type StatusEnum = '1' | '0'
/**
 * 个人 银行信息
 */
export type PersonBankInfoCommand = {
  /** id,更新时回传 */
  id?: number
  /** 银行账号 */
  account?: string
  /** 账号名称 */
  accountName?: string
  currency?: Currency
  /** 开户行名称 */
  openBankName?: string
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** SWIFT CODE */
  swiftCode?: string
  /** 支行名称 */
  branchName?: string
  status?: StatusEnum
  /** 失效时间 */
  invalidAt?: string
  primary?: StatusEnum
  /** 备注 */
  remark?: string
}
/**
 * 税表类型
 */
export type EnterpriseTaxType = 'W8-BEN' | 'W8-BEN-E' | 'W-8ECI' | 'W-8EXP' | 'W9' | 'others'
/**
 * 纳税主体
 */
export type EnterpriseTaxPayer =
  | 'Individual'
  | 'C corp'
  | 'S corp'
  | 'Partnership'
  | 'Trust/estate'
  | 'LLC-C'
  | 'LLC-S'
  | 'LLC-P'
  | 'others'
/**
 * 企业 税务信息 command
 */
export type EnterpriseTaxInfoCommand = {
  taxType?: EnterpriseTaxType
  taxPayer?: EnterpriseTaxPayer
  taxAttachment?: StorageObjectCommand
  /** 备注 */
  taxRemark?: string
}
/**
 * 供应商来源
 */
export type SourceType = '1' | '2'
/**
 * 提交 中国境外 + 个人
 */
export type CreateOverseaPersonCommand = {
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  baseInfo?: PersonBaseInfoCommand
  onboardingInfo?: SupplierOnboardingInfoCommand
  checkIn?: PersonCheckInCommand
  serviceCategories?: ServiceCategoryCommand
  /** 中国境外+个人 联系人信息 */
  contacts?: PersonContactCommand[]
  /** 中国境外+个人 银行信息 */
  bankInfos?: PersonBankInfoCommand[]
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoCommand[]
  sourceType?: SourceType
  /** 协作平台用户名 */
  partnershipUserName?: string
  /** 协作平台用户邮箱 */
  partnershipUserMail?: string
}
export type ResponseOnboardingApplicationId = {
  status?: number
  data?: number
  debugMessage?: string
  message?: string
}
/**
 * 基本信息
 */
export type BaseInfoCommand = {
  /** 基础信息 供应商名称 */
  supplierName?: string
  /** TIN */
  tin?: string
  /** 基础信息 管理员姓名 */
  userName?: string
  /** 基础信息 管理员邮箱 */
  mail?: string
}
/**
 * 纳税人资质
 */
export type TaxpayerQualification = '01' | '02' | '03' | '04'
/**
 * 企业 登记信息
 */
export type EnterpriseCheckInCommand = {
  /** 企业名称 */
  enterpriseName?: string
  /** 企业简称 */
  enterpriseAliasName?: string
  registerCapital?: MoneyCommand
  /** 公司主页地址 */
  homeSite?: string
  /** 成立日期 */
  establishTime?: string
  /** 营业期限 开始时间 */
  startAt?: string
  /** 营业期限 截止时间 */
  endAt?: string
  /** 行政区划 省州 id */
  provinceId?: number
  /** 行政区划 省州 code */
  provinceCode?: string
  /** 行政区划 市 */
  city?: string
  /** 行政区划 区/县 */
  district?: string
  /** 详细地址 */
  address?: string
  /** 经营范围 */
  businessScope?: string
  legalRegistrationLicenseFile?: StorageObjectCommand
  admittanceFile?: StorageObjectCommand
  licenseFile?: StorageObjectCommand
  taxpayerQualification?: TaxpayerQualification
  /** 统一社会信用代码 */
  creditCode?: string
  /** 法定代表人 */
  legalRepresentative?: string
  /** 邓白氏编码 */
  duns?: string
  /** TIN */
  tin?: string
  /** 邮编 */
  postCode?: string
}
/**
 * 企业 资质信息
 */
export type EnterpriseCredentialCommand = {
  /** 品类ID */
  categoryId?: number
  /** 资质名称 */
  credentialName?: string
  credentialFile?: StorageObjectCommand
  /** 有效期 开始时间 */
  startAt?: string
  /** 有效期 截止时间 */
  endAt?: string
  /** 备注 */
  remark?: string
}
/**
 * 联系人类型
 */
export type ContactType = '01' | '02' | '03'
/**
 * 企业 联系人信息
 */
export type EnterpriseContactCommand = {
  /** id,更新时回传 */
  id?: number
  contactType?: ContactType
  /** 联系人姓名 */
  name?: string
  /** 联系人职位 */
  post?: string
  /** 联系人邮箱 */
  mail?: string
  /** 联系人备注 */
  remark?: string
  /** 联系人手机 */
  phone?: string
}
/**
 * 职位
 */
export type SpSupervisorPosition = '01' | '02' | '03' | '04' | '05' | '06'
/**
 * 董监高数据
 */
export type SupervisorInfoCommand = {
  /** 姓名 */
  name?: string
  /** 职位 */
  positions?: SpSupervisorPosition[]
  personIdType?: PersonIdType
  /** 证件ID */
  personId?: string
  /** 备注 */
  remark?: string
}
/**
 * 企业 董监高信息
 */
export type EnterpriseSupervisorInfoCommand = {
  /** id,更新时回传 */
  id?: number
  /** 人员信息 */
  supervisors?: SupervisorInfoCommand[]
  /** 缺失原因 */
  absenceReason?: string
  /** 董监高利冲后继续准入原因 */
  continueReason?: string
}
/**
 * 手续费承担方
 */
export type HandlingFeeType = '01' | '02' | '03'
/**
 * 账期
 */
export type AccountPeriod = '15' | '30' | '45' | '60'
/**
 * 首选付款方式
 */
export type EnterprisePreferredPaymentMethod =
  | 'WIRE'
  | 'ACH'
  | 'ACH_EFT'
  | 'ACH_BACS'
  | 'CREDIT_CARD'
  | 'SEPA'
  | 'OTHERS'
/**
 * 账号类型
 */
export type EnterpriseBankAccountType = 'CHECKING' | 'SAVINGS' | 'CD' | 'MMA'
/**
 * 企业 银行信息
 */
export type EnterpriseBankInfoCommand = {
  /** id,更新时回传 */
  id?: number
  /** 银行账号 */
  account?: string
  /** 账号名称 */
  accountName?: string
  currency?: Currency
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 开户行名称 */
  openBankName?: string
  /** 支行名称 */
  branchName?: string
  /** 开户行省 id */
  openProvinceId?: number
  /** 开户行省 code */
  openProvinceCode?: string
  /** 开户行市 */
  openCity?: string
  /** 银行地址 */
  address?: string
  /** SWIFT CODE */
  swiftCode?: string
  /** 联行号 */
  cnaps?: string
  /** IBAN */
  iban?: string
  handlingFeeType?: HandlingFeeType
  accountPeriod?: AccountPeriod
  status?: StatusEnum
  /** 失效时间 */
  invalidAt?: string
  primary?: StatusEnum
  /** 备注 */
  remark?: string
  createPermitFile?: StorageObjectCommand
  preferredPaymentMethod?: EnterprisePreferredPaymentMethod
  accountType?: EnterpriseBankAccountType
  /** ABA/Routing number */
  abaRoutingNumber?: string
  /** Sort Code */
  sortCode?: string
  /** Bank ID */
  bankId?: string
  /** Branch ID/Code */
  branchId?: string
}
/**
 * 财务信息
 */
export type FinanceCommand = {
  /** id,更新时回传 */
  id?: number
  /** 年份 */
  year?: number
  currency?: Currency
  /** 营业收入（万） */
  income?: number
  /** 利润总额（万） */
  profit?: number
  /** 实缴资本（万） */
  paidUpCapital?: number
  /** 总资产（万） */
  totalAssets?: number
  annualAuditReportFile?: StorageObjectCommand
  financialStatementFile?: StorageObjectCommand
  /** 备注 */
  remark?: string
}
/**
 * 企业 财务状况
 */
export type EnterpriseFinanceCommand = {
  /** 财务信息 */
  financeInfos?: FinanceCommand[]
  /** 财务缺失原因 */
  financeAbsenceReason?: string
}
/**
 * 企业 成功案例
 */
export type EnterpriseSuccessCaseCommand = {
  /** id,更新时回传 */
  id?: number
  /** 案例名称 */
  name?: string
  /** 客户名称 */
  customerName?: string
  /** 服务/产品范围 */
  serviceScope?: string
  /** 项目时间 */
  projectTime?: string
  /** 项目规模（万元） */
  projectCapitalAmount?: string
  /** 项目简介 */
  introduction?: string
  /** 项目成果 */
  achievement?: string
  /** 联系人姓名 */
  contactName?: string
  /** 联系人职位 */
  contactPost?: string
  /** 联系方式 */
  contact?: string
  /** 备注 */
  remark?: string
  recommendationFile?: StorageObjectCommand
  projectFile?: StorageObjectCommand
}
/**
 * 企业 利益关系
 */
export type EnterpriseInterestRelationCommand = {
  /** id,更新时回传 */
  id?: number
  /** 员工姓名 */
  name?: string
  /** 员工所在公司 */
  company?: string
  /** 工号 */
  jobNumber?: string
  /** 邮箱 */
  mail?: string
  /** 关系 */
  relation?: string
  /** 备注 */
  remark?: string
}
/**
 * 中国境外 + 企业
 */
export type CreateOverseaEnterpriseCommand = {
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  baseInfo?: BaseInfoCommand
  onboardingInfo?: SupplierOnboardingInfoCommand
  checkIn?: EnterpriseCheckInCommand
  serviceCategories?: ServiceCategoryCommand
  /** 资质信息 */
  credentials?: EnterpriseCredentialCommand[]
  /** 联系人信息 */
  contacts?: EnterpriseContactCommand[]
  supervisorInfos?: EnterpriseSupervisorInfoCommand
  /** 银行信息 */
  bankInfos?: EnterpriseBankInfoCommand[]
  financeInfos?: EnterpriseFinanceCommand
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoCommand[]
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseCommand[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationCommand[]
  sourceType?: SourceType
  /** 协作平台用户名 */
  partnershipUserName?: string
  /** 协作平台用户邮箱 */
  partnershipUserMail?: string
}
/**
 * 提交 中国境内 + 个人
 */
export type CreateChinaPersonCommand = {
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  baseInfo?: PersonBaseInfoCommand
  onboardingInfo?: SupplierOnboardingInfoCommand
  checkIn?: PersonCheckInCommand
  serviceCategories?: ServiceCategoryCommand
  /** 联系人信息 */
  contacts?: PersonContactCommand[]
  /** 银行信息 */
  bankInfos?: PersonBankInfoCommand[]
  sourceType?: SourceType
  /** 协作平台用户名 */
  partnershipUserName?: string
  /** 协作平台用户邮箱 */
  partnershipUserMail?: string
}
/**
 * 提交 中国境内 + 企业
 */
export type CreateChinaEnterpriseCommand = {
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  baseInfo?: BaseInfoCommand
  onboardingInfo?: SupplierOnboardingInfoCommand
  checkIn?: EnterpriseCheckInCommand
  serviceCategories?: ServiceCategoryCommand
  /** 资质信息 */
  credentials?: EnterpriseCredentialCommand[]
  /** 联系人信息 */
  contacts?: EnterpriseContactCommand[]
  supervisorInfos?: EnterpriseSupervisorInfoCommand
  /** 银行信息 */
  bankInfos?: EnterpriseBankInfoCommand[]
  financeInfos?: EnterpriseFinanceCommand
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseCommand[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationCommand[]
  sourceType?: SourceType
  /** 协作平台用户名 */
  partnershipUserName?: string
  /** 协作平台用户邮箱 */
  partnershipUserMail?: string
}
/**
 * 变更 中国境外 + 个人
 */
export type ModifyOverseaPersonCommand = {
  /** 供应商ID */
  id?: string
  /** 业务线ID */
  businessId?: string
  /** 变更说明 */
  remark?: string
  checkIn?: PersonCheckInCommand
  serviceCategories?: ServiceCategoryCommand
  /** 联系人信息 */
  contacts?: PersonContactCommand[]
  /** 银行信息 */
  bankInfos?: PersonBankInfoCommand[]
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoCommand[]
  sourceType?: SourceType
  /** 协作平台用户名 */
  partnershipUserName?: string
  /** 协作平台用户邮箱 */
  partnershipUserMail?: string
}
export type ResponseModificationApplicationId = {
  status?: number
  data?: number
  debugMessage?: string
  message?: string
}
/**
 * 变更 中国境外 + 个人
 */
export type ModifyOverseaEnterpriseCommand = {
  /** 供应商ID */
  id?: string
  /** 业务线ID */
  businessId?: string
  /** 变更说明 */
  remark?: string
  checkIn?: EnterpriseCheckInCommand
  serviceCategories?: ServiceCategoryCommand
  /** 资质信息 */
  credentials?: EnterpriseCredentialCommand[]
  /** 联系人信息 */
  contacts?: EnterpriseContactCommand[]
  supervisorInfos?: EnterpriseSupervisorInfoCommand
  /** 银行信息 */
  bankInfos?: EnterpriseBankInfoCommand[]
  financeInfos?: EnterpriseFinanceCommand
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoCommand[]
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseCommand[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationCommand[]
  sourceType?: SourceType
  /** 协作平台用户名 */
  partnershipUserName?: string
  /** 协作平台用户邮箱 */
  partnershipUserMail?: string
}
/**
 * 变更 中国境内 + 个人
 */
export type ModifyChinaPersonCommand = {
  /** 供应商ID */
  id?: string
  /** 业务线ID */
  businessId?: string
  /** 变更说明 */
  remark?: string
  onboardingInfo?: SupplierOnboardingInfoCommand
  checkIn?: PersonCheckInCommand
  serviceCategories?: ServiceCategoryCommand
  /** 联系人信息 */
  contacts?: PersonContactCommand[]
  /** 银行信息 */
  bankInfos?: PersonBankInfoCommand[]
  sourceType?: SourceType
  /** 协作平台用户名 */
  partnershipUserName?: string
  /** 协作平台用户邮箱 */
  partnershipUserMail?: string
}
/**
 * 变更 中国境内 + 企业
 */
export type ModifyChinaEnterpriseCommand = {
  /** 供应商ID */
  id?: string
  /** 业务线ID */
  businessId?: string
  cooperateStatus?: SpCooperateStatus
  /** 变更说明 */
  remark?: string
  checkIn?: EnterpriseCheckInCommand
  serviceCategories?: ServiceCategoryCommand
  /** 资质信息 */
  credentials?: EnterpriseCredentialCommand[]
  /** 联系人信息 */
  contacts?: EnterpriseContactCommand[]
  supervisorInfos?: EnterpriseSupervisorInfoCommand
  /** 银行信息 */
  bankInfos?: EnterpriseBankInfoCommand[]
  financeInfos?: EnterpriseFinanceCommand
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseCommand[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationCommand[]
  sourceType?: SourceType
  /** 协作平台用户名 */
  partnershipUserName?: string
  /** 协作平台用户邮箱 */
  partnershipUserMail?: string
}
export type IdSendResetPasswordCodeCommand = {
  /** 邮箱 */
  mail: string
}
export type IdResetPasswordCommand = {
  /** 邮箱 */
  mail: string
  /** 验证码 */
  verificationCode: string
  /** 企业名称 */
  enterpriseName: string
  /** 密码 */
  password: string
  /** 确认密码 */
  confirmPassword: string
}
export type IdLoginCommand = {
  /** 账号 */
  account: string
  /** 密码 */
  password: string
  /** 重定向路径 */
  redirectPath?: string
}
export type SystemLocale = 'zh-CN' | 'en-US' | 'ja-JP' | 'fr-FR'
/**
 * 发货类型
 */
export type FmDeliveryType = '0' | '1'
/**
 * 附件
 */
export type StorageObject = {
  id?: number
  name?: string
  size?: number
}
/**
 * 发货信息
 */
export type FmDeliveryFulfillmentInfoCommand = {
  /** 发货人 */
  deliverer?: string
  /** 发货时间 */
  deliveryTime?: string
  type?: FmDeliveryType
  /** 预计到货时间 */
  estimateArrivalTime?: string
  /** 物流公司 */
  courierCompanyId?: number
  /** 物流单号 */
  trackingNo?: string
  /** 附件 */
  attachments?: StorageObject[]
  /** 备注 */
  remark?: string
}
/**
 * 金额
 */
export type Money = {
  amount?: number
  currency?: Currency
}
/**
 * 发货行
 */
export type FmOrderDeliveryFulfillmentLineCommand = {
  /** 发货行ID */
  id?: string
  /** 订单行ID */
  lineNo?: string
  orderMethod?: PlaceOrderMethod
  /** 发货数量 */
  quantity?: number
  amount?: Money
  unTaxAmount?: Money
  /** 附件 */
  attachments?: StorageObject[]
  /** 备注 */
  remark?: string
}
export type FmDeliveryFulfillmentCommand = {
  deliveryInfo?: FmDeliveryFulfillmentInfoCommand
  /** 发货行 */
  lines?: FmOrderDeliveryFulfillmentLineCommand[]
}
export type FmOrderListQuery = {
  current?: number
  limit?: number
  /** 订单号/商品名称 */
  keyword?: string
  /** 下单开始时间 */
  orderTimeStart?: string
  /** 下单结束时间 */
  orderTimeEnd?: string
  /** 期望到货开始时间 */
  expectedDeliveryTimeStart?: string
  /** 期望到货结束时间 */
  expectedDeliveryTimeEnd?: string
}
/**
 * 订单状态
 */
export type FmOrderStatus = '1' | '2' | '3' | '4'
/**
 * 报价方式
 */
export type QuoteMethod = '0' | '1' | '2'
/**
 * 订单行
 */
export type FmOrderListLineDto = {
  /** 行ID */
  lineId?: string
  /** 行号 */
  lineNo?: string
  /** 询价行号 */
  quoteLineId?: string
  /** 报价ID */
  quotationId?: string
  /** 商品名称 */
  goodsName?: string
  /** 品牌 */
  goodsBrand?: string
  /** 配置 */
  goodsConfiguration?: string
  /** 国家 */
  countryName?: string
  /** 收货地址 */
  address?: string
  /** 期望到货时间 */
  expectedDeliveryDate?: string
  /** 结算公司名称 */
  settlementCompanyName?: string
  /** 下单时间 */
  orderTime?: string
  quoteMethod?: QuoteMethod
  orderMethod?: PlaceOrderMethod
  shippingAmount?: MoneyDto
  /** 下单数量 */
  orderQuantity?: number
  orderAmount?: MoneyDto
  /** 可发货数量 */
  shippingQuantity?: number
}
export type FmOrderListDto = {
  /** 订单ID */
  id?: string
  /** 订单号 */
  orderNo?: string
  status?: FmOrderStatus
  /** 订单行 */
  orderLines?: FmOrderListLineDto[]
}
export type PageDtoFmOrderListDto = {
  total?: number
  records?: FmOrderListDto[]
}
export type ResponsePageDtoFmOrderListDto = {
  status?: number
  data?: PageDtoFmOrderListDto
  debugMessage?: string
  message?: string
}
/**
 * 验收状态
 */
export type AcceptanceStatus = '1' | '2' | '3' | '4'
export type FmFulfillmentListQuery = {
  current?: number
  limit?: number
  /** ASN单号 */
  keyword?: string
  /** 验收状态 */
  acceptanceStatus?: AcceptanceStatus[]
  /** 创建人 */
  creatorIds?: number[]
  /** 发货开始时间 */
  deliveryTimeStart?: string
  /** 发货结束时间 */
  deliveryTimeEnd?: string
}
/**
 * 发货状态
 */
export type FmDeliveryFulfillmentStatus = '1' | '2'
export type FmDeliveryFulfillmentListDto = {
  /** ASN单号 */
  id?: string
  /** PO订单号 */
  orderNo?: string
  /** po订单Id */
  fmOrderId?: string
  status?: FmDeliveryFulfillmentStatus
  acceptanceStatus?: AcceptanceStatus
  /** 收货人 */
  receiver?: string
  /** 联系方式 */
  contactWay?: string
  /** 收货地址 */
  address?: string
  /** 物流单号 */
  trackingNo?: string
  /** 发货时间 */
  deliveryTime?: string
  /** 预计到货时间 */
  estimateArrivalTime?: string
  /** 结算公司名称 */
  settlementCompanyName?: string
  creator?: PartnerOperator
  /** 创建时间 */
  createdAt?: string
}
export type PageDtoFmDeliveryFulfillmentListDto = {
  total?: number
  records?: FmDeliveryFulfillmentListDto[]
}
export type ResponsePageDtoFmDeliveryFulfillmentListDto = {
  status?: number
  data?: PageDtoFmDeliveryFulfillmentListDto
  debugMessage?: string
  message?: string
}
export type FmDeliveryDetailQuery = {
  /** 订单ID */
  id?: string
  /** 订单行ID */
  lineIds?: string[]
}
/**
 * 发货明细
 */
export type FmDeliveryFulfillmentDetailLineDto = {
  /** 发货单行号 */
  asnLineId?: string
  /** 订单行号 */
  orderLineNo?: string
  /** OA单号 */
  oaNo?: string
  /** OA行号 */
  oaLineNo?: string
  /** 商品名称 */
  goodsName?: string
  /** 品牌 */
  goodsBrand?: string
  /** 配置 */
  goodsConfiguration?: string
  orderMethod?: PlaceOrderMethod
  /** 发货数量 */
  quantity?: number
  amount?: MoneyDto
  unTaxAmount?: MoneyDto
  /** 附件 */
  attachments?: StorageObject[]
  /** 发货行备注 */
  remark?: string
}
export type FmDeliveryFulfillmentCreateDetailDto = {
  id?: string
  receivingInfo?: FmReceivingInfoDto
  /** 发货明细 */
  lines?: FmDeliveryFulfillmentDetailLineDto[]
}
export type ResponseFmDeliveryFulfillmentCreateDetailDto = {
  status?: number
  data?: FmDeliveryFulfillmentCreateDetailDto
  debugMessage?: string
  message?: string
}
export type FmDeliveryFulfillmentAcceptanceFormListQuery = {
  /** asn行号 */
  asnLineId?: string
}
export type FmDeliveryFulfillmentAcceptanceFormListDto = {
  /** asn行号 */
  asnLineId?: string
  /** 商品 */
  goodsName?: string
  moneyCalBenchmark?: MoneyCalBenchmark
  orderMethod?: PlaceOrderMethod
  /** 验收数量 */
  acceptanceQty?: number
  acceptanceMoney?: MoneyDto
  acceptanceUnTaxMoney?: MoneyDto
  /** 验收单号 */
  grNo?: string
  /** 验收时间 */
  createdAt?: string
}
export type ResponseListFmDeliveryFulfillmentAcceptanceFormListDto = {
  status?: number
  data?: FmDeliveryFulfillmentAcceptanceFormListDto[]
  debugMessage?: string
  message?: string
}
export type EcResponseObject = {
  data?: object
}
export type EcTaskType = 'IN_COMING' | 'OUT_GOING'
export type EcTaskExecuteStatus =
  | 'INIT'
  | 'SUCCESS'
  | 'WAITING'
  | 'PROCESSING'
  | 'VALIDATE_FAILED'
  | 'EXECUTION_FAILED'
  | 'CANCELLED'
  | 'RETRY'
export type EcTaskExecuteType = 'SYNC' | 'ASYNC' | 'ASYNC_BUSINESS_KEY_DEPENDENT'
export type EcTaskListQuery = {
  pageNum?: number
  pageSize?: number
  type?: EcTaskType
  businessCode?: string
  businessKey?: string
  executeStatus?: EcTaskExecuteStatus
  executeType?: EcTaskExecuteType
  createdAtStart?: string
  createdAtEnd?: string
}
export type EcAbstractTaskContent = {
  type?: EcTaskType
  headers?: {
    [key: string]: any
  }
  body?: string
  extra?: {
    [key: string]: any
  }
  params?: {
    [key: string]: any
  }
  saveResult?: boolean
}
export type EcTaskListDto = {
  id?: number
  type?: EcTaskType
  businessCode?: string
  businessKey?: string
  content?: EcAbstractTaskContent
  executeStatus?: EcTaskExecuteStatus
  executeType?: EcTaskExecuteType
  executeCount?: number
  executeResult?: string
  createdAt?: string
  updatedAt?: string
}
export type EcPageResponseEcTaskListDto = {
  total?: number
  records?: EcTaskListDto[]
}
export type Content = {
  headers?: {
    [key: string]: any
  }
  body?: string
  extra?: {
    [key: string]: any
  }
  params?: {
    [key: string]: any
  }
  saveResult?: boolean
}
export type EcTaskDto = {
  id?: number
  type?: EcTaskType
  businessCode?: string
  businessKey?: string
  content?: Content
  executeStatus?: EcTaskExecuteStatus
  executeType?: EcTaskExecuteType
  executeCount?: number
  executeResult?: string
  createdAt?: string
  updatedAt?: string
}
export type EcResponseEcTaskDto = {
  data?: EcTaskDto
}
export type StagingDetailDto = {
  /** 暂存信息 */
  stagingInfo?: string
}
export type ResponseStagingDetailDto = {
  status?: number
  data?: StagingDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 状态
 */
export type TaskStatus = 'RUNNING' | 'FAIL' | 'SUCCESS'
/**
 * 数据
 */
export type InvoiceOcrResultStatusDto = {
  status?: TaskStatus
}
export type ResponseInvoiceOcrResultStatusDto = {
  status?: number
  data?: InvoiceOcrResultStatusDto
  message?: string
}
/**
 * 发票OCR识别-发票行
 */
export type InvoiceOcrResultLineDto = {
  /** 商品或服务名称 */
  productName?: string
  /** 规格型号 */
  specification?: string
  /** 单位 */
  unit?: string
  /** 数量 */
  quantity?: string
  /** 未税单价 */
  unTaxUnitPrice?: number
  /** 未税金额 */
  unTaxMoney?: number
  /** 税率 */
  taxRate?: number
  /** 税额 */
  taxAmount?: number
  currency?: Currency
}
/**
 * 发票OCR识别结果
 */
export type InvoiceOcrResultDto = {
  truthCheckingStatus?: InvoiceCheckingStatus
  /** 税务银擎发票key */
  yqInvoiceKey?: string
  /** 税务银擎发票id */
  yqInvoiceId?: number
  subType?: StOcrInvoiceSubType
  /** 发票号码 */
  number?: string
  /** 发票代码 */
  code?: string
  /** 校验码后6位 */
  checkSum?: string
  /** 开票日期（yyyy-MM-dd） */
  invoiceDate?: string
  /** 结算公司编码 */
  settlementCompanyCode?: string
  /** 结算公司 */
  settlementCompany?: string
  /** 供应商ID */
  supplierId?: string
  /** 供应商名称 */
  supplierName?: string
  currency?: Currency
  /** 含税金额 */
  taxMoney?: number
  /** 税额 */
  tax?: number
  /** 不含税金额 */
  unTaxMoney?: number
  /** 备注 */
  remark?: string
  /** 发票行 */
  detailList?: InvoiceOcrResultLineDto[]
}
export type ResponseInvoiceOcrResultDto = {
  status?: number
  data?: InvoiceOcrResultDto
  message?: string
}
/**
 * 发票基础信息
 */
export type InvoiceBaseInfoDto = {
  /** 发票号码 */
  number?: string
  /** 发票代码 */
  code?: string
  /** 校验码 */
  checkSum?: string
  /** 开票日期 */
  openDate?: string
  /** 账期 */
  accountPeriod?: number
  /** 到期日 */
  expiredDate?: string
  currency?: Currency
  /** 含税金额 */
  taxMoney?: number
  /** 不含税金额 */
  unTaxMoney?: number
  /** 税额 */
  tax?: number
  /** 结算公司code */
  settlementCompanyCode?: string
  /** 结算公司名称 */
  settlementCompanyName?: string
  /** 供应商ID */
  supplierId?: string
  /** 供应商名称 */
  supplierName?: string
  /** 备注 */
  remark?: string
}
/**
 * 发票行列表
 */
export type InvoiceLineDto = {
  /** 商品或服务名称 */
  productName?: string
  /** 规格型号 */
  specification?: string
  /** 单位 */
  unit?: string
  /** 数量 */
  quantity?: string
  /** 未税单价 */
  unTaxUnitPrice?: number
  /** 未税金额 */
  unTaxMoney?: number
  /** 税率 */
  taxRate?: number
  /** 税额 */
  taxAmount?: number
}
/**
 * 发票列表DTO
 */
export type InvoiceDetailDto = {
  /** 发票id */
  id?: string
  type?: StInvoiceType
  subType?: StOcrInvoiceSubType
  truthCheckingStatus?: InvoiceCheckingStatus
  /** 是否同步OA(需要用户确认) */
  needUserConfirm?: boolean
  baseInfo?: InvoiceBaseInfoDto
  /** 关联的订单列表 */
  matchOrders?: InvoiceMatchOrderDto[]
  status?: StInvoiceStatus
  payStatus?: PayStatus
  /** 关联的OA单号 */
  oaNo?: string
  /** 关联的OA详情页 */
  oaUrl?: string
  /** 采购员 */
  buyers?: OperatorDto[]
  creator?: OperatorDto
  attachment?: StorageObjectDto
  createdAt?: string
  /** 发票行列表 */
  lines?: InvoiceLineDto[]
  /** 是否可以解除关联 */
  canUnMatch?: boolean
  /** 是否可以删除 */
  canDelete?: boolean
}
export type ResponseInvoiceDetailDto = {
  status?: number
  data?: InvoiceDetailDto
  message?: string
}
/**
 * 数据
 */
export type StCompanyDto = {
  /** 公司编码 */
  code?: string
  /** 名称 */
  name?: string
  currency?: Currency
  countryId?: number
  /** 注册地国家编码 */
  countryCode?: string
  /** 注册地国家名称 */
  countryName?: string
}
export type ResponseListStCompanyDto = {
  status?: number
  /** 数据 */
  data?: StCompanyDto[]
  message?: string
}
export type QuotationRevisionHistoryListDto = {
  /** 行号 */
  quotationLineId?: string
  /** 商品 */
  goodsName?: string
  /** 变更字段 */
  fieldName?: string
  /** 变更前值 */
  oldValue?: object
  /** 变更后值 */
  newValue?: object
  operator?: PartnerOperator
  /** 操作时间 */
  createdAt?: string
}
export type ResponseListQuotationRevisionHistoryListDto = {
  status?: number
  data?: QuotationRevisionHistoryListDto[]
  debugMessage?: string
  message?: string
}
export type ResponseQuotationTenderQuestionDto = {
  status?: number
  data?: QuotationTenderQuestionDto
  debugMessage?: string
  message?: string
}
export type QuotationTenderDetailDto = {
  /** 报价ID */
  id?: string
  /** 投标事件号 */
  rfxId?: string
  /** 询价标题 */
  title?: string
  /** 投标开始时间 */
  beginAt?: string
  /** 投标结束时间 */
  endAt?: string
  /** 是否发送应标确认函 */
  sendConfirmationLetter?: boolean
  confirmationLetterTemplate?: StorageObjectDto
  /** 是否发送保密协议 */
  sendConfidentialityAgreement?: boolean
  confidentialityAgreementTemplate?: StorageObjectDto
  status?: QuotationStatus
  /** 是否已应标 */
  respondTender?: boolean
}
export type ResponseQuotationTenderDetailDto = {
  status?: number
  data?: QuotationTenderDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 整单竞价排名
 */
export type TotalBidRank = {
  firstRankAmount?: MoneyDto
  /** 整单当前供应商报价排名 */
  rank?: number
}
/**
 * 行竞价排名
 */
export type LineBidRank = {
  /** 询价单行ID */
  rfxLineId?: string
  firstRankAmount?: MoneyDto
  /** 当前供应商报价排名 */
  rank?: number
}
/**
 * 报价排名
 */
export type QuotationBidRankDto = {
  totalBidRank?: TotalBidRank
  /** 行的报价排名 */
  lineBidRanks?: LineBidRank[]
}
export type QuotationPollingInfoDto = {
  /** 报价ID */
  id?: string
  /** 截止时间 */
  deadlineAt?: string
  bidRank?: QuotationBidRankDto
  /** 报价间隔时长标识 */
  satisfyTimeInterval?: boolean
}
export type ResponseQuotationPollingInfoDto = {
  status?: number
  data?: QuotationPollingInfoDto
  debugMessage?: string
  message?: string
}
/**
 * 报价方式
 */
export type QuotationMethod = '1' | '2'
/**
 * 付款方式
 */
export type PaymentMethod = '0' | '1' | '2'
/**
 * 行状态
 */
export type QuotationLineStatus = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11'
/**
 * 报价行
 */
export type QuotationLineDto = {
  /** 报价行ID */
  id?: string
  /** rfx行ID */
  rfxLineId?: string
  /** 商品名称 */
  goodsName?: string
  /** 品牌 */
  goodsBrand?: string
  /** 配置 */
  goodsConfiguration?: string
  orderMethod?: PlaceOrderMethod
  /** 数量 */
  quantity?: number
  status?: QuotationLineStatus
  /** 发货地国家名称 */
  countryName?: string
  /** 发货地省份名称 */
  stateName?: string
  /** 发货地城市名称 */
  cityName?: string
  /** 发货地详细地址 */
  address?: string
  moneyCalBenchmark?: MoneyCalBenchmark
  unitMoney?: MoneyDto
  unitUnTaxMoney?: MoneyDto
  quotationMoney?: MoneyDto
  quotationUnTaxMoney?: MoneyDto
  /** 税率 */
  taxRate?: number
  freight?: MoneyDto
  /** 授权期限 */
  licensingPeriod?: number
  /** 维保期限 */
  maintainPeriod?: number
  /** 供货周期 */
  supplyCycle?: number
  invoiceType?: InvoiceType
  /** 报价有效期 */
  validityPeriod?: string
  /** 附件 */
  attachments?: StorageObjectDto[]
  /** 备注 */
  remark?: string
  /** 是否招标弃标 */
  isTenderDiscarded?: boolean
  /** 是否历史招标弃标 */
  isHistoryTenderDiscarded?: boolean
  /** 报价附件 */
  quotationAttachments?: StorageObjectDto[]
  /** 是否需要技术标附件 */
  requireTechAttachments?: boolean
  /** 是否需要商务标附件 */
  requireBusinessAttachments?: boolean
  /** 商务标附件 */
  commercialMarkAttachments?: StorageObjectCommand[]
  /** 技术标附件 */
  technicalMarkAttachments?: StorageObjectCommand[]
  /** 报价备注 */
  quotationRemark?: string
  bidStartPrice?: MoneyDto
  firstPrice?: MoneyDto
  /** 当前排名 */
  rank?: number
  totalQuotationUnTaxMoney?: Money
  totalQuotationMoney?: Money
}
/**
 * 澄清状态
 */
export type QuotationClarificationStatus = '0' | '1' | '2'
/**
 * 竞价模式
 */
export type QuotationRuleBiddingMode = '1'
/**
 * 调整步幅类型
 */
export type QuotationRuleAdjustStepType = '1' | '2' | '3'
/**
 * 调价步幅
 */
export type QuotationAdjustStepDto = {
  adjustStepType?: QuotationRuleAdjustStepType
  amount?: MoneyDto
  /** 步幅比例 */
  proportion?: number
}
/**
 * 竞价规则
 */
export type QuotationRuleBiddingRegulation = '1' | '2' | '3'
/**
 * 自动延时时长
 */
export type QuotationDelayTime = {
  isDelay?: boolean
  lastDelayTime?: number
  delayTime?: number
}
/**
 * 供应商报价显示
 */
export type QuotationRuleShowTypeDto = {
  /** 起拍价 */
  showStartingBid?: boolean
  /** 第一名价格 */
  showFirstPlacePrice?: boolean
}
/**
 * 竞价规则
 */
export type QuotationRuleDto = {
  /** 是否整单中标 */
  isAllBid?: boolean
  biddingMode?: QuotationRuleBiddingMode
  adjustStep?: QuotationAdjustStepDto
  biddingRegulation?: QuotationRuleBiddingRegulation
  /** 报价间隔时长 */
  delayTime?: number
  autoDelayTime?: QuotationDelayTime
  showType?: QuotationRuleShowTypeDto
}
/**
 * 中标公告范围
 */
export type QuotationTenderAnnouncementScope = '01' | '02' | '03'
/**
 * 招投标配置
 */
export type QuotationTenderConfigDto = {
  /** 是否整单中标 */
  isAllBid?: boolean
  /** 时区 */
  timeZone?: string
  /** 投标开始时间 */
  beginAt?: string
  /** 是否提交后开始 */
  isSubmitAfterBegin?: boolean
  /** 投标结束时间 */
  endAt?: string
  /** 是否发送应标确认函 */
  isSendConfirmationLetter?: boolean
  confirmationLetterTemplate?: StorageObject
  /** 是否发送保密协议 */
  isSendConfidentialityAgreement?: boolean
  confidentialityAgreementTemplate?: StorageObject
  tenderAnnouncementScope?: QuotationTenderAnnouncementScope
}
/**
 * 报价历史
 */
export type QuotationBiddingHistoryDto = {
  /** 是否招标弃标 */
  isTenderDiscarded?: boolean
  operator?: PartnerOperator
  /** 创建时间 */
  createdAt?: string
  uniqueField?: string
}
export type QuotationDetailDto = {
  /** 报价ID */
  id?: string
  /** 询价ID */
  rfxId?: string
  /** 标题 */
  title?: string
  status?: QuotationStatus
  quotationMethod?: QuotationMethod
  /** 报价截止时间 */
  deadlineAt?: string
  /** 询价备注 */
  rfxRemark?: string
  /** 账期明细 */
  accountPeriod?: number
  paymentMethod?: PaymentMethod
  /** 报价行 */
  lines?: QuotationLineDto[]
  creator?: PartnerOperator
  /** 创建时间 */
  createdAt?: string
  quoteUser?: PartnerOperator
  moneyCalBenchmark?: MoneyCalBenchmark
  quotationCurrency?: Currency
  /** 头部询价附件 */
  attachments?: StorageObjectDto[]
  firstPrice?: MoneyDto
  /** 当前排名（整单） */
  rank?: number
  /** 是否开启竞价 */
  isBidding?: boolean
  /** 是否报价澄清 */
  isClarification?: boolean
  clarificationStatus?: QuotationClarificationStatus
  quotationRule?: QuotationRuleDto
  quotationTenderConfig?: QuotationTenderConfigDto
  /** 报价历史 */
  quotationHistories?: QuotationBiddingHistoryDto[]
  /** 结算公司名称 */
  settlementCompanyName?: string
  /** 结算公司国家编码 */
  settlementCompanyCountryCode?: string
  /** 是否可以报价 */
  quotable?: boolean
  totalPrice?: MoneyDto
  unTaxTotalPrice?: MoneyDto
}
export type ResponseQuotationDetailDto = {
  status?: number
  data?: QuotationDetailDto
  debugMessage?: string
  message?: string
}
export type QuotationHistoryChartLineDto = {
  /** 商品名称 */
  goodsName?: string
  /** 次数 */
  index?: number
  quotationAmount?: MoneyDto
  operator?: PartnerOperator
  /** 创建时间 */
  createdAt?: string
}
/**
 * 非整单报价历史
 */
export type Line = {
  lineId?: string
  quotationHistoryDTOList?: QuotationHistoryChartLineDto[]
}
export type QuotationHistoryChartDto = {
  /** 是否整单报价 */
  isAllBid?: boolean
  /** 整单报价历史 */
  totalQuotationHistory?: QuotationHistoryChartLineDto[]
  /** 非整单报价历史 */
  lineQuotationHistories?: Line[]
}
export type ResponseQuotationHistoryChartDto = {
  status?: number
  data?: QuotationHistoryChartDto
  debugMessage?: string
  message?: string
}
export type NotificationQuery = {
  current?: number
  limit?: number
}
/**
 * 通知状态
 */
export type NotificationStatus = '1' | '0'
/**
 * 通知类型
 */
export type NotificationType = '1' | '2' | '3' | '4'
export type NotificationListDto = {
  /** 通知ID */
  id?: string
  /** 通知标题 */
  title?: string
  /** 通知内容 */
  content?: string
  status?: NotificationStatus
  type?: NotificationType
  /** 创建时间 */
  createdAt?: string
}
export type PageDtoNotificationListDto = {
  total?: number
  records?: NotificationListDto[]
}
export type ResponsePageDtoNotificationListDto = {
  status?: number
  data?: PageDtoNotificationListDto
  debugMessage?: string
  message?: string
}
export type TaxDto = {
  /** ID */
  id?: number
  /** 税率值 */
  value?: number
  /** 税率百分比 */
  percentageValue?: string
}
export type ResponseListTaxDto = {
  status?: number
  data?: TaxDto[]
  debugMessage?: string
  message?: string
}
export type CategoryTreeDto = {
  /** 品类id */
  categoryId?: number
  /** 品类名称 */
  name?: string
  /** 品类编码 */
  code?: string
  /** 子品类 */
  children?: CategoryTreeDto[]
}
export type ResponseListCategoryTreeDto = {
  status?: number
  data?: CategoryTreeDto[]
  debugMessage?: string
  message?: string
}
/**
 * 服务品类
 */
export type ServiceCategory = {
  pathIds?: number[]
  categoryId?: number
}
export type RegisteredEnterpriseDto = {
  /** 供应商名称 */
  name?: string
  /** 邮箱 */
  mail?: string
  /** VMDM 供应商id */
  nevId?: string
  /** 服务品类 */
  serviceCategories?: ServiceCategory[]
  /** 供应商ID */
  supplierId?: string
}
export type ResponseRegisteredEnterpriseDto = {
  status?: number
  data?: RegisteredEnterpriseDto
  debugMessage?: string
  message?: string
}
export type CountryDto = {
  /** 地址ID */
  id?: number
  /** 地址编码 */
  code?: string
  /** 地址名称 */
  name?: string
}
export type ResponseListCountryDto = {
  status?: number
  data?: CountryDto[]
  debugMessage?: string
  message?: string
}
/**
 * 供应商省份信息
 */
export type SupplierProvinceDto = {
  /** ID */
  id?: number
  /** 所属国家ID */
  countryId?: number
  /** 省份 编码 */
  code?: string
  /** 省份 名称 */
  name?: string
}
export type ResponseListSupplierProvinceDto = {
  status?: number
  data?: SupplierProvinceDto[]
  debugMessage?: string
  message?: string
}
/**
 * 币种信息
 */
export type CurrencyDto = {
  /** 币种名称 */
  name?: string
  /** 币种精度 */
  precision?: number
  /** 币种符号 */
  symbol?: string
}
export type ResponseListCurrencyDto = {
  status?: number
  data?: CurrencyDto[]
  debugMessage?: string
  message?: string
}
/**
 * 供应商 - 城市
 */
export type SupplierCityDto = {
  /** ID */
  id?: number
  /** 所属省份 或 STATE ID */
  stateId?: number
  /** 城市 编码 */
  code?: string
  /** 城市 名称 */
  name?: string
}
export type ResponseListSupplierCityDto = {
  status?: number
  data?: SupplierCityDto[]
  debugMessage?: string
  message?: string
}
/**
 * 供应商 - 品类
 */
export type SpCategoryDto = {
  /** ID */
  id?: number
  /** 名称 */
  name?: string
  /** 编码 */
  code?: string
  /** 子品类 */
  children?: SpCategoryDto[]
}
export type ResponseListSpCategoryDto = {
  status?: number
  data?: SpCategoryDto[]
  debugMessage?: string
  message?: string
}
/**
 * 供应商 业务线
 */
export type SpBusinessLineDto = {
  /** 业务线ID */
  businessId?: string
  /** 业务线名称 */
  businessName?: string
  /** 法务联系人 */
  legalAffairs?: string
  /** 对应的 OA 流程中的值 */
  oaBusinessId?: string
}
export type ResponseListSpBusinessLineDto = {
  status?: number
  data?: SpBusinessLineDto[]
  debugMessage?: string
  message?: string
}
/**
 * 准入申请状态
 */
export type SpOaFlowStatus = '01' | '02' | '03' | '04'
/**
 * 供应商类型
 */
export type SupplierType = '01' | '02'
/**
 * 个人 基本信息
 */
export type PersonBaseInfoDto = {
  /** 基础信息 姓名 */
  userName?: string
  personIdType?: PersonIdType
  /** 证件号码 */
  personId?: string
  /** 基础信息 联系邮箱 */
  mail?: string
}
/**
 * 审批节点
 */
export type SpOaFlowApprovalInfoDto = {
  /** 审批节点名称 */
  nodeName?: string
  /** 审批操作 */
  operate?: string
  /** 审批时间标准(展示优先取标准时间，如果为空则取审批时间) */
  operateTimeStd?: string
  /** 审批人姓名 */
  operateUser?: string
  /** 审批意见 */
  suggest?: string
}
/**
 * 申请 准入信息
 */
export type SpOnboardingInfoDto = {
  cooperateStatus?: SpCooperateStatus
  /** 准入信息 业务线ID */
  businessId?: string[]
  /** 准入信息 业务线名称 */
  businessName?: string[]
  /** 准入信息 推荐人邮箱 */
  recommenderMail?: string
  /** 准入信息 推荐原因 */
  recommendReason?: string
  /** 准入信息 准入说明 */
  remark?: string
  /** 审批节点 */
  oaFlowApprovalInfos?: SpOaFlowApprovalInfoDto[]
}
/**
 * 审核类型
 */
export type AuditType = '1' | '2'
/**
 * 审核人
 */
export type Operator = {
  id?: number
  name?: string
  jobNumber?: string
  mail?: string
  system?: boolean
}
/**
 * 申请 准入信息
 */
export type SpBuyerAuditInfoDto = {
  /** 审核信息 */
  info?: string
  auditType?: AuditType
  /** 审核时间 */
  createdAt?: string
  creator?: Operator
}
/**
 * 个人 登记信息
 */
export type PersonCheckInDto = {
  /** 基础信息 姓名 */
  userName?: string
  personIdType?: PersonIdType
  /** 证件号码 */
  personId?: string
  /** 邮编 */
  postCode?: string
}
/**
 * 服务品类
 */
export type ServiceCategoryDto = {
  /** 品类路径，包含 categoryId */
  pathIds?: number[]
  /** 品类名称 */
  categoryNames?: string[]
  /** 最末级 品类ID */
  categoryId?: number
}
/**
 * 个人 联系人信息
 */
export type PersonContactDto = {
  /** id,更新时回传 */
  id?: number
  /** 联系人姓名 */
  name?: string
  /** 联系人邮箱 */
  mail?: string
  /** 联系人手机 */
  phone?: string
  /** sns */
  sns?: string
  /** 联系人备注 */
  remark?: string
}
/**
 * 个人 银行信息
 */
export type IdPersonBankInfoDto = {
  /** id */
  id?: number
  /** 银行账号 */
  account?: string
  /** 账号名称 */
  accountName?: string
  currency?: Currency
  /** SWIFT CODE */
  swiftCode?: string
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 名称 */
  countryName?: string
  /** 开户行名称 */
  openBankName?: string
  /** 支行名称 */
  branchName?: string
  status?: StatusEnum
  /** 失效时间 */
  invalidAt?: string
  primary?: StatusEnum
  /** 备注 */
  remark?: string
}
/**
 * 税务信息
 */
export type EnterpriseTaxInfoDto = {
  taxType?: EnterpriseTaxType
  taxPayer?: EnterpriseTaxPayer
  taxAttachment?: StorageObjectDto
  /** 备注 */
  taxRemark?: string
}
/**
 * 中国境外 + 个人 准入申请 详情
 */
export type IdOnboardAppOverseaPersonDetailDto = {
  /** 准入申请 id */
  id?: number
  oaFlowStatus?: SpOaFlowStatus
  /** 供应商ID */
  supplierId?: string
  supplierType?: SupplierType
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 */
  countryName?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  /** 关联的OA审批流程ID */
  oaNo?: string
  /** OA 跳转地址 */
  oaUrl?: string
  baseInfo?: PersonBaseInfoDto
  onboardingInfo?: SpOnboardingInfoDto
  auditInfo?: SpBuyerAuditInfoDto
  checkIn?: PersonCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 联系人信息 */
  contacts?: PersonContactDto[]
  /** 银行信息 */
  bankInfos?: IdPersonBankInfoDto[]
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoDto[]
  sourceType?: SourceType
  createdAt?: string
  updatedAt?: string
  creator?: OperatorDto
}
export type ResponseIdOnboardAppOverseaPersonDetailDto = {
  status?: number
  data?: IdOnboardAppOverseaPersonDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 基本信息
 */
export type OnboardAppOverseaBaseInfoDto = {
  /** 基础信息 供应商名称 */
  supplierName?: string
  /** TIN */
  tin?: string
  /** 基础信息 用户名 */
  userName?: string
  /** 基础信息 联系邮箱 */
  mail?: string
}
/**
 * 企业 登记信息 DTO
 */
export type IdEnterpriseCheckInDto = {
  /** 企业名称 */
  enterpriseName?: string
  /** 企业简称 */
  enterpriseAliasName?: string
  registerCapital?: MoneyDto
  /** 公司主页地址 */
  homeSite?: string
  /** 成立日期 */
  establishTime?: string
  /** 营业期限 开始时间 */
  startAt?: string
  /** 营业期限 截止时间 */
  endAt?: string
  /** 行政区划 省州 id */
  provinceId?: number
  /** 行政区划 省州 code */
  provinceCode?: string
  /** 行政区划 省州 名字 */
  provinceName?: string
  /** 行政区划 市 */
  city?: string
  /** 行政区划 区/县 */
  district?: string
  /** 详细地址 */
  address?: string
  /** 经营范围 */
  businessScope?: string
  licenseFile?: StorageObjectDto
  legalRegistrationLicenseFile?: StorageObjectDto
  admittanceFile?: StorageObjectDto
  taxpayerQualification?: TaxpayerQualification
  /** 统一社会信用代码 */
  creditCode?: string
  /** 法定代表人 */
  legalRepresentative?: string
  /** 邓白氏编码 */
  duns?: string
  /** TIN */
  tin?: string
  /** 邮编 */
  postCode?: string
}
/**
 * 企业 资质信息
 */
export type EnterpriseCredentialDto = {
  /** 品类ID */
  categoryId?: number
  /** 品类名称 */
  categoryName?: string
  /** 资质名称 */
  credentialName?: string
  credentialFile?: StorageObjectDto
  /** 有效期 开始时间 */
  startAt?: string
  /** 有效期 截止时间 */
  endAt?: string
  /** 备注 */
  remark?: string
}
/**
 * 企业 联系人信息
 */
export type EnterpriseContactDto = {
  /** id,更新时回传 */
  id?: number
  contactType?: ContactType
  /** 联系人姓名 */
  name?: string
  /** 联系人职位 */
  post?: string
  /** 联系人邮箱 */
  mail?: string
  /** 联系人手机 */
  phone?: string
  /** 联系人备注 */
  remark?: string
}
/**
 * 董监高数据
 */
export type EnterpriseSupervisorDto = {
  /** 姓名 */
  name?: string
  /** 职位 */
  positions?: SpSupervisorPosition[]
  personIdType?: PersonIdType
  /** 证件ID */
  personId?: string
  /** 备注 */
  remark?: string
}
/**
 * 企业 董监高信息
 */
export type EnterpriseSupervisorInfoDto = {
  /** id */
  id?: number
  /** 人员信息 */
  supervisors?: EnterpriseSupervisorDto[]
  /** 缺失原因 */
  absenceReason?: string
  /** 董监高利冲后继续准入原因 */
  continueReason?: string
}
/**
 * 企业 银行信息
 */
export type IdEnterpriseBankInfoDto = {
  /** id */
  id?: number
  /** 银行账号 */
  account?: string
  /** 账号名称 */
  accountName?: string
  currency?: Currency
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家名称 */
  countryName?: string
  /** 开户行名称 */
  openBankName?: string
  /** 支行名称 */
  branchName?: string
  /** 开户行省 id */
  openProvinceId?: number
  /** 开户行省 code */
  openProvinceCode?: string
  /** 开户行省 名称 */
  openProvinceName?: string
  /** 开户行市 */
  openCity?: string
  /** 银行地址 */
  address?: string
  /** SWIFT CODE */
  swiftCode?: string
  /** 联行号 */
  cnaps?: string
  /** IBAN */
  iban?: string
  handlingFeeType?: HandlingFeeType
  accountPeriod?: AccountPeriod
  status?: StatusEnum
  /** 失效时间 */
  invalidAt?: string
  primary?: StatusEnum
  /** 备注 */
  remark?: string
  createPermitFile?: StorageObjectDto
  preferredPaymentMethod?: EnterprisePreferredPaymentMethod
  accountType?: EnterpriseBankAccountType
  /** ABA/Routing number */
  abaRoutingNumber?: string
  /** Sort Code */
  sortCode?: string
  /** Bank ID */
  bankId?: string
  /** Branch ID/Code */
  branchId?: string
}
/**
 * 财务信息
 */
export type FinanceInfoDto = {
  /** id */
  id?: number
  /** 年份 */
  year?: number
  currency?: Currency
  /** 营业收入（万） */
  income?: number
  /** 利润总额（万） */
  profit?: number
  /** 实缴资本（万） */
  paidUpCapital?: number
  /** 总资产（万） */
  totalAssets?: number
  annualAuditReportFile?: StorageObjectDto
  financialStatementFile?: StorageObjectDto
  /** 备注 */
  remark?: string
}
/**
 * 企业 财务状况
 */
export type EnterpriseFinanceInfoDto = {
  /** 财务信息 */
  financeInfos?: FinanceInfoDto[]
  /** 财务缺失原因 */
  financeAbsenceReason?: string
}
/**
 * 企业 成功案例
 */
export type EnterpriseSuccessCaseDto = {
  /** id */
  id?: number
  /** 案例名称 */
  name?: string
  /** 客户名称 */
  customerName?: string
  /** 服务/产品范围 */
  serviceScope?: string
  /** 项目时间 */
  projectTime?: string
  /** 项目规模（万元） */
  projectCapitalAmount?: string
  /** 项目简介 */
  introduction?: string
  /** 项目成果 */
  achievement?: string
  /** 联系人姓名 */
  contactName?: string
  /** 联系人职位 */
  contactPost?: string
  /** 联系方式 */
  contact?: string
  /** 备注 */
  remark?: string
  recommendationFile?: StorageObjectDto
  projectFile?: StorageObjectDto
}
/**
 * 企业 利益关系
 */
export type EnterpriseInterestRelationDto = {
  /** id */
  id?: number
  /** 员工姓名 */
  name?: string
  /** 员工所在公司 */
  company?: string
  /** 工号 */
  jobNumber?: string
  /** 邮箱 */
  mail?: string
  /** 关系 */
  relation?: string
  /** 备注 */
  remark?: string
}
/**
 * 中国境外 + 企业 准入申请详情
 */
export type IdOnboardAppOverseaEnterpriseDetailDto = {
  /** 准入申请 id */
  id?: number
  oaFlowStatus?: SpOaFlowStatus
  /** 供应商ID */
  supplierId?: string
  supplierType?: SupplierType
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 */
  countryName?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  /** 关联的OA审批流程ID */
  oaNo?: string
  /** OA 跳转地址 */
  oaUrl?: string
  baseInfo?: OnboardAppOverseaBaseInfoDto
  onboardingInfo?: SpOnboardingInfoDto
  auditInfo?: SpBuyerAuditInfoDto
  checkIn?: IdEnterpriseCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 资质信息 */
  credentials?: EnterpriseCredentialDto[]
  /** 联系人信息 */
  contacts?: EnterpriseContactDto[]
  supervisorInfos?: EnterpriseSupervisorInfoDto
  /** 银行信息 */
  bankInfos?: IdEnterpriseBankInfoDto[]
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoDto[]
  financeInfos?: EnterpriseFinanceInfoDto
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseDto[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationDto[]
  sourceType?: SourceType
  createdAt?: string
  updatedAt?: string
  creator?: OperatorDto
}
export type ResponseIdOnboardAppOverseaEnterpriseDetailDto = {
  status?: number
  data?: IdOnboardAppOverseaEnterpriseDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 个人 银行信息
 */
export type PersonBankInfoDto = {
  /** id */
  id?: number
  /** 银行账号 */
  account?: string
  /** 账号名称 */
  accountName?: string
  currency?: Currency
  /** SWIFT CODE */
  swiftCode?: string
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 开户行名称 */
  openBankName?: string
  /** 支行名称 */
  branchName?: string
  status?: StatusEnum
  /** 失效时间 */
  invalidAt?: string
  primary?: StatusEnum
  /** 备注 */
  remark?: string
}
/**
 * 中国境内 + 个人 准入申请 详情
 */
export type IdOnboardAppChinaPersonDetailDto = {
  /** 准入申请 id */
  id?: number
  oaFlowStatus?: SpOaFlowStatus
  /** 供应商ID */
  supplierId?: string
  supplierType?: SupplierType
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 */
  countryName?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  /** 关联的OA审批流程ID */
  oaNo?: string
  /** OA 跳转地址 */
  oaUrl?: string
  baseInfo?: PersonBaseInfoDto
  onboardingInfo?: SpOnboardingInfoDto
  auditInfo?: SpBuyerAuditInfoDto
  checkIn?: PersonCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 联系人信息 */
  contacts?: PersonContactDto[]
  /** 银行信息 */
  bankInfos?: PersonBankInfoDto[]
  sourceType?: SourceType
  createdAt?: string
  updatedAt?: string
  creator?: OperatorDto
}
export type ResponseIdOnboardAppChinaPersonDetailDto = {
  status?: number
  data?: IdOnboardAppChinaPersonDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 基本信息
 */
export type OnboardAppChinaEnterpriseBaseInfoDto = {
  /** 基础信息 供应商名称 */
  supplierName?: string
  /** 统一社会信用代码 */
  creditCode?: string
  /** 基础信息 用户名 */
  userName?: string
  /** 基础信息 联系邮箱 */
  mail?: string
}
/**
 * 中国境内 + 企业 准入申请 详情
 */
export type IdOnboardAppChinaEnterpriseDetailDto = {
  /** 准入申请 id */
  id?: number
  oaFlowStatus?: SpOaFlowStatus
  /** 供应商ID */
  supplierId?: string
  supplierType?: SupplierType
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 */
  countryName?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  /** 关联的OA审批流程ID */
  oaNo?: string
  /** OA 跳转地址 */
  oaUrl?: string
  baseInfo?: OnboardAppChinaEnterpriseBaseInfoDto
  onboardingInfo?: SpOnboardingInfoDto
  auditInfo?: SpBuyerAuditInfoDto
  checkIn?: IdEnterpriseCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 资质信息 */
  credentials?: EnterpriseCredentialDto[]
  /** 联系人信息 */
  contacts?: EnterpriseContactDto[]
  supervisorInfos?: EnterpriseSupervisorInfoDto
  /** 银行信息 */
  bankInfos?: IdEnterpriseBankInfoDto[]
  financeInfos?: EnterpriseFinanceInfoDto
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseDto[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationDto[]
  sourceType?: SourceType
  createdAt?: string
  updatedAt?: string
  creator?: OperatorDto
}
export type ResponseIdOnboardAppChinaEnterpriseDetailDto = {
  status?: number
  data?: IdOnboardAppChinaEnterpriseDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 变更申请 - 个人 - content DTO
 */
export type SpModAppPersonContentDto = {
  checkIn?: PersonCheckInDto
  /** 中国境外+个人 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 中国境外+个人 联系人信息 */
  contacts?: PersonContactDto[]
  /** 中国境外+个人 银行信息 */
  bankInfos?: IdPersonBankInfoDto[]
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoDto[]
}
/**
 * 变更申请 - 个人 - 详情 DTO
 */
export type IdModAppPersonDetailDto = {
  /** 供应商 ID */
  supplierId?: string
  /** 供应商 名称 */
  supplierName?: string
  /** 供应商 邮箱 */
  supplierMail?: string
  supplierType?: SupplierType
  cooperateStatus?: SpCooperateStatus
  /** 供应商 注册国家 id */
  countryId?: number
  /** 供应商 国家名称 */
  countryName?: string
  /** 供应商 注册国家 code */
  countryCode?: string
  /** 变更单号 */
  id?: number
  creator?: OperatorDto
  /** 变更申请 创建时间 */
  createdAt?: string
  oaFlowStatus?: SpOaFlowStatus
  /** 业务线ID */
  businessId?: string[]
  /** 业务线名称 */
  businessName?: string[]
  /** 变更说明 */
  remark?: string
  /** 关联的OA审批流程ID */
  oaNo?: string
  /** OA 跳转地址 */
  oaUrl?: string
  /** 审批历史 */
  oaFlowApprovalInfos?: SpOaFlowApprovalInfoDto[]
  /** MDM 的ID */
  mdmId?: string
  prev?: SpModAppPersonContentDto
  current?: SpModAppPersonContentDto
  auditInfo?: SpBuyerAuditInfoDto
  sourceType?: SourceType
}
export type ResponseIdModAppPersonDetailDto = {
  status?: number
  data?: IdModAppPersonDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 变更申请 - 企业 DTO
 */
export type SpModAppEnterpriseContentDto = {
  checkIn?: IdEnterpriseCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 资质信息 */
  credentials?: EnterpriseCredentialDto[]
  /** 联系人信息 */
  contacts?: EnterpriseContactDto[]
  /** 银行信息 */
  bankInfos?: IdEnterpriseBankInfoDto[]
  supervisorInfos?: EnterpriseSupervisorInfoDto
  financeInfos?: EnterpriseFinanceInfoDto
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoDto[]
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseDto[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationDto[]
}
/**
 * 变更申请 - 企业 - 详情 DTO
 */
export type IdModAppEnterpriseDetailDto = {
  /** 供应商 ID */
  supplierId?: string
  /** 供应商 名称 */
  supplierName?: string
  /** 供应商 邮箱 */
  supplierMail?: string
  supplierType?: SupplierType
  cooperateStatus?: SpCooperateStatus
  /** 供应商 注册国家 id */
  countryId?: number
  /** 供应商 国家名称 */
  countryName?: string
  /** 供应商 注册国家 code */
  countryCode?: string
  /** 变更单号 */
  id?: number
  creator?: OperatorDto
  /** 变更申请 创建时间 */
  createdAt?: string
  oaFlowStatus?: SpOaFlowStatus
  /** 业务线ID */
  businessId?: string[]
  /** 业务线名称 */
  businessName?: string[]
  /** 变更说明 */
  remark?: string
  /** 关联的OA审批流程ID */
  oaNo?: string
  /** OA 跳转地址 */
  oaUrl?: string
  /** 审批历史 */
  oaFlowApprovalInfos?: SpOaFlowApprovalInfoDto[]
  /** MDM 的ID */
  mdmId?: string
  prev?: SpModAppEnterpriseContentDto
  current?: SpModAppEnterpriseContentDto
  auditInfo?: SpBuyerAuditInfoDto
  sourceType?: SourceType
}
export type ResponseIdModAppEnterpriseDetailDto = {
  status?: number
  data?: IdModAppEnterpriseDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 关联的 进行中的 变更申请类型
 */
export type SpApplicationType = '01' | '02' | '03' | '04' | '05' | '06'
/**
 * 中国境外 + 个人 供应商 详情
 */
export type IdOverseaPersonDetailDto = {
  /** 供应商 id */
  id?: string
  supplierType?: SupplierType
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 */
  countryName?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  applicationType?: SpApplicationType
  /** 关联的 进行中的 变更申请ID */
  applicationId?: number
  baseInfo?: PersonBaseInfoDto
  checkIn?: PersonCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 联系人信息 */
  contacts?: PersonContactDto[]
  /** 银行信息 */
  bankInfos?: IdPersonBankInfoDto[]
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoDto[]
  sourceType?: SourceType
  createdAt?: string
  updatedAt?: string
  creator?: OperatorDto
}
export type ResponseIdOverseaPersonDetailDto = {
  status?: number
  data?: IdOverseaPersonDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 基本信息
 */
export type OverseaEnterpriseBaseInfoDto = {
  /** 基础信息 供应商名称 */
  supplierName?: string
  /** TIN */
  tin?: string
  /** 基础信息 用户名 */
  userName?: string
  /** 基础信息 联系邮箱 */
  mail?: string
}
/**
 * 中国境外 + 企业 供应商 详情
 */
export type IdOverseaEnterpriseDetailDto = {
  /** 供应商 id */
  id?: string
  supplierType?: SupplierType
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 */
  countryName?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  applicationType?: SpApplicationType
  /** 关联的 进行中的 变更申请ID */
  applicationId?: number
  baseInfo?: OverseaEnterpriseBaseInfoDto
  checkIn?: IdEnterpriseCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 资质信息 */
  credentials?: EnterpriseCredentialDto[]
  /** 联系人信息 */
  contacts?: EnterpriseContactDto[]
  supervisorInfos?: EnterpriseSupervisorInfoDto
  /** 银行信息 */
  bankInfos?: IdEnterpriseBankInfoDto[]
  financeInfos?: EnterpriseFinanceInfoDto
  /** 税务信息 */
  taxInfos?: EnterpriseTaxInfoDto[]
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseDto[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationDto[]
  sourceType?: SourceType
  createdAt?: string
  updatedAt?: string
  creator?: OperatorDto
}
export type ResponseIdOverseaEnterpriseDetailDto = {
  status?: number
  data?: IdOverseaEnterpriseDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 中国境内 + 个人 供应商 详情
 */
export type IdChinaPersonDetailDto = {
  /** 供应商 id */
  id?: string
  supplierType?: SupplierType
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 */
  countryName?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  applicationType?: SpApplicationType
  /** 关联的 进行中的 变更申请ID */
  applicationId?: number
  baseInfo?: PersonBaseInfoDto
  checkIn?: PersonCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 联系人信息 */
  contacts?: PersonContactDto[]
  /** 银行信息 */
  bankInfos?: IdPersonBankInfoDto[]
  sourceType?: SourceType
  createdAt?: string
  updatedAt?: string
  creator?: OperatorDto
}
export type ResponseIdChinaPersonDetailDto = {
  status?: number
  data?: IdChinaPersonDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 基本信息
 */
export type ChinaEnterpriseBaseInfoDto = {
  /** 基础信息 供应商名称 */
  supplierName?: string
  /** 统一社会信用代码 */
  creditCode?: string
  /** 基础信息 管理员姓名 */
  userName?: string
  /** 基础信息 管理员邮箱 */
  mail?: string
}
/**
 * 企业 银行信息
 */
export type EnterpriseBankInfoDto = {
  /** id */
  id?: number
  /** 银行账号 */
  account?: string
  /** 账号名称 */
  accountName?: string
  currency?: Currency
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 开户行名称 */
  openBankName?: string
  /** 支行名称 */
  branchName?: string
  /** 开户行省 id */
  openProvinceId?: number
  /** 开户行省 code */
  openProvinceCode?: string
  /** 开户行市 */
  openCity?: string
  /** 银行地址 */
  address?: string
  /** SWIFT CODE */
  swiftCode?: string
  /** 联行号 */
  cnaps?: string
  /** IBAN */
  iban?: string
  handlingFeeType?: HandlingFeeType
  accountPeriod?: AccountPeriod
  status?: StatusEnum
  /** 失效时间 */
  invalidAt?: string
  primary?: StatusEnum
  /** 备注 */
  remark?: string
  createPermitFile?: StorageObjectDto
  preferredPaymentMethod?: EnterprisePreferredPaymentMethod
  accountType?: EnterpriseBankAccountType
  /** ABA/Routing number */
  abaRoutingNumber?: string
  /** Sort Code */
  sortCode?: string
  /** Bank ID */
  bankId?: string
  /** Branch ID/Code */
  branchId?: string
}
/**
 * 中国境内 + 企业 供应商 详情
 */
export type IdChinaEnterpriseDetailDto = {
  /** 供应商 id */
  id?: string
  supplierType?: SupplierType
  cooperateStatus?: SpCooperateStatus
  /** 国家 id */
  countryId?: number
  /** 国家 code */
  countryCode?: string
  /** 国家 */
  countryName?: string
  /** 来自 mdm 的ID */
  mdmId?: string
  applicationType?: SpApplicationType
  /** 关联的 进行中的 变更申请ID */
  applicationId?: number
  baseInfo?: ChinaEnterpriseBaseInfoDto
  checkIn?: IdEnterpriseCheckInDto
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  /** 资质信息 */
  credentials?: EnterpriseCredentialDto[]
  /** 联系人信息 */
  contacts?: EnterpriseContactDto[]
  supervisorInfos?: EnterpriseSupervisorInfoDto
  /** 银行信息 */
  bankInfos?: EnterpriseBankInfoDto[]
  financeInfos?: EnterpriseFinanceInfoDto
  /** 成功案例 */
  successCases?: EnterpriseSuccessCaseDto[]
  /** 利益关系 */
  interestRelations?: EnterpriseInterestRelationDto[]
  sourceType?: SourceType
  createdAt?: string
  updatedAt?: string
  creator?: OperatorDto
}
export type ResponseIdChinaEnterpriseDetailDto = {
  status?: number
  data?: IdChinaEnterpriseDetailDto
  debugMessage?: string
  message?: string
}
/**
 * 供应商子集
 */
export type SupplierSubSet = '01' | '02' | '03' | '04'
/**
 * 供应商 准入进度
 */
export type SpOnboardProgress = '01' | '05' | '02' | '03' | '04'
/**
 * 供应商 变更进度
 */
export type SpModifiedProgress = '01' | '02' | '03' | '04' | '05'
/**
 * 供应商 合作状态变更进度
 */
export type SpCooperateStatusChangeProgress = '01' | '02' | '03' | '04'
/**
 * 供应商 - mdm 登记信息
 */
export type MdmCheckInDto = {
  /** 企业名称 */
  enterpriseName?: string
  /** 企业别名 */
  enterpriseAliasName?: string
  registerCapital?: MoneyDto
  /** 成立日期 */
  establishTime?: string
  /** 行政区划 省 id */
  provinceId?: number
  /** 行政区划 省 code */
  provinceCode?: string
  /** 行政区划 省 */
  province?: string
  /** 行政区划 市 */
  city?: string
  /** 行政区划 区/县 */
  district?: string
  /** 详细地址 */
  addressDetail?: string
  /** 经营范围 */
  businessScope?: string
  /** 统一社会信用代码 */
  creditCode?: string
  /** 法定代表人 */
  legalRepresentative?: string
  taxpayerQualification?: TaxpayerQualification
  /** 邓白氏编码 */
  duns?: string
  /** TIN */
  tin?: string
  /** 注册国家 id */
  countryId?: number
  /** 注册国家 */
  countryName?: string
  /** 姓名 */
  personName?: string
  /** 证件类型 */
  identityCardType?: string
  /** 证件号码 */
  personId?: string
}
export type SupplierDto = {
  /** 供应商ID */
  id?: string
  supplierType?: SupplierType
  /** 供应商名称 */
  name?: string
  subSet?: SupplierSubSet
  /** MDM ID */
  mdmId?: string
  /** 国家ID */
  countryId?: number
  /** 国家code */
  countryCode?: string
  /** 国家名称 */
  countryName?: string
  /** 服务品类 */
  serviceCategories?: ServiceCategoryDto[]
  cooperateStatus?: SpCooperateStatus
  onboardProgress?: SpOnboardProgress
  modifiedProgress?: SpModifiedProgress
  csChangeProgress?: SpCooperateStatusChangeProgress
  applicationType?: SpApplicationType
  /** 流程ID */
  applicationId?: number
  /** 联系人邮箱 */
  contactMail?: string
  /** 统一社会信用码 */
  creditCode?: string
  personIdType?: PersonIdType
  /** 身份证号 */
  personId?: string
  /** 邓白氏 */
  duns?: string
  /** 法定代表人 */
  legalRepresentative?: string
  /** 税务ID */
  taxId?: string
  /** tin */
  tin?: string
  accountType?: AccountType
  checkIn?: MdmCheckInDto
}
export type ResponseSupplierDto = {
  status?: number
  data?: SupplierDto
  debugMessage?: string
  message?: string
}
/**
 * 供应商 - 变更记录 - 列表DTO
 */
export type SpChangeRecordListDto = {
  /** 申请单号 */
  id?: number
  applicationType?: SpApplicationType
  subSet?: SupplierSubSet
  oaFlowStatus?: SpOaFlowStatus
  /** 操作人 */
  userName?: string
  /** 操作人邮箱 */
  userMail?: string
  /** 申请时间 */
  createdAt?: string
  /** 变更说明 */
  remark?: string
  buyer?: boolean
}
export type ResponseListSpChangeRecordListDto = {
  status?: number
  data?: SpChangeRecordListDto[]
  debugMessage?: string
  message?: string
}
/**
 * 有权限的企业
 */
export type IdEnterpriseListDto = {
  /** 企业id */
  id?: string
  /** 名称 */
  name?: string
}
/**
 * 权限
 */
export type Authority =
  | 'enterprise:account:create'
  | 'enterprise:account:handover'
  | 'WORKBENCH_WAIT_QUOTATION'
  | 'WORKBENCH_WAIT_DELIVERY'
  | 'FM_DELIVERY'
  | 'FM_DETAIL'
  | 'FM_LIST'
  | 'FM_LINE_LIST'
  | 'FM_DELIVERY_DETAIL'
  | 'FM_DELIVERY_LIST'
  | 'QUOTATION_GIVE_UP'
  | 'QUOTATION_SUBMIT'
  | 'QUOTATION_UPDATE'
  | 'QUOTATION_RFX_DETAIL'
  | 'QUOTATION_RFX_LIST'
  | 'QUOTATION_RFX_LINE_LIST'
  | 'QUOTATION_DETAIL'
  | 'QUOTATION_COUNT'
  | 'QUOTATION_POLLING'
  | 'QUOTATION_CHART'
  | 'QUOTATION_TENDER_LIST'
  | 'QUOTATION_TENDER_DETAIL'
  | 'QUOTATION_TENDER_PARTICIPATE_TENDER'
  | 'QUOTATION_TENDER_DISCARDED_TENDER'
  | 'QUOTATION_TENDER_QUESTION_CONSULTATION'
  | 'QUOTATION_TENDER_QUESTION_LIST'
  | 'QUOTATION_TENDER_QUESTION_DETAIL'
  | 'QUOTATION_TENDER_REVISION_HISTORY'
  | 'STAGING_SAVE_QUOTATION'
  | 'STAGING_QUOTATION_DETAIL'
export type IdUserSessionInfoDto = {
  /** id */
  id?: number
  /** 姓名 */
  name?: string
  /** 邮箱 */
  mail?: string
  /** 当前账号id */
  accountId?: number
  /** 当前的企业id */
  enterpriseId?: string
  locale?: SystemLocale
  /** 有权限的企业 */
  enterprises?: IdEnterpriseListDto[]
  /** 账号拥有的权限 */
  authorities?: Authority[]
}
export type ResponseIdUserSessionInfoDto = {
  status?: number
  data?: IdUserSessionInfoDto
  debugMessage?: string
  message?: string
}
/**
 * 发货信息
 */
export type FmOrderDetailLineDeliveryDto = {
  /** 发货单号 */
  id?: string
  /** 发货时间 */
  deliveryTime?: string
  type?: FmDeliveryType
  /** 预计送达时间 */
  estimateArrivalTime?: string
  /** 物流公司ID */
  courierCompanyId?: number
  /** 物流公司名称 */
  courierCompanyName?: string
  /** 物流单号 */
  trackingNo?: string
  /** 已发货数量 */
  shippedQuantity?: number
  shippedAmount?: MoneyDto
  status?: FmDeliveryFulfillmentStatus
}
/**
 * 订单行
 */
export type FmOrderDetailLineDto = {
  /** FM订单行ID */
  id?: string
  /** 行号 */
  lineNo?: string
  /** OA单号 */
  oaNo?: string
  /** OA行号 */
  oaLineNo?: string
  status?: FmOrderLineStatus
  /** 商品名称 */
  goodsName?: string
  /** 品牌 */
  goodsBrand?: string
  /** 配置 */
  goodsConfiguration?: string
  /** 授权期限（月） */
  licensingPeriod?: number
  /** 订单附件 */
  attachments?: StorageObjectDto[]
  /** 备注 */
  remark?: string
  unitUnTaxMoney?: Money
  /** 税率 */
  taxRate?: number
  freight?: Money
  /** 数量 */
  totalQuantity?: number
  unitMoney?: Money
  orderMoney?: Money
  orderUnTaxMoney?: Money
  /** 期望到货时间 */
  expectedDeliveryDate?: string
  /** 维保期限（月） */
  maintenancePeriod?: number
  /** 下单时间 */
  orderTime?: string
  invoiceType?: InvoiceType
  moneyCalBenchmark?: MoneyCalBenchmark
  /** 发货信息 */
  deliveryLines?: FmOrderDetailLineDeliveryDto[]
  orderMethod?: PlaceOrderMethod
  shippingAmount?: MoneyDto
  shippedAmount?: MoneyDto
  /** 已发货数量 */
  shippedQuantity?: number
  /** 下单数量 */
  orderQuantity?: number
  orderAmount?: MoneyDto
  shippedUnTaxAmount?: MoneyDto
  shippingUnTaxAmount?: MoneyDto
  /** 可发货数量 */
  shippingQuantity?: number
}
export type FmOrderDetailDto = {
  /** 订单ID */
  id?: string
  /** 结算公司code */
  settlementCompanyCode?: string
  /** 下单时间 */
  orderTime?: string
  /** 订单号 */
  orderNo?: string
  /** 合同号 */
  contractNumber?: string
  paymentMethod?: PaymentMethod
  /** 账期 */
  accountPeriod?: number
  moneyCalBenchmark?: MoneyCalBenchmark
  orderMoney?: MoneyDto
  orderUnTaxMoney?: MoneyDto
  receivingInfo?: FmReceivingInfoDto
  /** 订单行 */
  orderLines?: FmOrderDetailLineDto[]
  status?: FmOrderStatus
  /** 备注 */
  remark?: string
  /** 附件 */
  attachments?: StorageObjectDto[]
  /** 结算公司名称 */
  settlementCompanyName?: string
  /** 结算公司注册国家code */
  settlementCompanyCountryCode?: string
}
export type ResponseFmOrderDetailDto = {
  status?: number
  data?: FmOrderDetailDto
  debugMessage?: string
  message?: string
}
export type CourierCompanyDto = {
  /** id */
  id?: number
  /** 名称 */
  name?: string
}
export type ResponseListCourierCompanyDto = {
  status?: number
  data?: CourierCompanyDto[]
  debugMessage?: string
  message?: string
}
/**
 * 发货信息
 */
export type FmDeliveryInfoDto = {
  /** 发货人 */
  deliverer?: string
  /** 发货时间 */
  deliveryTime?: string
  type?: FmDeliveryType
  /** 预计送达时间 */
  estimateArrivalTime?: string
  /** 物流公司ID */
  courierCompanyId?: number
  /** 物流公司名称 */
  courierCompanyName?: string
  /** 物流单号 */
  trackingNo?: string
  /** 物流单地址 */
  trackingUrl?: string
  /** 发货备注 */
  remark?: string
  /** 发货单附件 */
  attachments?: StorageObjectDto[]
}
export type FmDeliveryFulfillmentDetailDto = {
  /** ASN单号 */
  id?: string
  /** PO单号 */
  orderNo?: string
  /** 结算公司code */
  settlementCompanyCode?: string
  status?: FmDeliveryFulfillmentStatus
  acceptanceStatus?: AcceptanceStatus
  receivingInfo?: FmReceivingInfoDto
  deliveryInfo?: FmDeliveryInfoDto
  moneyCalBenchmark?: MoneyCalBenchmark
  /** 发货明细 */
  lines?: FmDeliveryFulfillmentDetailLineDto[]
  /** 结算公司名称 */
  settlementCompanyName?: string
}
export type ResponseFmDeliveryFulfillmentDetailDto = {
  status?: number
  data?: FmDeliveryFulfillmentDetailDto
  debugMessage?: string
  message?: string
}
export type FmOperatorDto = {
  /** 用户id */
  id?: number
  /** 姓名 */
  name?: string
}
export type ResponseSetFmOperatorDto = {
  status?: number
  data?: FmOperatorDto[]
  debugMessage?: string
  message?: string
}
/**
 * 企业账号
 */
export type EnterpriseAccountPair = {
  /** 企业id */
  enterpriseId?: string
  /** 账号id */
  accountId?: number
  /** 企业名称 */
  enterpriseName?: string
  /** 创建时间 */
  createdAt?: string
}
export type AdminUserSession = {
  /** id */
  id?: number
  /** 邮箱 */
  mail?: string
  /** 名称 */
  name?: string
  /** 刷新时间 */
  refreshedAt?: string
  /** 企业账号 */
  enterpriseAccounts?: EnterpriseAccountPair[]
}
export type ResponseAdminUserSession = {
  status?: number
  data?: AdminUserSession
  debugMessage?: string
  message?: string
}
/**
 * 待报价
 */
export type WorkbenchQuotationStatisticsDto = {
  /** 待报价数量 */
  pendingQuotationNum?: number
}
/**
 * 待发货
 */
export type WorkbenchShippingStatisticsDto = {
  /** 待发货数量 */
  pendingShippingNum?: number
}
/**
 * 待投标
 */
export type WorkbenchPendingBiddingStatisticsDto = {
  /** 待投标数量 */
  pendingBiddingNum?: number
}
export type WorkbenchStatisticsDto = {
  /** 总数 */
  total?: number
  quotation?: WorkbenchQuotationStatisticsDto
  shipping?: WorkbenchShippingStatisticsDto
  pendingBidding?: WorkbenchPendingBiddingStatisticsDto
}
export type ResponseWorkbenchStatisticsDto = {
  status?: number
  data?: WorkbenchStatisticsDto
  debugMessage?: string
  message?: string
}
export type MonthPeriodDto = {
  /** 月份 */
  month?: number
  /** 描述 */
  desc?: string
}
export type ResponseListMonthPeriodDto = {
  status?: number
  data?: MonthPeriodDto[]
  debugMessage?: string
  message?: string
}
/**
 * 枚举集合
 */
export const enums = {
  // 发票类型
  StInvoiceTypeEnum: createEnum([
    // 中国大陆发票
    {
      key: 'CHINA',
      value: '01',
      name: '中国大陆发票',
    },
    // 非中国大陆发票
    {
      key: 'OVER_SEA',
      value: '02',
      name: '非中国大陆发票',
    },
  ] as const),
  // 发票子分类
  StOcrInvoiceSubTypeEnum: createEnum([
    // 增值税专用发票
    {
      key: 'VAT_SPECIAL_INVOICE',
      value: '1',
      name: '增值税专用发票',
    },
    // 增值税电子专用发票
    {
      key: 'VAT_E_SPECIAL_INVOICE',
      value: '2',
      name: '增值税电子专用发票',
    },
    // 增值税普通发票
    {
      key: 'VAT_GENERAL_INVOICE',
      value: '3',
      name: '增值税普通发票',
    },
    // 增值税电子普通发票
    {
      key: 'VAT_E_GENERAL_INVOICE',
      value: '4',
      name: '增值税电子普通发票',
    },
    // 增值税电子普通发票（通行费）
    {
      key: 'VAT_E_GENERAL_INVOICE_TOLL',
      value: '5',
      name: '增值税电子普通发票（通行费）',
    },
    // 区块链电子发票
    {
      key: 'BLOCKCHAIN_E_INVOICE',
      value: '6',
      name: '区块链电子发票',
    },
    // 增值税普通发票（卷式）
    {
      key: 'VAT_GENERAL_INVOICE_ROLL',
      value: '7',
      name: '增值税普通发票（卷式）',
    },
    // 机动车销售统一发票
    {
      key: 'VEHICLE_SALES_UNIFIED_INVOICE',
      value: '8',
      name: '机动车销售统一发票',
    },
    // 通用机打发票（纸质）
    {
      key: 'GENERAL_MACHINE_PRINTED_INVOICE_PAPER',
      value: '9',
      name: '通用机打发票（纸质）',
    },
    // 通用机打发票（电子）
    {
      key: 'GENERAL_MACHINE_PRINTED_INVOICE_ELECTRONIC',
      value: '10',
      name: '通用机打发票（电子）',
    },
    // 航空行程单
    {
      key: 'AIR_TRAVEL_ITINERARY',
      value: '11',
      name: '航空行程单',
    },
    // 火车票
    {
      key: 'TRAIN_TICKET',
      value: '12',
      name: '火车票',
    },
    // 客运汽车票
    {
      key: 'PASSENGER_CAR_TICKET',
      value: '13',
      name: '客运汽车票',
    },
    // 船票
    {
      key: 'SHIP_TICKET',
      value: '14',
      name: '船票',
    },
    // 出租车票
    {
      key: 'TAXI_TICKET',
      value: '15',
      name: '出租车票',
    },
    // 定额发票
    {
      key: 'QUOTA_INVOICE',
      value: '16',
      name: '定额发票',
    },
    // 滴滴行程单
    {
      key: 'DIDI_TRAVEL_ITINERARY',
      value: '17',
      name: '滴滴行程单',
    },
    // 数电发票（专票）
    {
      key: 'DIGITAL_INVOICE_SPECIAL',
      value: '18',
      name: '数电发票（专票）',
    },
    // 数电发票（普票）
    {
      key: 'DIGITAL_INVOICE_GENERAL',
      value: '19',
      name: '数电发票（普票）',
    },
    // 海外INVOICE
    {
      key: 'OVERSEAS_INVOICE',
      value: '20',
      name: '海外INVOICE',
    },
  ] as const),
  // 验真状态
  InvoiceCheckingStatusEnum: createEnum([
    // 未验真
    {
      key: 'NOT_CHECK',
      value: '1',
      name: '未验真',
    },
    // 验真成功
    {
      key: 'CHECK_SUCCESS',
      value: '2',
      name: '验真成功',
    },
    // 验真失败
    {
      key: 'CHECK_FAILED',
      value: '3',
      name: '验真失败',
    },
  ] as const),
  // 币种
  CurrencyEnum: createEnum([
    {
      key: 'CNY',
      value: 'CNY',
      name: 'unknown',
    },
    {
      key: 'USD',
      value: 'USD',
      name: 'unknown',
    },
    {
      key: 'HKD',
      value: 'HKD',
      name: 'unknown',
    },
    {
      key: 'EUR',
      value: 'EUR',
      name: 'unknown',
    },
    {
      key: 'GBP',
      value: 'GBP',
      name: 'unknown',
    },
    {
      key: 'KRW',
      value: 'KRW',
      name: 'unknown',
    },
    {
      key: 'JPY',
      value: 'JPY',
      name: 'unknown',
    },
    {
      key: 'AUD',
      value: 'AUD',
      name: 'unknown',
    },
    {
      key: 'CAD',
      value: 'CAD',
      name: 'unknown',
    },
    {
      key: 'TWY',
      value: 'TWY',
      name: 'unknown',
    },
    {
      key: 'CHF',
      value: 'CHF',
      name: 'unknown',
    },
    {
      key: 'DKK',
      value: 'DKK',
      name: 'unknown',
    },
    {
      key: 'NZD',
      value: 'NZD',
      name: 'unknown',
    },
    {
      key: 'PHP',
      value: 'PHP',
      name: 'unknown',
    },
    {
      key: 'RUB',
      value: 'RUB',
      name: 'unknown',
    },
    {
      key: 'SGD',
      value: 'SGD',
      name: 'unknown',
    },
    {
      key: 'THB',
      value: 'THB',
      name: 'unknown',
    },
    {
      key: 'VND',
      value: 'VND',
      name: 'unknown',
    },
  ] as const),
  // 金额计算基准
  MoneyCalBenchmarkEnum: createEnum([
    // 未税
    {
      key: 'UNTAXED',
      value: 'UNTAXED',
      name: '未税',
    },
    // 含税
    {
      key: 'TAXED',
      value: 'TAXED',
      name: '含税',
    },
  ] as const),
  // 发票支付状态
  PayStatusEnum: createEnum([
    // 未支付
    {
      key: 'NOT_PAY',
      value: '01',
      name: '未支付',
    },
    // 已支付
    {
      key: 'PAYED',
      value: '02',
      name: '已支付',
    },
    // 部分支付
    {
      key: 'PAY_PART',
      value: '03',
      name: '部分支付',
    },
  ] as const),
  // 发票状态
  StInvoiceStatusEnum: createEnum([
    // 未关联单据
    {
      key: 'NO_MATCH_INFO',
      value: '01',
      name: '未关联单据',
    },
    // 已关联单据
    {
      key: 'HAS_MATCH_INFO',
      value: '02',
      name: '已关联单据',
    },
    // 已确认
    {
      key: 'CONFIRMED',
      value: '04',
      name: '已确认',
    },
    // 已关联付款单
    {
      key: 'HAS_MATCH_PAYMENT',
      value: '05',
      name: '已关联付款单',
    },
  ] as const),
  // 发票创建方
  InvoiceSourcePartyEnum: createEnum([
    // 采购方
    {
      key: 'NET_BUY',
      value: '0',
      name: '采购方',
    },
    // 供应商协同方
    {
      key: 'SUPPLIER_PARTNER',
      value: '1',
      name: '供应商协同方',
    },
  ] as const),
  // 状态
  QuotationStatusEnum: createEnum([
    // 待报价
    {
      key: 'WAIT_QUOTATION',
      value: '1',
      name: '待报价',
    },
    // 待开标
    {
      key: 'HAS_QUOTATION',
      value: '2',
      name: '待开标',
    },
    // 已中标
    {
      key: 'HAS_WINNING_BID',
      value: '3',
      name: '已中标',
    },
    // 未中标
    {
      key: 'NOT_WINNING_BID',
      value: '4',
      name: '未中标',
    },
    // 已弃标
    {
      key: 'ABANDONED_BID',
      value: '5',
      name: '已弃标',
    },
    // 未参与
    {
      key: 'NOT_BID',
      value: '6',
      name: '未参与',
    },
    // 已取消
    {
      key: 'CANCEL',
      value: '7',
      name: '已取消',
    },
    // 待应标
    {
      key: 'WAIT_RESPOND_TENDER',
      value: '8',
      name: '待应标',
    },
    // 待投标
    {
      key: 'WAIT_TENDER',
      value: '9',
      name: '待投标',
    },
    // 已投标
    {
      key: 'TENDERED',
      value: '10',
      name: '已投标',
    },
    // 已作废
    {
      key: 'DEPRECATED',
      value: '11',
      name: '已作废',
    },
  ] as const),
  // 状态
  FmOrderLineStatusEnum: createEnum([
    // 待发货
    {
      key: 'WAIT_DELIVERY',
      value: '1',
      name: '待发货',
    },
    // 部分发货
    {
      key: 'PART_DELIVERY',
      value: '2',
      name: '部分发货',
    },
    // 已发货
    {
      key: 'DELIVERED',
      value: '3',
      name: '已发货',
    },
    // 已取消
    {
      key: 'CANCELLED',
      value: '4',
      name: '已取消',
    },
  ] as const),
  // 下单方式
  PlaceOrderMethodEnum: createEnum([
    // 按金额下单
    {
      key: 'AMOUNT',
      value: '1',
      name: '按金额下单',
    },
    // 按数量下单
    {
      key: 'QUANTITY',
      value: '2',
      name: '按数量下单',
    },
  ] as const),
  // 发票类型
  InvoiceTypeEnum: createEnum([
    // 增值税专票
    {
      key: 'VAT_SPECIAL_INVOICE',
      value: 'VAT_SPECIAL_INVOICE',
      name: '增值税专票',
    },
    // 增值税普票
    {
      key: 'VAT_GENERAL_INVOICE',
      value: 'VAT_GENERAL_INVOICE',
      name: '增值税普票',
    },
    // 电子发票（专票）
    {
      key: 'E_INVOICE_SPECIAL',
      value: 'E_INVOICE_SPECIAL',
      name: '电子发票（专票）',
    },
    // 电子发票（普票）
    {
      key: 'E_INVOICE_GENERAL',
      value: 'E_INVOICE_GENERAL',
      name: '电子发票（普票）',
    },
    // 数电发票（专票）
    {
      key: 'D_INVOICE_SPECIAL',
      value: 'D_INVOICE_SPECIAL',
      name: '数电发票（专票）',
    },
    // 数电发票（普票）
    {
      key: 'D_INVOICE_GENERAL',
      value: 'D_INVOICE_GENERAL',
      name: '数电发票（普票）',
    },
    // 其他
    {
      key: 'OTHERS',
      value: 'OTHERS',
      name: '其他',
    },
  ] as const),
  // 查询类型
  QuotationQueryTypeEnum: createEnum([
    // 我的咨询
    {
      key: 'MY',
      value: 'MY',
      name: '我的咨询',
    },
    // 其他人咨询
    {
      key: 'OTHER',
      value: 'OTHER',
      name: '其他人咨询',
    },
  ] as const),
  // 问题状态
  QuotationQuestionStatusEnum: createEnum([
    // 待澄清
    {
      key: 'WAIT_CLARIFIED',
      value: '0',
      name: '待澄清',
    },
    // 已澄清
    {
      key: 'CLARIFIED',
      value: '1',
      name: '已澄清',
    },
  ] as const),
  // 状态
  QuotationDisplayTenderStatusEnum: createEnum([
    // 待应标
    {
      key: 'WAIT_RESPOND_TENDER',
      value: '8',
      name: '待应标',
    },
    // 待投标
    {
      key: 'WAIT_TENDER',
      value: '9',
      name: '待投标',
    },
    // 已投标
    {
      key: 'TENDERED',
      value: '10',
      name: '已投标',
    },
    // 已中标
    {
      key: 'HAS_WINNING_BID',
      value: '3',
      name: '已中标',
    },
    // 未中标
    {
      key: 'NOT_WINNING_BID',
      value: '4',
      name: '未中标',
    },
    // 已弃标
    {
      key: 'ABANDONED_BID',
      value: '5',
      name: '已弃标',
    },
    // 未参与
    {
      key: 'NOT_BID',
      value: '6',
      name: '未参与',
    },
    // 已取消
    {
      key: 'CANCEL',
      value: '7',
      name: '已取消',
    },
    // 已作废
    {
      key: 'DEPRECATED',
      value: '11',
      name: '已作废',
    },
  ] as const),
  // 报价状态
  QuotationDisplayStatusEnum: createEnum([
    // 待报价
    {
      key: 'WAIT_QUOTATION',
      value: '1',
      name: '待报价',
    },
    // 待开标
    {
      key: 'HAS_QUOTATION',
      value: '2',
      name: '待开标',
    },
    // 已中标
    {
      key: 'HAS_WINNING_BID',
      value: '3',
      name: '已中标',
    },
    // 未中标
    {
      key: 'NOT_WINNING_BID',
      value: '4',
      name: '未中标',
    },
    // 已弃标
    {
      key: 'ABANDONED_BID',
      value: '5',
      name: '已弃标',
    },
    // 未参与
    {
      key: 'NOT_BID',
      value: '6',
      name: '未参与',
    },
    // 已取消
    {
      key: 'CANCEL',
      value: '7',
      name: '已取消',
    },
  ] as const),
  // 状态
  AccountStatusEnum: createEnum([
    // 禁用
    {
      key: 'DISABLED',
      value: '0',
      name: '禁用',
    },
    // 启用
    {
      key: 'ENABLED',
      value: '1',
      name: '启用',
    },
  ] as const),
  // 账号类型
  AccountTypeEnum: createEnum([
    // 主账号
    {
      key: 'MAIN',
      value: '1',
      name: '主账号',
    },
    // 子账号
    {
      key: 'SUB',
      value: '0',
      name: '子账号',
    },
  ] as const),
  // 企业类型
  EnterpriseTypeEnum: createEnum([
    // 企业
    {
      key: 'ENTERPRISE',
      value: '01',
      name: '企业',
    },
    // 个人
    {
      key: 'PERSON',
      value: '02',
      name: '个人',
    },
  ] as const),
  // 证件类型
  PersonIdTypeEnum: createEnum([
    // 居民身份证
    {
      key: 'RESIDENCE_ID_CARD',
      value: '01',
      name: '居民身份证',
    },
    // 港澳居民来往内地通行证
    {
      key: 'MAINLAND_TRAVEL_PERMIT_FOR_HONG_KONG_MACAU_RESIDENTS',
      value: '02',
      name: '港澳居民来往内地通行证',
    },
    // 中华人民共和国港澳居民居住证
    {
      key: 'RESIDENCE_PERMIT_FOR_HONG_KONG_MACAU_RESIDENTS',
      value: '03',
      name: '中华人民共和国港澳居民居住证',
    },
    // 台湾居民来往大陆通行证
    {
      key: 'TAIWAN_TRAVEL_PERMIT',
      value: '04',
      name: '台湾居民来往大陆通行证',
    },
    // 中华人民共和国台湾居民居住证
    {
      key: 'RESIDENCE_PERMIT_FOR_TAIWAN_RESIDENTS',
      value: '05',
      name: '中华人民共和国台湾居民居住证',
    },
    // 中国护照
    {
      key: 'CHINESE_PASSPORT',
      value: '06',
      name: '中国护照',
    },
    // 外国护照
    {
      key: 'FOREIGN_PASSPORT',
      value: '07',
      name: '外国护照',
    },
    // 外国人永久居留身份证(外国人永久居留证)
    {
      key: 'PERMANENT_RESIDENCE_ID_CARD',
      value: '08',
      name: '外国人永久居留身份证(外国人永久居留证)',
    },
    // 中华人民共和国外国人工作许可证(A类)
    {
      key: 'WORK_PERMIT_A',
      value: '09',
      name: '中华人民共和国外国人工作许可证(A类)',
    },
    // 中华人民共和国外国人工作许可证(B类)
    {
      key: 'WORK_PERMIT_B',
      value: '10',
      name: '中华人民共和国外国人工作许可证(B类)',
    },
    // 中华人民共和国外国人工作许可证(C类)
    {
      key: 'WORK_PERMIT_C',
      value: '11',
      name: '中华人民共和国外国人工作许可证(C类)',
    },
    // 其他个人证件
    {
      key: 'OTHER_PERSON_ID',
      value: '12',
      name: '其他个人证件',
    },
  ] as const),
  // 供应商合作状态
  SpCooperateStatusEnum: createEnum([
    // 注册
    {
      key: 'REGISTERED',
      value: '01',
      name: '注册',
    },
    // 潜在/小金额
    {
      key: 'POTENTIAL_SMALL_AMOUNT',
      value: '02',
      name: '潜在/小金额',
    },
    // 潜在/常规
    {
      key: 'POTENTIAL_REGULAR',
      value: '03',
      name: '潜在/常规',
    },
    // 合作/常规
    {
      key: 'COOPERATION_REGULAR',
      value: '04',
      name: '合作/常规',
    },
    // 合作/小金额
    {
      key: 'COOPERATION_SMALL_AMOUNT',
      value: '05',
      name: '合作/小金额',
    },
    // 淘汰
    {
      key: 'ELIMINATED',
      value: '06',
      name: '淘汰',
    },
    // 黑名单
    {
      key: 'BLACKLISTED',
      value: '07',
      name: '黑名单',
    },
  ] as const),
  // 是否是默认账号
  StatusEnum: createEnum([
    // 启用/yes
    {
      key: 'ENABLE',
      value: '1',
      name: '启用/yes',
    },
    // 禁用/no
    {
      key: 'DISABLE',
      value: '0',
      name: '禁用/no',
    },
  ] as const),
  // 税表类型
  EnterpriseTaxTypeEnum: createEnum([
    // W8-BEN
    {
      key: 'W8_BEN',
      value: 'W8-BEN',
      name: 'W8-BEN',
    },
    // W8-BEN-E
    {
      key: 'W8_BEN_E',
      value: 'W8-BEN-E',
      name: 'W8-BEN-E',
    },
    // W-8ECI
    {
      key: 'W_8ECI',
      value: 'W-8ECI',
      name: 'W-8ECI',
    },
    // W-8EXP
    {
      key: 'W_8EXP',
      value: 'W-8EXP',
      name: 'W-8EXP',
    },
    // W9
    {
      key: 'W9',
      value: 'W9',
      name: 'W9',
    },
    // others
    {
      key: 'OTHERS',
      value: 'others',
      name: 'others',
    },
  ] as const),
  // 纳税主体
  EnterpriseTaxPayerEnum: createEnum([
    // Individual
    {
      key: 'INDIVIDUAL',
      value: 'Individual',
      name: 'Individual',
    },
    // C corp
    {
      key: 'C_CORP',
      value: 'C corp',
      name: 'C corp',
    },
    // S corp
    {
      key: 'S_CORP',
      value: 'S corp',
      name: 'S corp',
    },
    // Partnership
    {
      key: 'PARTNERSHIP',
      value: 'Partnership',
      name: 'Partnership',
    },
    // Trust/estate
    {
      key: 'TRUST_ESTATE',
      value: 'Trust/estate',
      name: 'Trust/estate',
    },
    // LLC-C
    {
      key: 'LLC_C',
      value: 'LLC-C',
      name: 'LLC-C',
    },
    // LLC-S
    {
      key: 'LLC_S',
      value: 'LLC-S',
      name: 'LLC-S',
    },
    // LLC-P
    {
      key: 'LLC_P',
      value: 'LLC-P',
      name: 'LLC-P',
    },
    // LLC-T
    {
      key: 'OTHERS',
      value: 'others',
      name: 'LLC-T',
    },
  ] as const),
  // 供应商来源
  SourceTypeEnum: createEnum([
    {
      key: 'NETBUY',
      value: '1',
      name: 'unknown',
    },
    {
      key: 'PARTNERSHIP',
      value: '2',
      name: 'unknown',
    },
  ] as const),
  // 纳税人资质
  TaxpayerQualificationEnum: createEnum([
    // 一般纳税人
    {
      key: 'GENERAL',
      value: '01',
      name: '一般纳税人',
    },
    // 小规模纳税人
    {
      key: 'SMALL_SCALE',
      value: '02',
      name: '小规模纳税人',
    },
    // 非增值税纳税人
    {
      key: 'NON_VALUE_ADDED',
      value: '03',
      name: '非增值税纳税人',
    },
    // 其他
    {
      key: 'OTHER',
      value: '04',
      name: '其他',
    },
  ] as const),
  // 联系人类型
  ContactTypeEnum: createEnum([
    // 商务对接人
    {
      key: 'BUSINESS',
      value: '01',
      name: '商务对接人',
    },
    // 财务对接人
    {
      key: 'FINANCIAL',
      value: '02',
      name: '财务对接人',
    },
    // 其他
    {
      key: 'OTHERS',
      value: '03',
      name: '其他',
    },
  ] as const),
  // 职位
  SpSupervisorPositionEnum: createEnum([
    // 法定代表人
    {
      key: 'LEGAL_REPRESENTATIVE',
      value: '01',
      name: '法定代表人',
    },
    // 董事
    {
      key: 'DIRECTOR',
      value: '02',
      name: '董事',
    },
    // (总)经理
    {
      key: 'MANAGER',
      value: '03',
      name: '(总)经理',
    },
    // 监事
    {
      key: 'SUPERVISOR',
      value: '04',
      name: '监事',
    },
    // 财务负责人
    {
      key: 'CHARGE_OF_FINANCE',
      value: '05',
      name: '财务负责人',
    },
    // 其他
    {
      key: 'OTHERS',
      value: '06',
      name: '其他',
    },
  ] as const),
  // 手续费承担方
  HandlingFeeTypeEnum: createEnum([
    // 供应商承担
    {
      key: 'SUPPLIER',
      value: '01',
      name: '供应商承担',
    },
    // 网易集团承担
    {
      key: 'NETEASE',
      value: '02',
      name: '网易集团承担',
    },
    // 双方
    {
      key: 'BOTH',
      value: '03',
      name: '双方',
    },
  ] as const),
  // 账期
  AccountPeriodEnum: createEnum([
    // 15天
    {
      key: 'FIFTEEN',
      value: '15',
      name: '15天',
    },
    // 30天
    {
      key: 'THIRTY',
      value: '30',
      name: '30天',
    },
    // 45天
    {
      key: 'FORTY_FIVE',
      value: '45',
      name: '45天',
    },
    // 60天
    {
      key: 'SIXTY',
      value: '60',
      name: '60天',
    },
  ] as const),
  // 首选付款方式
  EnterprisePreferredPaymentMethodEnum: createEnum([
    {
      key: 'WIRE',
      value: 'WIRE',
      name: 'unknown',
    },
    {
      key: 'ACH',
      value: 'ACH',
      name: 'unknown',
    },
    {
      key: 'ACH_EFT',
      value: 'ACH_EFT',
      name: 'unknown',
    },
    {
      key: 'ACH_BACS',
      value: 'ACH_BACS',
      name: 'unknown',
    },
    {
      key: 'CREDIT_CARD',
      value: 'CREDIT_CARD',
      name: 'unknown',
    },
    {
      key: 'SEPA',
      value: 'SEPA',
      name: 'unknown',
    },
    {
      key: 'OTHERS',
      value: 'OTHERS',
      name: 'unknown',
    },
  ] as const),
  // 账号类型
  EnterpriseBankAccountTypeEnum: createEnum([
    {
      key: 'CHECKING',
      value: 'CHECKING',
      name: 'unknown',
    },
    {
      key: 'SAVINGS',
      value: 'SAVINGS',
      name: 'unknown',
    },
    {
      key: 'CD',
      value: 'CD',
      name: 'unknown',
    },
    {
      key: 'MMA',
      value: 'MMA',
      name: 'unknown',
    },
  ] as const),
  SystemLocaleEnum: createEnum([
    {
      key: 'ZH',
      value: 'zh-CN',
      name: 'unknown',
    },
    {
      key: 'EN',
      value: 'en-US',
      name: 'unknown',
    },
    {
      key: 'JA',
      value: 'ja-JP',
      name: 'unknown',
    },
    {
      key: 'FR',
      value: 'fr-FR',
      name: 'unknown',
    },
  ] as const),
  // 发货类型
  FmDeliveryTypeEnum: createEnum([
    // 快递物流
    {
      key: 'EXPRESS_AND_LOGISTICS',
      value: '0',
      name: '快递物流',
    },
    // 非快递物流
    {
      key: 'NON_EXPRESS_AND_LOGISTICS',
      value: '1',
      name: '非快递物流',
    },
  ] as const),
  // 订单状态
  FmOrderStatusEnum: createEnum([
    // 待发货
    {
      key: 'WAIT_DELIVERY',
      value: '1',
      name: '待发货',
    },
    // 部分发货
    {
      key: 'PART_DELIVERY',
      value: '2',
      name: '部分发货',
    },
    // 已发货
    {
      key: 'DELIVERED',
      value: '3',
      name: '已发货',
    },
    // 已取消
    {
      key: 'CANCELLED',
      value: '4',
      name: '已取消',
    },
  ] as const),
  // 报价方式
  QuoteMethodEnum: createEnum([
    // 线下代理报价
    {
      key: 'OFFLINE',
      value: '0',
      name: '线下代理报价',
    },
    // 在线询价
    {
      key: 'ONLINE',
      value: '1',
      name: '在线询价',
    },
    // 招投标
    {
      key: 'TENDER',
      value: '2',
      name: '招投标',
    },
  ] as const),
  // 验收状态
  AcceptanceStatusEnum: createEnum([
    // 待验收
    {
      key: 'PENDING_ACCEPTANCE',
      value: '1',
      name: '待验收',
    },
    // 部分验收
    {
      key: 'PART_ACCEPTED',
      value: '2',
      name: '部分验收',
    },
    // 全部验收
    {
      key: 'ACCEPTED',
      value: '3',
      name: '全部验收',
    },
    // 已关闭
    {
      key: 'CLOSED',
      value: '4',
      name: '已关闭',
    },
  ] as const),
  // 发货状态
  FmDeliveryFulfillmentStatusEnum: createEnum([
    // 已发货
    {
      key: 'DELIVERED',
      value: '1',
      name: '已发货',
    },
    // 已关闭
    {
      key: 'CLOSED',
      value: '2',
      name: '已关闭',
    },
  ] as const),
  EcTaskTypeEnum: createEnum([
    {
      key: 'IN_COMING',
      value: 'IN_COMING',
      name: 'unknown',
    },
    {
      key: 'OUT_GOING',
      value: 'OUT_GOING',
      name: 'unknown',
    },
  ] as const),
  EcTaskExecuteStatusEnum: createEnum([
    {
      key: 'INIT',
      value: 'INIT',
      name: 'unknown',
    },
    {
      key: 'SUCCESS',
      value: 'SUCCESS',
      name: 'unknown',
    },
    {
      key: 'WAITING',
      value: 'WAITING',
      name: 'unknown',
    },
    {
      key: 'PROCESSING',
      value: 'PROCESSING',
      name: 'unknown',
    },
    {
      key: 'VALIDATE_FAILED',
      value: 'VALIDATE_FAILED',
      name: 'unknown',
    },
    {
      key: 'EXECUTION_FAILED',
      value: 'EXECUTION_FAILED',
      name: 'unknown',
    },
    {
      key: 'CANCELLED',
      value: 'CANCELLED',
      name: 'unknown',
    },
    {
      key: 'RETRY',
      value: 'RETRY',
      name: 'unknown',
    },
  ] as const),
  EcTaskExecuteTypeEnum: createEnum([
    {
      key: 'SYNC',
      value: 'SYNC',
      name: 'unknown',
    },
    {
      key: 'ASYNC',
      value: 'ASYNC',
      name: 'unknown',
    },
    {
      key: 'ASYNC_BUSINESS_KEY_DEPENDENT',
      value: 'ASYNC_BUSINESS_KEY_DEPENDENT',
      name: 'unknown',
    },
  ] as const),
  // 状态
  TaskStatusEnum: createEnum([
    // 执行中
    {
      key: 'RUNNING',
      value: 'RUNNING',
      name: '执行中',
    },
    // 执行失败
    {
      key: 'FAIL',
      value: 'FAIL',
      name: '执行失败',
    },
    // 执行成功
    {
      key: 'SUCCESS',
      value: 'SUCCESS',
      name: '执行成功',
    },
  ] as const),
  // 报价方式
  QuotationMethodEnum: createEnum([
    // 在线询价
    {
      key: 'ONLINE',
      value: '1',
      name: '在线询价',
    },
    // 招投标
    {
      key: 'TENDER',
      value: '2',
      name: '招投标',
    },
  ] as const),
  // 付款方式
  PaymentMethodEnum: createEnum([
    // 转账
    {
      key: 'TRANSFER',
      value: '0',
      name: '转账',
    },
    // 信用卡
    {
      key: 'CREDIT_CARD',
      value: '1',
      name: '信用卡',
    },
    // 转账-预付
    {
      key: 'TRANSFER_PREPAY',
      value: '2',
      name: '转账-预付',
    },
  ] as const),
  // 行状态
  QuotationLineStatusEnum: createEnum([
    // 待报价
    {
      key: 'WAIT_QUOTATION',
      value: '1',
      name: '待报价',
    },
    // 待开标
    {
      key: 'HAS_QUOTATION',
      value: '2',
      name: '待开标',
    },
    // 已中标
    {
      key: 'HAS_WINNING_BID',
      value: '3',
      name: '已中标',
    },
    // 未中标
    {
      key: 'NOT_WINNING_BID',
      value: '4',
      name: '未中标',
    },
    // 已弃标
    {
      key: 'ABANDONED_BID',
      value: '5',
      name: '已弃标',
    },
    // 未参与
    {
      key: 'NOT_BID',
      value: '6',
      name: '未参与',
    },
    // 已取消
    {
      key: 'CANCEL',
      value: '7',
      name: '已取消',
    },
    // 待应标
    {
      key: 'WAIT_RESPOND_TENDER',
      value: '8',
      name: '待应标',
    },
    // 待投标
    {
      key: 'WAIT_TENDER',
      value: '9',
      name: '待投标',
    },
    // 已投标
    {
      key: 'TENDERED',
      value: '10',
      name: '已投标',
    },
    // 已作废
    {
      key: 'DEPRECATED',
      value: '11',
      name: '已作废',
    },
  ] as const),
  // 澄清状态
  QuotationClarificationStatusEnum: createEnum([
    // 待澄清
    {
      key: 'WAIT_CLARIFIED',
      value: '0',
      name: '待澄清',
    },
    // 已澄清
    {
      key: 'CLARIFIED',
      value: '1',
      name: '已澄清',
    },
    // 澄清结束
    {
      key: 'END_CLARIFIED',
      value: '2',
      name: '澄清结束',
    },
  ] as const),
  // 竞价模式
  QuotationRuleBiddingModeEnum: createEnum([
    // 英氏报价（反向）
    {
      key: 'ENGLISH_QUOTATION_REVERSE',
      value: '1',
      name: '英氏报价（反向）',
    },
  ] as const),
  // 调整步幅类型
  QuotationRuleAdjustStepTypeEnum: createEnum([
    // 按金额
    {
      key: 'BY_AMOUNT',
      value: '1',
      name: '按金额',
    },
    // 按比例
    {
      key: 'BY_PROPORTION',
      value: '2',
      name: '按比例',
    },
    // 不限
    {
      key: 'UNLIMITED',
      value: '3',
      name: '不限',
    },
  ] as const),
  // 竞价规则
  QuotationRuleBiddingRegulationEnum: createEnum([
    // 前三名不允许报价相同
    {
      key: 'TOP_THREE_NOT_SAME',
      value: '1',
      name: '前三名不允许报价相同',
    },
    // 所有报价需超过第一名
    {
      key: 'MUST_EXCEED_FIRST_PLACE',
      value: '2',
      name: '所有报价需超过第一名',
    },
    // 所有排名不允许报价相同
    {
      key: 'QUOTATION_NOT_SAME',
      value: '3',
      name: '所有排名不允许报价相同',
    },
  ] as const),
  // 中标公告范围
  QuotationTenderAnnouncementScopeEnum: createEnum([
    // 无公告
    {
      key: 'NONE',
      value: '01',
      name: '无公告',
    },
    // 参与采购商可见
    {
      key: 'PARTNER',
      value: '02',
      name: '参与采购商可见',
    },
    // 采购商门户可见
    {
      key: 'PARTNER_PORTAL',
      value: '03',
      name: '采购商门户可见',
    },
  ] as const),
  // 通知状态
  NotificationStatusEnum: createEnum([
    {
      key: 'HAS_READ',
      value: '1',
      name: 'unknown',
    },
    {
      key: 'NO_READ',
      value: '0',
      name: 'unknown',
    },
  ] as const),
  // 通知类型
  NotificationTypeEnum: createEnum([
    {
      key: 'QUOTATION_TYPE',
      value: '1',
      name: 'unknown',
    },
    {
      key: 'ORDER_TYPE',
      value: '2',
      name: 'unknown',
    },
    {
      key: 'APPROVAL_TYPE',
      value: '3',
      name: 'unknown',
    },
    {
      key: 'TENDER_TYPE',
      value: '4',
      name: 'unknown',
    },
  ] as const),
  // 准入申请状态
  SpOaFlowStatusEnum: createEnum([
    // 待审核
    {
      key: 'DRAFT',
      value: '01',
      name: '待审核',
    },
    // 审批中
    {
      key: 'IN_APPROVE',
      value: '02',
      name: '审批中',
    },
    // 审批成功
    {
      key: 'APPROVE_SUCCESS',
      value: '03',
      name: '审批成功',
    },
    // 审批拒绝
    {
      key: 'REJECTED',
      value: '04',
      name: '审批拒绝',
    },
  ] as const),
  // 供应商类型
  SupplierTypeEnum: createEnum([
    // 企业
    {
      key: 'ENTERPRISE',
      value: '01',
      name: '企业',
    },
    // 个人
    {
      key: 'PERSON',
      value: '02',
      name: '个人',
    },
  ] as const),
  // 审核类型
  AuditTypeEnum: createEnum([
    // 审批通过
    {
      key: 'AUDIT_PASS',
      value: '1',
      name: '审批通过',
    },
    // 审批拒绝
    {
      key: 'AUDIT_REFUSE',
      value: '2',
      name: '审批拒绝',
    },
  ] as const),
  // 关联的 进行中的 变更申请类型
  SpApplicationTypeEnum: createEnum([
    // 准入审批
    {
      key: 'ON_BOARDING',
      value: '01',
      name: '准入审批',
    },
    // 内容变更
    {
      key: 'MODIFICATION',
      value: '02',
      name: '内容变更',
    },
    // 合作状态变更
    {
      key: 'COOPERATE_STATUS',
      value: '03',
      name: '合作状态变更',
    },
    // 供应商创建
    {
      key: 'SUPPLIER_CREATE',
      value: '04',
      name: '供应商创建',
    },
    // 供应商找回
    {
      key: 'SUPPLIER_RETRIEVAL',
      value: '05',
      name: '供应商找回',
    },
    // 供应商利益冲突
    {
      key: 'SUPPLIER_INTEREST_CONFLICT',
      value: '06',
      name: '供应商利益冲突',
    },
  ] as const),
  // 供应商子集
  SupplierSubSetEnum: createEnum([
    // 中国境内企业
    {
      key: 'CHINA_ENTERPRISE',
      value: '01',
      name: '中国境内企业',
    },
    // 中国境内个人
    {
      key: 'CHINA_PERSON',
      value: '02',
      name: '中国境内个人',
    },
    // 境外企业
    {
      key: 'OVERSEAS_ENTERPRISE',
      value: '03',
      name: '境外企业',
    },
    // 境外个人
    {
      key: 'OVERSEAS_PERSON',
      value: '04',
      name: '境外个人',
    },
  ] as const),
  // 供应商 准入进度
  SpOnboardProgressEnum: createEnum([
    // 待准入
    {
      key: 'NOT_BEGIN',
      value: '01',
      name: '待准入',
    },
    // 待采购员审核
    {
      key: 'IN_AUDIT',
      value: '05',
      name: '待采购员审核',
    },
    // 准入审批中
    {
      key: 'IN_PROGRESS',
      value: '02',
      name: '准入审批中',
    },
    // 准入审批拒绝
    {
      key: 'FAIL',
      value: '03',
      name: '准入审批拒绝',
    },
    // 准入审批成功
    {
      key: 'SUCCEED',
      value: '04',
      name: '准入审批成功',
    },
  ] as const),
  // 供应商 变更进度
  SpModifiedProgressEnum: createEnum([
    // 未发起变更
    {
      key: 'NOT_BEGIN',
      value: '01',
      name: '未发起变更',
    },
    // 变更中
    {
      key: 'IN_PROGRESS',
      value: '02',
      name: '变更中',
    },
    // 变更失败,OA审核拒绝
    {
      key: 'FAIL',
      value: '03',
      name: '变更失败,OA审核拒绝',
    },
    // 变更成功,OA审核通过
    {
      key: 'SUCCEED',
      value: '04',
      name: '变更成功,OA审核通过',
    },
    // 待采购员审核
    {
      key: 'IN_AUDIT',
      value: '05',
      name: '待采购员审核',
    },
  ] as const),
  // 供应商 合作状态变更进度
  SpCooperateStatusChangeProgressEnum: createEnum([
    // 待审批
    {
      key: 'NOT_BEGIN',
      value: '01',
      name: '待审批',
    },
    // 审批中
    {
      key: 'IN_PROGRESS',
      value: '02',
      name: '审批中',
    },
    // 审批拒绝
    {
      key: 'FAIL',
      value: '03',
      name: '审批拒绝',
    },
    // 审批成功
    {
      key: 'SUCCEED',
      value: '04',
      name: '审批成功',
    },
  ] as const),
  // 权限
  AuthorityEnum: createEnum([
    // 企业管理-账号管理-新建
    {
      key: 'ENTERPRISE_ACCOUNT_CRATE',
      value: 'enterprise:account:create',
      name: '企业管理-账号管理-新建',
    },
    // 企业管理-账号管理-转交
    {
      key: 'ENTERPRISE_ACCOUNT_HANDOVER',
      value: 'enterprise:account:handover',
      name: '企业管理-账号管理-转交',
    },
    // 工作台-待报价
    {
      key: 'WORKBENCH_WAIT_QUOTATION',
      value: 'WORKBENCH_WAIT_QUOTATION',
      name: '工作台-待报价',
    },
    // 工作台-待发货
    {
      key: 'WORKBENCH_WAIT_DELIVERY',
      value: 'WORKBENCH_WAIT_DELIVERY',
      name: '工作台-待发货',
    },
    // FM订单-发货
    {
      key: 'FM_DELIVERY',
      value: 'FM_DELIVERY',
      name: 'FM订单-发货',
    },
    // FM订单-详情
    {
      key: 'FM_DETAIL',
      value: 'FM_DETAIL',
      name: 'FM订单-详情',
    },
    // FM订单-列表
    {
      key: 'FM_LIST',
      value: 'FM_LIST',
      name: 'FM订单-列表',
    },
    // FM订单-行列表
    {
      key: 'FM_LINE_LIST',
      value: 'FM_LINE_LIST',
      name: 'FM订单-行列表',
    },
    // FM订单-发货详情
    {
      key: 'FM_DELIVERY_DETAIL',
      value: 'FM_DELIVERY_DETAIL',
      name: 'FM订单-发货详情',
    },
    // FM订单-发货详情
    {
      key: 'FM_DELIVERY_LIST',
      value: 'FM_DELIVERY_LIST',
      name: 'FM订单-发货详情',
    },
    // 报价-弃标
    {
      key: 'QUOTATION_GIVE_UP',
      value: 'QUOTATION_GIVE_UP',
      name: '报价-弃标',
    },
    // 报价-提交报价
    {
      key: 'QUOTATION_SUBMIT',
      value: 'QUOTATION_SUBMIT',
      name: '报价-提交报价',
    },
    // 报价-修改报价
    {
      key: 'QUOTATION_UPDATE',
      value: 'QUOTATION_UPDATE',
      name: '报价-修改报价',
    },
    // 报价询价单-详情
    {
      key: 'QUOTATION_RFX_DETAIL',
      value: 'QUOTATION_RFX_DETAIL',
      name: '报价询价单-详情',
    },
    // 报价询价单-列表
    {
      key: 'QUOTATION_RFX_LIST',
      value: 'QUOTATION_RFX_LIST',
      name: '报价询价单-列表',
    },
    // 报价询价单-行列表
    {
      key: 'QUOTATION_RFX_LINE_LIST',
      value: 'QUOTATION_RFX_LINE_LIST',
      name: '报价询价单-行列表',
    },
    // 报价单-详情
    {
      key: 'QUOTATION_DETAIL',
      value: 'QUOTATION_DETAIL',
      name: '报价单-详情',
    },
    // 报价单-数量
    {
      key: 'QUOTATION_COUNT',
      value: 'QUOTATION_COUNT',
      name: '报价单-数量',
    },
    // 竞价-轮询报价相关信息
    {
      key: 'QUOTATION_POLLING',
      value: 'QUOTATION_POLLING',
      name: '竞价-轮询报价相关信息',
    },
    // 竞价-图表信息
    {
      key: 'QUOTATION_CHART',
      value: 'QUOTATION_CHART',
      name: '竞价-图表信息',
    },
    // 招投标-列表
    {
      key: 'QUOTATION_TENDER_LIST',
      value: 'QUOTATION_TENDER_LIST',
      name: '招投标-列表',
    },
    // 招投标-投标详情
    {
      key: 'QUOTATION_TENDER_DETAIL',
      value: 'QUOTATION_TENDER_DETAIL',
      name: '招投标-投标详情',
    },
    // 招投标-参与投标
    {
      key: 'QUOTATION_TENDER_PARTICIPATE_TENDER',
      value: 'QUOTATION_TENDER_PARTICIPATE_TENDER',
      name: '招投标-参与投标',
    },
    // 招投标-弃标
    {
      key: 'QUOTATION_TENDER_DISCARDED_TENDER',
      value: 'QUOTATION_TENDER_DISCARDED_TENDER',
      name: '招投标-弃标',
    },
    // 招投标-咨询
    {
      key: 'QUOTATION_TENDER_QUESTION_CONSULTATION',
      value: 'QUOTATION_TENDER_QUESTION_CONSULTATION',
      name: '招投标-咨询',
    },
    // 招投标-澄清咨询列表
    {
      key: 'QUOTATION_TENDER_QUESTION_LIST',
      value: 'QUOTATION_TENDER_QUESTION_LIST',
      name: '招投标-澄清咨询列表',
    },
    // 招投标-澄清咨询详情
    {
      key: 'QUOTATION_TENDER_QUESTION_DETAIL',
      value: 'QUOTATION_TENDER_QUESTION_DETAIL',
      name: '招投标-澄清咨询详情',
    },
    // 招投标-报价历史
    {
      key: 'QUOTATION_TENDER_REVISION_HISTORY',
      value: 'QUOTATION_TENDER_REVISION_HISTORY',
      name: '招投标-报价历史',
    },
    // 暂存-暂存报价
    {
      key: 'STAGING_SAVE_QUOTATION',
      value: 'STAGING_SAVE_QUOTATION',
      name: '暂存-暂存报价',
    },
    // 暂存-暂存报价详情
    {
      key: 'STAGING_QUOTATION_DETAIL',
      value: 'STAGING_QUOTATION_DETAIL',
      name: '暂存-暂存报价详情',
    },
  ] as const),
}
/**
 * 暂存报价 /api/purchase-partnership/admin/staging/quotation/save/{quotationId}
 */
export function stagingStagingSaveQuotation(
  params: {
    quotationId: string
  },
  data: StagingQuotationSaveCommand,
  opts?: ReqOpts
) {
  const { quotationId } = params
  return request<DataOfResponse<ResponseVoid>>(
    `/api/purchase-partnership/admin/staging/quotation/save/${quotationId}`,
    { method: 'POST', data, ...opts }
  )
}
/**
 * OCR发票识别任务创建 /api/purchase-partnership/admin/settlement/ocr/task
 */
export function settlementOcrCreateOcrTask(
  params: {
    fileId: number
    fileName: string
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseOcrTaskId>>(
    createUri('/api/purchase-partnership/admin/settlement/ocr/task', query),
    { method: 'POST', ...opts }
  )
}
/**
 * 提交 /api/purchase-partnership/admin/settlement/invoice/submit
 */
export function settlementSubmit(data: InvoiceSubmitCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseStInvoiceId>>('/api/purchase-partnership/admin/settlement/invoice/submit', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 查询可匹配的订单列表 /api/purchase-partnership/admin/settlement/invoice/match-orders
 */
export function settlementQueryMatchOrders(data: InvoiceMatchDataQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListInvoiceMatchOrderDto>>(
    '/api/purchase-partnership/admin/settlement/invoice/match-orders',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 查询可匹配的订单行列表 /api/purchase-partnership/admin/settlement/invoice/match-order-lines
 */
export function settlementQueryMatchOrderLines(data: InvoiceMatchDataQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListInvoiceMatchOrderLineDto>>(
    '/api/purchase-partnership/admin/settlement/invoice/match-order-lines',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 查询可匹配的gr列表 /api/purchase-partnership/admin/settlement/invoice/match-grs
 */
export function settlementQueryMatchGRs(data: InvoiceMatchDataQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListInvoiceMatchGrDto>>(
    '/api/purchase-partnership/admin/settlement/invoice/match-grs',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 列表 /api/purchase-partnership/admin/settlement/invoice/list
 */
export function settlementListForSupplier(data: InvoiceListQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponsePageDtoInvoiceListDto>>(
    '/api/purchase-partnership/admin/settlement/invoice/list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 订单转换成树形关联关系 /api/purchase-partnership/admin/settlement/invoice/convert-order-match-tree
 */
export function settlementConvertOrder2MatchTree(data: InvoiceConvertMatchTreeCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListInvoiceMatchOrderDto>>(
    '/api/purchase-partnership/admin/settlement/invoice/convert-order-match-tree',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 订单行转换成树形关联关系 /api/purchase-partnership/admin/settlement/invoice/convert-order-line-match-tree
 */
export function settlementConvertOrderLine2MatchTree(data: InvoiceConvertMatchTreeCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListInvoiceMatchOrderDto>>(
    '/api/purchase-partnership/admin/settlement/invoice/convert-order-line-match-tree',
    { method: 'POST', data, ...opts }
  )
}
/**
 * gr转换成树形关联关系 /api/purchase-partnership/admin/settlement/invoice/convert-gr-match-tree
 */
export function settlementConvertGr2MatchTree(data: InvoiceConvertMatchTreeCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListInvoiceMatchOrderDto>>(
    '/api/purchase-partnership/admin/settlement/invoice/convert-gr-match-tree',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 请求上传文件 /api/purchase-partnership/admin/settlement/config/request-upload-file
 */
export function settlementConfigRequestUploadFile(data: StFileRequestUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileRequestUploadDto>>(
    '/api/purchase-partnership/admin/settlement/config/request-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 完成文件上传 /api/purchase-partnership/admin/settlement/config/complete-upload-file
 */
export function settlementConfigCompleteUploadFile(data: FileCompleteUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileCompleteUploadDto>>(
    '/api/purchase-partnership/admin/settlement/config/complete-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 待投标 /api/purchase-partnership/admin/quotation/workbench/wait-tender-list
 */
export function quotationWorkbenchQueryQuotationTenderList(data: QuotationTenderWorkbenchQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponsePageDtoQuotationTenderWorkbenchListDto>>(
    '/api/purchase-partnership/admin/quotation/workbench/wait-tender-list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 待报价 /api/purchase-partnership/admin/quotation/workbench/wait-list
 */
export function quotationWorkbenchQueryQuotationList(data: QuotationWorkbenchQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponsePageDtoQuotationWorkbenchListDto>>(
    '/api/purchase-partnership/admin/quotation/workbench/wait-list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 待发货 /api/purchase-partnership/admin/quotation/workbench/wait-delivery-list
 */
export function fmWorkbenchQueryWaitDeliveryList(data: FmFulfillmentWorkbenchQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponsePageDtoFmDeliveryFulfillmentWaitListDto>>(
    '/api/purchase-partnership/admin/quotation/workbench/wait-delivery-list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 修改报价 /api/purchase-partnership/admin/quotation/update/{quotationId}
 */
export function quotationQuotationUpdateQuotation(
  params: {
    quotationId: string
  },
  data: UpdateQuotationCommand,
  opts?: ReqOpts
) {
  const { quotationId } = params
  return request<DataOfResponse<ResponseVoid>>(`/api/purchase-partnership/admin/quotation/update/${quotationId}`, {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 澄清咨询列表 /api/purchase-partnership/admin/quotation/tender/question-list
 */
export function quotationTenderQueryQuestionList(data: QuotationTenderQuestionListQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListQuotationTenderQuestionDto>>(
    '/api/purchase-partnership/admin/quotation/tender/question-list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 应标 /api/purchase-partnership/admin/quotation/tender/participate-tender/{id}
 */
export function quotationTenderParticipateTender(
  params: {
    id: string
  },
  data: ParticipateTenderCommand,
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseVoid>>(
    `/api/purchase-partnership/admin/quotation/tender/participate-tender/${id}`,
    { method: 'POST', data, ...opts }
  )
}
/**
 * 投标管理列表 /api/purchase-partnership/admin/quotation/tender/list
 */
export function quotationTenderList(data: QuotationTenderQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponsePageDtoQuotationTenderListDto>>(
    '/api/purchase-partnership/admin/quotation/tender/list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 咨询 /api/purchase-partnership/admin/quotation/tender/consultation/{id}
 */
export function quotationTenderConsultation(
  params: {
    id: string
  },
  data: QuestionTenderConsultationCommand,
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseVoid>>(`/api/purchase-partnership/admin/quotation/tender/consultation/${id}`, {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 请求上传文件 /api/purchase-partnership/admin/quotation/request-upload-file
 */
export function quotationQuotationRequestUploadFile(data: FileRequestUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileRequestUploadDto>>(
    '/api/purchase-partnership/admin/quotation/request-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 报价列表 /api/purchase-partnership/admin/quotation/line-list
 */
export function quotationQuotationQueryRfxLineList(data: QuotationQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponsePageDtoQuotationListDto>>(
    '/api/purchase-partnership/admin/quotation/line-list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 弃标 /api/purchase-partnership/admin/quotation/give-up/{quotationId}
 */
export function quotationQuotationGiveUpQuotation(
  params: {
    quotationId: string
  },
  opts?: ReqOpts
) {
  const { quotationId } = params
  return request<DataOfResponse<ResponseVoid>>(`/api/purchase-partnership/admin/quotation/give-up/${quotationId}`, {
    method: 'POST',
    ...opts,
  })
}
/**
 * 完成文件上传 /api/purchase-partnership/admin/quotation/complete-upload-file
 */
export function quotationQuotationCompleteUploadFile(data: FileCompleteUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileCompleteUploadDto>>(
    '/api/purchase-partnership/admin/quotation/complete-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 账户列表 /api/purchase-partnership/admin/identity/enterpriseaccounts
 */
export function identityEnterpriseAccountList(
  params: {
    current: number
    limit: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponsePageDtoIdAccountListDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterpriseaccounts', query),
    { ...opts }
  )
}
/**
 * 添加账户 /api/purchase-partnership/admin/identity/enterpriseaccounts
 */
export function identityEnterpriseAccountAdd(data: IdCreateAccountCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>('/api/purchase-partnership/admin/identity/enterpriseaccounts', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 账户详情 /api/purchase-partnership/admin/identity/enterpriseaccounts/{accountId}
 */
export function identityEnterpriseAccountGet(
  params: {
    accountId: number
  },
  opts?: ReqOpts
) {
  const { accountId } = params
  return request<DataOfResponse<ResponseIdAccountDto>>(
    `/api/purchase-partnership/admin/identity/enterpriseaccounts/${accountId}`,
    { ...opts }
  )
}
/**
 * 更新账号 /api/purchase-partnership/admin/identity/enterpriseaccounts/{accountId}
 */
export function identityEnterpriseAccountUpdate(
  params: {
    accountId: number
  },
  data: IdUpdateAccountCommand,
  opts?: ReqOpts
) {
  const { accountId } = params
  return request<DataOfResponse<ResponseVoid>>(
    `/api/purchase-partnership/admin/identity/enterpriseaccounts/${accountId}`,
    { method: 'POST', data, ...opts }
  )
}
/**
 * 转移账户 /api/purchase-partnership/admin/identity/enterpriseaccounts/transfer
 */
export function identityEnterpriseAccountTransfer(data: IdTransferAccountCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>('/api/purchase-partnership/admin/identity/enterpriseaccounts/transfer', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 查询是否存在相同邮箱账号 /api/purchase-partnership/admin/identity/enterpriseaccounts/existSameMail/{mail}
 */
export function identityEnterpriseAccountExistSameMail(
  params: {
    mail: string
  },
  opts?: ReqOpts
) {
  const { mail } = params
  return request<DataOfResponse<ResponseBoolean>>(
    `/api/purchase-partnership/admin/identity/enterpriseaccounts/existSameMail/${mail}`,
    { method: 'POST', ...opts }
  )
}
/**
 * 启用账户 /api/purchase-partnership/admin/identity/enterpriseaccounts/enable
 */
export function identityEnterpriseAccountEnable(data: IdEnableAccountCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>('/api/purchase-partnership/admin/identity/enterpriseaccounts/enable', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 禁用账户 /api/purchase-partnership/admin/identity/enterpriseaccounts/disable
 */
export function identityEnterpriseAccountDisable(data: IdDisableAccountCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>('/api/purchase-partnership/admin/identity/enterpriseaccounts/disable', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 修改账户密码 /api/purchase-partnership/admin/identity/enterpriseaccounts/change-password
 */
export function identityEnterpriseAccountChangePassword(data: IdChangeAccountPasswordCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>(
    '/api/purchase-partnership/admin/identity/enterpriseaccounts/change-password',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 注册 /api/purchase-partnership/admin/identity/enterprise/reg
 */
export function identityEnterpriseRegistrationRegister(data: IdEnterpriseRegistrationCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseString>>('/api/purchase-partnership/admin/identity/enterprise/reg', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 发送验证码 /api/purchase-partnership/admin/identity/enterprise/reg/send-verification-code
 */
export function identityEnterpriseRegistrationSendVerificationCode(
  data: IdSendRegistrationVerificationCodeCommand,
  opts?: ReqOpts
) {
  return request<DataOfResponse<ResponseVoid>>(
    '/api/purchase-partnership/admin/identity/enterprise/reg/send-verification-code',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 管理员注册-发送验证码 /api/purchase-partnership/admin/identity/enterprise/reg/send-admin-register-verification-code
 */
export function identityEnterpriseRegistrationAdminRegisterSendVerificationCode(
  data: IdSendRegistrationVerificationCodeCommand,
  opts?: ReqOpts
) {
  return request<DataOfResponse<ResponseVoid>>(
    '/api/purchase-partnership/admin/identity/enterprise/reg/send-admin-register-verification-code',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 请求上传文件 /api/purchase-partnership/admin/identity/enterprise/reg/request-upload-file
 */
export function identityEnterpriseRegistrationRequestUploadFile(data: FileRequestUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileRequestUploadDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/reg/request-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 完成文件上传 /api/purchase-partnership/admin/identity/enterprise/reg/complete-upload-file
 */
export function identityEnterpriseRegistrationCompleteUploadFile(data: FileCompleteUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileCompleteUploadDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/reg/complete-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 管理员注册 /api/purchase-partnership/admin/identity/enterprise/reg/admin
 */
export function identityEnterpriseRegistrationAdminRegister(data: IdSupplierAdminRegistrationCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>('/api/purchase-partnership/admin/identity/enterprise/reg/admin', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 准入 - 中国境外+个人 /api/purchase-partnership/admin/identity/enterprise/boarded/submit/oversea-person
 */
export function identityEnterpriseBoardedSubmitOverseaPerson(data: CreateOverseaPersonCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseOnboardingApplicationId>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/submit/oversea-person',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 准入 - 中国境外+企业 /api/purchase-partnership/admin/identity/enterprise/boarded/submit/oversea-enterprise
 */
export function identityEnterpriseBoardedSubmitOverseaEnterprise(data: CreateOverseaEnterpriseCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseOnboardingApplicationId>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/submit/oversea-enterprise',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 准入 - 中国境内+个人 /api/purchase-partnership/admin/identity/enterprise/boarded/submit/china-person
 */
export function identityEnterpriseBoardedSubmitChinaPerson(data: CreateChinaPersonCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseOnboardingApplicationId>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/submit/china-person',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 准入 - 中国境内+企业 /api/purchase-partnership/admin/identity/enterprise/boarded/submit/china-enterprise
 */
export function identityEnterpriseBoardedSubmitChinaEnterprise(data: CreateChinaEnterpriseCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseOnboardingApplicationId>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/submit/china-enterprise',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 请求上传文件 /api/purchase-partnership/admin/identity/enterprise/boarded/request-upload-file
 */
export function identityEnterpriseBoardedRequestUploadFile(data: FileRequestUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileRequestUploadDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/request-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 变更 - 中国境外+个人 /api/purchase-partnership/admin/identity/enterprise/boarded/modify/oversea-person
 */
export function identityEnterpriseBoardedModifyOverseaPerson(data: ModifyOverseaPersonCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseModificationApplicationId>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/modify/oversea-person',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 变更 - 中国境外+企业 /api/purchase-partnership/admin/identity/enterprise/boarded/modify/oversea-enterprise
 */
export function identityEnterpriseBoardedModifyOverseaEnterprise(data: ModifyOverseaEnterpriseCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseModificationApplicationId>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/modify/oversea-enterprise',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 变更 - 中国境内+个人 /api/purchase-partnership/admin/identity/enterprise/boarded/modify/china-person
 */
export function identityEnterpriseBoardedModifyChinaPerson(data: ModifyChinaPersonCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseModificationApplicationId>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/modify/china-person',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 变更 - 中国境内+企业 /api/purchase-partnership/admin/identity/enterprise/boarded/modify/china-enterprise
 */
export function identityEnterpriseBoardedModifyChinaEnterprise(data: ModifyChinaEnterpriseCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseModificationApplicationId>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/modify/china-enterprise',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 完成文件上传 /api/purchase-partnership/admin/identity/enterprise/boarded/complete-upload-file
 */
export function identityEnterpriseBoardedCompleteUploadFile(data: FileCompleteUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileCompleteUploadDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/complete-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 发送重置密码验证码 /api/purchase-partnership/admin/identity/auth/send-reset-password-code
 */
export function identityAuthenticationSendResetPasswordCode(data: IdSendResetPasswordCodeCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>(
    '/api/purchase-partnership/admin/identity/auth/send-reset-password-code',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 重置密码 /api/purchase-partnership/admin/identity/auth/reset-password
 */
export function identityAuthenticationResetPassword(data: IdResetPasswordCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>('/api/purchase-partnership/admin/identity/auth/reset-password', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 退出登录 /api/purchase-partnership/admin/identity/auth/logout
 */
export function identityAuthenticationLogout(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>('/api/purchase-partnership/admin/identity/auth/logout', {
    method: 'POST',
    ...opts,
  })
}
/**
 * 用户登录 /api/purchase-partnership/admin/identity/auth/login
 */
export function identityAuthenticationLogin(data: IdLoginCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseVoid>>('/api/purchase-partnership/admin/identity/auth/login', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 修改语言 /api/purchase-partnership/admin/identity/auth/change-locale
 */
export function identityAuthenticationChangeLocale(
  params: {
    locale: SystemLocale
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseVoid>>(
    createUri('/api/purchase-partnership/admin/identity/auth/change-locale', query),
    { method: 'POST', ...opts }
  )
}
/**
 * 发货 /api/purchase-partnership/admin/fm/order/{id}/delivery
 */
export function fmFmDelivery(
  params: {
    id: string
  },
  data: FmDeliveryFulfillmentCommand,
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseString>>(`/api/purchase-partnership/admin/fm/order/${id}/delivery`, {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 请求上传文件 /api/purchase-partnership/admin/fm/order/request-upload-file
 */
export function fmFmRequestUploadFile(data: FileRequestUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileRequestUploadDto>>(
    '/api/purchase-partnership/admin/fm/order/request-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 订单列表 /api/purchase-partnership/admin/fm/order/list
 */
export function fmFmList(data: FmOrderListQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponsePageDtoFmOrderListDto>>('/api/purchase-partnership/admin/fm/order/list', {
    method: 'POST',
    data,
    ...opts,
  })
}
/**
 * 履约列表 /api/purchase-partnership/admin/fm/order/fulfillment-list
 */
export function fmFmFulfillmentList(data: FmFulfillmentListQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponsePageDtoFmDeliveryFulfillmentListDto>>(
    '/api/purchase-partnership/admin/fm/order/fulfillment-list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 新建发货单-获取信息 /api/purchase-partnership/admin/fm/order/delivery-detail
 */
export function fmFmDeliveryDetail(data: FmDeliveryDetailQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFmDeliveryFulfillmentCreateDetailDto>>(
    '/api/purchase-partnership/admin/fm/order/delivery-detail',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 完成文件上传 /api/purchase-partnership/admin/fm/order/complete-upload-file
 */
export function fmFmCompleteUploadFile(data: FileCompleteUploadCommand, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseFileCompleteUploadDto>>(
    '/api/purchase-partnership/admin/fm/order/complete-upload-file',
    { method: 'POST', data, ...opts }
  )
}
/**
 * 查看验收单列表 /api/purchase-partnership/admin/fm/order/acceptance-form-list
 */
export function fmFmQueryReceiptTransitionList(data: FmDeliveryFulfillmentAcceptanceFormListQuery, opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListFmDeliveryFulfillmentAcceptanceFormListDto>>(
    '/api/purchase-partnership/admin/fm/order/acceptance-form-list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * unknown /api/purchase-partnership/admin/common/develop/easy-task/retry/{id}
 */
export function easyConnectorControllerRetryTask(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<EcResponseObject>>(
    `/api/purchase-partnership/admin/common/develop/easy-task/retry/${id}`,
    { method: 'POST', ...opts }
  )
}
/**
 * unknown /api/purchase-partnership/admin/common/develop/easy-task/list
 */
export function easyConnectorControllerListTask(data: EcTaskListQuery, opts?: ReqOpts) {
  return request<DataOfResponse<EcPageResponseEcTaskListDto>>(
    '/api/purchase-partnership/admin/common/develop/easy-task/list',
    { method: 'POST', data, ...opts }
  )
}
/**
 * unknown /api/purchase-partnership/admin/common/develop/easy-task/detail/{id}
 */
export function easyConnectorControllerQueryTaskDetail(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<EcResponseEcTaskDto>>(
    `/api/purchase-partnership/admin/common/develop/easy-task/detail/${id}`,
    { method: 'POST', ...opts }
  )
}
/**
 * 暂存报价详情 /api/purchase-partnership/admin/staging/quotation/detail/{quotationId}
 */
export function stagingStagingQuotationDetail(
  params: {
    quotationId: string
  },
  opts?: ReqOpts
) {
  const { quotationId } = params
  return request<DataOfResponse<ResponseStagingDetailDto>>(
    `/api/purchase-partnership/admin/staging/quotation/detail/${quotationId}`,
    { ...opts }
  )
}
/**
 * OCR发票识别任务运行状态查询 /api/purchase-partnership/admin/settlement/ocr/task/{id}/status
 */
export function settlementOcrGetOcrTaskStatus(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseInvoiceOcrResultStatusDto>>(
    `/api/purchase-partnership/admin/settlement/ocr/task/${id}/status`,
    { ...opts }
  )
}
/**
 * OCR发票识别任务结果查询 /api/purchase-partnership/admin/settlement/ocr/task/{id}/result
 */
export function settlementOcrGetOcrTaskResult(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseInvoiceOcrResultDto>>(
    `/api/purchase-partnership/admin/settlement/ocr/task/${id}/result`,
    { ...opts }
  )
}
/**
 * 详情 /api/purchase-partnership/admin/settlement/invoice/detail
 */
export function settlementDetail(
  params: {
    invoiceId: string
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseInvoiceDetailDto>>(
    createUri('/api/purchase-partnership/admin/settlement/invoice/detail', query),
    { ...opts }
  )
}
/**
 * 获取结算公司列表 /api/purchase-partnership/admin/settlement/config/settlement-companies
 */
export function settlementConfigQuerySettlementCompanies(
  params: {
    keyword?: string
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseListStCompanyDto>>(
    createUri('/api/purchase-partnership/admin/settlement/config/settlement-companies', query),
    { ...opts }
  )
}
/**
 * 获取附件预览地址 /api/purchase-partnership/admin/settlement/config/attachment-preview-url
 */
export function settlementConfigGetAttachmentPreviewUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/settlement/config/attachment-preview-url', query),
    { ...opts }
  )
}
/**
 * 获取附件下载地址 /api/purchase-partnership/admin/settlement/config/attachment-download-url
 */
export function settlementConfigGetAttachmentDownloadUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/settlement/config/attachment-download-url', query),
    { ...opts }
  )
}
/**
 * 查看变更记录列表 /api/purchase-partnership/admin/quotation/tender/revision-history-list/{id}
 */
export function quotationTenderRevisionHistoryList(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseListQuotationRevisionHistoryListDto>>(
    `/api/purchase-partnership/admin/quotation/tender/revision-history-list/${id}`,
    { ...opts }
  )
}
/**
 * 澄清咨询详情 /api/purchase-partnership/admin/quotation/tender/question-detail/{questionId}
 */
export function quotationTenderQuestionDetail(
  params: {
    questionId: string
  },
  opts?: ReqOpts
) {
  const { questionId } = params
  return request<DataOfResponse<ResponseQuotationTenderQuestionDto>>(
    `/api/purchase-partnership/admin/quotation/tender/question-detail/${questionId}`,
    { ...opts }
  )
}
/**
 * 弃标 /api/purchase-partnership/admin/quotation/tender/discarded-tender/{id}
 */
export function quotationTenderDiscardedTender(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseVoid>>(
    `/api/purchase-partnership/admin/quotation/tender/discarded-tender/${id}`,
    { ...opts }
  )
}
/**
 * 查看待应标标的详情 /api/purchase-partnership/admin/quotation/tender/detail/{id}
 */
export function quotationTenderTenderDetail(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseQuotationTenderDetailDto>>(
    `/api/purchase-partnership/admin/quotation/tender/detail/${id}`,
    { ...opts }
  )
}
/**
 * 轮询报价相关信息 /api/purchase-partnership/admin/quotation/polling-info/{quotationId}
 */
export function quotationQuotationPollingInfo(
  params: {
    quotationId: string
  },
  opts?: ReqOpts
) {
  const { quotationId } = params
  return request<DataOfResponse<ResponseQuotationPollingInfoDto>>(
    `/api/purchase-partnership/admin/quotation/polling-info/${quotationId}`,
    { ...opts }
  )
}
/**
 * 变更详情列表 /api/purchase-partnership/admin/quotation/history-list/{id}
 */
export function quotationQuotationRevisionHistoryList(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseListQuotationRevisionHistoryListDto>>(
    `/api/purchase-partnership/admin/quotation/history-list/${id}`,
    { ...opts }
  )
}
/**
 * 查看报价详情 /api/purchase-partnership/admin/quotation/detail/{id}
 */
export function quotationQuotationDetail(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseQuotationDetailDto>>(`/api/purchase-partnership/admin/quotation/detail/${id}`, {
    ...opts,
  })
}
/**
 * 获取报价排名图表信息 /api/purchase-partnership/admin/quotation/chart/{id}
 */
export function quotationQuotationQueryChart(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseQuotationHistoryChartDto>>(
    `/api/purchase-partnership/admin/quotation/chart/${id}`,
    { ...opts }
  )
}
/**
 * 获取附件预览地址 /api/purchase-partnership/admin/quotation/attachment-preview-url
 */
export function quotationQuotationGetAttachmentPreviewUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/quotation/attachment-preview-url', query),
    { ...opts }
  )
}
/**
 * 获取附件下载地址 /api/purchase-partnership/admin/quotation/attachment-download-url
 */
export function quotationQuotationGetAttachmentDownloadUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/quotation/attachment-download-url', query),
    { ...opts }
  )
}
/**
 * 报价消息 /api/purchase-partnership/admin/notification/query-quotation-notification
 */
export function notificationNotificationQueryQuotationNotification(params: NotificationQuery, opts?: ReqOpts) {
  const { ...query } = params
  return request<DataOfResponse<ResponsePageDtoNotificationListDto>>(
    createUri('/api/purchase-partnership/admin/notification/query-quotation-notification', query),
    { ...opts }
  )
}
/**
 * 订单消息 /api/purchase-partnership/admin/notification/query-fm-notification
 */
export function notificationNotificationQueryFmNotification(params: NotificationQuery, opts?: ReqOpts) {
  const { ...query } = params
  return request<DataOfResponse<ResponsePageDtoNotificationListDto>>(
    createUri('/api/purchase-partnership/admin/notification/query-fm-notification', query),
    { ...opts }
  )
}
/**
 * 审批消息 /api/purchase-partnership/admin/notification/query-approval-notification
 */
export function notificationNotificationQueryApprovalNotification(params: NotificationQuery, opts?: ReqOpts) {
  const { ...query } = params
  return request<DataOfResponse<ResponsePageDtoNotificationListDto>>(
    createUri('/api/purchase-partnership/admin/notification/query-approval-notification', query),
    { ...opts }
  )
}
/**
 * 全部已读 /api/purchase-partnership/admin/notification/has-read-list
 */
export function notificationNotificationHasReadList(params: NotificationQuery, opts?: ReqOpts) {
  const { ...query } = params
  return request<DataOfResponse<ResponsePageDtoNotificationListDto>>(
    createUri('/api/purchase-partnership/admin/notification/has-read-list', query),
    { ...opts }
  )
}
/**
 * 税率列表 /api/purchase-partnership/admin/material/tax/list
 */
export function materialTaxListCategory(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListTaxDto>>('/api/purchase-partnership/admin/material/tax/list', { ...opts })
}
/**
 * 品类列表 /api/purchase-partnership/admin/material/category/list
 */
export function materialCategoryListCategory(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListCategoryTreeDto>>(
    '/api/purchase-partnership/admin/material/category/list',
    { ...opts }
  )
}
/**
 * 根据邀请Token获取注册的企业 /api/purchase-partnership/admin/identity/enterprise/reg/name
 */
export function identityEnterpriseRegistrationGetRegisteredName(
  params: {
    token: string
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseRegisteredEnterpriseDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/reg/name', query),
    { ...opts }
  )
}
/**
 * 所有国家列表 /api/purchase-partnership/admin/identity/enterprise/reg/countries
 */
export function identityEnterpriseRegistrationCountries(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListCountryDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/reg/countries',
    { ...opts }
  )
}
/**
 * 获取附件下载地址 /api/purchase-partnership/admin/identity/enterprise/reg/attachment-download-url
 */
export function identityEnterpriseRegistrationGetAttachmentDownloadUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/reg/attachment-download-url', query),
    { ...opts }
  )
}
/**
 * 国家下省份列表 /api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/provinces
 */
export function identityEnterpriseConfigProvinces(
  params: {
    countryId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseListSupplierProvinceDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/provinces', query),
    { ...opts }
  )
}
/**
 * 获取币种列表 /api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/currencies
 */
export function identityEnterpriseConfigListCurrency(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListCurrencyDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/currencies',
    { ...opts }
  )
}
/**
 * 所有国家列表 /api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/countries
 */
export function identityEnterpriseConfigCountries(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListCountryDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/countries',
    { ...opts }
  )
}
/**
 * 省份下城市列表 /api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/cities
 */
export function identityEnterpriseConfigCities(
  params: {
    provinceId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseListSupplierCityDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/cities', query),
    { ...opts }
  )
}
/**
 * 所有品类 /api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/categories
 */
export function identityEnterpriseConfigListCategories(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListSpCategoryDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/categories',
    { ...opts }
  )
}
/**
 * 业务线 /api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/business-lines
 */
export function identityEnterpriseConfigListBusinessLines(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListSpBusinessLineDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/enterpriseConfig/business-lines',
    { ...opts }
  )
}
/**
 * 准入申请 中国境外+个人 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail/oversea-person
 */
export function identityEnterpriseBoardedQueryOverseaPersonOnboardDetail(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdOnboardAppOverseaPersonDetailDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail/oversea-person', query),
    { ...opts }
  )
}
/**
 * 准入申请 中国境外+企业 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail/oversea-enterprise
 */
export function identityEnterpriseBoardedQueryOverseaEnterpriseOnboardDetail(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdOnboardAppOverseaEnterpriseDetailDto>>(
    createUri(
      '/api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail/oversea-enterprise',
      query
    ),
    { ...opts }
  )
}
/**
 * 准入申请 中国境内+个人 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail/china-person
 */
export function identityEnterpriseBoardedQueryChinaPersonOnboardDetail(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdOnboardAppChinaPersonDetailDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail/china-person', query),
    { ...opts }
  )
}
/**
 * 准入申请 中国境内+企业 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail/china-enterprise
 */
export function identityEnterpriseBoardedQueryChinaEnterpriseOnboardDetail(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdOnboardAppChinaEnterpriseDetailDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail/china-enterprise', query),
    { ...opts }
  )
}
/**
 * 准入申请 中国境外+个人 编辑详情 /api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail-update/oversea-person
 */
export function identityEnterpriseBoardedQueryOverseaPersonOnboardUpdateDetail(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdOnboardAppOverseaPersonDetailDto>>(
    createUri(
      '/api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail-update/oversea-person',
      query
    ),
    { ...opts }
  )
}
/**
 * 准入申请 中国境外+企业 编辑详情 /api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail-update/oversea-enterprise
 */
export function identityEnterpriseBoardedQueryOverseaEnterpriseOnboardUpdateDetail(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdOnboardAppOverseaEnterpriseDetailDto>>(
    createUri(
      '/api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail-update/oversea-enterprise',
      query
    ),
    { ...opts }
  )
}
/**
 * 准入申请 中国境内+个人 编辑详情 /api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail-update/china-person
 */
export function identityEnterpriseBoardedQueryChinaPersonOnboardUpdateDetail(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdOnboardAppChinaPersonDetailDto>>(
    createUri(
      '/api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail-update/china-person',
      query
    ),
    { ...opts }
  )
}
/**
 * 准入申请 中国境内+企业 编辑详情 /api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail-update/china-enterprise
 */
export function identityEnterpriseBoardedQueryChinaEnterpriseOnboardUpdateDetail(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdOnboardAppChinaEnterpriseDetailDto>>(
    createUri(
      '/api/purchase-partnership/admin/identity/enterprise/boarded/onboarding/detail-update/china-enterprise',
      query
    ),
    { ...opts }
  )
}
/**
 * 信息变更 境外+个人 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail/oversea-person
 */
export function identityEnterpriseBoardedQuerySpAppModOverseaPersonDetailDto(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdModAppPersonDetailDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail/oversea-person', query),
    { ...opts }
  )
}
/**
 * 信息变更 境外+企业 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail/oversea-enterprise
 */
export function identityEnterpriseBoardedQuerySpAppModOverseaEnterpriseDetailDto(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdModAppEnterpriseDetailDto>>(
    createUri(
      '/api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail/oversea-enterprise',
      query
    ),
    { ...opts }
  )
}
/**
 * 信息变更 中国+个人 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail/china-person
 */
export function identityEnterpriseBoardedQuerySpAppModChinaPersonDetailDto(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdModAppPersonDetailDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail/china-person', query),
    { ...opts }
  )
}
/**
 * 信息变更 中国+企业 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail/china-enterprise
 */
export function identityEnterpriseBoardedQuerySpAppModChinaEnterpriseDetailDto(
  params: {
    applicationId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseIdModAppEnterpriseDetailDto>>(
    createUri(
      '/api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail/china-enterprise',
      query
    ),
    { ...opts }
  )
}
/**
 * 信息变更 中国境外 + 个人 供应商 编辑详情 /api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail-update/detail/oversea-person
 */
export function identityEnterpriseBoardedQueryOverseaPersonSupplierUpdateDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdOverseaPersonDetailDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail-update/detail/oversea-person',
    { ...opts }
  )
}
/**
 * 信息变更 中国境外 + 企业 供应商 编辑详情 /api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail-update/detail/oversea-enterprise
 */
export function identityEnterpriseBoardedQueryOverseaEnterpriseSupplierUpdateDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdOverseaEnterpriseDetailDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail-update/detail/oversea-enterprise',
    { ...opts }
  )
}
/**
 * 信息变更 中国境内 + 个人 供应商 编辑详情 /api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail-update/detail/china-person
 */
export function identityEnterpriseBoardedQueryChinaPersonSupplierUpdateDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdChinaPersonDetailDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail-update/detail/china-person',
    { ...opts }
  )
}
/**
 * 信息变更 中国境内 + 企业 供应商 编辑详情 /api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail-update/china-enterprise
 */
export function identityEnterpriseBoardedQueryChinaEnterpriseSupplierUpdateDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdChinaEnterpriseDetailDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/modification/detail-update/china-enterprise',
    { ...opts }
  )
}
/**
 * 供应商详情 /api/purchase-partnership/admin/identity/enterprise/boarded/detail
 */
export function identityEnterpriseBoardedQuerySupplierDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseSupplierDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/detail',
    { ...opts }
  )
}
/**
 * 中国境外 + 个人 供应商 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/detail/oversea-person
 */
export function identityEnterpriseBoardedQueryOverseaPersonSupplierDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdOverseaPersonDetailDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/detail/oversea-person',
    { ...opts }
  )
}
/**
 * 中国境外 + 企业 供应商 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/detail/oversea-enterprise
 */
export function identityEnterpriseBoardedQueryOverseaEnterpriseSupplierDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdOverseaEnterpriseDetailDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/detail/oversea-enterprise',
    { ...opts }
  )
}
/**
 * 中国境内 + 个人 供应商 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/detail/china-person
 */
export function identityEnterpriseBoardedQueryChinaPersonSupplierDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdChinaPersonDetailDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/detail/china-person',
    { ...opts }
  )
}
/**
 * 中国境内 + 企业 供应商 详情 /api/purchase-partnership/admin/identity/enterprise/boarded/detail/china-enterprise
 */
export function identityEnterpriseBoardedQueryChinaEnterpriseSupplierDetail(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdChinaEnterpriseDetailDto>>(
    '/api/purchase-partnership/admin/identity/enterprise/boarded/detail/china-enterprise',
    { ...opts }
  )
}
/**
 * 供应商 档案记录 内容变更 /api/purchase-partnership/admin/identity/enterprise/boarded/change-records/all
 */
export function identityEnterpriseBoardedQueryAllSpChangeRecordListDto(
  params: {
    enterpriseId: string
    startAt?: string
    endAt?: string
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseListSpChangeRecordListDto>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/boarded/change-records/all', query),
    { ...opts }
  )
}
/**
 * 获取附件预览地址 /api/purchase-partnership/admin/identity/enterprise/boarded/attachment-preview-url
 */
export function identityEnterpriseBoardedGetAttachmentPreviewUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/boarded/attachment-preview-url', query),
    { ...opts }
  )
}
/**
 * 获取附件下载地址 /api/purchase-partnership/admin/identity/enterprise/boarded/attachment-download-url
 */
export function identityEnterpriseBoardedGetAttachmentDownloadUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/identity/enterprise/boarded/attachment-download-url', query),
    { ...opts }
  )
}
/**
 * 获取当前用户 /api/purchase-partnership/admin/identity/auth/current-user
 */
export function identityAuthenticationGetCurrentUser(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseIdUserSessionInfoDto>>(
    '/api/purchase-partnership/admin/identity/auth/current-user',
    { ...opts }
  )
}
/**
 * 订单详情 /api/purchase-partnership/admin/fm/order/{id}
 */
export function fmFmDetail(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseFmOrderDetailDto>>(`/api/purchase-partnership/admin/fm/order/${id}`, {
    ...opts,
  })
}
/**
 * 可选物流公司 /api/purchase-partnership/admin/fm/order/list-courier-companies
 */
export function fmFmListCourierCompanies(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListCourierCompanyDto>>(
    '/api/purchase-partnership/admin/fm/order/list-courier-companies',
    { ...opts }
  )
}
/**
 * 履约单详情 /api/purchase-partnership/admin/fm/order/fulfillment/{id}
 */
export function fmFmFulfillmentDetail(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseFmDeliveryFulfillmentDetailDto>>(
    `/api/purchase-partnership/admin/fm/order/fulfillment/${id}`,
    { ...opts }
  )
}
/**
 * 创建人信息 /api/purchase-partnership/admin/fm/order/creator-list
 */
export function fmFmCreatorList(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseSetFmOperatorDto>>('/api/purchase-partnership/admin/fm/order/creator-list', {
    ...opts,
  })
}
/**
 * 获取附件预览地址 /api/purchase-partnership/admin/fm/order/attachment-preview-url
 */
export function fmFmGetAttachmentPreviewUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/fm/order/attachment-preview-url', query),
    { ...opts }
  )
}
/**
 * 获取附件下载地址 /api/purchase-partnership/admin/fm/order/attachment-download-url
 */
export function fmFmGetAttachmentDownloadUrl(
  params: {
    fileId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseString>>(
    createUri('/api/purchase-partnership/admin/fm/order/attachment-download-url', query),
    { ...opts }
  )
}
/**
 * unknown /api/purchase-partnership/admin/develop/identity/login
 */
export function identityDevelopLogin(
  params: {
    mail: string
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseAdminUserSession>>(
    createUri('/api/purchase-partnership/admin/develop/identity/login', query),
    { ...opts }
  )
}
/**
 * unknown /api/purchase-partnership/admin/develop/identity/import
 */
export function identityDevelopImportSupplier(
  params: {
    filePath: string
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseVoid>>(
    createUri('/api/purchase-partnership/admin/develop/identity/import', query),
    { ...opts }
  )
}
/**
 * 统计信息 /api/purchase-partnership/admin/common/workbench/statistics
 */
export function commonWorkbenchGetStatistics(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseWorkbenchStatisticsDto>>(
    '/api/purchase-partnership/admin/common/workbench/statistics',
    { ...opts }
  )
}
/**
 * 可选维保期限 /api/purchase-partnership/admin/common/list-maintenance-periods
 */
export function commonCommonListMaintenancePeriods(opts?: ReqOpts) {
  return request<DataOfResponse<ResponseListMonthPeriodDto>>(
    '/api/purchase-partnership/admin/common/list-maintenance-periods',
    { ...opts }
  )
}
/**
 * 重新执行 ec task /api/purchase-partnership/admin/common/develop/do-retry-task
 */
export function commonDevelopRetryTask(
  params: {
    taskId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseVoid>>(
    createUri('/api/purchase-partnership/admin/common/develop/do-retry-task', query),
    { ...opts }
  )
}
/**
 * 重新发送事件 /api/purchase-partnership/admin/common/develop/do-republish-event
 */
export function commonDevelopDoRepublishEvent(
  params: {
    eventId: number
  },
  opts?: ReqOpts
) {
  const { ...query } = params
  return request<DataOfResponse<ResponseBoolean>>(
    createUri('/api/purchase-partnership/admin/common/develop/do-republish-event', query),
    { ...opts }
  )
}
/**
 * 删除 /api/purchase-partnership/admin/settlement/invoice/delete/{id}
 */
export function settlementDelete(
  params: {
    id: string
  },
  opts?: ReqOpts
) {
  const { id } = params
  return request<DataOfResponse<ResponseVoid>>(`/api/purchase-partnership/admin/settlement/invoice/delete/${id}`, {
    method: 'DELETE',
    ...opts,
  })
}
