import { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { Checkbox, Input, Select, Space, Typography } from '@bedrock/components'
import { ProFilter, ProTable, ProTablePlus } from '@bedrock/eed-react'
import type { IFilterData, IProFilterProps } from '@bedrock/eed-react/lib/components/ProFilter/filter'
import { FilterType } from '@bedrock/eed-react/lib/components/ProFilter/interface'
import {
  IProTableColumn,
  IProTableProps,
  IProTableRef,
  QueryParams,
} from '@bedrock/eed-react/lib/components/ProTable/interface'
import { RecordType } from '@bedrock/components/es/Table/interfaces'
import { BEMGenerator, useRCMount, useRCUpdate, useRCVars, useRCWatch } from '@oahz/neact-utils'
import { InputProps } from '@bedrock/components/es/Input'
import { TypographyProps } from '@bedrock/components/lib/Typography'
import { formatDateTime } from '@feature/shared'
import { CheckboxChangeEvent } from '@bedrock/components/es/Checkbox'
import { IProFilterGroupRef } from '@bedrock/eed-react/lib/components/ProFilter/components/FilterGroup'
import { IProTablePlusProps } from '@bedrock/eed-react/lib/components/ProTablePlus/interface'
import { SelectProps } from '@bedrock/components/es/Select'
import I18N from '@feature/i18n'
import { renderWithClamp } from '../render-ellipsis'
import { NameWithPopo } from '#name-with-popo'
import { SystemTags, ListMoneyView } from '#data-view'
import './style.less'

const bem = BEMGenerator('ftpro-table')

export enum COLUMN_NORMAL_TYPE {
  TIME = 'time',
  SYSTEM_TAG = 'SYSTEM_TAG',
  TAG_STATUS = 'tag_status',
  MOENY = 'money',
  NAME = 'name',
  NAME_POPO = 'name_popo',
}

export function createTableProColumns<T extends RecordType>(
  columns: (NonNullable<IProTablePlusProps<T>['columns'][number]> & {
    // title?: string | ReactNode
    paragraph?: true | TypographyProps['ellipsis']
    renderType?: COLUMN_NORMAL_TYPE
    timeFormat?: string
  })[]
): IProTablePlusProps<T>['columns'] {
  const normalRender = (
    value: any,
    record: T & RecordType,
    idx: number,
    renderType?: COLUMN_NORMAL_TYPE,
    timeFormat?: string
  ) => {
    switch (renderType) {
      case COLUMN_NORMAL_TYPE.TIME: {
        return formatDateTime(value, timeFormat || 'YYYY-MM-DD')
      }
      case COLUMN_NORMAL_TYPE.MOENY: {
        return <ListMoneyView value={value} />
      }
      case COLUMN_NORMAL_TYPE.SYSTEM_TAG: {
        return <SystemTags value={value} wrap />
      }
      // 不统一，先放着吧
      // case COLUMN_NORMAL_TYPE.TAG_STATUS: {
      //   return <ListMoneyView value={value} />
      // }
      case COLUMN_NORMAL_TYPE.NAME: {
        return !value?.name && !value?.mail ? '-' : `${value?.name || '-'}${value?.mail ? `(${value.mail})` : ''}`
      }
      case COLUMN_NORMAL_TYPE.NAME_POPO: {
        return <NameWithPopo name={value?.name} mail={value?.mail} />
      }
    }
  }

  return columns?.map(v => {
    const _render = v.render
    v.render =
      (v.renderType
        ? (...args) => normalRender(...args, v.renderType, v.timeFormat)
        : _render
        ? (...args) => _render(...args)
        : v => v) ?? '-'

    return {
      align: 'left',
      // 这里不用ellipsis参数，单元格使用Typography.Paragraph，表头通过css控制
      ellipsis: false,
      // 默认都可以列宽调整
      resizable: true,
      ...v,
      // TODO: 全局共用的MoneyDto、日期之类的也可以这里统一展示
      ...(v.paragraph
        ? {
            render: (value, record, idx) => {
              const result = v.render ? v.render(value, record, idx) : value
              if (!result) return '-'
              return renderWithClamp(result)
              // return (
              //   <Typography.Paragraph
              //     ellipsis={{
              //       rows: 1,
              //       showTooltip: true,
              //       cssEllipsis: true,
              //       ...(v.paragraph === true ? {} : v.paragraph),
              //     }}>
              //     {result}
              //   </Typography.Paragraph>
              // )
            },
          }
        : {}),
      ...(v.searchRender
        ? {
            searchRender: { ...v.searchRender, showCondition: false },
          }
        : {}),
    }
  })
}

export function AppTablePro<P extends { current?: number; limit?: number }, T extends RecordType>(
  props: Omit<IProTableProps, 'request' | 'columns'> & {
    columns: IProTableColumn<T>[]
    request?: (params: QueryParams & P) => Promise<{ records?: T[]; total?: number } | undefined>
    renderHeader?: (v: { doRequest: (params?: P) => void }) => ReactNode
    renderColumns?: (v: { doRequest: () => void }) => IProTableProps<T>['columns']
  }
) {
  const tableRef = useRef<IProTableRef<T>>(null)
  const { columns: propColumns, request, renderHeader, renderColumns, className, ...reset } = props
  const queryService: NonNullable<IProTableProps<T>['request']>['queryService'] = async data => {
    const { params, order } = data
    if (!request) return { data: { list: [], total: 0 }, code: '0' }
    return await request({ limit: params?.pageSize, ...params, pageSize: undefined, order } as QueryParams as P).then(
      v => {
        return { data: { list: v?.records || [], total: v?.total || 0 } }
      }
    )
  }

  const doRequest = (params?: P) => {
    if (!request) return
    if (params) {
      tableRef.current?.query(params)
    } else {
      tableRef.current?.query()
    }
  }

  const columns = propColumns || renderColumns?.({ doRequest }) || []

  useRCMount(() => {
    tableRef.current?.query()
  })

  return (
    <div className={bem('atp')}>
      {renderHeader && renderHeader({ doRequest: doRequest })}
      <ProTable
        {...reset}
        tableSetting={{
          type: 'field',
        }}
        columns={columns}
        tableFullScreen
        tableRefresh
        className={bem('atpt', [className])}
        ref={tableRef}
        request={{ queryService }}
        size="default"
        outerBorder
        emptyCellRender={() => '-'}
        scroll={{ x: true }}
        pagination={{
          size: 'default',
          pageSize: 30,
          pageSizeOptions: [30, 50, 100],
        }}
      />
    </div>
  )
}

const genCheckValues = (arr: { label: string; key: string }[]) => {
  return (arr || [])
    .map(v => ({ [v?.key!]: false }))
    .reduce((acc, cur) => {
      Object.keys(cur).forEach(key => {
        acc[key] = cur[key]
      })
      return acc
    }, {})
}
export function AppTableProHeader<T>(
  props: IProFilterProps & {
    onSearch?: (v: T) => void
    actions?: ReactNode
    queryInput?: InputProps
    checkProps?: { label: string; key: string } | { label: string; key: string }[]
  }
) {
  const { onSearch, actions, queryInput, checkProps, ...resetFilter } = props
  const filterRef = useRef<IProFilterGroupRef>(null)
  const vars = useRCVars({ params: {} as T })
  // 构建多个check的情况的数据
  const genCheckProps = Array.isArray(checkProps) ? checkProps : checkProps ? [checkProps] : undefined
  // 多个check筛选项的value数据
  const [checkVal, setCheckVal] = useState<Record<string, boolean | undefined>>(genCheckValues(genCheckProps || []))

  const doChange = (data: IFilterData[], reset?: boolean) => {
    if (reset) {
      setCheckVal({})
      vars.params = {}
    }
    const params = data?.reduce((acc, item) => {
      ;(acc as any)[item.value] = item.filterValue
      return acc
    }, {} as T)
    onSearch?.((vars.params = { ...vars.params, ...params }))
  }

  const checkChange = (e: CheckboxChangeEvent, key: string) => {
    setCheckVal((vars.params = { ...vars.params, [key]: e.target.checked }))
    onSearch?.((vars.params = { ...vars.params, [key]: e.target.checked }))
  }

  useRCWatch(() => {
    if (queryInput?.defaultValue) {
      setTimeout(() => onSearch?.((vars.params = { ...vars.params, keyword: queryInput?.defaultValue })), 100)
    }
  })

  return (
    <div className={bem('atpf')}>
      <div className={bem('atpf-row')}>
        <Input.Search
          allowOnSearchByDel={false}
          className={bem('atpf-keyword')}
          {...queryInput}
          onSearch={v => onSearch?.((vars.params = { ...vars.params, keyword: v }))}
        />
        {actions}
      </div>
      <ProFilter
        {...resetFilter}
        ref={filterRef}
        showCondition={false}
        showSearchButton
        Component
        onSearch={filterData => doChange(filterData)}
        onChange={filterData => doChange(filterData)}
        onReset={filterData => doChange(filterData, true)}
        addonBefore={
          !genCheckProps
            ? undefined
            : (genCheckProps || []).map((v, i) => (
                <AppQueryCheckbox
                  key={v?.key!}
                  label={v?.label!}
                  value={checkVal[v?.key]}
                  onChange={e => checkChange(e, v?.key!)}
                />
              ))
        }>
        <ProFilter.FilterGroup />
      </ProFilter>
    </div>
  )
}

// TODO：封装一个Checkbox的查询条件，参考UX，需要支持：value & onChange
const AppQueryCheckbox = (props: { label: string; value?: boolean; onChange: (v: CheckboxChangeEvent) => void }) => {
  const { label, value, ...rest } = props
  return (
    <div className={bem('atpf-checkbox')}>
      <span className={bem('atpf-checkbox-label')}>{`${label}:`}</span>
      <Checkbox checked={value} {...rest} />
    </div>
  )
}

export function TablePlusPro<P extends { current?: number; limit?: number }, T extends RecordType>(
  props: Omit<IProTablePlusProps, 'request' | 'columns'> & {
    columns?: IProTableColumn<T>[]
    wrapperClassName?: string
    request?: (params: QueryParams & P) => Promise<{ records?: T[]; total?: number } | undefined>
    renderHeader?: (v: { doRequest: (params?: P) => void }) => ReactNode
    renderColumns?: (v: { doRequest: () => void }) => IProTablePlusProps<T>['columns']
    genToolbar?: (v: { doRequest: () => void }) => IProTablePlusProps<T>['toolbar']
  }
) {
  const tableRef = useRef<IProTableRef<T>>(null)
  const filterRef = useRef<Partial<IProFilterGroupRef>>({})
  const {
    wrapperClassName,
    columns: propColumns,
    request,
    renderHeader,
    renderColumns,
    className,
    toolbar: propToolbar,
    genToolbar,
    tableSetting = {},
    sticky = {},
    ...reset
  } = props
  const queryService: NonNullable<IProTablePlusProps<T>['request']>['queryService'] = async data => {
    const { params, order } = data
    if (!request) return { data: { list: [], total: 0 }, code: '0' }

    return await request({
      limit: params?.pageSize,
      ...params,
      pageSize: undefined,
      paramsWithConditions: undefined,
      order,
    } as QueryParams as P).then(v => {
      return { data: { list: v?.records || [], total: v?.total || 0 } }
    })
  }

  const doRequest = (params?: P) => {
    if (!request) return
    if (params) {
      tableRef.current?.query(params)
    } else {
      tableRef.current?.query()
    }
  }

  const toolbar = () => {
    const gToolbar = genToolbar ? genToolbar({ doRequest }) : propToolbar || undefined
    return gToolbar
      ? {
          ...gToolbar,
          search: {
            ...gToolbar.search,
            placeholder: gToolbar.search?.placeholder || '请输入搜索内容',
            id: gToolbar.search?.id || 'keyword',
            className: bem('atpp-search'),
          },
          filterSetting: false,
        }
      : undefined
  }

  const columns = useMemo(
    () => propColumns || renderColumns?.({ doRequest }) || [],
    [propColumns, renderColumns, doRequest]
  )

  return (
    <div className={bem('atpp-wrapper', [wrapperClassName])}>
      <ProTablePlus
        tableFullScreen
        tableRefresh
        outerBorder
        stickyPagination
        emptyCellRender={() => '-'}
        scroll={{ x: true, y: 'hidden' }}
        overflow="hidden"
        pagination={{
          size: 'default',
          pageSize: 30,
          pageSizeOptions: [30, 50, 100],
        }}
        sticky={
          typeof sticky === 'boolean'
            ? sticky
            : { offsetHeader: 56, offsetScroll: 60, getContainer: () => document.getElementById('root'), ...sticky }
        }
        {...reset}
        containerClassName={bem('atpp', [className])}
        ref={tableRef}
        headerCollapse={false}
        filterRef={filterRef}
        columns={columns}
        toolbar={toolbar()}
        request={{ queryService }}
        tableSetting={{
          type: 'field',
          ...tableSetting,
        }}
      />
    </div>
  )
}

export const ProSelectFilter = (props: SelectProps) => {
  const Portal = useCallback((props: any) => {
    const { popup } = props
    return <div>{popup}</div>
  }, [])
  return (
    <Select
      multiple
      showSearch
      // allowClear
      {...props}
      style={{ minWidth: '240px', ...props.style }}
      arrow={null}
      portal={Portal}
    />
  )
}

export const useProSelectFilterProps = (props?: SelectProps) => {
  const Portal = useCallback((props: any) => {
    const { popup } = props
    return <div>{popup}</div>
  }, [])
  return {
    // allowClear: true,
    multiple: true,
    showSearch: true,
    ...props,
    showCondition: false,
    arrow: null,
    // portal: Portal,
  }
}

export const URGENT_FILTER = {
  title: I18N.auto.jiaJi,
  dataIndex: 'urgent',
  hideInTable: true,
  searchRender: {
    type: FilterType.CHECKBOX,
    disableResetButton: true, // 隐藏重置按钮
  },
}
