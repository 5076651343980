import { createEnum } from '@oahz/neact-openapi'
import I18N from '@feature/i18n'
import { memoize } from 'lodash'
import { apiService, defs, enums as apiEnums } from '@/services'

export const enums = {
  // 流程状态
  StatusEnum: createEnum([
    // 启用/yes
    {
      key: 'ENABLE',
      value: '1',
      name: I18N.auto.qiYong,
    },
    // 禁用/no
    {
      key: 'DISABLE',
      value: '0',
      name: I18N.auto.jinYong,
    },
  ] as const),
  // 流程状态
  StatusInUseEnum: createEnum([
    // 启用/yes
    {
      key: 'ENABLE',
      value: '1',
      name: I18N.auto.qiYong,
    },
    // 禁用/no
    {
      key: 'DISABLE',
      value: '0',
      name: I18N.auto.jinYong,
    },
  ] as const),
  // 是否状态
  StatusBooleanEnum: createEnum([
    // 启用/yes
    {
      key: 'YES',
      value: '1',
      name: I18N.auto.shi,
    },
    // 禁用/no
    {
      key: 'NO',
      value: '0',
      name: I18N.auto.fou,
    },
  ] as const),
  // bool值
  BoolEnum: createEnum([
    // 真/启用/有效
    {
      key: 'TRUE',
      value: '1',
      name: I18N.auto.zhen,
    },
    // 假/不启用/失效
    {
      key: 'FALSE',
      value: '0',
      name: I18N.auto.jia,
    },
  ] as const),
  // bool值
  BoolEnableEnum: createEnum([
    // 真/启用/有效
    {
      key: 'TRUE',
      value: '1',
      name: I18N.auto.qiYong,
    },
    // 假/不启用/失效
    {
      key: 'FALSE',
      value: '0',
      name: I18N.auto.buQiYong,
    },
  ] as const),
  // bool值
  BooleValidEnum: createEnum([
    // 真/启用/有效
    {
      key: 'TRUE',
      value: '1',
      name: I18N.auto.youXiao,
    },
    // 假/不启用/失效
    {
      key: 'FALSE',
      value: '0',
      name: I18N.auto.shiXiao,
    },
  ] as const),
  // 供应商状态
  SupplierStatusEnum: createEnum([
    {
      key: 'DRAFT',
      value: '0',
      name: I18N.auto.caoGao,
    },
    {
      key: 'VALID',
      value: '1',
      name: I18N.auto.shengXiao,
    },
    {
      key: 'INVALID',
      value: '2',
      name: I18N.auto.shiXiao,
    },
  ] as const),
  // 账期
  SpAccountPeriodEnum: createEnum([
    // 15天
    {
      key: 'ZERO',
      value: '0',
      name: `0天`,
    },
    // 15天
    {
      key: 'FIFTEEN',
      value: '15',
      name: I18N.auto.tian_3,
    },
    // 30天
    {
      key: 'THIRTY',
      value: '30',
      name: I18N.auto.tian_4,
    },
    // 45天
    {
      key: 'FORTY_FIVE',
      value: '45',
      name: I18N.auto.tian_5,
    },
    // 60天
    {
      key: 'SIXTY',
      value: '60',
      name: I18N.auto.tian_6,
    },
  ] as const),
  // 供应商类型
  SupplierTypeEnum: createEnum([
    // 企业
    {
      key: 'ENTERPRISE',
      value: '01',
      name: I18N.auto.qiYe,
    },
    // 个人
    {
      key: 'PERSON',
      value: '02',
      name: I18N.auto.geRen,
    },
  ] as const),
  // 证件类型
  PersonIdTypeEnum: createEnum([
    // 居民身份证
    {
      key: 'RESIDENCE_ID_CARD',
      value: '01',
      name: I18N.auto.juMinShenFenZheng,
    },
    // 港澳居民来往内地通行证
    {
      key: 'MAINLAND_TRAVEL_PERMIT_FOR_HONG_KONG_MACAU_RESIDENTS',
      value: '02',
      name: I18N.auto.gangAoJuMinLai,
    },
    // 中华人民共和国港澳居民居住证
    {
      key: 'RESIDENCE_PERMIT_FOR_HONG_KONG_MACAU_RESIDENTS',
      value: '03',
      name: I18N.auto.zhongHuaRenMinGong,
    },
    // 台湾居民来往大陆通行证
    {
      key: 'TAIWAN_TRAVEL_PERMIT',
      value: '04',
      name: I18N.auto.taiWanJuMinLai,
    },
    // 中华人民共和国台湾居民居住证
    {
      key: 'RESIDENCE_PERMIT_FOR_TAIWAN_RESIDENTS',
      value: '05',
      name: I18N.auto.zhongHuaRenMinGong_2,
    },
    // 中国护照
    {
      key: 'CHINESE_PASSPORT',
      value: '06',
      name: I18N.auto.zhongGuoHuZhao,
    },
    // 外国护照
    {
      key: 'FOREIGN_PASSPORT',
      value: '07',
      name: I18N.auto.waiGuoHuZhao,
    },
    // 外国人永久居留身份证(外国人永久居留证)
    {
      key: 'PERMANENT_RESIDENCE_ID_CARD',
      value: '08',
      name: I18N.auto.waiGuoRenYongJiu,
    },
    // 中华人民共和国外国人工作许可证(A类)
    {
      key: 'WORK_PERMIT_A',
      value: '09',
      name: I18N.auto.zhongHuaRenMinGong_3,
    },
    // 中华人民共和国外国人工作许可证(B类)
    {
      key: 'WORK_PERMIT_B',
      value: '10',
      name: I18N.auto.zhongHuaRenMinGong_4,
    },
    // 中华人民共和国外国人工作许可证(C类)
    {
      key: 'WORK_PERMIT_C',
      value: '11',
      name: I18N.auto.zhongHuaRenMinGong_5,
    },
    // 其他个人证件
    {
      key: 'OTHER_PERSON_ID',
      value: '12',
      name: I18N.auto.qiTaGeRenZheng,
    },
  ] as const),
  // 纳税人资质
  TaxpayerQualificationEnum: createEnum([
    // 一般纳税人
    {
      key: 'GENERAL',
      value: '01',
      name: I18N.auto.yiBanNaShuiRen,
    },
    // 小规模纳税人
    {
      key: 'SMALL_SCALE',
      value: '02',
      name: I18N.auto.xiaoGuiMoNaShui,
    },
    // 非增值税纳税人
    {
      key: 'NON_VALUE_ADDED',
      value: '03',
      name: I18N.auto.feiZengZhiShuiNa,
    },
    // 其他
    {
      key: 'OTHER',
      value: '04',
      name: I18N.auto.qiTa,
    },
  ] as const),
  // 联系人类型
  ContactTypeEnum: createEnum([
    // 商务对接人
    {
      key: 'BUSINESS',
      value: '01',
      name: I18N.auto.shangWuDuiJieRen,
    },
    // 财务对接人
    {
      key: 'FINANCIAL',
      value: '02',
      name: I18N.auto.caiWuDuiJieRen,
    },
    // 其他
    {
      key: 'OTHERS',
      value: '03',
      name: I18N.auto.qiTa,
    },
  ] as const),
  // 手续费承担方
  HandlingFeeTypeEnum: createEnum([
    // 他方
    {
      key: 'SUPPLIER',
      value: '01',
      name: I18N.auto.gongYingShang,
    },
    // 我方
    {
      key: 'NETEASE',
      value: '02',
      name: I18N.auto.wangYiJiTuan,
    },
    // 双方
    {
      key: 'BOTH',
      value: '03',
      name: I18N.auto.shuangFang,
    },
  ] as const),
  // 供应商来源
  SourceTypeEnum: createEnum([
    {
      key: 'NETBUY',
      value: '1',
      name: 'unknown',
    },
    {
      key: 'PARTNERSHIP',
      value: '2',
      name: 'unknown',
    },
  ] as const),
  // 准入状态
  SpOaFlowStatusEnum: createEnum([
    // 草稿
    {
      key: 'DRAFT',
      value: '01',
      name: '待审核',
    },
    // 审批中
    {
      key: 'IN_APPROVE',
      value: '02',
      name: I18N.auto.shenPiZhong,
    },
    // 审批成功
    {
      key: 'APPROVE_SUCCESS',
      value: '03',
      name: I18N.auto.shenPiChengGong,
    },
    // 审批拒绝
    {
      key: 'REJECTED',
      value: '04',
      name: I18N.auto.shenPiJuJue,
    },
  ] as const),
  // 类型
  SpApplicationTypeEnum: createEnum([
    // 准入审批
    {
      key: 'ON_BOARDING',
      value: '01',
      name: I18N.auto.zhunRuShenPi,
    },
    // 内容变更
    {
      key: 'MODIFICATION',
      value: '02',
      name: I18N.auto.neiRongBianGeng,
    },
    // 合作状态变更
    {
      key: 'COOPERATE_STATUS',
      value: '03',
      name: I18N.auto.heZuoZhuangTaiBian,
    },
    // 供应商创建
    {
      key: 'SUPPLIER_CREATE',
      value: '04',
      name: I18N.auto.gongYingShangChuangJian,
    },
    // 供应商找回
    {
      key: 'SUPPLIER_RETRIEVAL',
      value: '05',
      name: I18N.auto.gongYingShangZhaoHui,
    },
    // 供应商利益冲突
    {
      key: 'SUPPLIER_INTEREST_CONFLICT',
      value: '06',
      name: '供应商利益冲突',
    },
  ] as const),
  // 准入信息 合作状态
  SpCooperateStatusEnum: createEnum([
    // 注册
    {
      key: 'REGISTERED',
      value: '01',
      name: I18N.auto.zhuCe,
    },
    // 潜在/小金额
    {
      key: 'POTENTIAL_SMALL_AMOUNT',
      value: '02',
      name: I18N.auto.qianZaiXiaoJinE,
    },
    // 潜在/常规
    {
      key: 'POTENTIAL_REGULAR',
      value: '03',
      name: I18N.auto.qianZaiChangGui,
    },
    // 合作/常规
    {
      key: 'COOPERATION_REGULAR',
      value: '04',
      name: I18N.auto.heZuoChangGui,
    },
    // 合作/小金额
    {
      key: 'COOPERATION_SMALL_AMOUNT',
      value: '05',
      name: I18N.auto.heZuoXiaoJinE,
    },
    // 淘汰
    {
      key: 'ELIMINATED',
      value: '06',
      name: I18N.auto.taoTai,
    },
    // 黑名单
    {
      key: 'BLACKLISTED',
      value: '07',
      name: I18N.auto.heiMingDan,
    },
  ] as const),
  //准入信息 来源
  SpOnboardingSourceEnum: createEnum([
    // 采购寻源
    {
      key: 'PROCURE_SOURCE',
      value: '01',
      name: I18N.auto.caiGouXunYuan,
    },
    // 其他人推荐
    {
      key: 'OTHER_RECOMMEND',
      value: '02',
      name: I18N.auto.qiTaRenTuiJian,
    },
  ] as const),
  // 职位
  SpSupervisorPositionEnum: createEnum([
    // 法定代表人
    {
      key: 'LEGAL_REPRESENTATIVE',
      value: '01',
      name: '法定代表人',
    },
    // 董事
    {
      key: 'DIRECTOR',
      value: '02',
      name: '董事',
    },
    // (总)经理
    {
      key: 'MANAGER',
      value: '03',
      name: '(总)经理',
    },
    // 监事
    {
      key: 'SUPERVISOR',
      value: '04',
      name: '监事',
    },
    // 财务负责人
    {
      key: 'CHARGE_OF_FINANCE',
      value: '05',
      name: '财务负责人',
    },
    // 其他
    {
      key: 'OTHERS',
      value: '06',
      name: '其他',
    },
  ] as const),
  // 税表类型
  SpTaxTypeEnum: createEnum([
    // W8-BEN
    {
      key: 'W8_BEN',
      value: 'W8-BEN',
      name: 'W8-BEN',
    },
    // W8-BEN-E
    {
      key: 'W8_BEN_E',
      value: 'W8-BEN-E',
      name: 'W8-BEN-E',
    },
    // W-8ECI
    {
      key: 'W_8ECI',
      value: 'W-8ECI',
      name: 'W-8ECI',
    },
    // W-8EXP
    {
      key: 'W_8EXP',
      value: 'W-8EXP',
      name: 'W-8EXP',
    },
    // W9
    {
      key: 'W9',
      value: 'W9',
      name: 'W9',
    },
    // others
    {
      key: 'OTHERS',
      value: 'others',
      name: 'others',
    },
  ] as const),
  // 纳税主体
  SpTaxPayerEnum: createEnum([
    // Individual
    {
      key: 'INDIVIDUAL',
      value: 'Individual',
      name: 'Individual',
    },
    // C corp
    {
      key: 'C_CORP',
      value: 'C corp',
      name: 'C corp',
    },
    // S corp
    {
      key: 'S_CORP',
      value: 'S corp',
      name: 'S corp',
    },
    // Partnership
    {
      key: 'PARTNERSHIP',
      value: 'Partnership',
      name: 'Partnership',
    },
    // Trust/estate
    {
      key: 'TRUST_ESTATE',
      value: 'Trust/estate',
      name: 'Trust/estate',
    },
    // LLC-C
    {
      key: 'LLC_C',
      value: 'LLC-C',
      name: 'LLC-C',
    },
    // LLC-S
    {
      key: 'LLC_S',
      value: 'LLC-S',
      name: 'LLC-S',
    },
    // LLC-P
    {
      key: 'LLC_P',
      value: 'LLC-P',
      name: 'LLC-P',
    },
    // LLC-T
    {
      key: 'OTHERS',
      value: 'others',
      name: 'others',
    },
  ] as const),
  // 首选付款方式
  SpPreferredPaymentMethodEnum: createEnum([
    {
      key: 'WIRE',
      value: 'WIRE',
      name: 'Wire',
    },
    {
      key: 'ACH',
      value: 'ACH',
      name: 'ACH',
    },
    {
      key: 'ACH_EFT',
      value: 'ACH_EFT',
      name: 'ACH (EFT)',
    },
    {
      key: 'ACH_BACS',
      value: 'ACH_BACS',
      name: 'ACH (BACS)',
    },
    {
      key: 'CREDIT_CARD',
      value: 'CREDIT_CARD',
      name: 'Credit Card',
    },
    {
      key: 'SEPA',
      value: 'SEPA',
      name: 'SEPA',
    },
    {
      key: 'OTHERS',
      value: 'OTHERS',
      name: 'others',
    },
  ] as const),
  // 账号类型
  SpBankAccountTypeEnum: createEnum([
    {
      key: 'CHECKING',
      value: 'CHECKING',
      name: 'Checking',
    },
    {
      key: 'SAVINGS',
      value: 'SAVINGS',
      name: 'Savings',
    },
    {
      key: 'CD',
      value: 'CD',
      name: 'CD (Certificate of Deposit)',
    },
    {
      key: 'MMA',
      value: 'MMA',
      name: 'MMA (Money Market Account)',
    },
  ] as const),
  // 账号类型
  SpAccountTypeEnum: createEnum([
    // 主账号
    {
      key: 'MAJOR',
      value: '01',
      name: I18N.auto.zhuZhangHao,
    },
    // 子账号
    {
      key: 'SUB',
      value: '02',
      name: I18N.auto.ziZhangHao,
    },
  ] as const),
  // 供应商 准入进度
  SpOnboardProgressEnum: createEnum([
    // 待准入
    {
      key: 'NOT_BEGIN',
      value: '01',
      name: '待准入',
    },
    // 待采购员审核
    {
      key: 'IN_AUDIT',
      value: '05',
      name: '待审核',
    },
    // 准入审批中
    {
      key: 'IN_PROGRESS',
      value: '02',
      name: '审批中',
    },
    // 准入审批拒绝
    {
      key: 'FAIL',
      value: '03',
      name: '审批拒绝',
    },
    // 准入审批成功
    {
      key: 'SUCCEED',
      value: '04',
      name: '审批成功',
    },
  ] as const),
  // 供应商 变更进度
  SpModifiedProgressEnum: createEnum([
    // 未发起变更
    {
      key: 'NOT_BEGIN',
      value: '01',
      name: '未变更',
    },
    // 变更中
    {
      key: 'IN_PROGRESS',
      value: '02',
      name: '变更中',
    },
    // 变更失败,OA审核拒绝
    {
      key: 'FAIL',
      value: '03',
      name: '审核不通过',
    },
    // 变更成功,OA审核通过
    {
      key: 'SUCCEED',
      value: '04',
      name: '审核通过',
    },
    // 待采购员审核
    {
      key: 'IN_AUDIT',
      value: '05',
      name: '待审核',
    },
  ] as const),
  // 账号类型
  AccountTypeEnum: createEnum([
    // 主账号
    {
      key: 'MAIN',
      value: '1',
      name: '主账号',
    },
    // 子账号
    {
      key: 'SUB',
      value: '0',
      name: '子账号',
    },
  ] as const),
  AccountStatusEnum: createEnum([
    // 禁用
    {
      key: 'DISABLED',
      value: '0',
      name: '禁用',
    },
    // 启用
    {
      key: 'ENABLED',
      value: '1',
      name: '启用',
    },
  ] as const),
  // 金额计算基准
  MoneyCalBenchmarkEnum: createEnum([
    // 未税
    {
      key: 'UNTAXED',
      value: 'UNTAXED',
      name: '未税',
    },
    // 含税
    {
      key: 'TAXED',
      value: 'TAXED',
      name: '含税',
    },
  ] as const),
  // 付款方式
  PaymentMethodEnum: createEnum([
    // 转账
    {
      key: 'TRANSFER',
      value: '0',
      name: '转账',
    },
    // 信用卡
    {
      key: 'CREDIT_CARD',
      value: '1',
      name: '信用卡',
    },
    // 转账-预付
    {
      key: 'TRANSFER_PREPAY',
      value: '2',
      name: '转账-预付',
    },
  ] as const),
  // 账期
  AccountPeriodEnum: createEnum([
    // 15天
    {
      key: 'FIFTEEN',
      value: '15',
      name: '15天',
    },
    // 30天
    {
      key: 'THIRTY',
      value: '30',
      name: '30天',
    },
    // 45天
    {
      key: 'FORTY_FIVE',
      value: '45',
      name: '45天',
    },
    // 60天
    {
      key: 'SIXTY',
      value: '60',
      name: '60天',
    },
  ] as const),
  // 发票类型
  InvoiceTypeEnum: createEnum([
    // 增值税专票
    {
      key: 'VAT_SPECIAL_INVOICE',
      value: 'VAT_SPECIAL_INVOICE',
      name: '增值税专票',
    },
    // 增值税普票
    {
      key: 'VAT_GENERAL_INVOICE',
      value: 'VAT_GENERAL_INVOICE',
      name: '增值税普票',
    },
    // 电子发票（专票）
    {
      key: 'E_INVOICE_SPECIAL',
      value: 'E_INVOICE_SPECIAL',
      name: '电子发票（专票）',
    },
    // 电子发票（普票）
    {
      key: 'E_INVOICE_GENERAL',
      value: 'E_INVOICE_GENERAL',
      name: '电子发票（普票）',
    },
    // 数电发票（专票）
    {
      key: 'D_INVOICE_SPECIAL',
      value: 'D_INVOICE_SPECIAL',
      name: '数电发票（专票）',
    },
    // 数电发票（普票）
    {
      key: 'D_INVOICE_GENERAL',
      value: 'D_INVOICE_GENERAL',
      name: '数电发票（普票）',
    },
    // 其他
    {
      key: 'OTHERS',
      value: 'OTHERS',
      name: '其他',
    },
  ] as const),
  // 发货类型
  DeliveryTypeEnum: createEnum([
    // 快递物流
    {
      key: 'EXPRESS_AND_LOGISTICS',
      value: '0',
      name: '快递物流',
    },
    // 非快递物流
    {
      key: 'NON_EXPRESS_AND_LOGISTICS',
      value: '1',
      name: '非快递物流',
    },
  ] as const),
  // 发货配额类型
  PlaceOrderMethodEnum: createEnum([
    // 按金额下单
    {
      key: 'AMOUNT',
      value: '1',
      name: '按金额下单',
    },
    // 按数量下单
    {
      key: 'QUANTITY',
      value: '2',
      name: '按数量下单',
    },
  ] as const),
  // 订单状态
  FmOrderLineStatusEnum: createEnum([
    // 待发货
    {
      key: 'WAIT_DELIVERY',
      value: '1',
      name: '待发货',
    },
    // 部分发货
    {
      key: 'PART_DELIVERY',
      value: '2',
      name: '部分发货',
    },
    // 已发货
    {
      key: 'DELIVERED',
      value: '3',
      name: '已发货',
    },
  ] as const),
  // 状态
  QuotationStatusEnum: createEnum([
    // 待报价
    {
      key: 'WAIT_QUOTATION',
      value: '1',
      name: '待报价',
    },
    // 待开标
    {
      key: 'HAS_QUOTATION',
      value: '2',
      name: '待开标',
    },
    // 已中标
    {
      key: 'HAS_WINNING_BID',
      value: '3',
      name: '已中标',
    },
    // 未中标
    {
      key: 'NOT_WINNING_BID',
      value: '4',
      name: '未中标',
    },
    // 已弃标
    {
      key: 'ABANDONED_BID',
      value: '5',
      name: '已弃标',
    },
    // 未参与
    {
      key: 'NOT_BID',
      value: '6',
      name: '未参与',
    },
    // 已取消
    {
      key: 'CANCEL',
      value: '7',
      name: '已取消',
    },
    // 待应标
    {
      key: 'WAIT_RESPOND_TENDER',
      value: '8',
      name: '待应标',
    },
    // 待投标
    {
      key: 'WAIT_TENDER',
      value: '9',
      name: '待投标',
    },
    // 已投标
    {
      key: 'TENDERED',
      value: '10',
      name: '已投标',
    },
    // 已作废
    {
      key: 'DEPRECATED',
      value: '11',
      name: '已作废',
    },
  ] as const),
  // 报价方式
  QuotationMethodEnum: createEnum([
    // 在线询价
    {
      key: 'ONLINE',
      value: '1',
      name: '在线询价',
    },
    // 招投标
    {
      key: 'TENDER',
      value: '2',
      name: '招投标',
    },
  ] as const),
  // 行状态
  QuotationLineStatusEnum: createEnum([
    // 待报价
    {
      key: 'WAIT_QUOTATION',
      value: '1',
      name: '待报价',
    },
    // 待开标
    {
      key: 'HAS_QUOTATION',
      value: '2',
      name: '待开标',
    },
    // 已中标
    {
      key: 'HAS_WINNING_BID',
      value: '3',
      name: '已中标',
    },
    // 未中标
    {
      key: 'NOT_WINNING_BID',
      value: '4',
      name: '未中标',
    },
    // 已弃标
    {
      key: 'ABANDONED_BID',
      value: '5',
      name: '已弃标',
    },
    // 未参与
    {
      key: 'NOT_BID',
      value: '6',
      name: '未参与',
    },
  ] as const),
  // 发票支付状态
  PayStatusEnum: createEnum([
    // 未支付
    {
      key: 'NOT_PAY',
      value: '01',
      name: I18N.auto.weiZhiFu,
    },
    // 已支付
    {
      key: 'PAYED',
      value: '02',
      name: I18N.auto.yiZhiFu,
    },
    // 部分支付
    {
      key: 'PAY_PART',
      value: '03',
      name: '部分支付',
    },
  ] as const),
  // 验真状态
  InvoiceCheckingStatusEnum: createEnum([
    // 未验真
    {
      key: 'NOT_CHECK',
      value: '1',
      name: '未验真',
    },
    // 验真成功
    {
      key: 'CHECK_SUCCESS',
      value: '2',
      name: '验真成功',
    },
    // 验真失败
    {
      key: 'CHECK_FAILED',
      value: '3',
      name: '验真失败',
    },
  ] as const),
  // 发票类型
  StInvoiceTypeEnum: createEnum([
    // 中国大陆发票
    {
      key: 'CHINA',
      value: '01',
      name: '中国大陆发票',
    },
    // 非中国大陆发票
    {
      key: 'OVER_SEA',
      value: '02',
      name: '非中国大陆发票',
    },
  ] as const),
  // 发票子分类
  StOcrInvoiceSubTypeEnum: createEnum([
    // 增值税专用发票
    {
      key: 'VAT_SPECIAL_INVOICE',
      value: '1',
      name: '增值税专用发票',
    },
    // 增值税电子专用发票
    {
      key: 'VAT_E_SPECIAL_INVOICE',
      value: '2',
      name: '增值税电子专用发票',
    },
    // 增值税普通发票
    {
      key: 'VAT_GENERAL_INVOICE',
      value: '3',
      name: '增值税普通发票',
    },
    // 增值税电子普通发票
    {
      key: 'VAT_E_GENERAL_INVOICE',
      value: '4',
      name: '增值税电子普通发票',
    },
    // 增值税电子普通发票（通行费）
    {
      key: 'VAT_E_GENERAL_INVOICE_TOLL',
      value: '5',
      name: '增值税电子普通发票（通行费）',
    },
    // 区块链电子发票
    {
      key: 'BLOCKCHAIN_E_INVOICE',
      value: '6',
      name: '区块链电子发票',
    },
    // 增值税普通发票（卷式）
    {
      key: 'VAT_GENERAL_INVOICE_ROLL',
      value: '7',
      name: '增值税普通发票（卷式）',
    },
    // 机动车销售统一发票
    {
      key: 'VEHICLE_SALES_UNIFIED_INVOICE',
      value: '8',
      name: '机动车销售统一发票',
    },
    // 通用机打发票（纸质）
    {
      key: 'GENERAL_MACHINE_PRINTED_INVOICE_PAPER',
      value: '9',
      name: '通用机打发票（纸质）',
    },
    // 通用机打发票（电子）
    {
      key: 'GENERAL_MACHINE_PRINTED_INVOICE_ELECTRONIC',
      value: '10',
      name: '通用机打发票（电子）',
    },
    // 航空行程单
    {
      key: 'AIR_TRAVEL_ITINERARY',
      value: '11',
      name: '航空行程单',
    },
    // 火车票
    {
      key: 'TRAIN_TICKET',
      value: '12',
      name: '火车票',
    },
    // 客运汽车票
    {
      key: 'PASSENGER_CAR_TICKET',
      value: '13',
      name: '客运汽车票',
    },
    // 船票
    {
      key: 'SHIP_TICKET',
      value: '14',
      name: '船票',
    },
    // 出租车票
    {
      key: 'TAXI_TICKET',
      value: '15',
      name: '出租车票',
    },
    // 定额发票
    {
      key: 'QUOTA_INVOICE',
      value: '16',
      name: '定额发票',
    },
    // 滴滴行程单
    {
      key: 'DIDI_TRAVEL_ITINERARY',
      value: '17',
      name: '滴滴行程单',
    },
    // 数电发票（专票）
    {
      key: 'DIGITAL_INVOICE_SPECIAL',
      value: '18',
      name: '数电发票（专票）',
    },
    // 数电发票（普票）
    {
      key: 'DIGITAL_INVOICE_GENERAL',
      value: '19',
      name: '数电发票（普票）',
    },
    // 海外INVOICE
    {
      key: 'OVERSEAS_INVOICE',
      value: '20',
      name: '海外INVOICE',
    },
  ] as const),
  // 发票状态
  StInvoiceStatusEnum: createEnum([
    // 未关联单据
    {
      key: 'NO_MATCH_INFO',
      value: '01',
      name: '未关联单据',
    },
    // 已关联单据
    {
      key: 'HAS_MATCH_INFO',
      value: '02',
      name: '已关联单据',
    },
    // 已确认
    {
      key: 'CONFIRMED',
      value: '04',
      name: '已确认',
    },
    // 已关联付款单
    {
      key: 'HAS_MATCH_PAYMENT',
      value: '05',
      name: '已关联付款单',
    },
  ] as const),
  // 竞价模式
  QuotationRuleBiddingModeEnum: createEnum([
    // 英氏报价（反向）
    {
      key: 'ENGLISH_QUOTATION_REVERSE',
      value: '1',
      name: '英式（反向）',
    },
  ] as const),
  // 调整步幅类型
  QuotationRuleAdjustStepTypeEnum: createEnum([
    // 按金额
    {
      key: 'BY_AMOUNT',
      value: '1',
      name: '按金额',
    },
    // 按比例
    {
      key: 'BY_PROPORTION',
      value: '2',
      name: '按比例',
    },
    // 不限
    {
      key: 'UNLIMITED',
      value: '3',
      name: '不限',
    },
  ] as const),
  // 竞价规则
  QuotationRuleBiddingRegulationEnum: createEnum([
    // 前三名不允许报价相同
    {
      key: 'TOP_THREE_NOT_SAME',
      value: '1',
      name: '前三名不允许报价相同',
    },
    // 所有报价需超越第一名
    {
      key: 'MUST_EXCEED_FIRST_PLACE',
      value: '2',
      name: '所有报价需超越第一名',
    },
    // 所有排名不允许报价相同
    {
      key: 'QUOTATION_NOT_SAME',
      value: '3',
      name: '所有排名不允许报价相同',
    },
  ] as const),
  // 问题状态
  QuotationQuestionStatusEnum: createEnum([
    // 待澄清
    {
      key: 'WAIT_CLARIFIED',
      value: '0',
      name: '待澄清',
    },
    // 已澄清
    {
      key: 'CLARIFIED',
      value: '1',
      name: '已澄清',
    },
  ] as const),
  // 状态
  QuotationDisplayTenderStatusEnum: createEnum([
    // 待应标
    {
      key: 'WAIT_RESPOND_TENDER',
      value: '8',
      name: '待应标',
    },
    // 待投标
    {
      key: 'WAIT_TENDER',
      value: '9',
      name: '待投标',
    },
    // 已投标
    {
      key: 'TENDERED',
      value: '10',
      name: '已投标',
    },
    // 已中标
    {
      key: 'HAS_WINNING_BID',
      value: '3',
      name: '已中标',
    },
    // 未中标
    {
      key: 'NOT_WINNING_BID',
      value: '4',
      name: '未中标',
    },
    // 已弃标
    {
      key: 'ABANDONED_BID',
      value: '5',
      name: '已弃标',
    },
    // 未参与
    {
      key: 'NOT_BID',
      value: '6',
      name: '未参与',
    },
    // 已取消
    {
      key: 'CANCEL',
      value: '7',
      name: '已取消',
    },
    // 已作废
    {
      key: 'DEPRECATED',
      value: '11',
      name: '已作废',
    },
  ] as const),
  // 验收状态
  AcceptanceStatusEnum: createEnum([
    // 待验收
    {
      key: 'PENDING_ACCEPTANCE',
      value: '1',
      name: '待验收',
    },
    // 部分验收
    {
      key: 'PART_ACCEPTED',
      value: '2',
      name: '部分验收',
    },
    // 全部验收
    {
      key: 'ACCEPTED',
      value: '3',
      name: '全部验收',
    },
    // 已关闭
    {
      key: 'CLOSED',
      value: '4',
      name: '已关闭',
    },
  ] as const),
  // 澄清状态
  QuotationClarificationStatusEnum: createEnum([
    // 待澄清
    {
      key: 'WAIT_CLARIFIED',
      value: '0',
      name: '待澄清',
    },
    // 已澄清
    {
      key: 'CLARIFIED',
      value: '1',
      name: '已澄清',
    },
    // 澄清结束
    {
      key: 'END_CLARIFIED',
      value: '2',
      name: '澄清结束',
    },
  ] as const),
  // 报价方式
  QuoteMethodEnum: createEnum([
    // 线下代理报价
    {
      key: 'OFFLINE',
      value: '0',
      name: '线下代理报价',
    },
    // 在线询价
    {
      key: 'ONLINE',
      value: '1',
      name: '在线询价',
    },
    // 招投标
    {
      key: 'TENDER',
      value: '2',
      name: '招投标',
    },
  ] as const),
}

// 这里聚会一些apiService接口，包括入参和出参的处理
class BizService {
  currencyMap = {} as Record<string, defs.CurrencyDto>
  supplierCountryMap = {} as Record<string, defs.CountryDto>

  bootstrap = async () => {
    await this.commonCurrencyListCurrency()
  }

  commonCurrencyListCurrency = memoize(async () => {
    const res = await apiService.identityEnterpriseConfigListCurrency()
    this.currencyMap = res?.reduce((r, v) => ({ ...r, [v.name!]: v }), {} as Record<string, defs.CurrencyDto>) || {}
    return res
  })
  getCurrencySymbol = (v: defs.Currency) => this.currencyMap[v]?.symbol
  getCurrencyFractionDigits = (v: defs.Currency) => this.currencyMap[v]?.precision

  supplierConfigCountries = memoize(async () => {
    const res = await apiService.identityEnterpriseConfigCountries()
    this.supplierCountryMap = res?.reduce((r, v) => ({ ...r, [v.id!]: v }), {} as Record<string, defs.CountryDto>) || {}
    return res
  })
  getSupplierCountryName = (id: number) => this.supplierCountryMap[id]?.name
  supplierConfigProvinces = memoize(apiService.identityEnterpriseConfigProvinces)
  supplierConfigCities = memoize(apiService.identityEnterpriseConfigCities)

  rfxQuoteListTaxRates = memoize(async () => {
    const res = await apiService.materialTaxListCategory()
    return res?.sort((a, b) => (a.value! < b.value! ? -1 : 1))
  })

  // 这里详情的数据有脱敏
  supplierQuerySupplierDetail = async (params: { subSet?: defs.SupplierSubSet }) => {
    switch (params.subSet) {
      case apiEnums.SupplierSubSetEnum.CHINA_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQueryChinaEnterpriseSupplierDetail()
      case apiEnums.SupplierSubSetEnum.CHINA_PERSON:
        return apiService.identityEnterpriseBoardedQueryChinaPersonSupplierDetail()
      case apiEnums.SupplierSubSetEnum.OVERSEAS_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQueryOverseaEnterpriseSupplierDetail()
      case apiEnums.SupplierSubSetEnum.OVERSEAS_PERSON:
        return apiService.identityEnterpriseBoardedQueryOverseaPersonSupplierDetail()
    }
  }
  // 这里详情的数据是原始的
  supplierQuerySupplierRawDetail = async (params: { subSet?: defs.SupplierSubSet }) => {
    switch (params.subSet) {
      case apiEnums.SupplierSubSetEnum.CHINA_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQueryChinaEnterpriseSupplierUpdateDetail()
      case apiEnums.SupplierSubSetEnum.CHINA_PERSON:
        return apiService.identityEnterpriseBoardedQueryChinaPersonSupplierUpdateDetail()
      case apiEnums.SupplierSubSetEnum.OVERSEAS_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQueryOverseaEnterpriseSupplierUpdateDetail()
      case apiEnums.SupplierSubSetEnum.OVERSEAS_PERSON:
        return apiService.identityEnterpriseBoardedQueryOverseaPersonSupplierUpdateDetail()
    }
  }

  supplierApplicationQuerySupplierOnboardDetail = async (params: {
    subSet?: defs.SupplierSubSet
    applicationId: number
  }) => {
    const { subSet } = params
    switch (subSet) {
      case apiEnums.SupplierSubSetEnum.CHINA_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQueryChinaEnterpriseOnboardDetail(params)
      case apiEnums.SupplierSubSetEnum.CHINA_PERSON:
        return apiService.identityEnterpriseBoardedQueryChinaPersonOnboardDetail(params)
      case apiEnums.SupplierSubSetEnum.OVERSEAS_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQueryOverseaEnterpriseOnboardDetail(params)
      case apiEnums.SupplierSubSetEnum.OVERSEAS_PERSON:
        return apiService.identityEnterpriseBoardedQueryOverseaPersonOnboardDetail(params)
    }
  }

  supplierApplicationQuerySupplierRawOnboardDetail = async (params: {
    subSet?: defs.SupplierSubSet
    applicationId: number
  }) => {
    const { subSet } = params
    switch (subSet) {
      case apiEnums.SupplierSubSetEnum.CHINA_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQueryChinaEnterpriseOnboardUpdateDetail(params)
      case apiEnums.SupplierSubSetEnum.CHINA_PERSON:
        return apiService.identityEnterpriseBoardedQueryChinaPersonOnboardUpdateDetail(params)
      case apiEnums.SupplierSubSetEnum.OVERSEAS_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQueryOverseaEnterpriseOnboardUpdateDetail(params)
      case apiEnums.SupplierSubSetEnum.OVERSEAS_PERSON:
        return apiService.identityEnterpriseBoardedQueryOverseaPersonOnboardUpdateDetail(params)
    }
  }

  supplierApplicationQuerySpAppModDetail = async (params: { subSet?: defs.SupplierSubSet; applicationId: number }) => {
    const { subSet } = params
    switch (subSet) {
      case apiEnums.SupplierSubSetEnum.CHINA_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQuerySpAppModChinaEnterpriseDetailDto(params)
      case apiEnums.SupplierSubSetEnum.CHINA_PERSON:
        return apiService.identityEnterpriseBoardedQuerySpAppModChinaPersonDetailDto(params)
      case apiEnums.SupplierSubSetEnum.OVERSEAS_ENTERPRISE:
        return apiService.identityEnterpriseBoardedQuerySpAppModOverseaEnterpriseDetailDto(params)
      case apiEnums.SupplierSubSetEnum.OVERSEAS_PERSON:
        return apiService.identityEnterpriseBoardedQuerySpAppModOverseaPersonDetailDto(params)
    }
  }
}

export default new BizService()
