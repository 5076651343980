import { useMemo, useState } from 'react'
import { useManualService, useRoute } from '@oahz/neact'
import { useRCMount, useRCUpdate } from '@oahz/neact-utils'
import { apiService, bizEnums, type defs } from '@/services'
import { PageLoading } from '@/components/page-view'
import { computeBidMoney } from '@/components/app-rfx'
import Show from '../show'
import Bidding from '../bidding'

const parseBackup = (data?: defs.StagingDetailDto) =>
  (data?.stagingInfo ? JSON.parse(data?.stagingInfo) : []) as defs.UpdateQuotationCommand
const genData = (detaildata: defs.QuotationDetailDto, backup: defs.UpdateQuotationCommand) => {
  // 构建出来一个金额数据
  const totalPrice = { currency: detaildata.quotationCurrency, amount: 0 }
  const gen: defs.QuotationDetailDto = {
    ...detaildata,
    lines: detaildata?.lines?.map(dl => {
      const isQuantity = dl.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
      const unTaxBenchmark = dl.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
      const backupLine = backup?.lines?.find(bl => bl.id === dl.id)
      const { bidUnitUnTaxMoney, bidUnitMoney, bidUnTaxMoney, bidMoney } = computeBidMoney({
        bidUnitUnTaxMoney: backupLine?.unitUnTaxMoney,
        bidUnitMoney: backupLine?.unitMoney,
        bidUnTaxMoney: backupLine?.quotationUnTaxMoney,
        bidMoney: backupLine?.quotationMoney,
        taxRate: backupLine?.taxRate,
        // 原始数据
        quantity: dl.quantity,
        moneyCalBenchmark: dl.moneyCalBenchmark,
        orderMethod: dl.orderMethod,
      })

      totalPrice.amount +=
        ((unTaxBenchmark ? bidUnTaxMoney?.amount : bidMoney?.amount) || 0) +
        (isQuantity ? backupLine?.freight?.amount || 0 : 0)

      return {
        ...dl,
        unitUnTaxMoney: bidUnitUnTaxMoney,
        quotationUnTaxMoney: bidUnTaxMoney,
        unitMoney: bidUnitMoney,
        quotationMoney: bidMoney,
        ...backupLine,
      }
    }),
  }
  // 是草稿，就直接都赋值一下总额算了
  return { ...gen, totalPrice, unTaxTotalPrice: totalPrice }
}

export default () => {
  const {
    data: detail,
    loading: detailLoading,
    request: detailRequest,
    mutate,
  } = useManualService(apiService.quotationQuotationDetail)
  const {
    data: backupData,
    loading: backupLoading,
    request: backupRequest,
  } = useManualService(apiService.stagingStagingQuotationDetail)
  const loading = detailLoading || backupLoading
  // 请求过保存的数据就不能再请求了
  const [backupFlag, setBackupFlag] = useState(false)
  // 合并保存的数据和详情，就不能再算了
  const [mutateWithBackup, setMutateWithBackup] = useState(false)

  // 需要获取保存的数据条件
  const backupCondition =
    !!(
      detail?.status &&
      [bizEnums.QuotationStatusEnum.WAIT_QUOTATION, bizEnums.QuotationStatusEnum.WAIT_TENDER].includes(detail?.status)
    ) && !detail.isBidding

  useRCMount(() => query.id && detailRequest({ id: query.id }))

  useRCUpdate(() => {
    if (backupCondition && !backupFlag) {
      setBackupFlag(true)
      backupRequest({ quotationId: query.id })
    }
  }, [detail, backupFlag])
  // 根据情况 构建详情数据
  const data: defs.QuotationDetailDto | undefined = useMemo(() => {
    if (backupCondition && backupData && !mutateWithBackup) {
      setMutateWithBackup(true)
      // 把暂存的数据存储到detail中，后面放弃或触发detail更新时使用，数据无误
      const gdb = genData(detail, parseBackup(backupData))
      mutate(gdb)
      return gdb
    } else {
      return detail
    }
  }, [detail, backupData, backupCondition, mutateWithBackup])
  const { query } = useRoute()

  if (loading || !data) return <PageLoading />

  if (!data?.isBidding && data?.quotationMethod === bizEnums.QuotationMethodEnum.ONLINE) {
    return <Show data={data} id={query.id} mutate={mutate} />
  }

  return <Bidding data={data} id={query.id} mutate={mutate} refresh={() => detailRequest({ id: query.id })} />
}
