import { type FC, useRef, useState, useEffect } from 'react'
import { Tooltip } from '@bedrock/components'
import type { TooltipProps } from '@bedrock/components/lib/Tooltip'

export interface ClampProps {
  /** 文本内容 */
  value: string
  className?: string
  /** 透传的tooltip属性 */
  tooltipProps?: Partial<TooltipProps>
  /** 是否省略状态切换时触发，一般用于debug */
  onChange?: (value: boolean) => void
}

const Clamp: FC<ClampProps> = props => {
  const { value, tooltipProps, onChange } = props

  const ref = useRef<HTMLDivElement>(null)
  const [isOverflowed, setIsOverflowed] = useState(false)

  const checkOverflow = () => {
    const el = ref.current
    if (!el) return
    onChange?.(el.scrollHeight > el.offsetHeight)
    setIsOverflowed(el.scrollHeight > el.offsetHeight)
  }

  useEffect(() => {
    if (!ref.current) return

    checkOverflow()

    const observer = new ResizeObserver(checkOverflow)
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref.current])

  const style = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
  }

  return isOverflowed ? (
    <Tooltip mouseEnterDelay={0.2} title={value} {...tooltipProps}>
      <div ref={ref} style={style}>
        {value}
      </div>
    </Tooltip>
  ) : (
    <div ref={ref} style={style}>
      {value}
    </div>
  )
}

export const renderWithClamp = (value: string, className?: string) => <Clamp value={value} className={className} />

export default Clamp
