import { useMemo, useState, type ReactNode } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import { pick, omit, map } from 'lodash'
import { create as createStore } from 'zustand'
import { useManualService } from '@oahz/neact'
import {
  BEMGenerator,
  useBoolean,
  useRCComputed,
  useRCMount,
  useRCUnmount,
  useRCUpdate,
  useRCVars,
  useRCWatch,
} from '@oahz/neact-utils'
import {
  Button,
  Tooltip,
  Modal,
  Table,
  Radio,
  Form,
  DatePicker,
  InputNumber,
  Input,
  Message,
  Spin,
  Empty,
  Typography,
  Select,
  Switch,
  Drawer,
} from '@bedrock/components'
import SvgNoData from '@bedrock/components/es/Empty/images/large/NoData'
import type { CheckboxChangeEvent } from '@bedrock/components/lib/Checkbox'
import type { RowSelectionType } from '@bedrock/components/lib/Table/interfaces'
import type { ColumnsType } from '@bedrock/components/lib/Table'
import type { FormInstance } from '@bedrock/components/lib/Form'
import { Catalogue, Check, Edit, NoticeTextUp, RelevanceTemplate, TaskShow2, User, Unlink } from '@bedrock/icons-react'
import I18N, { translate } from '@feature/i18n'
import { NameWithPopo } from '@feature/views'
import { formatDateTime } from '@feature/shared'
import Tag, { TagEnumMap } from '@/components/tag'
import { apiService, bizEnums, type defs } from '@/services'
import {
  ListMoneyView,
  DataForm,
  DataFormItem,
  FileUpload,
  CurrencySelect,
  DataMoney,
  PoPageLink,
  DataItem,
} from '@/components/data-view'
import {
  InvoiceFormDataBlock,
  SettlementCompanySelect,
  InvoiceInfoView,
  genSubmitDara,
  InvoiceDetailView,
  RelatedItemHeaderView,
  AmountInput,
} from '@/components/invoice'
import { isChinaCountryCode } from '@/components/supplier'
import { useSelector } from '@/store'
import usePolling from './usePolling'
import InvoiceLine from './invoice-line'
import { RelatedDataViewShow } from './detail'
import './create.less'

const bem = BEMGenerator('pseic')

type RelatedDataTypeProps = 'PO' | 'POLine' | 'GR'
const RelatedTypeMap = {
  PO: {
    text: I18N.auto.guanLianDingDanZheng,
    icon: <TaskShow2 size={28} />,
  },
  POLine: {
    text: I18N.auto.guanLianDingDanMing,
    icon: <Catalogue size={28} />,
  },
  GR: {
    text: I18N.auto.guanLianYanShouDan,
    icon: <User size={28} />,
  },
}
type InvoiceFormDataStore = {
  // 用于在特殊情况下，改变form的值
  form: FormInstance
  //判断国内外发票
  inChina: boolean
  //发票验真状态
  truthCheckingStatus?: defs.InvoiceCheckingStatus
  yqInvoiceKey?: string
  yqInvoiceId?: number
  // 编辑的话，会存一个editId，也作为一个区分新建、编辑的标视
  editId?: string
  //发票类型
  subType?: defs.StOcrInvoiceSubType
  detail?: defs.InvoiceDetailDto
  lines: defs.InvoiceSubmitLineCommand & { id?: string }[]
  needUserConfirm?: boolean
  // 用于在第二步，展示图片用的
  attachmentShowData: { name: string; url: string }
  fetchAttachmentUrl: (fileId: number, name: string) => Promise<void>
  // 关联数据选择列表，基础的筛选条件，控制关联按钮展示与否
  fetchRelatedDataParams: Pick<defs.InvoiceMatchDataQuery, 'currency' | 'supplierId' | 'settlementCompanyCode'>
  // 关联数据的类型
  relatedDataType: RelatedDataTypeProps
  // 已经选中的关联数据
  relatedData: defs.InvoiceMatchOrderDto[]
  // 设置relatedDataType、relatedData
  setRelatedInfo: (type: RelatedDataTypeProps, data: defs.InvoiceMatchOrderDto[]) => void
  // 关联的总金额
  relatedAmount: number
  // 计算
  computedAmount: (value: number | undefined, record: defs.InvoiceMatchGrDto, computedAllAmount?: boolean) => void
  // 清空store中的数据
  resetStoreData: () => void
}
const useInvoiceFormData = createStore<InvoiceFormDataStore>((set, get) => ({
  // 用于在特殊情况下，改变form的值
  form: {} as FormInstance,
  editId: '',
  detail: {},
  inChina: false,
  lines: [],
  subType: undefined,
  needUserConfirm: false,
  truthCheckingStatus: undefined,
  yqInvoiceKey: undefined,
  yqInvoiceId: undefined,
  // 需要一个数据控制是否可以点击下一步
  fetchRelatedDataParams: {},
  attachmentShowData: { name: '', url: '' },
  fetchAttachmentUrl: async (fileId: number, name: string) => {
    try {
      const url = await apiService.settlementConfigGetAttachmentDownloadUrl({ fileId: fileId })
      set({ attachmentShowData: { url: url || '', name: name } })
    } catch (error) {
      console.log(error)
    }
  },
  // 关联的总金额
  relatedAmount: 0,
  // 关联数据的类型
  relatedDataType: '' as RelatedDataTypeProps,
  // 已经选中的关联数据
  relatedData: [],
  // 设置relatedDataType、relatedData
  setRelatedInfo: (type: RelatedDataTypeProps, data: defs.InvoiceMatchOrderDto[]) => {
    // ## 在金额基准需求迭代 ##
    // 这里可剩余可开票金额的未税值(unTaxRemainedMoney)和含税值(remainedMoney)，揉成原来代码中的字段值(remainedMoney)
    // 而只需要在poLine和grLine中需要处理，po中仍然只有一个值，不需要处理
    for (const po of data) {
      for (const poLine of po.lines || []) {
        const unTaxBenchmark = poLine.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
        poLine.remainedMoney = unTaxBenchmark ? poLine.unTaxRemainedMoney : poLine.remainedMoney
        for (const gr of poLine.grList || []) {
          const unTaxBenchmark = gr.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
          gr.remainedMoney = unTaxBenchmark ? gr.unTaxRemainedMoney : gr.remainedMoney
        }
      }
    }

    set({ relatedDataType: type, relatedData: data })
  },
  // 把所有数据的总金额，也放在这里计算，因为在每条数据修改的时候，总金额都会触发，一起变动
  // computedAllAmount 代表刚关联的数据，过来计算总金额的
  computedAmount: (value: number | undefined, record: defs.InvoiceMatchGrDto = {}, computedAllAmount = false) => {
    const { relatedDataType, relatedData } = get()
    const { orderId, orderLineId, grNo } = record
    // console.log('relatedData', relatedData, 'record', record, relatedDataType)
    let genAllAmount = 0
    const genData = relatedData.map(order => {
      // 这个方法循环较多了，后面优化下
      if (relatedDataType === 'PO') {
        // 这个是初始化数据计算
        if (computedAllAmount) {
          order.occupiedMoney = order.remainedMoney
          genAllAmount += order.remainedMoney?.amount || 0
        } else if (order.orderId === orderId) {
          order.occupiedMoney = { amount: value, currency: order.remainedMoney?.currency }
          // 计算总金额
          genAllAmount += value || 0
        } else {
          // 计算总金额
          genAllAmount += order.occupiedMoney?.amount || 0
        }
      }
      let genOrder = { ...order }
      genOrder.lines = order.lines?.map(orderLine => {
        if (relatedDataType === 'POLine') {
          // 这个是初始化数据计算
          if (computedAllAmount) {
            let occupiedAmount = Math.min(orderLine.remainedMoney?.amount!, order.remainedMoney?.amount!)
            orderLine.occupiedMoney = { amount: occupiedAmount, currency: orderLine.remainedMoney?.currency }
            genAllAmount += occupiedAmount || 0
          } else if (orderLine.orderLineId === orderLineId) {
            orderLine.occupiedMoney = { amount: value, currency: orderLine.remainedMoney?.currency }
            // 计算总金额
            genAllAmount += value || 0
          } else {
            // 计算总金额
            genAllAmount += orderLine.occupiedMoney?.amount || 0
          }
        }
        let genOrderLine = { ...orderLine }
        genOrderLine.grList = orderLine.grList?.map(gr => {
          if (gr.grNo === grNo) {
            gr.occupiedMoney = { amount: value, currency: gr.remainedMoney?.currency }
            // 计算总金额
            genAllAmount += value || 0
          } else if (relatedDataType === 'GR') {
            // 这个是初始化数据计算
            if (computedAllAmount) {
              let occupiedAmount = Math.min(
                gr.remainedMoney?.amount!,
                orderLine.remainedMoney?.amount!,
                order.remainedMoney?.amount!
              )
              gr.occupiedMoney = { amount: occupiedAmount, currency: gr.remainedMoney?.currency }
              genAllAmount += occupiedAmount || 0
            } else {
              // 计算总金额
              genAllAmount += gr.occupiedMoney?.amount || 0
            }
          }
          return gr
        })
        return genOrderLine
      })
      return genOrder
    })
    // console.log('relatedData', relatedData, 'genAllAmount', genAllAmount, Number(genAllAmount.toFixed(2)))
    set({ relatedData: genData, relatedAmount: Number(genAllAmount.toFixed(2)) })
  },
  resetStoreData: () => {
    set({
      editId: '',
      detail: {},
      inChina: false,
      lines: [],
      subType: undefined,
      needUserConfirm: false,
      truthCheckingStatus: undefined,
      yqInvoiceKey: undefined,
      yqInvoiceId: undefined,
      // 需要一个数据控制是否可以点击下一步
      fetchRelatedDataParams: {},
      relatedAmount: 0,
      relatedDataType: '' as RelatedDataTypeProps,
      relatedData: [],
      attachmentShowData: { name: '', url: '' },
    })
  },
}))

const StepTwoFormContent = () => {
  const { supplierInfo } = useSelector(state => state.account)
  // props中需要form的管理，针对关联数据需要的筛选项，可以外面点击关联的时候获取最新值
  const [currency, setCurrency] = useState('')
  const [accountPeriod, setAccountPeriod] = useState<string | number>()
  const [expiredDate, setExpiredDate] = useState<Dayjs>()
  const form = useInvoiceFormData(state => state.form)
  const inChina = useInvoiceFormData(state => state.inChina)
  const fetchRelatedDataParams = useInvoiceFormData(state => state.fetchRelatedDataParams)

  useRCUpdate(() => {
    const openDate = form.getFieldValue('openDate')
    if (openDate && accountPeriod) {
      const genExpiredDate = openDate.add(Number(accountPeriod), 'day')
      // setExpiredDate(genExpiredDate)
      form.setFieldsValue({ expiredDate: genExpiredDate })
    }
  }, [accountPeriod])
  useRCUpdate(() => {
    const openDate = form.getFieldValue('openDate')
    if (openDate && expiredDate) {
      const genAccountPeriod = expiredDate.diff(openDate, 'day')
      const _genAccountPeriod = bizEnums.SpAccountPeriodEnum.pick(genAccountPeriod)?.value
      setAccountPeriod(_genAccountPeriod)
      form.setFieldsValue({ accountPeriod: _genAccountPeriod })
    }
  }, [expiredDate])

  const updateRelatedParams = async (val: any, type: 'currency' | 'supplierId' | 'settlementCompanyCode') => {
    if (type === 'currency') {
      setCurrency(val)
      // 做币种对金额小数位的控制
      if (['JPY', 'KRW'].includes(val)) {
        const formVal = form.getFieldsValue()
        form.setFieldsValue({
          taxMoney: formVal?.taxMoney ? formVal?.taxMoney.toFixed(0) : undefined,
          tax: formVal?.tax ? formVal?.tax.toFixed(0) : undefined,
          unTaxMoney: formVal?.unTaxMoney ? formVal?.unTaxMoney.toFixed(0) : undefined,
        })
      }
    }
    const prevParams = useInvoiceFormData.getState().fetchRelatedDataParams
    useInvoiceFormData.setState({
      fetchRelatedDataParams: { ...prevParams, [type]: val },
      relatedDataType: 'PO',
      relatedData: [],
    })
  }

  useRCWatch(() => {
    // 如果该供应商ID没有，就使用当前登录供应商ID
    if (!fetchRelatedDataParams.supplierId) {
      updateRelatedParams(supplierInfo.id, 'supplierId')
    }
  }, [fetchRelatedDataParams])

  return (
    <div className={bem('st-fc')}>
      {/* formItem */}
      <InvoiceFormDataBlock>
        <DataFormItem w667 label={I18N.auto.faPiaoHaoMa} name="number" required>
          <Input />
        </DataFormItem>
        <DataFormItem w667 label={I18N.auto.faPiaoDaiMa} name="code">
          <Input />
        </DataFormItem>
        <DataFormItem w667 label={I18N.auto.jiaoYanMa} name="checkSum">
          <Input />
        </DataFormItem>
        <DataFormItem w667 label={I18N.auto.jieSuanGongSi} name="settlementCompanyCode" required>
          <SettlementCompanySelect onChange={v => updateRelatedParams(v, 'settlementCompanyCode')} />
        </DataFormItem>
        <DataItem w667 label={I18N.auto.gongYingShang}>
          {supplierInfo.name}
        </DataItem>
        <DataFormItem w667 label={I18N.auto.faPiaoBiZhong} name="currency" required>
          <CurrencySelect onChange={v => updateRelatedParams(v, 'currency')} />
        </DataFormItem>
        <DataFormItem w667 label={I18N.auto.kaiPiaoRiQi} name="openDate" required>
          <DatePicker onChange={() => form.setFieldsValue({ accountPeriod: undefined, expiredDate: undefined })} />
        </DataFormItem>
        <DataFormItem w667 label={I18N.auto.zhangQiTian} name="accountPeriod" required>
          <Select options={bizEnums.SpAccountPeriodEnum.values()} onChange={e => setAccountPeriod(e)} />
        </DataFormItem>
        <DataFormItem w667 label={I18N.auto.daoQiRi} name="expiredDate" required={!inChina}>
          <DatePicker onChange={e => setExpiredDate(e)} />
        </DataFormItem>
        <AmountInput
          toFixed={['JPY', 'KRW'].includes(currency) ? 0 : 2}
          label={I18N.auto.hanShuiJinE}
          name="taxMoney"
        />
        <DataFormItem w667 label={I18N.auto.shuiE} name="tax">
          <InputNumber toFixed={['JPY', 'KRW'].includes(currency) ? 0 : 2} direction="vertical" />
        </DataFormItem>
        <AmountInput
          toFixed={['JPY', 'KRW'].includes(currency) ? 0 : 2}
          label={I18N.auto.buHanShuiJinE}
          name="unTaxMoney"
        />
        <DataFormItem w100 label={I18N.auto.beiZhu} name="remark">
          <Input />
        </DataFormItem>
      </InvoiceFormDataBlock>
    </div>
  )
}

// 统一的关联数据外部的view层，包括未选择时候的三种数据的按钮、选中后得数据展示，集合在这里做一个控制
const RelatedContainer = () => {
  const relatedData = useInvoiceFormData(state => state.relatedData)
  const detail = useInvoiceFormData(state => state.detail)
  const needUserConfirm = useInvoiceFormData(state => state.needUserConfirm)
  const relatedDataType = useInvoiceFormData(state => state.relatedDataType)

  const onchangeUserConfirm = (v: boolean) => {
    useInvoiceFormData.setState({ needUserConfirm: v })
  }
  const unMatch = async () => {
    // const res = await apiService.settlementUnMatch({ id: detail?.id })
    // const _detail = await apiService.settlementDetailUsingGet({ id: detail?.id })
    // useInvoiceFormData.setState({ detail: _detail })
  }

  if (detail?.matchOrders) {
    return (
      <RelatedDataViewShow
        detailData={detail}
        needUserConfirm={needUserConfirm}
        onchangeUserConfirm={onchangeUserConfirm}
        unMatch={unMatch}
      />
    )
  }

  return (
    <div className={bem('rc')}>
      {!relatedData?.length ? (
        <>
          <div className={bem('rc-sa-title')}>
            关联信息
            <span className={bem('rc-sa-title-tip')}>{I18N.auto.gaiFaPiaoZanWei}</span>
          </div>
          <div className={bem('rc-sa')}>
            {/* <RelatedDataPoModal /> */}
            <RelatedDataPoLineModal />
            <RelatedDataGrModal />
          </div>
        </>
      ) : (
        <>
          <RelatedDataView />
          {/* {relatedDataType === 'PO' ? (
            <div className={bem('user-confirm')}>
              <div className={bem('user-confirm-header')}>是否需用户确认</div>
              <div className={bem('user-confirm-content')}>
                <Switch labelOn="是" labelOff="否" checked={needUserConfirm} onChange={onchangeUserConfirm} />
                <span className={bem('user-confirm-label')}>选择是，将由用户在OA上确认发票和验收信息。</span>
              </div>
            </div>
          ) : null} */}
        </>
      )}
    </div>
  )
}

// 选择后、确定后的，统一的关联数据外部的view层，包括关联的类型title、总金额的展示
const RelatedDataView = () => {
  const state = useInvoiceFormData()
  const { relatedDataType, relatedData, relatedAmount, fetchRelatedDataParams } = state

  const unTaxBenchmark = relatedData?.[0]?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  return (
    <div className={bem('rd')}>
      <div className={bem('rd-header')}>
        <div className={bem('rd-title')}>{RelatedTypeMap[relatedDataType].text}</div>
        <div className={bem('rd-all-amount')}>
          <div className={bem('rd-all-amount-label')}>
            {unTaxBenchmark ? I18N.auto.guanLianZongJinE : '关联总金额（含税）'}
          </div>
          <DataMoney size="small" value={{ amount: relatedAmount, currency: fetchRelatedDataParams.currency }} />
        </div>
      </div>
      {!!relatedData?.length && (
        <div>
          {/* {relatedDataType === 'PO' && <PORelatedDataView />} */}
          {relatedDataType === 'POLine' && <POLineRelatedDataView />}
          {relatedDataType === 'GR' && <GRRelatedDataView />}
        </div>
      )}
    </div>
  )
}

// 统一的关联数据占用金额输入框，在这里统一处理金额的变动，计算全部的金额, maxAmount是po、line、gr的金额最小值上限
const RelatedAmountInput = (props: {
  record: (defs.InvoiceMatchOrderDto | defs.InvoiceMatchOrderLineDto | defs.InvoiceMatchGrDto) & { maxAmount: number }
}) => {
  const { record } = props
  const computedAmount = useInvoiceFormData(state => state.computedAmount)
  const form = useInvoiceFormData(state => state.form)
  const relatedDataType = useInvoiceFormData(state => state.relatedDataType)
  const [currency, setCurrency] = useState<defs.Currency>()
  const formItemName = `remainedMoney-${relatedDataType}-${record.orderId}-${record.orderLineId}-${record.grNo}`
  // 直接改store里面的，relateddata
  // @ts-ignore
  const onChaneg = (v: number | string) => computedAmount(v, record)
  const valueRenderChange = () => {
    // console.log('record.occupiedMoney', record.occupiedMoney?.amount, 'formItemName', formItemName)
    setCurrency(record.occupiedMoney?.currency)
    form.setFieldValue(formItemName, record.occupiedMoney?.amount)
  }
  // 删除后重复添加，避免name一致，form的值会事原来填写的，数据不对
  useRCMount(() => {
    valueRenderChange()
  })
  useRCUpdate(() => {
    valueRenderChange()
  }, [record])
  return (
    <div className={bem('rd-amount-input')}>
      <div style={{ whiteSpace: 'nowrap' }}>{record.remainedMoney?.currency}</div>
      <DataFormItem required className={bem('rd-amount-input-fi')} initialValue={record.maxAmount} name={formItemName}>
        <InputNumber
          min={0}
          trimOnBlur
          toFixed={['JPY', 'KRW'].includes(currency!) ? 0 : 2}
          addonAfter={null}
          onChange={onChaneg}
          direction="vertical"
          max={record.maxAmount}
          // parser={value => +(value || '').replace(/\$\s?|(,*)/g, '')}
          // formatter={value => (value === undefined ? '' : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
        />
      </DataFormItem>
    </div>
  )
}

const EllipsisRes = (result: ReactNode) => {
  return (
    <Typography.Paragraph
      ellipsis={{
        rows: 1,
        showTooltip: true,
        cssEllipsis: true,
      }}>
      {result}
    </Typography.Paragraph>
  )
}

// 表单中展示的关联数据 - POLine
const POLineRelatedDataView = () => {
  const relatedData = useInvoiceFormData(state => state.relatedData)
  const relatedAmount = useInvoiceFormData(state => state.relatedAmount)
  const unTaxBenchmark = relatedData?.[0]?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  const deleteData = (record: defs.InvoiceMatchOrderLineDto) => {
    const { orderId, orderLineId } = record
    // 删除的时候对应着总金额的计算
    let deleteAmount = 0
    const genData = relatedData.map(order => {
      if (order.orderId === orderId) {
        order.lines = order.lines?.filter(line => {
          if (line.orderLineId === orderLineId) {
            deleteAmount = line.occupiedMoney?.amount || 0
          }
          return line.orderLineId !== orderLineId
        })
      }
      return order
    })
    const genRelatedAmount = Number((relatedAmount - deleteAmount).toFixed(2))
    useInvoiceFormData.setState({
      relatedData: genData.filter(order => order.lines?.length),
      relatedAmount: genRelatedAmount,
    })
  }

  const columns: ColumnsType<defs.InvoiceMatchOrderLineDto> = [
    {
      dataIndex: 'orderLineIndex',
      title: 'PO行号',
      width: 100,
      align: 'left',
    },
    {
      dataIndex: 'goodsName',
      title: I18N.auto.shangPinMingCheng,
      width: 200,
      align: 'left',
      render: v => EllipsisRes(v || '-'),
    },
    {
      dataIndex: 'quantity',
      title: I18N.auto.shuLiang,
      width: 140,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'unTaxMoney',
      title: I18N.auto.jinEHanBuShui,
      width: 160,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
    {
      dataIndex: 'tax',
      title: I18N.auto.shuiE,
      width: 120,
      align: 'right',
      render: (_, record) =>
        record.tax === 0 ? (
          record.tax
        ) : record.tax !== undefined ? (
          <ListMoneyView value={{ amount: record.tax, currency: record.remainedMoney?.currency }} />
        ) : (
          '-'
        ),
    },
    {
      dataIndex: 'remainedMoney',
      title: unTaxBenchmark ? I18N.auto.guanLianJinEBu : I18N.auto.guanLianJinEHan,
      width: 200,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
    {
      dataIndex: 'occupiedMoney',
      title: unTaxBenchmark ? I18N.auto.guanLianJinEBu : '关联金额（含税）',
      width: 240,
      align: 'right',
      fixed: 'right',
      render: (_, record) => <RelatedAmountInput record={record} />,
    },
    {
      dataIndex: 'opt',
      title: I18N.auto.caoZuo,
      width: translate({ zh: 80, en: 90, ja: 110, fr: 90 }),
      align: 'right',
      fixed: 'right',
      render: (_, record) => (
        <Button danger type="text" onClick={() => deleteData(record)}>
          {I18N.auto.shanChu}
        </Button>
      ),
    },
  ]

  return (
    <>
      {relatedData.map((item, i) =>
        !item.lines?.length ? null : (
          <div key={i} className={bem('rd-line-item')}>
            <div className={bem('rd-item-header')}>
              <RelatedItemHeaderView label={I18N.auto.dingDanHao} value={<PoPageLink orderId={item.orderId} />} />
              <RelatedItemHeaderView
                label={unTaxBenchmark ? I18N.auto.shengYuKeKaiPiao : '剩余可开票金额（含税）'}
                value={<ListMoneyView value={item.remainedMoney} />}
              />
            </div>
            <Table
              rowKey="orderLineId"
              columns={columns}
              data={
                item.lines.map(line => ({
                  ...line,
                  maxAmount: Math.min(line.remainedMoney?.amount!, item.remainedMoney?.amount!),
                })) || []
              }
              scroll={{ x: true }}
              emptyCellRender={() => '-'}
            />
          </div>
        )
      )}
      <RelatedDataPoLineModal onlyBtn />
    </>
  )
}
// 表单中展示的关联数据 - GR
const GRRelatedDataView = () => {
  const relatedData = useInvoiceFormData(state => state.relatedData)
  const relatedAmount = useInvoiceFormData(state => state.relatedAmount)

  const unTaxBenchmark = relatedData?.[0]?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  const deleteData = (record: defs.InvoiceMatchGrDto) => {
    const { grNo, orderId, orderLineId } = record
    // 删除的时候对应着总金额的计算
    let deleteAmount = 0
    const genData = relatedData.map(order => {
      if (order.orderId === orderId) {
        order.lines = order.lines
          ?.map(line => {
            if (line.orderLineId === orderLineId) {
              line.grList = line.grList?.filter(gr => {
                if (gr.grNo === grNo) {
                  deleteAmount = gr.occupiedMoney?.amount || 0
                }
                return gr.grNo !== grNo
              })
            }
            return line
          })
          .filter(line => line.grList?.length)
      }
      return order
    })
    const genRelatedAmount = Number((relatedAmount - deleteAmount).toFixed(2))
    useInvoiceFormData.setState({
      relatedData: genData.filter(order => order.lines?.length),
      relatedAmount: genRelatedAmount,
    })
  }

  const columns: ColumnsType<defs.InvoiceMatchGrDto> = [
    {
      dataIndex: 'goodsName',
      title: I18N.auto.shangPinMingCheng,
      width: 200,
      align: 'left',
      render: v => EllipsisRes(v || '-'),
    },
    {
      dataIndex: 'quantity',
      title: I18N.auto.shuLiang,
      width: 140,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'unTaxMoney',
      title: I18N.auto.xingJinErBuHanShui,
      width: 160,
      align: 'left',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
    {
      dataIndex: 'tax',
      title: I18N.auto.shuiE,
      width: 120,
      align: 'right',
      render: (_, record) =>
        record.tax === 0 ? (
          record.tax
        ) : record.tax !== undefined ? (
          <ListMoneyView value={{ amount: record.tax, currency: record.remainedMoney?.currency }} />
        ) : (
          '-'
        ),
    },
    {
      dataIndex: 'remainedMoney',
      title: unTaxBenchmark ? I18N.auto.guanLianJinEBu : I18N.auto.guanLianJinEHan,
      width: 200,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
    {
      dataIndex: 'grNo',
      title: I18N.auto.yanShouDingDan,
      width: 200,
      align: 'left',
    },
    {
      dataIndex: 'createdAt',
      title: I18N.auto.yanShouShiJian,
      width: 190,
      align: 'left',
      render: val => formatDateTime(val),
    },
    {
      dataIndex: 'occupiedMoney',
      title: unTaxBenchmark ? I18N.auto.guanLianJinEBu : '关联金额（含税）',
      width: 240,
      align: 'right',
      fixed: 'right',
      render: (_, record) => <RelatedAmountInput record={record} />,
    },
    {
      dataIndex: 'opt',
      title: I18N.auto.caoZuo,
      width: translate({ zh: 80, en: 90, ja: 110, fr: 90 }),
      align: 'right',
      fixed: 'right',
      render: (_, record) => (
        <Button danger type="text" onClick={() => deleteData(record)}>
          {I18N.auto.shanChu}
        </Button>
      ),
    },
  ]

  return (
    <>
      {relatedData.map(v => {
        const { lines } = v
        return (
          <>
            {(lines || []).map((item, i) =>
              !item.grList?.length ? null : (
                <div key={i} className={bem('rd-line-item')}>
                  <div className={bem('rd-item-header-container')}>
                    <RelatedItemHeaderView label={I18N.auto.dingDanHao} value={<PoPageLink orderId={item.orderId} />} />

                    <RelatedItemHeaderView
                      label={unTaxBenchmark ? I18N.auto.shengYuKeKaiPiao : '剩余可开票金额（含税）'}
                      value={<ListMoneyView value={v.remainedMoney} />}
                    />
                    <RelatedItemHeaderView
                      label={I18N.auto.xingHao}
                      value={<div>{item.orderLineId?.split('-')[1]}</div>}
                    />
                    <RelatedItemHeaderView
                      label={unTaxBenchmark ? I18N.auto.shengYuKeKaiPiao : '剩余可开票金额（含税）'}
                      value={<ListMoneyView value={item.remainedMoney} />}
                    />
                  </div>
                  <Table
                    rowKey="grNo"
                    columns={columns}
                    data={
                      item.grList.map(gr => ({
                        ...gr,
                        maxAmount: Math.min(
                          gr.remainedMoney?.amount!,
                          item.remainedMoney?.amount!,
                          v.remainedMoney?.amount!
                        ),
                      })) || []
                    }
                    scroll={{ x: true }}
                    emptyCellRender={() => '-'}
                  />
                </div>
              )
            )}
          </>
        )
      })}
      <RelatedDataGrModal onlyBtn />
    </>
  )
}

// 关联数据的统一按钮样式
const RelatedDataRadio = (props: { type: RelatedDataTypeProps; onClick: () => void; onlyBtn?: boolean }) => {
  const { type, onClick, onlyBtn = false } = props
  const fetchRelatedDataParams = useInvoiceFormData(state => state.fetchRelatedDataParams)
  const disabled = !(
    fetchRelatedDataParams.currency &&
    fetchRelatedDataParams.supplierId &&
    fetchRelatedDataParams.settlementCompanyCode
  )

  return onlyBtn ? (
    <Button
      className={bem('rc-only-btn')}
      type="checked"
      disabled={disabled}
      icon={<RelevanceTemplate size={14} />}
      onClick={onClick}>
      {I18N.auto.guanLian}
    </Button>
  ) : (
    <div className={bem('rc-radio')}>
      {RelatedTypeMap[type]?.icon}
      <div className={bem('rc-radio-text')}>{RelatedTypeMap[type]?.text}</div>
      <Button type="checked" disabled={disabled} icon={<RelevanceTemplate size={14} />} onClick={onClick}>
        {I18N.auto.guanLian}
      </Button>
    </div>
  )
}

type relatedSelectDataProps =
  | defs.InvoiceMatchOrderDto[]
  | defs.InvoiceMatchOrderLineDto[]
  | defs.InvoiceMatchGrDto[]
  | undefined
const RelatedDataModal = (props: {
  type: RelatedDataTypeProps
  tableDataApi: <A extends any[]>(...args: A) => Promise<relatedSelectDataProps>
  fetchRelatedApi: <A extends any[]>(...args: A) => Promise<relatedSelectDataProps>
  columns: ColumnsType<any>
  onlyBtn?: boolean
}) => {
  const { type, tableDataApi, fetchRelatedApi, columns, ...reset } = props
  const vars = useRCVars({ params: {} as defs.InvoiceMatchDataQuery })
  const { data, loading, request } = useManualService(tableDataApi)
  const setRelatedInfo = useInvoiceFormData(state => state.setRelatedInfo)
  const computedAmount = useInvoiceFormData(state => state.computedAmount)
  const relatedData = useInvoiceFormData(state => state.relatedData)
  const fetchRelatedDataParams = useInvoiceFormData(state => state.fetchRelatedDataParams)
  const [visible, [show, hide]] = useBoolean(false)
  const [keyword, setKeyword] = useState<string>()
  const [submitLoading, [showSL, hideSL]] = useBoolean(false)
  const [batchLines, setBatchLines] = useState<NonNullable<relatedSelectDataProps>>([])
  const [relatedSelectData, setRelatedSelectData] = useState<relatedSelectDataProps>([])
  const rowKey = type === 'PO' ? 'orderId' : type === 'POLine' ? 'orderLineId' : 'grNo'

  useRCUpdate(() => {
    if (visible && relatedData?.length) {
      if (type === 'PO') {
        setRelatedSelectData(relatedData)
        setBatchLines(relatedData)
      }
      // 拍平数组，默认外面选中的数据，再table中要选中且不可编辑，
      if (visible && type === 'POLine') {
        const selectRenderData = relatedData.reduce((acc, item) => {
          return acc.concat(item.lines || [])
        }, [] as defs.InvoiceMatchOrderLineDto[])
        setBatchLines(selectRenderData)
        setRelatedSelectData(selectRenderData)
      }
      if (visible && type === 'GR') {
        const genSelectData = relatedData.reduce((acc, item) => {
          item.lines?.forEach(line => {
            acc = acc.concat(line.grList || [])
          })
          return acc
        }, [] as defs.InvoiceMatchGrDto[])
        setBatchLines(genSelectData)
        setRelatedSelectData(genSelectData)
        return genSelectData
      }
    }
  }, [visible, relatedData, type])

  const getData = (keyword?: string) => {
    request((vars.params = { ...fetchRelatedDataParams, keyword: keyword }))
  }

  const showAndFetch = () => {
    show()
    getData()
  }
  const onSearch = async (searchval: string) => {
    const trimVal = searchval.trim()
    setKeyword(trimVal)
    getData(trimVal)
  }

  const onCancel = () => {
    hide()
    setKeyword(undefined)
    setBatchLines([])
  }
  const onSubmit = async () => {
    showSL()
    try {
      const draftSelectdData = [...(relatedSelectData || []), ...batchLines]
      if (draftSelectdData.some(v => v.moneyCalBenchmark !== draftSelectdData[0].moneyCalBenchmark)) {
        Message.error(I18N.auto.buTongJiZhunJia)
        return
      }
      const ids = [...new Set([...(relatedSelectData || []).map(v => v[rowKey]), ...batchLines.map(v => v[rowKey])])]
      const res = await fetchRelatedApi({ ids: ids })
      if (res) {
        setRelatedInfo(type, res as defs.InvoiceMatchOrderDto[])
        computedAmount(undefined, {}, true)
        onCancel()
      }
    } catch (error) {
      console.log(error)
    } finally {
      hideSL()
    }
  }

  return (
    <>
      <RelatedDataRadio type={type} onClick={showAndFetch} {...reset} />
      <Modal
        width={1000}
        destroyOnClose
        title={RelatedTypeMap[type].text}
        visible={visible}
        onCancel={onCancel}
        onClose={onCancel}
        onOk={onSubmit}
        footer={
          !data?.length && !keyword ? null : (
            <div className={bem('rdm-action')}>
              <Button onClick={onCancel}>{I18N.auto.quXiao}</Button>
              <Button type="primary" onClick={onSubmit} disabled={!batchLines.length} loading={submitLoading}>
                {I18N.auto.queDing}
              </Button>
            </div>
          )
        }>
        {loading ? (
          <div className={bem('rdm-loading')}>
            <Spin />
          </div>
        ) : !data?.length && !keyword ? (
          <Empty
            className={bem('rdm-empty')}
            imageNode={<SvgNoData />}
            text={<span className={bem('empty-text')}>{I18N.auto.zanWuShuJu}</span>}
            description={I18N.auto.qingJianChaBiZhong}
          />
        ) : (
          <>
            <Input.Search
              trimOnBlur
              size="large"
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
              className={bem('rd-search')}
              allowOnSearchByDel={false}
              placeholder="请输入采购订单号"
              onSearch={onSearch}
            />

            <Table
              rowKey={rowKey}
              columns={columns}
              data={data || []}
              isShowLoading={loading}
              onSelectChange={setBatchLines}
              emptyCellRender={() => '-'}
              scroll={{ x: true, y: 'calc(100vh - 430px)' }}
              rowSelection={{
                type: 'checkbox' as RowSelectionType,
                disabledRowKeys: (relatedSelectData || []).map(v => v[rowKey]),
                selectedRowKeys: [
                  ...new Set([...(relatedSelectData || []).map(v => v[rowKey]), ...batchLines.map(v => v[rowKey])]),
                ],
              }}
            />
          </>
        )}
      </Modal>
    </>
  )
}

// 关联数据的弹窗 - POLine
const RelatedDataPoLineModal = (props: { onlyBtn?: boolean }) => {
  const columns: ColumnsType<defs.InvoiceMatchOrderLineDto> = [
    {
      dataIndex: 'orderId',
      title: '订单号-行号',
      width: 200,
      align: 'left',
      fixed: 'left',
      render: (v, record) => <PoPageLink orderId={v}>{record.orderLineId}</PoPageLink>,
    },
    {
      dataIndex: 'goodsName',
      title: I18N.auto.shangPinMingCheng,
      width: 200,
      align: 'left',
      render: v => EllipsisRes(v || '-'),
    },
    {
      dataIndex: 'quantity',
      title: I18N.auto.shuLiang,
      width: 140,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'unTaxMoney',
      title: I18N.auto.jinEHanBuShui,
      width: 160,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
    {
      dataIndex: 'tax',
      title: I18N.auto.shuiE,
      width: 120,
      align: 'right',
      render: (_, record) =>
        record.tax === 0 ? (
          record.tax
        ) : record.tax !== undefined ? (
          <ListMoneyView value={{ amount: record.tax, currency: record.remainedMoney?.currency }} />
        ) : (
          '-'
        ),
    },
    {
      dataIndex: 'unTaxRemainedMoney',
      title: I18N.auto.shengYuKeKaiPiao,
      width: 210,
      align: 'right',
      fixed: 'right',
      render: (val: defs.MoneyDto, record) => {
        return <ListMoneyView value={val} />
      },
    },
    {
      dataIndex: 'remainedMoney',
      title: '剩余可开票金额（含税）',
      width: 210,
      align: 'right',
      fixed: 'right',
      render: (val: defs.MoneyDto, record) => {
        return <ListMoneyView value={val} />
      },
    },
  ]

  return (
    <RelatedDataModal
      type="POLine"
      tableDataApi={apiService.settlementQueryMatchOrderLines}
      fetchRelatedApi={apiService.settlementConvertOrderLine2MatchTree}
      columns={columns}
      {...props}
    />
  )
}

// 关联数据的弹窗 - GR
const RelatedDataGrModal = (props: { onlyBtn?: boolean }) => {
  const columns: ColumnsType<defs.InvoiceMatchGrDto> = [
    {
      dataIndex: 'orderLineId',
      title: I18N.auto.dingDanHangHao_2,
      width: 200,
      align: 'left',
      fixed: 'left',
      render: v => <PoPageLink orderId={v} linePin />,
    },
    {
      dataIndex: 'goodsName',
      title: I18N.auto.shangPinMingCheng,
      width: 200,
      align: 'left',
      render: v => EllipsisRes(v || '-'),
    },
    {
      dataIndex: 'quantity',
      title: I18N.auto.shuLiang,
      width: 140,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'unTaxMoney',
      title: I18N.auto.jinEHanBuShui,
      width: 160,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
    {
      dataIndex: 'tax',
      title: I18N.auto.shuiE,
      width: 120,
      align: 'right',
      render: (_, record) =>
        record.tax === 0 ? (
          record.tax
        ) : record.tax !== undefined ? (
          <ListMoneyView value={{ amount: record.tax, currency: record.remainedMoney?.currency }} />
        ) : (
          '-'
        ),
    },
    {
      dataIndex: 'grNo',
      title: I18N.auto.yanShouDingDan,
      width: 200,
      align: 'left',
    },
    {
      dataIndex: 'createdAt',
      title: I18N.auto.yanShouShiJian,
      width: 190,
      align: 'left',
      render: val => formatDateTime(val),
    },
    {
      dataIndex: 'unTaxRemainedMoney',
      title: I18N.auto.shengYuKeKaiPiao,
      width: 210,
      align: 'right',
      fixed: 'right',
      render: (val: defs.MoneyDto, record) => {
        return <ListMoneyView value={val} />
      },
    },
    {
      dataIndex: 'remainedMoney',
      title: '剩余可开票金额（含税）',
      width: 210,
      align: 'right',
      fixed: 'right',
      render: (val: defs.MoneyDto, record) => {
        return <ListMoneyView value={val} />
      },
    },
  ]

  return (
    <RelatedDataModal
      type="GR"
      tableDataApi={apiService.settlementQueryMatchGRs}
      fetchRelatedApi={apiService.settlementConvertGr2MatchTree}
      columns={columns}
      {...props}
    />
  )
}

const StepOne = (props: { changeStep: () => void; onClose: () => void }) => {
  const { changeStep, onClose } = props
  const { supplierInfo } = useSelector(state => state.account)
  const fetchAttachmentUrl = useInvoiceFormData(state => state.fetchAttachmentUrl)
  const form = useInvoiceFormData(state => state.form)
  const [stepBtnDisabled, setStepBtnDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  const cancelFn = () => {
    setStepBtnDisabled(false)
    setLoading(false)
  }

  //识别成功后执行
  const getOcrTaskResult = async (params: { id: string }) => {
    const data = await apiService.settlementOcrGetOcrTaskResult(params)
    const dataList = data?.detailList?.map(item => {
      return {
        ...omit(item, 'currency'),
        id: `line${Date.now().toString(36) + Math.random().toString(36).substr(2)}`,
      }
    })

    form.setFieldsValue({
      openDate: dayjs(data?.invoiceDate), //后端返回字符串,前端转化为了计算日期
      ...pick(data, [
        'number',
        'code',
        'checkSum',
        'taxMoney',
        'tax',
        'unTaxMoney',
        'currency',
        'settlementCompanyCode',
        'supplierId',
      ]),
    })
    useInvoiceFormData.setState({
      fetchRelatedDataParams: {
        currency: data?.currency,
        supplierId: supplierInfo.id,
        settlementCompanyCode: data?.settlementCompanyCode,
      },
      lines: dataList || [],
      truthCheckingStatus: data?.truthCheckingStatus,
      subType: data?.subType,
      yqInvoiceKey: data?.yqInvoiceKey,
      yqInvoiceId: data?.yqInvoiceId,
    })
    console.log(data, '识别结果')
    changeStep()
  }

  const submitPublicBagPolling = async (params: { id: string }) => {
    try {
      const res = await apiService.settlementOcrGetOcrTaskStatus(params)
      if (res?.status === 'RUNNING') {
        return { run: true }
      }
      if (res?.status === 'FAIL') {
        Message.error('OCR识别失败')
        return { run: false }
      }
      if (res?.status === 'SUCCESS') {
        return {
          run: false,
          handleOk: getOcrTaskResult,
        }
      }
    } catch (err) {
      cancelFn()
      return { run: false }
    }
    return { run: false }
  }

  const [doPolling, cancelPolling] = usePolling<{ id: string }>(submitPublicBagPolling, 3000, cancelFn)

  const beforChangeStep = async () => {
    const { type, attachment } = form.getFieldsValue()
    if (type === bizEnums.StInvoiceTypeEnum.CHINA) {
      console.log(form.getFieldsValue())
      setStepBtnDisabled(true)
      setLoading(true)
      //创建识别任务
      const taskId = await apiService.settlementOcrCreateOcrTask({ fileId: attachment.id, fileName: attachment.name })
      if (taskId) {
        //轮询接口
        doPolling({ id: taskId })
      }
    } else {
      changeStep()
    }
  }

  const typeChange = (v: any) => {
    useInvoiceFormData.setState({ inChina: v === bizEnums.StInvoiceTypeEnum.CHINA })
    form.setFieldValue('type', v)
  }

  const attaOnChange = (e: any) => {
    setStepBtnDisabled(!e)
    if (e?.id && e?.name) {
      fetchAttachmentUrl(e.id, e.name)
    }
  }

  useRCWatch(() => {
    const isChina = isChinaCountryCode(supplierInfo.countryCode)
    typeChange(isChina ? bizEnums.StInvoiceTypeEnum.CHINA : bizEnums.StInvoiceTypeEnum.OVER_SEA)
  }, [supplierInfo])

  return (
    <div className={bem('so')}>
      {loading && (
        <div className={bem('so-loading')}>
          <Spin tip={<span className={bem('so-loading-info')}>OCR识别中...</span>} />
        </div>
      )}
      <div className={bem('so-content', { loading: loading })}>
        <DataFormItem
          className={bem('so-label')}
          w100
          label={I18N.auto.faPiaoLeiXing}
          initialValue={bizEnums.StInvoiceTypeEnum.OVER_SEA}
          name="type"
          required>
          <Radio.Group options={bizEnums.StInvoiceTypeEnum.values()} onChange={e => typeChange(e.target.value)} />
        </DataFormItem>
        <DataFormItem className={bem('so-label')} w100 label={I18N.auto.faPiaoFuJian} name="attachment" required>
          <FileUpload
            fill
            dataId=""
            limit={1}
            type="draggable"
            className={bem('upload')}
            onChange={attaOnChange}
            tip={I18N.auto.zhiChiPNG}
            accept=".jpg,.png,.jpeg,.pdf,.ofd"
            requestUploadFile={apiService.settlementConfigRequestUploadFile}
            completeUploadFile={apiService.settlementConfigCompleteUploadFile}
            getDownloadUrl={v => apiService.settlementConfigGetAttachmentDownloadUrl({ fileId: v.fileId })}
          />
        </DataFormItem>
      </div>

      <div className={bem('action')}>
        <div />
        <div>
          <Button onClick={onClose} className={bem('action-cancel')}>
            {I18N.auto.quXiao}
          </Button>
          <Button type="primary" disabled={stepBtnDisabled} onClick={beforChangeStep}>
            {I18N.auto.xiaYiBu}
          </Button>
        </div>
      </div>
    </div>
  )
}

const StepTwo = (props: {
  changeStep: () => void
  onClose: () => void
  doSave: () => void
  onDelete: () => Promise<void>
}) => {
  const { changeStep, onClose, doSave, onDelete } = props
  const resetStoreData = useInvoiceFormData(state => state.resetStoreData)
  const form = useInvoiceFormData(state => state.form)
  const attachmentShowData = useInvoiceFormData(state => state.attachmentShowData)
  const editId = useInvoiceFormData(state => state.editId)
  const detail = useInvoiceFormData(state => state.detail)
  const lines = useInvoiceFormData(state => state.lines)
  const inChina = useInvoiceFormData(state => state.inChina)
  const subType = useInvoiceFormData(state => state.subType)
  const truthCheckingStatus = useInvoiceFormData(state => state.truthCheckingStatus)
  const [loading, setLoading] = useState(false)

  const beforChangeStep = () => {
    form.resetFields()
    resetStoreData()
    changeStep()
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      await doSave()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const action = () => (
    <div className={bem('action', 'action-st')}>
      {editId ? (
        <Button danger onClick={onDelete} disabled={detail?.canDelete === false}>
          {I18N.auto.shanChu}
        </Button>
      ) : (
        <div />
      )}

      {/* 编辑的时候，如果有关联数据不需要保存操作 */}
      {!detail?.matchOrders?.length && (
        <div>
          <Button onClick={onClose} className={bem('action-cancel')}>
            {I18N.auto.quXiao}
          </Button>
          {!editId && <Button onClick={beforChangeStep}>{I18N.auto.shangYiBu}</Button>}
          <Button type="primary" icon={<Check />} loading={loading} onClick={onSubmit}>
            {I18N.auto.baoCun}
          </Button>
        </div>
      )}
    </div>
  )

  const lineChange = value => {
    useInvoiceFormData.setState({ lines: value })
  }

  const content = () => (
    <div className={bem('st-r-c')}>
      <div className={bem('st-header')}>
        <div className={bem('st-name')}>{bizEnums.StOcrInvoiceSubTypeEnum.pick(subType || '')?.name || '海外发票'}</div>
        <div>
          {/* TODO  国内发票状态处理 新建都没有状态*/}
          <Tag status={detail?.payStatus || '01'} statusMap={TagEnumMap.PayStatusStatusEnum} />
        </div>
      </div>
      {editId ? <InvoiceDetailView baseData={detail?.baseInfo || {}} /> : <StepTwoFormContent />}
      <InvoiceLine list={lines} onChange={lineChange} canEdit={!editId} />
      <RelatedContainer />
    </div>
  )

  return (
    <InvoiceInfoView
      className={bem('st')}
      action={action()}
      content={content()}
      attachmentShowData={{ ...attachmentShowData, truthCheckingStatus }}
    />
  )
}

export default (props: { editId?: string; refresh: () => void }) => {
  const { editId, refresh } = props
  const [visible, [show, hide]] = useBoolean(false)
  // 采用css-display-none的方式控制第一步第二步的展示，这样form管理数据因为试图丢失数据
  const [step, setStep] = useState<'1' | '2'>(editId ? '2' : '1')
  const [fm] = Form.useForm()
  const width = useMemo(() => (editId || step === '2' ? 1080 : 640), [editId, step])
  const relatedData = useInvoiceFormData(state => state.relatedData)
  const relatedAmount = useInvoiceFormData(state => state.relatedAmount)
  const fetchAttachmentUrl = useInvoiceFormData(state => state.fetchAttachmentUrl)
  const resetStoreData = useInvoiceFormData(state => state.resetStoreData)
  const lines = useInvoiceFormData(state => state.lines)
  const needUserConfirm = useInvoiceFormData(state => state.needUserConfirm)
  const truthCheckingStatus = useInvoiceFormData(state => state.truthCheckingStatus)
  const subType = useInvoiceFormData(state => state.subType)
  const yqInvoiceKey = useInvoiceFormData(state => state.yqInvoiceKey)
  const yqInvoiceId = useInvoiceFormData(state => state.yqInvoiceId)
  // 编辑 获取详情，附件展示、baseinfo
  const { data: detail, loading, request } = useManualService(() => apiService.settlementDetail({ invoiceId: editId! }))

  useRCUpdate(() => {
    // 当form绑定的时候再存form的值，不然会空
    if (visible && !loading) {
      useInvoiceFormData.setState({ form: fm })
    }
  }, [visible, loading])

  useRCUpdate(() => {
    if (visible && editId) {
      setStep('2')
      useInvoiceFormData.setState({ editId: editId })
      request()
    }
  }, [visible])

  useRCUpdate(() => {
    if (detail) {
      const { baseInfo = {} } = detail
      useInvoiceFormData.setState({ detail: detail })
      fetchAttachmentUrl(detail.attachment?.id!, detail.attachment?.name!)
      useInvoiceFormData.setState({
        subType: detail.subType,
        inChina: detail.type === bizEnums.StInvoiceTypeEnum.CHINA,
        fetchRelatedDataParams: {
          currency: baseInfo.currency,
          supplierId: baseInfo.supplierId,
          settlementCompanyCode: baseInfo.settlementCompanyCode,
        },
        needUserConfirm: detail.needUserConfirm,
        truthCheckingStatus: detail.truthCheckingStatus,
        lines: map(detail.lines, item => {
          return {
            id: `line${Date.now().toString(36) + Math.random().toString(36).substr(2)}`,
            ...item,
          }
        }),
      })
    }
  }, [detail])

  useRCUnmount(() => onClose())

  const changeStep = async () => setStep(prev => (prev === '1' ? '2' : '1'))

  const onClose = () => {
    hide()
    setTimeout(() => {
      fm.resetFields()
      resetStoreData()
      setStep('1')
    }, 300)
  }

  const doSave = async () => {
    try {
      await fm.validateFields()
      console.log('doSave')
      const formVal = await fm.getFieldsValue()
      if (!editId && formVal.openDate.isAfter(formVal.expiredDate)) {
        Message.warn({ content: I18N.auto.daoQiRiBuKe, closable: true })
        return
      }
      if (!editId && formVal.unTaxMoney > formVal.taxMoney) {
        Message.warn({ content: I18N.auto.buHanShuiJinE_2, closable: true })
        return
      }
      const unEqualAmount = (a: number, b: number) => Math.abs(a - b) > 10

      // 根绝关联数据的第一条数据的基准，确定是采用含税还是采用未税金额，全局根据这个来的
      const unTaxBenchmark = relatedData?.[0]?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

      const formTotalAmount = fm.getFieldValue(unTaxBenchmark ? 'unTaxMoney' : 'taxMoney') // 得看看是不是不含税金额
      if (
        formVal.type === bizEnums.StInvoiceTypeEnum.OVER_SEA &&
        ((!editId && relatedData.length && unEqualAmount(formTotalAmount, relatedAmount)) ||
          (editId &&
            relatedData.length &&
            unEqualAmount(
              (unTaxBenchmark ? detail?.baseInfo?.unTaxMoney : detail?.baseInfo?.taxMoney) || 0,
              relatedAmount
            )))
      ) {
        Modal.confirm({
          title: I18N.auto.guanLianJinEBu_2,
          okText: I18N.auto.zhiDaoLe,
          okCancel: false,
        })
        return
      }
      const params = await genSubmitDara(
        fm,
        relatedData,
        detail,
        lines,
        needUserConfirm,
        truthCheckingStatus,
        subType,
        yqInvoiceKey,
        yqInvoiceId
      )
      const res = await apiService.settlementSubmit(params)
      Message.success({ content: I18N.auto.baoCunChengGong, closable: true })
      refresh()
      onClose()
    } catch (error) {
      console.log(error)
    }
  }
  const onDelete = async () => {
    Modal.confirm({
      title: I18N.auto.shanChuHouBuKe,
      cancelButton: false,
      okButtonProps: { danger: true },
      onOk: async () => {
        try {
          await apiService.settlementDelete({ id: editId! })
          Message.success({ content: I18N.auto.shanChuChengGong, closable: true })
          refresh()
          onClose()
        } catch (error) {
          Message.error(I18N.auto.shanChuShiBai)
        }
      },
    })
  }

  return (
    <div className={bem()}>
      {editId ? (
        <Tooltip title={I18N.auto.bianJi}>
          <Button icon={<Edit size={20} />} className={bem('create-btn')} type="text-subtle-primary" onClick={show} />
        </Tooltip>
      ) : (
        <Button icon={<NoticeTextUp />} className={bem('create-btn')} type="primary" onClick={show}>
          {I18N.auto.shangChuanFaPiao}
        </Button>
      )}

      <Drawer
        title={I18N.template(I18N.auto.faPiao_2, { val1: editId ? I18N.auto.bianJi : I18N.auto.shangChuan })}
        onClose={onClose}
        className={bem('drawer')}
        width={width}
        destroyOnClose
        visible={visible}
        footer={null}>
        {loading ? (
          <div className={bem('loading')}>
            <Spin />
          </div>
        ) : (
          <DataForm form={fm} className={bem('form', { st: step === '2' })}>
            {!editId && <StepOne onClose={onClose} changeStep={changeStep} />}
            <StepTwo onClose={onClose} changeStep={changeStep} doSave={doSave} onDelete={onDelete} />
          </DataForm>
        )}
      </Drawer>
    </div>
  )
}
