import type { ReactNode } from 'react'
import { useManualService } from '@oahz/neact'
import { Eye } from '@bedrock/icons-react'
import { Button, Tooltip, Table, Typography, Switch, Drawer } from '@bedrock/components'
import { BEMGenerator, useBoolean, useRCUpdate } from '@oahz/neact-utils'
import I18N, { translate } from '@feature/i18n'
import { Catalogue, TaskShow2, User, Unlink } from '@bedrock/icons-react'
import type { ColumnsType } from '@bedrock/components/lib/Table'
import { formatDateTime } from '@feature/shared'
import { PageLoading } from '@/components/page-view'
import { PayStatus, InvoiceInfoView, InvoiceDetailView, RelatedItemHeaderView } from '@/components/invoice'
import { type defs, apiService, bizEnums } from '@/services'
import { ListMoneyView, PoPageLink, DataMoney } from '@/components/data-view'
import LinesTable from './invoice-line'
import './detail.less'

const bem = BEMGenerator('pseid')
type RelatedDataTypeProps = 'PO' | 'POLine' | 'GR'

const RelatedTypeMap = {
  PO: {
    text: I18N.auto.guanLianDingDanZheng,
    icon: <TaskShow2 size={28} />,
  },
  POLine: {
    text: I18N.auto.guanLianDingDanMing,
    icon: <Catalogue size={28} />,
  },
  GR: {
    text: I18N.auto.guanLianYanShouDan,
    icon: <User size={28} />,
  },
}

const EllipsisRes = (result: ReactNode) => {
  return (
    <Typography.Paragraph
      ellipsis={{
        rows: 1,
        showTooltip: true,
        cssEllipsis: true,
      }}>
      {result}
    </Typography.Paragraph>
  )
}

// 表单中展示的关联数据 - POLine
const POLineRelatedDataView = (props: { relatedData?: defs.InvoiceMatchOrderDto[] }) => {
  const { relatedData } = props
  const unTaxBenchmark = relatedData?.[0]?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  const columns: ColumnsType<defs.InvoiceMatchOrderLineDto> = [
    {
      dataIndex: 'orderLineIndex',
      title: 'PO行号',
      width: 100,
      align: 'left',
    },
    {
      dataIndex: 'goodsName',
      title: I18N.auto.shangPinMingCheng,
      width: 200,
      align: 'left',
      render: v => EllipsisRes(v || '-'),
    },
    {
      dataIndex: 'quantity',
      title: I18N.auto.shuLiang,
      width: 140,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'unTaxMoney',
      title: I18N.auto.jinEHanBuShui,
      width: 160,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
    {
      dataIndex: 'tax',
      title: I18N.auto.shuiE,
      width: 120,
      align: 'right',
      render: (_, record) =>
        record.tax === 0 ? (
          record.tax
        ) : record.tax !== undefined ? (
          <ListMoneyView value={{ amount: record.tax, currency: record.remainedMoney?.currency }} />
        ) : (
          '-'
        ),
    },
    {
      dataIndex: 'occupiedMoney',
      title: unTaxBenchmark ? I18N.auto.guanLianJinEBu : I18N.auto.guanLianJinEHan,
      width: 240,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
  ]

  return (
    <>
      {relatedData?.map((item, i) => (
        <div key={i} className={bem('rd-line-item')}>
          <div className={bem('rd-item-header')}>
            <RelatedItemHeaderView label={I18N.auto.dingDanHao} value={<PoPageLink orderId={item.orderId} />} />
          </div>
          <Table rowKey="orderLineId" columns={columns} data={item.lines || []} scroll={{ x: true }} />
        </div>
      ))}
    </>
  )
}
// 表单中展示的关联数据 - GR
const GRRelatedDataView = (props: { relatedData?: defs.InvoiceMatchOrderDto[] }) => {
  const { relatedData } = props
  const unTaxBenchmark = relatedData?.[0]?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  const columns: ColumnsType<defs.InvoiceMatchGrDto> = [
    {
      dataIndex: 'goodsName',
      title: I18N.auto.shangPinMingCheng,
      width: 200,
      align: 'left',
      render: v => EllipsisRes(v || '-'),
    },
    {
      dataIndex: 'quantity',
      title: I18N.auto.shuLiang,
      width: 140,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'unTaxMoney',
      title: I18N.auto.xingJinErBuHanShui,
      width: 160,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
    {
      dataIndex: 'tax',
      title: I18N.auto.shuiE,
      width: 120,
      align: 'right',
      render: (_, record) =>
        record.tax === 0 ? (
          record.tax
        ) : record.tax !== undefined ? (
          <ListMoneyView value={{ amount: record.tax, currency: record.remainedMoney?.currency }} />
        ) : (
          '-'
        ),
    },
    {
      dataIndex: 'grNo',
      title: I18N.auto.yanShouDingDan,
      width: 200,
      align: 'left',
    },
    {
      dataIndex: 'createdAt',
      title: I18N.auto.yanShouShiJian,
      width: 190,
      align: 'left',
      render: val => formatDateTime(val),
    },
    {
      dataIndex: 'occupiedMoney',
      title: unTaxBenchmark ? I18N.auto.guanLianJinEBu : I18N.auto.guanLianJinEHan,
      width: 240,
      align: 'right',
      render: (val: defs.MoneyDto) => <ListMoneyView value={val} />,
    },
  ]

  return (
    <>
      {relatedData?.map((v, index) => {
        const { lines } = v
        return (
          <div key={index}>
            {(lines || []).map((item, i) => (
              <div key={i} className={bem('rd-line-item')}>
                <div className={bem('rd-item-header')}>
                  <RelatedItemHeaderView label={I18N.auto.dingDanHao} value={<PoPageLink orderId={item.orderId} />} />
                </div>
                <Table rowKey="grNo" columns={columns} data={item.grList || []} scroll={{ x: true }} />
              </div>
            ))}
          </div>
        )
      })}
    </>
  )
}

export const RelatedDataViewShow = (props: {
  detailData: defs.InvoiceDetailDto
  needUserConfirm?: boolean
  onchangeUserConfirm?: (v: boolean) => void
  unMatch?: () => void
}) => {
  const { detailData, needUserConfirm, onchangeUserConfirm, unMatch } = props
  const { baseInfo, matchOrders, canUnMatch } = detailData
  const { currency, unTaxMoney, taxMoney } = baseInfo || {}
  const unTaxBenchmark = detailData.matchOrders?.[0]?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  if (!matchOrders?.[0]) return null
  let relatedDataType: RelatedDataTypeProps = 'PO'

  if (matchOrders[0].lines?.length) {
    relatedDataType = 'POLine'
    if (matchOrders[0].lines[0]?.grList?.length) {
      relatedDataType = 'GR'
    }
  }

  // 供应商这边不需要整单数据
  if (relatedDataType === 'PO') return null

  return (
    <>
      <div className={bem('rd')}>
        <div className={bem('rd-header')}>
          <div className={bem('rd-title')}>{RelatedTypeMap[relatedDataType].text}</div>
          <div className={bem('rd-all-amount')}>
            <div className={bem('rd-all-amount-label')}>
              {unTaxBenchmark ? I18N.auto.guanLianZongJinE : '关联总金额（含税）'}
            </div>
            <DataMoney value={{ amount: unTaxBenchmark ? unTaxMoney : taxMoney, currency: currency }} size="small" />
          </div>
        </div>
        <div>
          {/* {relatedDataType === 'PO' && <PORelatedDataView relatedData={matchOrders} />} */}
          {relatedDataType === 'POLine' && <POLineRelatedDataView relatedData={matchOrders} />}
          {relatedDataType === 'GR' && <GRRelatedDataView relatedData={matchOrders} />}
        </div>
      </div>

      {/* {canUnMatch ? (
        <Button type="checked" onClick={unMatch} icon={<Unlink />}>
          解除关联
        </Button>
      ) : null} */}

      {/* {relatedDataType === 'PO' ? (
        <div className={bem('user-confirm')}>
          <div className={bem('user-confirm-header')}>是否需用户确认</div>
          <div className={bem('user-confirm-content')}>
            <Switch
              labelOn="是"
              labelOff="否"
              checked={needUserConfirm}
              onChange={onchangeUserConfirm}
              disabled={!onchangeUserConfirm}
            />
            <span className={bem('user-confirm-label')}>选择是，将由用户在OA上确认发票和验收信息。</span>
          </div>
        </div>
      ) : null} */}
    </>
  )
}

export default (props: { invoiceData?: defs.InvoiceListDto }) => {
  const { invoiceData } = props
  const [visible, [show, hide]] = useBoolean(false)
  const {
    data: urlData,
    loading: loadingUrl,
    request: requestUrl,
  } = useManualService(apiService.settlementConfigGetAttachmentPreviewUrl)
  const {
    data: detailData,
    loading: loadingDetail,
    request: requestDetail,
  } = useManualService(apiService.settlementDetail)

  const dataList = detailData?.lines?.map(item => {
    return {
      ...item,
      quantity: Number(item.quantity),
      id: `line${Date.now().toString(36) + Math.random().toString(36).substr(2)}`,
    }
  })

  const content = (invoiceName?: string, payStatus?: string) => {
    return (
      <div className={bem('content')}>
        <div className={bem('header')}>
          <span className={bem('header-title')}>{invoiceName}</span>
          <PayStatus value={payStatus} />
        </div>
        <InvoiceDetailView baseData={detailData?.baseInfo || {}} />
        <LinesTable list={dataList} />
        {detailData ? <RelatedDataViewShow detailData={detailData} /> : null}
      </div>
    )
  }

  useRCUpdate(() => {
    if (invoiceData?.attachment?.id && visible) {
      requestUrl({ fileId: invoiceData?.attachment?.id })
    }
    if (invoiceData?.id && visible) {
      requestDetail({ invoiceId: invoiceData.id })
    }
  }, [visible])

  return (
    <div className={bem()}>
      <Tooltip title={I18N.auto.chaKan}>
        <Button icon={<Eye size={20} />} type="text-subtle-primary" onClick={show} />
      </Tooltip>
      <Drawer
        title={I18N.auto.faPiaoXiangQing}
        className={bem('drawer')}
        onClose={hide}
        width={1080}
        destroyOnClose
        visible={visible}
        footer={null}>
        {loadingDetail ? (
          <PageLoading />
        ) : (
          <InvoiceInfoView
            className={bem('drawer-view')}
            content={content(invoiceData?.attachment?.name, invoiceData?.payStatus)}
            attachmentShowData={{ name: invoiceData?.attachment?.name || '', url: urlData || '' }}
          />
        )}
      </Drawer>
    </div>
  )
}
