export default {
  weiGuanLianZhangDan: 'Factures non liées',
  yiGuanLianZhangDan: 'Factures liées',
  dingDanFaHuo: 'Expédition',
  xuQiuDan: 'Commande',
  ziCaiYuanGongCe: 'Configuration des extensions ASN (Achat autonome)',
  guoNeiBuMenZi: 'Demande d’achat autonome (Chine)',
  xiangMuFenBao: 'Sous-traitance de projet',
  fanHuiXiuGai: 'Retour pour modifier',
  yiXiaLIN: 'Il y a des offres non attribuées dans les lignes suivantes. Merci de confirmer.',
  jiaoFuXinXi: 'Informations de livraison',
  shiFouGuanLianZhang: 'Lier aux factures',
  guanLianZhangDan: 'Factures liées',
  weiQuanBuDingBiao: 'Raison de l’attribution incomplète',
  caoZuoShiJian_2: 'Heure de modification :',
  keDiShuiJinE_2: 'Le montant de taxe déductible ne peut pas excéder le montant de taxe.',
  shuiEBuKeDa: 'Le montant de la taxe ne peut pas excéder le montant hors taxe.',
  pingMuSuoFangShe: 'Ajustement d’écran',
  shiFouDiKouShui: 'Taxe déductible',
  hanShuiJinEBu: 'Montant taxes incluses = Montant hors taxes + Taxes + Fret + Autres frais',
  gaiJinEJiangZi: 'Ce montant est calculé automatiquement.',
  keDiShuiJinE: 'Montant de taxe déductible',
  qiTaFeiYong: 'Autres frais',
  tiJiaoShiBai: 'Échec de soumission',
  yiXiaGongYingShang:
    "Le fournisseur suivant a atteint les conditions suivantes : le montant total attribué l'année dernière a dépassé 500 000 CNY ou le montant attribué cette fois a dépassé 500 000 CNY. Veuillez ajouter l'état financier.",
  jinYiNianLeiJi: "Montant total attribué l'année dernière",
  qianWangTianJia: 'Ajouter',
  qianWangShengJi: 'Mettre à jour',
  gongYingShangShangWei:
    "Le fournisseur n'a pas fourni les informations des directeurs, superviseurs, cadres supérieurs, ni l'état financier.",
  shenPiXinXi: 'Informations d’approbation',
  shenPiShiFouTong: 'Si approuvé',
  qingXuanZeHeZuo: 'Veuillez sélectionner l’état de collaboration.',
  liYiChongTuShen: 'Approbation de conflit d’intérêt',
  guanLianLiuCheng: 'Processus associé',
  zhunRuYuanYin: 'Raison d’approbation',
  liChongGuanXi: 'Relation de conflit d’intérêt',
  dongJianGaoXinXi:
    "Veuillez noter qu'il existe des conflits d'intérêt entre les informations fournies par les directeurs, superviseurs et cadres supérieurs et NetEase. Un processus d'approbation des conflits d'intérêt sera mis en place. Si approuvé, la coopération pourra se poursuivre. Sinon, il sera impossible de continuer.",
  qingRenZhenTianXie:
    "La raison sera visible au responsable de groupe d'affaires et au PCC lors de l'approbation des conflits d'intérêt. Veuillez faire preuve de prudence.",
  jiXuFaQiShen: "Continuez à soumettre l'approbation",
  qingShuRuWeiShu: 'Veuillez saisir 18 chiffres ou 17 chiffres avec X.',
  gouXuanHouYiTian: 'Les contenus rédigés seront effacés une fois sélectionné.',
  dongJianGaoXinXi_2: 'Informations de la part des directeurs, superviseurs et cadres supérieurs',
  shiFouShangChuanDong:
    'Est-ce que vous voulez télécharger les informations de la part des directeurs, superviseurs et cadres supérieurs ?',
  dongJianGaoQueShi: 'Raison d’absence d’informations de la part des directeurs, superviseurs et cadres supérieurs',
  yiCunZaiFaDing: 'Ces informations fiscales sont déjà enregistrées, veuillez les modifier avant de les soumettre.',
  yiCunZaiXiangTong_8:
    'Ces informations de personnes sont déjà enregistrées, veuillez les modifier avant de les soumettre.',
  tianJiaRenYuanXin: 'Ajouter les informations des personnes',
  vALRenYuan: 'Informations des personnes{val1}',
  shiFouShangChuanCai: 'Est-ce que vous voulez télécharger les informations de l’état financier ?',
  caiWuZhuangKuangQue: 'Raison d’absence de l’état financier',
  zongZiChanWan: 'Actifs total (10k) ',
  qingGenJuYiXia:
    "Veuillez remplir les informations demandées par les directeurs, superviseurs et cadres supérieurs. L'entreprise examinera ces informations, et la coopération avec le fournisseur en sera influencée.",
  tianXieFanWeiDong:
    '1. Contenu ：directeurs, superviseurs et cadres supérieurs （eg : Directeur général, responsable financier）；',
  xinXiChaXunZhi: '2. Instructions de recherche d’informations :',
  youXianTongGuo: 'En priorité via',
  guoJiaQiYeXin: '【Système national de publication des informations sur le crédit des entreprises】',
  jiChuXinXiZhu: '→【 Informations essentielles 】→remplir selon【Informations d’employés】；',
  ruoShiJiRenYuan:
    "Si les informations réelles diffèrent de celles du système de publication (par exemple, si le changement d'employé n'est pas mis à jour), veuillez vous référer aux informations les plus récentes.",
  laiYuanFang: 'Source',
  shiBieDaoYouWei:
    'Des informations non publiées provenant de directeurs, superviseurs et cadres supérieurs ont été détectées. Veuillez vérifier avec le fournisseur si ces informations sont complètes.',
  anJiTuanZhiDu:
    'Selon les règlements de NetEase, le fournisseur a besoin de fournir : le rapport de l’état financier des dernières deux années (en incluant le bilan et le compte de résultat et le tableau des flux de trésorerie)',
  ruoCaiWuBaoGao:
    "1. Si l'état financier a été audité, veuillez fournir le rapport d'audit avec l'empreinte du cabinet d'expertise comptable.",
  ruoBaoGaoWeiJing:
    "2. Si l'état financier n’est pas audité, veuillez fournir le rapport financier original avec l’empreinte de l’entreprise.",
  qingAnYiXiaYao:
    "3. Veuillez fournir les données conformément aux exigences suivantes et assurer leur concordance avec le rapport financier. Si ces données diffèrent de celles du rapport financier, le fournisseur doit fournir une lettre de déclaration accompagnée d'un cachet officiel.",
  yingYeShouRuZhi:
    'Revenu : désigne le ‘revenu’ annuel dans le compte de résultat. Veuillez utiliser le montant sans modification.',
  liRunZongEZhi:
    'Profit : désigne le ‘profit’ annuel dans le compte de résultat. Veuillez utiliser le montant de taxe avant profit.',
  shiJiaoZiBenZhi:
    'Capital versé: le montant total des contributions effectivement versées à la société par les actionnaires (autre que le «capital social»); Veuillez vous référer au montant versé dans la colonne «capital versé» du bilan ou dans le rapport d’inspection des capitaux;',
  zongZiChanZhiZi: 'Actif : désigne le ‘revenu’ annuel dans le bilan. Veuillez utiliser le montant sans modification.',
  shiBieDaoYouWei_2:
    'Des informations l’état financier non publiées  ont été détectées. Veuillez vérifier avec le fournisseur si ces informations sont complètes.',
  gongYingShangLiYi: 'Conflit d’intérêt de fournisseur',
  dongShi: 'Directeur',
  zongJingLi: 'Directeur （général）',
  jianShi: 'Superviseur',
  caiWuFuZeRen: 'Responsable financier',
  shiFouJiXuFu: 'Continuer le paiement',
  shengYu: 'Sol.',
  qingTianXieXuQiu: 'Veuillez indiquer le département et décrire votre demande.',
  baoJiaDanJiaHan: 'Quotation unit price (tax incl.)',
  cangKuMingCheng: 'Nom d’entrepôt',
  cangKuCOD: 'Code d’entrepôt',
  tianJiaShouHuoRen: 'Destinataire ',
  souSuoShouHuoRen: 'Veuillez rechercher le nom de destinataire ou le nom d’entrepôt.',
  yiXiaXiaoJinE_3:
    "Le fournisseur de petit montant a atteint l'un des critères suivants : soit le montant d'attribution accumulé au cours de la seconde moitié de l'année a dépassé 100.000 CNY, soit le montant d'attribution pour cette fois a dépassé 100.000 CNY. Veuillez compléter les informations nécessaires pour la mise à jour du fournisseur.",
  gongYingShangMingCheng_2:
    "Nom du fournisseur / Nom abrégé / Code d'identification unifié de crédit social  / TIN / ID VMDM",
  shouXuanFuKuanFang: 'Mode de paiement',
  yinHangID: 'ID de banque',
  zhiHangID: 'ID de succursale bancaire',
  shuiWuXinXi: 'Informations fiscales',
  shuiBiaoLeiXing: 'Type de formulaire fiscal',
  naShuiZhuTi: 'Entité de contribuable',
  yiCunZaiXiangTong_7:
    'Ces informations fiscales sont déjà enregistrées, veuillez les modifier avant de les soumettre.',
  tianJiaShuiWuXin: 'Ajouter les informations fiscales',
  vALShuiWu: '{val1}Informations fiscales',
  yiZhanShangPei: 'Yizhan / Coupon de restauration',
  buMenYongChe: 'Utilisation des véhicules de département',
  buMenYongCheCai: 'Achat des véhicules de département',
  shiWu: 'Produit physique',
  gongYingShangShengJi: 'Notification de mise à jour de fournisseur',
  yiXiaXiaoJinE:
    "Le fournisseur de petit montant a atteint l'un des critères suivants : soit le montant d'attribution accumulé au cours de la seconde moitié de l'année a dépassé 100 000 CNY, soit le montant d'attribution pour cette fois a dépassé 50 000 CNY. Veuillez compléter les informations nécessaires pour la mise à jour du fournisseur.",
  jinBanNianLeiJi: "Montant d'attribution accumulé au cours de la seconde moitié de l'année",
  benCiDingBiaoJin: "Montant d'attribution pour cette fois ",
  shengJi: 'Mis à jour',
  yiXiaXiaoJinE_2:
    "`Le fournisseur de petit montant a atteint l'un des critères suivants : soit le montant d'attribution accumulé au cours de la seconde moitié de l'année a dépassé 10.000 CNY, soit le montant d'attribution pour cette fois a dépassé 10.000 CNY. Veuillez compléter les informations nécessaires pour la mise à jour du fournisseur.`",
  jiXuFaSongXun: 'Envoyer la demande de devis',
  jiXuFaSongZhao: 'Envoyer un appel d’offres',
  huiZongJinEHan_2: 'Montant total （taxes incluses）：',
  huiZongJinEHan: 'Montant total （taxes incluses）',
  heTongYuEBu_2: 'Le solde du contrat est insuffisant, ce qui rend la sélection impossible.',
  qiTaGongYingShang: 'D’autres fournisseurs ：',
  fangQiBaoJia: 'Abandonner l’offre',
  tiJiaoHouJiangJin_3: "L'étape d'approbation de l'attribution de l'offre sera activée dès la soumission.",
  xuQiuXingYiBei: 'La ligne de besoin est occupée par d’autres RFx, veuillez le vérifier.',
  pinLeiWei: 'Catégorie : {val1}',
  canKaoJiaHeJi_3: 'Prix de référence au total : {val1} {val2}',
  xiaDanFangShiWei: 'Mode de commande : {val1}',
  fenPeiJiCaiGou: 'À l’acheteur',
  congCaiGouYuan: 'De l’acheteur',
  qingXianXuanZeXia: 'Veuillez sélectionner la catégorie de commande.',
  qingShuRuDingDan_2: 'Veuillez saisir le numéro de commande / le numéro de demande OA / le nom d’article.',
  mingCheng: 'Nom :',
  oADanHaoWu_2: 'Numéro de commande OA / numéro de suivi / numéro d’expédition / nom d’article',
  oADanHaoShou_2: 'Numéro de OA / numéro de livraison / numéro de commande / numéro d’accrual / nom d’article',
  fenPei: 'Désigner',
  yanShouShangPinFu: 'Article / service de réception :',
  yanShouDian: 'Détails de réception',
  yanShouYaoQiuMing: 'Critères de réception ({val1})',
  piLiangYanShouQue: 'Confirmation de réception par lot',
  queRenXiangGuanYan: 'Confirmer que les documents de réception ont été téléchargés (s’il y en a)',
  yanShouYaoQiuMing_2: 'Critères de réception ({val1}/{val2})',
  zaiYouCeGouXuan:
    'Sélectionner "Confirmer la réception" à droite signifie que la réception est approuvée. Sinon, cela signifie que la réception n\'est pas approuvée.',
  weiWanZhengYanShou: 'Raison de réception incomplète',
  ruWeiQuanBuQue: 'Veuillez entrer la raison pour réception incomplète.',
  yanShouQueRenJie: 'Résultat de confirmation de réception',
  beiZhuXinXiDui: 'Remarque (interne)',
  dingDanHangBeiZhu: 'Note de ligne de commande (Envoyé au fournisseur)',
  beiZhuXinXiFa: 'Remarque (Envoyé au fournisseur)',
  dingDanHangFuJian: 'Pièces jointes de ligne de commande (Envoyé au fournisseur)',
  fuJianDuiNei: 'Pièces jointes (interne)',
  fuJianFaGeiGong: 'Pièces jointes de ligne de commande (Envoyé au fournisseur)',
  yanShouPingZheng: 'Justificatif de réception',
  heTongYiDaoQi:
    "Le contrat a expiré. Merci de vérifier avec le fournisseur s'il souhaite poursuivre l'expédition des articles.",
  jiXuTiJiao: 'Continuer',
  beiZhuDuiNei: 'Remarque (interne)',
  beiZhuFaGeiGong: 'Remarque (Envoyé au fournisseur)',
  qingShuRuJieSuan_2: 'Veuillez entrer le numéro de règlement, de commande, de l’OA ou de paiement.',
  yiQueRen: 'Confirmé',
  bangZhuFanKui: "Centre\nd'aide",
  bangZhunZhongXin: 'Help Center',
  dianShangZiDingDan: 'Punchout PO Line',
  dianShangFuDingDan: 'Punchout PO',
  boHuiXinXi: 'Informations de refus',
  jiangTongBuZhiO:
    "Les informations seront synchronisées avec le processus d'OA pour servir de référence à l'approbateur.",
  qing: 'Veuillez',
  tiGongKaiHuXu:
    "Veuillez fournir le permis d'ouverture de compte bancaire ou tout autre document bancaire officiel avec le cachet, et veuillez vous assurer que les informations fournies correspondent à celles renseignées.",
  tiGongZuiJinLiang:
    "Veuillez fournir les états financiers et les rapports financiers des deux dernières années, déjà audités ou fiables. En l'absence d'audit, veuillez ajouter un cachet officiel.",
  tiGongDeCaiWu:
    'L’état financier correspond au rapport financier. Le revenu = ”revenu“ de formulaire de compte de résultat, total de profit = ”total de profit“ de compte de résultat. Le capital social libéré (différent de capital social) se réfère au montant total des apports reçus des actionnaires.',
  zhaoBiaoTouFuJian: 'Pièces jointes d’appel d’offres\n(envoyer au fournisseur)',
  pingFenShiJian: 'Heure d’évaluation',
  zhengDanZhongBiaoBu:
    "Il n'est pas possible d'inviter plusieurs fournisseurs pour soumissionner sur l'ensemble de l'offre. De plus, un fournisseur ne peut pas se retirer de l'appel d'offres par ligne.",
  qingShuRuYongHu: 'Veuillez saisir le nom d’utilisateur/l’adresse courriel.',
  zhiShaoTianJiaYi_2: 'Ajouter au moins un membre',
  xuQiuShuLiang_2: 'Quantité demandée :',
  pingFenMuBan: "Modèle d'évaluation",
  cunZaiLIN:
    'Le 【{val2}】 de la ligne {val1} n’est pas invité. Vous pouvez supprimer la ligne {val3} ou soumettre à nouveau après l’invitation.',
  gongYingShangWuYou: 'Le fournisseur 【{val1}】n’a pas de sujet valide. Veuillez soumettre à nouveau après modifier.',
  jinRuShangWuPing: 'Accéder à l’évaluation commerciale',
  pingGuWeiQuanBu: "L'évaluation n'est pas encore terminée.",
  queDingYaoJinRu_2: 'Êtes-vous sûr d’accéder à l’évaluation commerciale ?',
  queRenHouJiShu:
    "Une fois la confirmation reçue, l'appel d'offres technique sera scellé, et l'évaluation commerciale commencera.",
  pingGuJieShuTi: "L'évaluation est terminée. La vérification sera activée après la soumission.",
  xuXianWanChengJi: 'Il faut compléter l’évaluation technique des appels d’offres d’abord.',
  jiShuBiaoKaiBiao_2: 'Heure de l’ouverture de l’appel d’offres technique',
  shangWuBiaoKaiBiao_2: 'Heure de l’ouverture de l’appel d’offres commercial',
  tianJiaChengGong: 'Ajouté',
  tianJiaPingGuCheng: 'Ajouter un membre d’évaluation',
  pingGuChengYuan: 'Membre d’évaluation',
  tianJiaYuanYin: 'Raison',
  yiChuChengGong: 'Supprimé avec succès ',
  yiChuPingGuCheng: 'Supprimer le membre d’évaluation',
  yiChuYuanYin: 'Raison de suppression',
  caoZuoNeiRong: 'Contenu de modification',
  caoZuoYuanYin: 'Raison de modification',
  bianGengLiShi: 'Historique de modification',
  zanWeiTianJiaPing_2: 'Membres du groupe',
  quXiaoYaoBiao: 'Annuler l’invitation',
  huiFu: 'Restaurer',
  jiShuBiaoXiaoZu: 'Les membres du groupe technique ne sont pas configurés.',
  shangWuBiaoXiaoZu: 'Les membres du groupe commercial ne sont pas configurés.',
  queDingJinRuPing: 'Êtes-vous sûr d’accéder à l’évaluation ?',
  jinRuPingBiaoHou:
    'Après accéder à l’évaluation, un courriel d’invitation sera envoyé aux membres du groupe d’évaluation.',
  kaiShiShangWuPing: 'Commencer l’évaluation commerciale',
  kaiShiJiShuPing: 'Commencer l’évaluation technique ',
  pingGuZhong: 'Évaluation en cours',
  pRShenPiZhong_3: 'En cours / renseigné',
  pRYiShenPi_3: 'Approuvé par PR / renseigné',
  weiKaiShiPingGu: 'En attente',
  jiShuBiaoPingGu: 'Évaluation technique en cours',
  jiShuMiFengZhong: "Scellage de l'appel d'offres technique",
  shangWuBiaoPingGu: 'Évaluation commerciale en cours',
  xunYuanLeiXing: 'Type de source',
  gengDuo: 'Plus',
  jieShouRen: 'Destinataire',
  yiWanChengYanShou: "La réception est terminée et l'historique est maintenant disponible.",
  xunYuanDanHao: 'ID de ticket',
  xunYuanDanChuangJian: 'Heure de création',
  qingShuRuXunYuan: 'Numéro de ticket ou numéro de l’OA',
  qingShuRuXunYuan_2: 'Veuillez saisir le numéro de ticket, le numéro de demande OA, le nom d’article.',
  xunBiJiaJingJia: 'Comparaison des offres - Enchères',
  zhaoTouBiaoJingJia: 'Appel d’offres - Enchères',
  congTiaoZhengWei: 'De {val1} à {val2}',
  chengQingDaoJiShi: 'Temps restant avant la clarification :',
  queDingJieShuCheng: 'Êtes-vous sûr de vouloir terminer la clarification ?',
  jieShuChengQingHou: 'Une fois la clarification terminée, le fournisseur ne peut plus soumettre le devis.',
  jieShuChengQing: 'Terminer la clarification',
  baoJiaZhuangTai: 'État de devis',
  chengQingZhuangTai: 'État de clarification',
  xinZengChengQingGong: 'Ajouter un fournisseur de clarification',
  baoJiaChengQing: 'Clarification de devis',
  xuanZeChengQingGong: 'Sélectionner un fournisseur de clarification',
  faSongChengQing: 'Envoyer la clarification',
  chengQingQueRen: 'Confirmer la clarification',
  tiJiaoHouJiangXiang: 'Après la soumission, un courriel d’invitation sera envoyé au fournisseur.',
  chengQingJieZhiShi: 'Date limite de clarification',
  qingXuanZeRiQi: 'Veuillez sélectionner la date',
  chengQingYuanYin: 'Raison de clarification',
  xiangQing: 'Détails',
  baoJiaChengQingJi: 'Historique de clarification de devis',
  chengQingFaQiShi: 'Heure de soumission de clarification : {val1}',
  chengQingXiangQing: 'Détails de clarification',
  weiChengQing: 'Non clarifié',
  zhouShengShi: 'État/province/ville',
  bianWei: '{val1} {val2} → {val3}',
  weiShuiBaoJia: 'Devis (Hors taxes) :',
  hanShuiBaoJia: 'Devis (Taxes incluses) :',
  faPiaoLeiXing_2: 'Type de facture :',
  bianWei_2: '→',
  shuiLu_2: 'Taux de taxation :',
  daiShenPi: 'En attente d’approbation',
  queDingTiJiaoBao: 'Êtes-vous sûr de sauvegarder ?',
  yiXiaYongHuWei: "Les utilisateurs ci-dessous n'ont pas terminé les certifications d'achat.",
  shuRuYouXiangHou: 'Saisissez l’adresse courriel pour obtenir automatiquement',
  zhengZaiHuoQu: 'Obtention en cours',
  yiRenZheng: 'Certifié',
  weiRenZheng: 'Pas certifié',
  gaiYongHuWeiWan: "Cet utilisateur n'a pas terminé les certifications d'achat.",
  heGuiRenZhengZhuang: "État de certifications d'achat",
  queRenChongXinFa: 'Êtes-vous sûr de renvoyer un courriel de notification ?',
  yanShouTongZhi: 'Notification de réception ',
  gongYingShangZhaoHui: 'Récupération des fournisseurs',
  boHuiZhuCe: 'Rejeter l’inscription ',
  boHuiYuanYin: 'Raison du rejet',
  qingShuRuBoHui: 'Veuillez entrer le raison du rejet.',
  queDingShenPiTong: 'Êtes-vous sûr d’approuver le processus ?',
  shenPiTongGuoHou:
    "Une fois approuvé, le compte administrateur du fournisseur sera remplacé par le compte actuel. Le compte d'administrateur précédent sera désactivé et deviendra un compte secondaire.",
  yaoQingXinXi: 'Informations de l’invitation',
  xinGuanLiYuanYou: 'Nouvelle adresse courriel d’administrateur',
  yaoQingShiJian: 'Heure d’invitation',
  zhuCeXinXi: 'Informations d’inscription ',
  zhuCeYouXiang: 'Adresse courriel d’inscription ',
  wangYiCeDuiJie: 'Nom de l’agent d’achat de NetEase',
  wangYiCeDuiJie_2: 'Adresse courriel de l’agent d’achat de NetEase',
  zhuCeShuoMing: 'Description d’inscription',
  gongYingShangChuangJian: 'Création de fournisseur',
  zuHuPeiZhi: 'Configuration de locataire',
  zuHu: 'Locataire ',
  xiTongSheZhi: 'Système',
  chaKanCaoZuoJi: 'Voir l’historique de modification',
  gengGaiJiLu: 'Modifier l’historique',
  gengGai: 'Modifier',
  zhiShaoTianJiaYi: 'Ajouter au moins un administrateur',
  bianJiChengGong: 'Modifié',
  xinZengZuHu: 'Ajouter un locataire',
  bianJiZuHu: 'Modifier le locataire',
  zuHuMingCheng: 'Nom du locataire',
  zuHuCOD: 'Code de locataire',
  oALaiYuan: 'Source d’OA',
  zuHuShuoMing: 'Description de locataire ',
  zuHuGuanLiYuan: 'Administrateur',
  qingShuRuZuHu: 'Veuillez entrer le nom/code du locataire.',
  qingNinBuChongYin: 'Veuillez fournir la province et la ville pour les informations du compte bancaire.',
  ziGuanRuKu: 'Entrée en stock',
  yanShouShenPi: 'Approbation de réception',
  yuanGongYanShou: 'Réception d’employé',
  jinZhanXiangQing: 'Détails de progression',
  yanShouJinZhanXiang: 'Détails de progression de réception',
  daiYanShouJinE_5: 'Montant en attente de réception  (taxes incluses)',
  ziGuan: 'Responsable d’actifs',
  daiZhiXingRen: 'Opérateur en attente',
  yanShouJinZhan: 'Progression de réception',
  jiZhunJia: 'Prix de référence',
  weiShuiJinE: 'Montant hors taxes',
  keFaHuoJinE_2: 'Montant de commandes prêtes à être expédiées (hors taxes)',
  keFaHuoJinE_3: 'Montant de commandes prêtes à être expédiées (taxes incluses)',
  benCiFaHuoJin_2: 'Montant de cette expédition (hors taxes) ',
  benCiFaHuoJin_3: 'Montant de cette expédition (taxes incluses)',
  xuQiuJiZhunJia: 'Prix de référence :',
  dingBiaoZongEWei: 'Montant total attribué (hors taxes) ',
  dingBiaoZongEHan: 'Montant total attribué (taxes incluses)',
  daiYanShouJinE_3: 'Montant en attente de réception (hors taxes) ',
  daiYanShouJinE_4: 'Montant en attente de réception (taxes incluses)',
  benCiYanShouJin_4: 'Montant pour réception actuelle (hors taxes)',
  benCiYanShouJin_5: 'Montant de réception actuelle (taxes incluses)',
  weiShui_2: '(Hors taxes)',
  hanShui_2: '(Taxes incluses)',
  benCiYanShouJin_6: 'Montant pour réception actuelle (hors taxes) :',
  weiShui_3: '{val1} (hors taxes)',
  hanShui_3: '{val1} (taxes incluses)',
  xuQiuDanXiaHai: 'La demande inclut des informations ci-dessous.',
  xiaDanJinEWei: 'Montant de commande (hors taxes) ',
  xiaDanJinEHan: 'Montant de commande (taxes incluses)',
  daiKaiBiao: 'En attente de l’ouverture',
  yanShouJinEWei: 'Montant en attente de réception (hors taxes) ',
  hanShuiDanJia: 'Prix unitaire avec taxes',
  guanLianZongJinE_2: 'Montant total lié (taxes incluses)',
  shengYuKeKaiPiao_2: 'Solde restant (taxes incluses)',
  guanLianJinEHan: 'Montant lié (taxes incluses)',
  buTongJiZhunJia: 'Le prix de référence est différent donc il est impossible de lier.',
  oaLineNoBuYiZhiBuKe: 'Impossible de créer car le source de ticket d’OA est différent.',
  pingFenManFenYiBai: 'Note (sur 100)',
  queDingYaoFangQi: "Êtes-vous sûr de vouloir abandonner l'évaluation ?",
  fangQiHouBuKe: 'Si vous abandonnez, il ne sera pas possible de réévaluer.',
  wuLiHaiGuanXi: "Attestation d'absence de conflit d'intérêts",
  zhaoBiaoPingShiXiao: '(Attestation d’appel d’offres)',
  benRen: 'Je,  ',
  zuoWeiWangYiJi: ', employé chez NetEase',
  biaoShuBianHaoZhao: ", identifié par le numéro de soumission {val1} et concernant le sujet d'appel d'offres {val2}",
  zhaoTouBiaoXiaoZu: ", en tant que membre de l'équipe d'appel d'offres, déclare que je :",
  yiZunShouZhongHua:
    '1. respecte les règles de la <Loi sur les marchés publics du gouvernement de la République populaire de Chine> et n’a pas de conflit d’intérêts avec le fournisseur ',
  wuLiHaiGuanXi_2: 'de ce projet.',
  erZaiPingShenGuo: '2. au cours de l’évaluation, respecte strictement ',
  zhongHuaRenMinGong_6: "<Loi chinoise sur les appels d'offres et les soumissions de la République populaire de Chine>",
  zhongHuaRenMinGong_7: '<Loi sur les marchés publics du gouvernement de la République populaire de Chine>',
  jiYouGuanFaLu:
    "et les règles de l'égalité, de la justice, de l'objectivité et de l'efficacité. Je respecte également les principes moraux et éthiques liés au travail. Je m'engage à remplir consciencieusement les tâches d'évaluation tout en respectant les accords de confidentialité.",
  yYYYNian: 'Le D M YYYY',
  queRenQianShu: 'Confirmer la signature',
  fangQiPingGu: "Abandonner l'évaluation",
  zaiCiFaSong: 'Renvoyer',
  zaiCiFaSong_2: 'Renvoyer ({val1})',
  qingShuRuKaiBiao: 'Veuillez entrer le mot de passe d’ouverture.',
  queRenKaiBiao: 'Confirmer l’ouverture de l’appel d’offres ',
  weiShouDaoYanZheng: 'Vous n’avez pas reçu le code de validation ?',
  jiShuBiaoMiFeng: "Verrouillage de l'appel d'offres technique",
  shangWuBiaoMiFeng: "Verrouillage de l'appel d'offres commercial",
  huoQuKaiBiaoMi: 'Obtenir le mot de passe d’ouverture',
  dianJiHouKaiBiao:
    "Cliquez sur l'icône ci-dessus pour recevoir le mot de passe dans votre boîte mail. Il est valide pendant 5 minutes.",
  kaiBiaoHouJiangZi:
    'Après l’ouverture, la notification de l’évaluation sera envoyée automatiquement aux membres ci-dessous :',
  pingBiaoZuYuan: 'Membres de l’équipe d’évaluation',
  jiShuPingGuZhong: 'Évaluation technique ',
  zhaoBiaoShiJianHao: 'Numéro de sujet',
  pinPai_3: 'Marque :',
  peiZhi_3: 'Attribut :',
  beiZhu_2: 'Note : {val1}',
  beiZhu_3: 'Note :',
  shangPinFuWuMing_2: 'Nom de service/article',
  xuQiuXingFuJian: 'Pièces jointes de la ligne',
  jiShuBiaoFuJian: 'Pièces jointes de l’appel d’offres technique',
  shangWuBiaoFuJian: 'Pièces jointes de l’appel d’offres commercial',
  baoJiaShiJianU: 'Heure de l’offre (UTC+08:00)',
  touBiaoLieBiao: 'Liste des soumissions',
  zhaoBiaoXuQiuFu: 'Pièces jointes de détails de l’appel d’offres',
  pingBiaoZuZhang: 'Chef de l’équipe d’évaluation',
  heJiaXinXi: 'Informations de tarification',
  jiangBenJinE: 'Montant de réduction des coûts',
  dingBiaoFuJian: 'Pièces jointes d’attribution',
  feiBiao: "Annulation de l'appel d'offres",
  pingFenZuiYou: 'Meilleure évaluation',
  paiMing: 'Classement',
  zongHePingFen: 'Évaluation globale',
  baoJiaZongJinE: 'Montant total de l’offre (taxes incluses)',
  pingFenRen: 'Évaluateur',
  pingFen: 'Note',
  pingFenFuJian: 'Pièces jointes d’évaluation',
  jiShuBiao: 'Appel d’offres technique',
  shangWuBiao: 'Appel d’offres commercial',
  pingFenQuanZhong: 'Pourcentage de l’évaluation :',
  mingXi: 'Détails',
  pingFenMingXi: 'Détails de l’évaluation',
  zongHeFenShu: 'Scores globaux',
  baoJiaWeiShui: 'Devis (Hors taxes)',
  baoJiaMingXi: 'Détails de devis',
  touBiaoXiangQing: 'Détails de soumission',
  weiPingFen: 'Non évalué',
  yiFangQi: 'Abandonné',
  yiPingFen: 'Évalué',
  daiChengQing: 'En attente de réponse',
  yiChengQing: 'Répondu',
  pingGuGuanLi: 'Gestion d’évaluation ',
  jiShuPingFenQuan: 'Pourcentage de l’évaluation technique',
  shangWuPingFenQuan: 'Pourcentage de l’évaluation commerciale',
  jiShuBiaoPingFen: "Modèle d'évaluation technique des appels d'offres",
  xiaoZuChengYuan: 'Membres du groupe',
  shangWuBiaoPingFen: "Modèle d'évaluation commerciale des appels d'offres",
  pingBiaoChengYuanQian:
    "Les membres de l'équipe d'évaluation ont signé une déclaration attestant l'absence de conflit d'intérêts.",
  zuoFeiZhaoBiao: "Annulation de l'appel d'offres",
  zhaoBiaoLiuChengJie: "Le processus d'appel d'offres est clos et le fournisseur ne peut plus soumettre d'offre.",
  zuoFeiChengGong: 'Annulé',
  feiBiaoYuanYin: "Raison d’annulation de l'appel d'offres",
  zhaoTouBiaoShiJian: "Nom de sujet d’appel d'offres",
  zhaoTouBiao: "Appel d'offres",
  yuFuKuan: 'Montant de paiement anticipé',
  heJiaTiJiaoShen: 'Heure de soumission de tarification',
  dingBiaoZongJinE_2: 'Montant d’attribution total',
  pingFenBuNengWei: 'L’évaluation est requise.',
  shangWuPingGuZhong: 'Évaluation commerciale en cours',
  touBiaoKaiShiShi: "Heure de début de l'offre",
  touBiaoJieShuShi: 'Heure de fin de l’offre',
  jiShuBiaoKaiBiao: 'Responsable de l’ouverture de l’appel d’offres technique',
  shangWuBiaoKaiBiao: 'Responsable de l’ouverture de l’appel d’offres commercial',
  jiShuPingGu: 'Évaluation technique ',
  shangWuPingGu: 'Évaluation commerciale',
  jiShuPingFenQuan_2: 'Pourcentage de l’évaluation technique :',
  shangWuPingFenQuan_2: 'Pourcentage de l’évaluation commerciale :',
  queDingYaoFaSong: 'Êtes-vous sûr de vouloir envoyer l’évaluation ?',
  pingGuTiJiaoHou:
    "Une fois l'évaluation soumise, le processus d’attribution d’offres sera automatiquement déclenché et ne pourra pas être modifié.",
  pingGu: 'Évaluation ',
  faSongPingGu: 'Envoyer l’évaluation',
  zanWuQuanXian: 'Accès refusé',
  dingBiaoZhuangTai: 'État d’attribution',
  caoZuoRen_4: 'Opérateur :',
  shiJian_2: 'Heure :',
  yuanYin: 'Raison :',
  dingBiaoShenPiShi: 'Heure d’approbation d’attribution',
  shiFouFeiBiao: 'Annulation d’appel offres',
  baoJiaZongEHan: 'Montant total de l’offre (taxes incluses)',
  queDingYaoJinRu: 'Êtes-vous certain de vouloir accéder à la tarification ?',
  dangQianCunZaiWei: "Il y a {val1} membres qui n'ont pas encore terminé l'évaluation.",
  tiJiaoHouJiangJin: 'Après la soumission, le processus de tarification sera automatiquement déclenché.',
  zhaoBiaoXiangQing: 'Détails d’appel d’offres {val1}',
  jinRuHeJia: 'Accéder à la tarification',
  jiShuKaiBiaoRen: 'Responsable de l’ouverture de l’appel d’offres technique',
  shangWuKaiBiaoRen: 'Responsable de l’ouverture de l’appel d’offres commercial',
  baoJiaJinEWei_2: 'Montant de l’offre (hors taxes)',
  zhaoBiaoXuQiuFu_2: 'Pièces jointes de détails d’appel d’offres (envoyées au fournisseur)',
  yingBiaoQueRenHan: 'Lettre de confirmation de soumission',
  baoMiXieYi: 'Accord de confidentialité',
  gongYingShangXiangGuan: 'Pièces jointes de fournisseurs',
  shenFen: 'Identité',
  pingFenZhuangTai: 'État d’évaluation',
  wuFaTiJiao: 'Impossible de soumettre',
  zanWeiTianJiaPing: "Veuillez ajouter le chef de l'équipe d'évaluation avant de soumettre.",
  queDingYaoTiJiao_2: "Êtes-vous certain de vouloir soumettre l'appel d'offres ?",
  tiJiaoHouJiangJin_2: 'Après avoir soumis, le processus de soumission d’offres sera automatiquement lancé.',
  chuangJianZhaoBiao: 'Créer un appel d’offres',
  faSongZhaoBiao: 'Envoyer un appel d’offres',
  zhaoBiaoXuQiuFu_3: 'Pièces jointes de détails d’appel d’offres (envoyées au fournisseur)',
  shiJianSheZhi: 'Paramètres des processus',
  shiQu_2: 'Fuseau horaire',
  faSongYingBiaoQue: 'Envoyer la lettre de confirmation de l’appel d’offres',
  queRenHanMuBan: 'Modèle de lettre de confirmation de l’appel d’offres',
  faSongBaoMiXie: 'Envoyer l’accord de confidentialité',
  baoMiXieYiMo: 'Modèle de l’accord de confidentialité',
  zhongBiaoGongGaoFan: 'Visibilité de l’annonce d’attribution',
  tiJiaoHouKaiShi: 'Commencer après soumission',
  dingBiaoTiJiaoHou_2:
    "Après avoir soumis la demande d’attribution, l'étape d'approbation sera automatiquement lancée.",
  dingBiaoShenQingShi: 'Heure de demande d’attribution',
  shiFouDingBiao_2: 'Attribution d’offres',
  pingBiaoRen: 'Évaluateur',
  jiShuPingBiaoZu: 'Chef de l’équipe d’évaluation des offres techniques',
  shangWuPingBiaoZu: 'Chef de l’équipe d’évaluation des offres commerciales',
  jinRuPingBiaoShi: 'Échec d’accéder à l’évaluation',
  jiShuPingBiaoZu_2: 'Le chef de l’équipe d’évaluation des offres techniques n’est pas ajouté.',
  shangWuPingBiaoZu_2: 'Le chef de l’équipe d’évaluation des offres commerciales n’est pas ajouté.',
  jinRuPingBiao: 'Accéder à l’évaluation',
  jiangYaoQingPingBiao: 'Le chef de l’équipe d’évaluation sera invité pour démarrer le processus d’évaluation.',
  xuQiuLieBiao: 'Liste des produits souhaités',
  daiYingBiao: 'En attente de confirmation',
  daiTouBiao: 'En attente de soumission',
  yiTouBiao: 'Soumissionné  ',
  yiQiBiao: 'Annulé',
  wenTiBiaoTi: 'Titre de question',
  tiChuGongYingShang: 'Fournisseurs',
  wenTiChuangJianRen: 'Soumis par',
  wenTiTiJiaoShi: 'Soumis à',
  chengQingGongGaoBiao: "Titre de l'annonce de clarification",
  chengQingRen: 'Personne de clarification',
  chengQingGongGao: 'Annonce de clarification',
  zhaoBiao: "Appel d'offres",
  touBiao: 'Soumission',
  heJia: 'Tarification',
  touBiaoZhong: 'Soumission en cours',
  yiKaiBiao: 'Ouverture d’appel d’offres',
  pRShenPiZhong: 'Approbation PR en cours / Soumission en cours',
  pRYiShenPi: 'Approuvé par PR / Soumission en cours',
  pRShenPiZhong_2: 'Approbation PR en cours / Ouverture d’appel d’offres',
  pRYiShenPi_2: 'Approuvé par PR / Ouverture d’appel d’offres',
  wuGongGao: 'Pas d’annonces',
  canYuCaiGouShang: 'Visible par fournisseurs qui participent',
  gongYingShangMenHu: 'Visible par tous les fournisseurs',
  zuZhangFangQiPing: 'Le chef d’équipe a abandonné l’évaluation.',
  zuZhangWeiQianShu: "Le chef d’équipe n’a pas signé l’attestation d'absence de conflit d’intérêts.",
  zuZhangWeiKaiBiao: 'Le chef d’équipe n’a pas ouvert l’appel d’offres.',
  zuChangYiKaiBiao: 'Le chef d’équipe a ouvert l’appel d’offres.',
  zuYuanWeiQianShu: "Les membres d’équipe n’ont pas signé l’attestation d'absence de conflit d’intérêts.",
  zuYuanYiQianShu: "Les membres d’équipe ont signé l’attestation d'absence de conflit d’intérêts.",
  zuYuanYiFangQi: 'Les membres d’équipe ont abandonné.',
  zuZhang: 'Chef',
  zuYuan: 'Membres',
  weiQianShuLiHai: "n’ont pas signé l’attestation d'absence de conflit d’intérêts.",
  qingShuRuBiaoTi: 'Veuillez entrer le titre.',
  qingShuRuZiXun: 'Veuillez entrer le contenu de consultation.',
  chengQingHuiFu: 'Réponse de clarification',
  weiTianXieNeiRong: "Aucun contenu n'a été saisi.",
  huiFuZiXun: 'Réponse',
  chengQingZiXun: 'Consultation de clarification',
  ziXunXiangQing: 'Détails de consultation',
  jiaZaiZhong: 'Téléchargement...',
  sheZhiYunFei: 'Fret ',
  dianShangDiZhi: 'Adresse d’expédition',
  zhouShengDiQu: 'Région',
  chengShi: 'Ville',
  qingXuanZeRenYuan: 'Veuillez sélectionner l’utilisateur',
  qingXianSouSuoZai: 'Veuillez rechercher puis sélectionner',
  xinJianDianShangDi: 'Créer une adresse d’expédition',
  bianJiDianShangDi: 'Modifier une adresse d’expédition',
  xinJianDiZhiCheng: 'Adresse créée',
  bianJiDianShangDi_2: 'Adresse modifiée',
  xinJianDiZhi: 'Créer une adresse',
  dianShangPingTai: 'Plateforme de commerce électronique',
  dianShangPingTaiBu: 'La plateforme de commerce électronique ne peut pas être vide.',
  diZhiBianMa: 'Code d’emplacement ',
  diZhiBianMaBu: 'Le code d’emplacement ne peut pas être vide.',
  shouHuoRenBuNeng: 'Le destinataire ne peut pas être vide.',
  qingShuRuZhengQue_4: 'Veuillez entrer un moyen de contact correct.',
  fenPeiLeiXing: 'Type d’attribution',
  shouHuoRenXingMing: 'Destinataire',
  qingShuRuDiZhi: 'Veuillez saisir le code d’emplacement/le nom.',
  yaMaXun: 'Amazon',
  daiEr: 'Dell',
  shiTaiBo: 'Staples',
  jingDong: 'JD.com',
  wuRenYuanShiYong: 'Aucun',
  quanYuanShiYong: 'Tous',
  buFenYuanGong: 'Certains employés',
  zuiDuoShuRuGe: 'Veuillez saisir un maximum de 20 caractères.',
  yanShouJianJie: 'Résumé',
  yanShouJinEChao:
    'Si le montant de la réception dépasse la limite, une approbation du CEO est requise. 【Le résumé, la description et le justificatif de la réception】 seront envoyés via POPO pour approbation.',
  tiJiaoZhong: 'Soumission en cours',
  tiJiaoShenPi: 'Soumettre',
  benCiYanShouJin_3: 'Montant de réception actuelle (taxes comprises) :',
  benCiYanShouJin_2: 'Montant de réception actuelle / montant de réception en attente',
  benCiYanShouShu_2: 'Quantité de réception actuelle / quantité de réception en attente',
  qiPaiJiaWeiShuiDanJia: 'Prix de départ (prix unitaire hors taxes)',
  yuFuJinEXuDaYu: 'Le montant prépayé doit être supérieur à 0.',
  zhiFuFangShiWeiYuFu: 'Le mode de paiement est prépayé et doit être lié au contrat.',
  tiaoZhengBuFuDanJia: 'Ajustement de prix (prix unitaire)',
  xinZengBaoJia_2: 'Nouveau devis : {val1}',
  diYiMing: 'Premier prix',
  diErMing: 'Deuxième prix',
  diSanMing: 'Troisième prix',
  anJinE: 'Selon le montant',
  anBiLi: 'Selon la proportion',
  buXian: 'Sans restriction',
  qiPaiJia: 'Prix de départ ',
  diYiMingJiaGe: 'Premier prix',
  jianGe: 'Écart',
  fenZhong: 'Minute',
  zuiHou: 'Dans les dernières',
  fenZhongNeiChuXian: "minutes, un délai sera activé en cas d'apparition d'un nouveau premier prix.",
  yanShi: 'Délai',
  wuYanShi: 'Aucun délai',
  kaiQiZaiXianJing: 'Enchère en ligne',
  shiFouZhengDanZhong: 'Est-ce que l’ensemble est remporté ?',
  jingJiaMoShi: 'Mode d’enchère',
  tiaoJiaBuFuZheng: 'Niveau de modification de prix (ensemble)',
  tiaoJiaBuFuB: 'Niveau de modification de prix (par ligne)',
  jingJiaGuiZe: 'Règles d’enchère ',
  baoJiaJianGeShi: 'Écart de temps de devis',
  ziDongYanShiShi: 'Prolongation automatique du délai',
  gongYingShangBaoJia_2: 'Affichage des offres de fournisseur',
  anJinE_2: 'Selon le montant（{val1} {val2}） ',
  anBiLi_2: 'Selon la proportion（{val1} %）',
  fenZhongNeiChuXian_2:
    'Si un nouveau premier prix est ajouté pendant {val1} minutes, il y aura un délai de {val2} minutes.',
  baoJiaShiJian: 'Heure de devis',
  jiaGe: 'Prix',
  gengGaiBaoJiaBian: 'Changement de devis : De {val1} à {val2}',
  caoZuoXing: 'Ligne de modification',
  weiCanYu: 'Non participé',
  baoJiaJinEHan_2: 'Montant de devis (Taxes comprises) :',
  shiFouDingBiao: 'Si attribué :',
  wuBaoJiaJinE: 'Pas de devis',
  zanWuGongYingShang: 'Il n’y a pas de classement de fournisseurs.',
  baoJiaLiShi: 'Historique de devis',
  gongYingShangPaiMing: 'Classement de fournisseurs',
  canYuGongYingShang_2: 'Le nombre de fournisseurs participants est insuffisant.',
  kaiQiZaiXianJing_2: "Pour activer une enchère en ligne, la participation d'au moins deux fournisseurs est requise.",
  yingShiBaoJiaFan: 'Enchère anglaise (enchère inversée)',
  qianSanMingBuYun: 'Les trois principaux devis ne proposent pas le même prix.',
  suoYouBaoJiaXu: 'Tous les devis doivent être supérieurs au prix le plus élevé.',
  suoYouPaiMingBu: "Le classement des devis ne permet pas d'accepter le même prix.",
  juJieShuHaiSheng: 'Il reste ',
  tiaoZhengHeTongJieSuan:
    "Changer l'entreprise de règlement, le fournisseur ou la devise entraînera la suppression de la liste de prix, veuillez confirmer.",
  chaiFenDingBiaoMing: 'Division de liste d’attribution',
  xingVALDing_2:
    'Ligne {val1} : le montant total de la ligne de liste d’attribution n’est pas identique au montant d’attribution.',
  duiWaiLiPin: 'Cadeau externe',
  banGongYongFeiI: 'Utilisation au bureau (logiciel et matériel informatique non géré par IT)',
  zhuanShou: 'Revente',
  banShuSongShen: "Soumission à l'approbation de l'autorité de régulation",
  banGongYongIT: 'Utilisation au bureau - utilisation publique pour IT',
  banGongYongYeWu: 'Utilisation au bureau - utilisation publique pour affaires',
  guoNeiITRuan: 'Demande et achat des matériels informatiques et des logiciels',
  zhuCeDiWei: 'Le lieu d’inscription de {val1} est {val2}.',
  cunZaiDuoZhong: 'Il existe plusieurs types, {val1}.',
  cunZaiDuoKaHao: '{val1} (Il existe plusieurs numéros de carte, {val2})',
  yuFuJinE_2: '{val1} (Montant de prépaiement :',
  zhuangTaiWeiBianHua: 'État inchangé',
  zhuangTaiXiuGaiCheng: 'Modifié avec succès ',
  xiuGaiZhuangTai: 'État de modification',
  guanLianWangYiJi: 'Lier aux toutes les entreprises de NetEase',
  yiGuanLian: 'Lié :',
  guanLianGongSi: 'Entreprise liée',
  rFXXingBao: 'RFx - Ligne - Devis',
  peiZhiGuiGe: 'Configuration / Taille',
  xuanZeRFX: 'Sélectionner un RFx',
  qingShuRuRF: 'Veuillez entrer le numéro de RFx',
  ruoTianLeShuLiang:
    'Si la quantité est déjà remplie, le montant incluant la taxe sera calculé automatiquement comme suit : quantité * prix unitaire (taxes incluses). Si seul le montant {val1}(taxes incluses), la quantité ne peut pas être calculée automatiquement.',
  xingHao_2: 'Modèle',
  shuLiangBuKeWei: 'La quantité ne peut pas être 0.',
  bianJiJiaGeQing: 'Modifier la liste de prix',
  tianJiaJiaGeQing: 'Ajouter la liste de prix',
  laiYuanXinXi: 'Informations de source',
  mingXiXinXi: 'Détails',
  jiaGeYouXiaoQi: 'Validité de prix',
  xuJieChuDuiYing: 'Il faut dissocier la facture correspondante avant de pouvoir modifier.',
  xuJieChuDuiYing_2: 'Il faut dissocier la facture correspondante avant de pouvoir supprimer.',
  jiaGeQingDan: 'Liste de prix',
  zanWuNeiRong: 'Aucun contenu',
  cRPHeTong: 'Contrat CRP',
  nETBU: 'Contrat Netbuy',
  heTongLeiXing: 'Type de contrat',
  heTongYuE: 'Montant de contrat',
  qiShiRiQi: 'Date de début',
  zhongZhiRiQi: 'Date de fin',
  tianJiaHeTong: 'Ajouter le contrat',
  woFangZhuTi: 'Entité interne',
  qingShuRuHeTong: 'Veuillez saisir le numéro / le titre de contrat.',
  shengXiaoRiQi: 'Date d’entrée en vigueur',
  qingXuanZeShengXiao: "Veuillez sélectionner la date d'entrée en vigueur.",
  heTongZhuangTaiFei: "Vous ne pouvez pas sélectionner car le contrat n'est pas encore en vigueur.",
  biZhongBuYiZhi: 'Impossible de sélectionner en raison de devise différente',
  heTongYuEBu: 'Le solde de contrat n’est pas suffisant. Veuillez le vérifier avant de soumettre.',
  dingDanHanShuiJin: 'Montant incluant la taxe de commande :',
  heTong: 'Contrat',
  dangQianKeYongYu: 'Solde disponible :',
  qingJianChaHouZai: 'Veuillez vérifier avant de soumettre.',
  jiaGeKuangJiaHe: 'Contrat de cadre de prix',
  xuXuanZeYouJia: 'Veuillez sélectionner le contrat actif avec la liste de prix et un solde positif.',
  xuanZeJiaGeQing: 'Sélectionner la liste de prix',
  xiaDanFangShiLei: 'Le type de moyen de commande est incorrect, veuillez vérifier avant de soumettre.',
  baoJiaQingDan: 'Liste de devis',
  dingBiaoShuLiangBi: 'La quantité d’attribution doit être supérieure à 0.',
  dingBiaoJinEBi: 'Le montant d’attribution doit être supérieur à 0.',
  shangPinFuWuMing: "Nom du service ou de l'article",
  yiYouYunFeiBu: 'Le fret ne peut pas être ajouté car il est déjà présent.',
  chuangJian: 'Créer ',
  gaiHeTongYiCun: 'Ce contrat existe déjà. Souhaitez-vous accéder à la page de détail pour le modifier ?',
  qieHuanHeTongBi:
    'La liste de prix sera supprimée si vous changez la devise du contrat. Êtes-vous sûr de vouloir continuer ?',
  jiaGeQingDanBu: 'La liste de prix de peut pas être vide.',
  chuangJianHeTong: 'Créer le contrat ',
  qingXuanZeZhengQue:
    'Veuillez choisir le fournisseur/contrat/entreprise approprié. La modification entraînera la suppression des contenus suivants.',
  daoQiCaoZuo: 'Choix de renouvellement',
  xuQianTiXing: 'Notification de renouvellement',
  buXuQian: 'Non renouvellement',
  woFangZhuTiJie: 'Entité interne (entreprise de règlement)',
  faBuRiQi: 'Date de publication',
  youXiaoQi: 'Validité',
  danJuLeiXing: 'Type de facture',
  guanLianShiJian: 'Heure de liaison',
  guanLianJiaGeQing: 'Liste de prix liée - numéro',
  guanLianJiaGeQing_2: 'Liste de prix liée - titre d’article',
  heTongXiangQing: 'Détails de contrat - {val1}',
  faBuShiJian: 'Heure de publication',
  heTongYuEHe: 'Solde de contrat = Montant total de contrat - Montant attribué',
  xuQian: 'Renouvellement',
  guanLianRFX: 'RFx/PO lié',
  rFXShenPi_3: 'Total des RFx approuvées, des commandes et des bons de commande non annulés',
  heTongBianJi: 'Modification de contrat - {val1}',
  dingDanJinEHan_2: 'Montant de commande (fret inclus)',
  shangPin_2: 'Article : {val1}',
  jiaGeQingDanDe: 'Le montant total des attributions dans la liste de prix dépasse le montant total alloué.',
  rFXDingBiao: 'Montant attribué de RFx :',
  dangQianKeYongYu_2: 'Solde disponible actuellement ',
  yiYouJiaGeQing: 'Impossible d’ajouter la liste de prix existante',
  kuangJiaXiaDan: 'Commande cadre',
  daiShengXiao: 'Application en cours',
  yiGuoQi: 'Expiré',
  kuangJiaHeTong: 'Contrat de cadre',
  danXiangMuHeTong: 'Contrat de projet unique',
  yiBanHeTong: 'Contrat général',
  shouGong: 'Manuellement',
  oADanHaoDing_2: 'Numéro de OA / Numéro de commande / Numéro de suivi / Numéro d’expédition',
  zhiFuJinE: 'Montant de paiement',
  oADanHaoFa: 'Numéro de OA / Numéro d’expédition / Numéro de réception / Numéro de commande / Numéro d’accrual',
  zhiFuZhong: 'Paiement en cours',
  yuGuJinE: 'Montant estimé',
  shenPiJuJueYuan: 'Raison de rejet',
  shenQingFuKuanJin: 'Il existe une différence entre le montant de paiement demande et le montant de facture.',
  shenQingFuKuanJin_2: 'Il n’existe pas de différence entre le montant de paiement demande et le montant de facture.',
  shenQingFuKuanJin_3: 'Montant de demande (Taxes Incluses)',
  faPiaoJinEHan: 'Montant de facture (Taxes Incluses)',
  chaYiJinEHan: 'Montant de différence (Taxes Incluses)',
  jinEWeiShui: 'Montant (hors taxes)',
  faPiaoXinXi: 'Information de facture',
  benCiShenQingJin: 'Montant de cette demande (Taxes Incluses)',
  fuKuanXinXi: 'Information de paiement',
  lianXingHangHao: 'Code CNAPS',
  benCiShiJiFu: 'Montant de paiement \n (Taxes Incluses)',
  qiTaFuJian: 'D’autres pièces jointes',
  daiJiTiRuZhang: 'Comptabilité d’accrual en attente',
  yiGuiDang: 'Archivé',
  yiCheHui: 'Retiré',
  fuKuanDan: 'Bon de paiement',
  gongYingShangBaoJia: 'Devis de fournisseur',
  qingShuRuShangPin: 'Veuillez entrer le nom de l’article',
  heJiaCeLue: 'Stratégie de tarification',
  fuKuanShenQing: 'Demande de paiement',
  guanLianXinXi: 'Informations associées',
  shiFouXuYongHu: 'Une confirmation par l’utilisateur est-elle requise ?',
  xuanZeShiJiangYou:
    'Si vous sélectionnez oui, l’utilisateur devra confirmer la facture et les informations de réception sur OA.',
  dingDanHaoHangHao: 'Numéro de commande - numéro de ligne',
  oCRShiBie: 'La reconnaissance de l’OCR a échoué.',
  oCRShiBie_2: 'Reconnaissance de l’OCR en cours ...',
  haiWaiFaPiao: 'Facture internationale',
  queRenHouJiangJie:
    'Une fois confirmé, les informations PO liées à la facture seront supprimées et la liaison sera annulée. Vous pourrez ensuite lier de nouvelles informations à la facture.',
  jieChuGuanLian: 'Annuler la liaison',
  yanZhenZhuangTai: 'État de cohérence',
  chuangJianFang: 'Créateur',
  qingShuRuFaPiao_2: 'Veuillez saisir le numéro de facture / le numéro de PO / le fournisseur',
  shangPinMingChengHuo: 'Nom d’article / nom de service',
  guiGeXingHao: 'Spécifications',
  danWei: 'Unité',
  xinZengXing: 'Ajouter la ligne',
  shanChuXing: 'Supprimer la ligne',
  faPiaoXing: 'Ligne de facture',
  zhiHangMingCheng_2: 'Succursale bancaire :',
  lianXingHangHao_2: 'Code CNAPS :',
  zhangHuMingCheng_2: 'Titre de compte bancaire :',
  yinHangZhangHao_2: 'Numéro de compte bancaire :',
  shouXuFeiChengDan_2: 'Frais de transaction pris en charge par :',
  xuanZeKaiHuHang: 'Sélectionner la banque d’ouverture',
  xuanZeYinHangXin: 'Sélectionner les informations de banque',
  chuangJianFuKuanShen: 'Créer la demande de paiement',
  baoXiaoRen: 'Demandeur',
  shenQingFuKuanShuo: 'Description de demande de paiement',
  danGeWenJianXiao: 'Chaque dossier doit être inférieur à 100 Mo.',
  fuKuanShenQingXiang: 'Détails de demande de paiement : {val1}',
  jieSuanGongSiGong:
    'Il est impossible de régler les factures de l’entreprise ensemble en raison de leur provenance de fournisseurs et entreprises de paiement différents, de numéros de contrat distincts et de devises différentes. Veuillez confirmer.',
  qingShuRuFaPiao_3: 'Veuillez saisir le numéro de facture / numéro PO',
  jieSuanDanHao: 'Numéro de règlement',
  fuKuanJinEHan: 'Montant de demande (Taxes Incluses)',
  fuKuanDanHao: 'Numéro de bon de paiement',
  zhiFuJinEHan: 'Montant payé (Taxes Incluses)',
  qingShuRuJieSuan: "Veuillez entrer le numéro de règlement, de commande ou de l'OA.",
  tian_8: '0 jour',
  caiGouFang: 'Acheteur',
  weiYanZhen: 'Non vérifié',
  yanZhenChengGong: 'Réussi',
  yanZhenShiBai: 'Échoué',
  zengZhiShuiZhuanYong: 'Facture de TVA spéciale',
  zengZhiShuiDianZi: 'Facture électronique de TVA spéciale',
  zengZhiShuiPuTong: 'Facture de TVA ordinaire',
  zengZhiShuiDianZi_2: 'Facture électronique de TVA ordinaire',
  zengZhiShuiDianZi_3: 'Facture électronique de TVA ordinaire (frais de péage)',
  quKuaiLianDianZi: 'Facture de la Blockchain',
  zengZhiShuiPuTong_2: 'Facture de TVA ordinaire (rouleau)',
  jiDongCheXiaoShou: 'Facture unifiée de vente de véhicules automobiles',
  tongYongJiDaFa: 'Facture imprimée générale (papier)',
  tongYongJiDaFa_2: 'Facture imprimée générale (électronique)',
  hangKongXingChengDan: 'Itinéraire de vol',
  huoChePiao: 'Billet de train',
  keYunQiChePiao: 'Billet de bus',
  chuanPiao: 'Billet de bateau',
  chuZuChePiao: 'Billet de taxi',
  dingEFaPiao: "Facture d'un montant fixe",
  diDiXingChengDan: 'Itinéraire de DiDi',
  haiWaiINV: 'Facture internationale',
  shi_2: 'heure(s)',
  fen: 'minute(s)',
  miao: 'seconde(s)',
  jieSuanGongSiZhu: 'Le site d’inscription de l’entreprise de règlement est',
  gongYingShangZhuCe_2: 'Le site d’inscription du fournisseur est',
  wuYunFei: 'Sans fret',
  wangYiGongSiBan: 'NetEase, Inc. Tous droits réservés ©1997-{val1}',
  oADanHao_2: "Numéro de l'OA :",
  xunJiaBeiZhu: 'Note',
  faGeiGongYingShang: '(Envoyé au fournisseur)',
  xunJiaFuJian: 'Pièces jointes',
  dingBiaoJinEChao: "Le montant d'attribution dépasse le prix de référence.",
  dingBiaoJinEDi: "Le montant d'attribution est inférieur au prix de référence.",
  xunJiaShuLiang: 'Quantité de demandes de devis',
  gongJiJiaBaoJia: '{val1} fournisseurs au total',
  shouQiQuanBu: 'Masquer',
  zhanKaiQuanBu: 'Afficher',
  gongYingShangZhuCe: "Le site d'inscription du fournisseur est {val1}.",
  jinEBuWanZheng: "Le montant n'est pas complet.",
  buTongOADan: "Impossible de demander le devis en cas de numéro de l'OA différent",
  qingShuRuXingMing_2: "Nom/ID d'employé/Adresse courriel",
  daiHeJia: 'En attente de demander le devis',
  xunJiaGongYingShang: 'Fournisseur de devis',
  kaiBiaoHeJia: 'Demande de devis en attente',
  pinPai_2: 'Marque :',
  peiZhi_2: 'Configuration :',
  shiFouZhongBiao: 'Si attribué',
  shiFouZuiYouJia: 'Si le meilleur prix',
  zhongBiaoShuLiang: "Quantité d'attribution",
  zhongBiaoJinEHan: "Montant d'attribution (Taxes Incluses)",
  baoJiaDanJiaWei: 'Prix unitaire hors taxe (Hors taxes)',
  weiBaoQiYue: 'Garantie (mois)',
  shouQuanQiRuanJian: 'Durée de licence (logiciel/mois)',
  jiaoFuZhouQiZi: 'Durée de livraison (jour calendaire)',
  canKaoDanJiaHan: 'Prix unitaire de référence (Taxes Incluses)',
  canKaoZongJiaHan: 'Prix total (Taxes Incluses)',
  shenPiJieDian: 'Étape',
  shenPiRen: 'Approbateur',
  caiGouXuQiuShen: "Informations de demande d'achat",
  xuQiuShenQingRen: 'Demandeur',
  xuQiuShenQingRen_2: 'Département de demandeur',
  xuQiuShenQingYuan: 'Motif de la demande',
  xuQiuShenPiFu: 'Pièces jointes',
  xuQiuYiShenPi: 'Approbateur',
  dingJiaDingBiaoShen: 'Approbation de tarification / attribution',
  xunJiaDingBiaoHao_2: 'Référence de renseignement :',
  dingBiaoShenPiBiao: 'Titre',
  canKaoJiaJinE: 'Prix de référence',
  dingBiaoZongJinE: "Montant d'attribution total (Taxes incluses)",
  dingBiaoShuoMing: "Description d'attribution",
  dingJiaDingBiaoMing: 'Détails de tarification / attribution ',
  dingJiaDingBiaoFu: 'Pièces jointes de tarification / attribution',
  qingZaiDingBiaoMing: "Voir plus d'informations dans les détails d'attribution",
  dingBiaoShenQing: "Demande d'attribution - {val1}",
  xunYuanFangShi: 'Moyen de sourcing',
  dingBiaoCeLue: "Stratégie d'attribution",
  heJiaFuJian: "Pièces jointes de l'évaluation de devis",
  heJiaShenQingTi: "Date de soumission de demande de l'évaluation",
  canKaoJiaHeJi_2: 'Prix de référence au total (Taxes Incluses)',
  qingZaiBaoJiaXin: "Voir plus d'informations dans les détails de devis",
  renYuanXinXi: 'Informations personnelles',
  shiYongRenYouXiang: "Adresse courriel de l'utilisateur",
  shiYongRenSuoShu: "Département de l'utilisateur",
  shouHuoRenYouBian: 'Code postal de destinataire',
  shouQuanQi: 'Durée de licence',
  qiWangJiaoHuoRi: 'Date de livraison souhaitée',
  caiGouXinXi: "Informations d'achat",
  baoJiaXinXi: 'Informations de devis',
  fuJianXinXi: 'Pièces jointes',
  baoJiaYouXiaoQi: 'Validité de devis',
  gongHuoZhouQiZi: 'Durée de livraison (jour calendaire)',
  dingDanSheZhi: 'Paramètres de commande',
  faSongDingDanZhi: 'Envoyer la commande au fournisseur',
  ziDongFaSongDing_2: 'Envoyer automatiquement la commande',
  baoJiaHeJia: 'Devis & évaluation',
  chuangJianBaoJiaDan: 'Créer un devis',
  xunYuanCeLue: 'Stratégie de sourcing',
  dingJiaCeLue: 'Stratégie de tarification',
  tiJiaoHeJiaHou: "Afficher après soumission de l'évaluation",
  queDingYaoShanChu_2: 'Êtes-vous sûr de supprimer la ligne ?',
  zanWeiXuanZeGong: 'Veuillez sélectionner un fournisseur',
  dingBiaoShenQingTi: "Heure de soumission de demande d'attribution",
  kaiBiaoShiJian: "Heure de l'ouverture des offres",
  heJiaRen: 'Évalué par',
  baoJia_3: 'Devis',
  faSongXunJia: 'Envoyer la demande de devis',
  queRenYaoFaSong: "Êtes-vous sûr d'envoyer automatiquement la demande de devis au fournisseur ?",
  baoJiaJieZhiShi: 'Date limite de devis',
  shiQu: 'Fuseau horaire :',
  xunJiaShiJian: 'Heure de demande de devis',
  faChuXunJiaHou: 'Afficher après soumission de demande',
  canYuGongYingShang: 'Fournisseur',
  lianXiRen_3: 'Contact : {val1}',
  xunJiaShuLiang_2: 'Quantité de demandes de devis : ',
  xunJiaXiangQing: 'Informations de demandes de devis {val1}',
  baoJiaXiangQing_2: 'Informations de devis {val1}',
  quXiaoXunJia: 'Annuler la demande de devis',
  quXiaoChengGong: 'Annulé',
  xunJiaFaQiRen: 'Demandeur de devis',
  tiaoZhengBaoJiaShi: "Modifier l'heure de demande de devis",
  tiaoZhengHouKeNeng: "Changer l'heure pourrait affecter le devis du fournisseur",
  dangQianYiYouJia: '{val1} fournisseurs ont terminé le devis',
  tiaoZhengShiJian: 'Heure de modification',
  queDingTiJiaoKai: "Êtes-vous sûr de lancer l'ouverture des offres ?",
  cunZaiBuFenBao: "Certains devis n'ont pas été reçus. Êtes-vous sûr de vouloir procéder à l'ouverture des offres ?",
  baoJiaTiJiaoHou:
    "Après la soumission du devis, le processus d'évaluation sera automatiquement déclenché. Le fournisseur ne pourra pas modifier le devis pendant cette phase.",
  baoJiaDaoJiShi: 'Temps restants avant la date limite du devis :',
  xunJiaJieZhiShi: 'Date limite de demande de devis',
  yiBaoJiaGongYing: 'Fournisseurs ayant soumis le devis : {val1}',
  weiBaoJia: 'Non soumis',
  yiBaoJia: 'Soumis',
  buCanYu: 'Quitter',
  caiGouFangShi: "Moyen d'achat",
  zhiDingCaiGouLei: "Type de l'achat particulier",
  yuanYinShuoMing: 'Raison',
  juJue: 'Refuser',
  tongYi: 'Approuver',
  shenHeXinXi: "Informations d'approbation ",
  shenHeShuoMing: "Description d'approbation ",
  caiGouYuanDaiLi: 'Inscription par acheteur',
  gongYingShangZiZhu: 'Inscription par fournisseur',
  faSongYaoQingCheng: 'Invitation envoyée',
  yaoQingGongYingShang: "Invitation d'inscription des fournisseurs",
  faQiYaoQing: 'Envoyer une invitation',
  tiJiaoChengGongHou: 'Après la soumission, un courriel de notification sera automatiquement envoyé au fournisseur.',
  qingXuanZeFuWu: 'Veuillez sélectionner une catégorie de service.',
  zhangHaoGuanLi: 'Gestion des comptes',
  xianXiaBaoJia: 'Devis hors ligne',
  chuangJianXunJia: 'Créer une demande de devis',
  yiZhongBiao: 'Attribué',
  weiZhongBiao: 'Non attribué',
  xianXiaBaoJiaHe: 'Hors ligne - devis & évaluation',
  xianXiaDingBiao: 'Hors ligne - attribution',
  xianShangXunJia: 'En ligne - demande de devis',
  xianShangBaoJia: 'En ligne - devis',
  xianShangHeJia: 'En ligne - évaluation',
  xianShangDingBiao: 'En ligne - attribution',
  xunBiJia: 'Comparaison des offres',
  zhiJieCaiGou: 'Achat immédiat',
  zhiDingCaiGou: 'Achat désigné',
  zuiDiJiaFa: "Méthode du prix d'évaluation le plus bas",
  zongHePingFenFa: 'Méthode de notation globale',
  zuiGaoJiaFa: "Méthode du prix d'évaluation le plus haut",
  dingBiaoShenPiZhong: 'Attribution en approbation',
  baoJiaZhong: 'Devis en cours',
  heJiaZhong: 'Évaluation en cours',
  shenPiZhongBaoJia: 'En approbation / devis en cours',
  yiShenPiBaoJia: 'Approuvé / devis en cours',
  shenPiZhongHeJia: 'En approbation / évaluation en cours',
  yiShenPiHeJia: 'Approuvé / évaluation en cours',
  feiITWuZi: 'Non matériel informatique ',
  xingZhengZhuanYongGong: 'Administration exclusive - achat public',
  weiXiuYongPin: 'Matériel de réparation',
  yuanGongFuLi: 'Avantages salariaux',
  qingJieLei: 'Nettoyage',
  shiTangBaoXiang: 'Cabine',
  canYinShangPei: 'Coupon de restauration',
  changGuiCaiGou: 'Achat quotidien',
  gongYingShangXuYue: 'Renouvellement de fournisseur',
  cunZaiJiShuBi:
    "Les cas nécessitant une technologie existante : tels que garantir la cohérence des projets d'achat existants ou les exigences de service complémentaires, les exigences de continuité technologique, ou d'autres situations où les produits ou services ne peuvent pas être remplacés. Le responsable technique des affaires (qui ne peut être inférieur au responsable du département de niveau deux ou au responsable technique le plus élevé de l'entreprise) est responsable de confirmer la nécessité de cette technologie. En cas de conflit d'opinions avec le département des achats, une évaluation par des experts avant l'achat peut être envisagée.",
  youTeShuBaoMi: 'Quand il y a confidentialité spécifique ou restrictions de service',
  keHuZhiDingDe:
    'Pour les fournisseurs désignés par client, il est obligatoire de saisir les instructions claires de clients telles que dossiers en papier, historique de messages et la confirmation de chef de département primaire associé.',
  shouDaoCaiGouShi:
    "En raison de restrictions de durée d'achat et de région, il n'y a qu'un seul fournisseur compétent pour fournir le produit et le service.",
  zhengFuHangYeZhun:
    "Fournisseur désigné par gouvernement, norme de l'industrie, organisation de qualification, loi ou réglementation",
  shuDianFaPiaoZhuan: 'Facture électronique (spéciale)',
  shuDianFaPiaoPu: 'Facture électronique (ordinaire)',
  haiWaiCaiGou: 'Achat international ',
  guoNeiZongHeCai: 'Achat général en chine',
  xingJinErBuHanShui: 'Montant de ligne hors taxes',
  jinEHanBuShui: 'Montant hors taxes',
  yiJuJue: 'Refusé',
  qingShuRu: 'Veuillez saisir',
  qingShuRuFaPiaoJinE: 'Veuillez saisir le montant de facture',
  chuangJianLuYueJi: 'Créer un projet de livraison',
  meiYue: 'Le',
  ri: 'de chaque mois',
  geYue_2: 'mois',
  faPiaoHaoMa: 'Numéro de facture',
  faPiaoDaiMa: 'Code de facture',
  jiaoYanMa: 'Code de validation',
  kaiPiaoRiQi: 'Date de facturation',
  zhangQiTian: 'Délai de recouvrement (jours)',
  daoQiRi: 'Date de règlement',
  shuiE: 'Taxes',
  buHanShuiJinE: 'Montant hors taxes',
  faPiaoBiZhong: 'Devise de facture',
  yanCongXiang: 'Yan Congxiang',
  weiZhiFu: 'Non payé',
  yiZhiFu: 'Payé',
  buFenZhiFu: 'Partiellement payé',
  jieSuan: 'Règlement',
  jieSuanGuanLi: 'Gestion de règlement',
  faPiao: 'Facture',
  shangChuanFaPiao: 'Téléverser la facture',
  luYueXinXi: 'Informations de livraison',
  jiaoFuFangShi: 'Mode de livraison',
  luYueRiQi: 'Date de livraison',
  jiaoFuZhouQiMei: 'Période de livraison (chaque mois)',
  luYueJiHuaXiang: 'Projet de livraison {val1}',
  meiYueRi: 'Le {val1} de chaque mois',
  geYueMeiYueRi: '{val1} mois ; Le {val2} de chaque mois',
  guanLianDingDanZheng: 'Commande liée (commande globale)',
  guanLianDingDanMing: 'Commande liée (détails)',
  guanLianYanShouDan: 'Bon de réception lié',
  gaiFaPiaoZanWei: "La facture n'est liée à aucune autre facture. Veuillez choisir le moyen de liaison si nécessaire :",
  guanLianZongJinE: 'Montant total hors taxes :',
  jinEHanShui: 'Montant taxes incluses',
  shengYuKeKaiPiao: 'Solde restant dû hors taxes',
  guanLianJinEBu: 'Montant lié hors taxes',
  xingJinE: 'Montant de ligne',
  yanShouDingDan: 'Réception de commande',
  guanLian: 'Lier',
  queDing: 'Confirmer',
  qingJianChaBiZhong: 'Veuillez vérifier la devise, l’entreprise de paiement, le fournisseur etc.',
  qingShuRuCaiGou: 'Numéro de commande ou numéro de l’OA',
  faPiaoFuJian: 'Pièces jointes',
  zhiChiPNG: "Prend en charge les formats d'image png, jpg, jpeg ;",
  daoQiRiBuKe: 'La date de règlement ne peut pas être antérieure à la date de facturation. Veuillez la modifier.',
  buHanShuiJinE_2:
    'Le montant hors taxes ne peut pas dépasser le montant toutes taxes comprises. Veuillez le modifier.',
  guanLianJinEBu_2: 'Le montant lié (hors taxes) doit être égal au montant de facture (hors taxes).',
  shanChuHouBuKe: 'Impossible de restaurer après la suppression',
  faPiao_2: '{val1} factures',
  shangChuan: 'Téléverser',
  faPiaoXiangQing: 'Détails de facture',
  guanLianDanJu: 'Facture liée',
  zhiFuZhuangTai: 'État de paiement',
  guanLianFuKuanDan: 'Bon de paiement lié',
  faPiaoDaoQiRi: 'Date de règlement',
  faPiaoID: 'ID de facture',
  shangChuanShiJian: 'Heure de téléversement',
  qingShuRuFaPiao: 'Veuillez saisir le numéro de facture et le fournisseur.',
  fuWuLeiJiaoFu: 'Service',
  zhongGuoDaLuFa: 'Factures (Chine continentale)',
  feiZhongGuoDaLu: 'Factures (Non Chine continentale)',
  yiCiXingJiaoFu: 'Livraison forfaitaire',
  meiYueGuDingJiao: 'Livraison forfaitaire par mois',
  meiYueAnXuJiao: 'Livraison mensuelle sur demande',
  shiJiLvYueJinE: 'Montant de livraison',
  shiJiLvYueShuLiang: 'Quantité de livraison',
  lvYueDanHangHao: 'Bon de livraison - numéro de ligne',
  lvYueXinXi: 'Information de livraison({val1})',
  buTongShouHuoQu: 'Impossible de demander le devis en cas de région différente',
  zhiFuFangShiCunZaiYu: 'Impossible de combiner les commandes car le mode de paiement est prépayé',
  tuiJianRenXingMing: 'Référent (Nom + Adresse courriel)',
  guanLiYuanShiZhi: "L'administrateur désigne l'employé responsable du système de portail des fournisseurs.",
  gongYingShangLaiYuan: 'Source des fournisseurs',
  caiGouXunYuan: "Source d'achat",
  qiTaRenTuiJian: "Référence par d'autres ",
  yiTiJiao: 'Soumis',
  dingBiaoJinEWeiLing: 'Veuillez entrer le montant attribué avant de soumettre.',
  shuRuYouWu: 'Erreur',
  biTian: 'Requis',
  biZhongBuYiZhiBuKe: 'Impossible de créer en raison de devise différente',
  gaiShuJuBuCunZai: 'Cette donnée n’existe pas.',
  caiGouFuJian: 'Pièces jointes',
  xunJiaDanShenPiZhong: 'Approbation en cours',
  ziDongChuangJianDing: 'Créer automatiquement une commande',
  baoJiaJinEHan: 'Montant de devis (Taxes comprises)',
  dingDanJinEWeiShui: 'Valeur de commande (Hors Taxes)',
  weiBaoQiXianYue_2: 'Garantie (mois)',
  shouQuanQiXianYue_2: 'Type de permis (mois)',
  xuQiuBeiZhu: 'Commentaire',
  xuQiuFuJian: 'Pièces jointes',
  daiShouHuo: 'Réception en attente',
  xianShiLiuChengTu: 'Afficher le diagramme de processus',
  shenPiBuTongGuo: 'Rejeté',
  jinDuFanKui: 'Feed-back',
  zheSuanRenMinBi: 'Montant en CNY (taxes incluses)',
  xuQiuYuYuanYin: 'Motif d’achat',
  zhiFuFangShiBu: 'Impossible de créer car le moyen de paiement est différent',
  qingShuRuZhengQue_3: 'Veuillez saisir une adresse courriel valide ',
  caiGouXuQiuXiang: 'Détails de commande {val1}',
  kaPian: 'Carte',
  lieBiao: 'Liste',
  dengDaiShiChangChao: 'Plus de 3 jours d’attente',
  qingShuRuDingDan: 'Numéro de demande ou numéro de OA',
  dingDanXiangQing: 'Détails de commande',
  baoJiaHangHao: 'Ligne de devis',
  ziDongFaSongDing: 'Envoyer automatiquement la commande au fournisseur',
  bianGeng: 'Modifier',
  chaKanBianGeng: 'Voir les modifications',
  chaKanBianGeng_2: 'Voir les modifications-{val1}',
  fuWuLei_2: 'Service ',
  shenQingJieShuRi: 'Date de fin ',
  yongJiuYouXiao: 'Perpétuel',
  shiQuShuoMing: 'Description de fuseau horaire',
  gaiShiJianShiAn: 'Il s’agit de l’heure locale.',
  fuZhiChengGong: 'Copié',
  queDingLiKaiYe: 'Êtes-vous sûr de quitter la page ?',
  xunJiaDanChuangJian: 'Date de créer un demande de devis',
  caiGouPinLei: 'Catégorie de produit',
  buTongXuQiuBan: 'Impossible de demander un devis en version différente',
  shouHuoDiQu: 'Région ',
  xuQiuRen: 'Utilisateur final',
  heZuoZhuangTai: 'État de collaboration ',
  heZuoZhuangTaiShuo: "Description d'état de collaboration ",
  chaKanXiangQing: 'Voir les détails',
  caiGouYuanKeDui:
    "L'agent d'achat peut gérer le cycle de vie des fournisseurs, y compris l'inscription, la possibilité, la collaboration, l’élimination ou la liste noire.",
  wangQi: 'Wang Qi',
  shangHaiXingZhengZong: 'Besoin de service général à Shanghai',
  wangYunHui: 'Wang Yunhui',
  jiTuanRenLiGong:
    "Ressources humaines à l'exception du Global HRO Center, Global Studios BP Center, Global Total Rewards, Centre de paiement de divertissement mutuel exigences de service complet.",
  liuLingYan: 'Liu Lingyan',
  gLOBA:
    'Centre des ressources humaines mondiales, Centre BP des studios mondiaux, Exigences de service complet des récompenses mondiales.',
  linYang: 'Lin Yang',
  jiTuanRenLiRen:
    'Centre des Ressources Humaines et Gestion des Talents / Centre de Développement des Talents / Centre de Développement Organisationnel et Culturel / Besoin de services généraux au Centre EHR.',
  liuFang: 'Liu Fang',
  yunFuWuDC:
    "Services cloud, matériel DC ; exigences d'approvisionnement pour des services complets autres que les départements administratifs et des ressources humaines de Shanghai et Beijing ; projets de rénovation de parcs à Hangzhou et Beijing.",
  guoXia: 'Guo Xia',
  iTRuanYingJian: 'Logiciel et matériel informatique, autres ressources IDC, message texte',
  shiMengQing: 'Shi Mengqing',
  qingPuSanYaMeng: 'Projet de rénovation Xuhui, Qingpu, Sanya, Montréal',
  xuHuiBeiJingYuan: 'Exigences de service administratif complet à Shanghai ; Projets de rénovation du parc Xuhui',
  dengWenJing: 'Deng Wenjing',
  hangSanGuiAnHang:
    "Hangsan, Gui'an, Projets d'infrastructure du parc de Guangzhou et Transformation des faibles courants; Exigences de services administratifs globaux à Beijing",
  duiJieRen: 'Contact',
  duiJieYeWu: 'Affaires de liaison',
  yeWuXianShuoMing: "Description des secteurs d'affaires",
  yeWuXian: 'Secteur d’affaires',
  zhuanJiao: 'Transférer',
  suoShuGongYingShang: 'Fournisseur',
  zhiFuBaoZhongGuo: 'Alipay (Chine) Network Technology Co., Ltd',
  zhuanJiaoYouXiang: 'Transférer à',
  zhuCe: 'Inscription ',
  qianZaiXiaoJinE: 'Possibilité / petit montant',
  qianZaiChangGui: 'Possibilité / moyen',
  heZuoChangGui: 'Collaboration / moyen',
  heZuoXiaoJinE: 'Collaboration / petit montant',
  taoTai: 'Élimination ',
  heiMingDan: 'Liste noire ',
  shenPiChengGong: 'Approuvé ',
  chengGong: 'Réussi',
  shiBai: 'Échoué',
  xunYuan: 'Source',
  dingDan: 'Commande',
  yanShou: 'Réception ',
  gongYingShangLieBiao: 'Fournisseurs',
  gongYingShangZhangHao: 'Compte de fournisseur ',
  xuQiuLaiYuan: 'Provenance ',
  xiaDanGuiZe: 'Type de commande',
  jinE: 'Montant',
  bianGengJiLu_2: 'Historique de modification-{val1}',
  caoZuoRen_3: 'Opérateur : ',
  chuangJianDingDan: 'Créer une commande',
  gongYingShangYiRu: 'Liste des fournisseurs enregistrés',
  xinXiBianGeng: 'Modifier les informations ',
  heZuoZhuangTaiBian: 'Modifier l’état de collaboration  ',
  gongYingShangWeiRu: 'Liste des fournisseurs non enregistrés',
  chaKanJiLu: 'Voir ',
  chongXinZhunRu: 'Nouvelle autorisation ',
  bianGengJiLuDai: 'Historique de modification - en attente d’approbation ',
  shenHe: 'Approbation ',
  bianGengJiLuXin: 'Historique de modification - Modifier les informations',
  bianGengJiLuHe: 'Historique de modification - Modifier l’état de collaboration  ',
  bianGengJiLuZhun: 'Historique de modification - Demande d’entrée ',
  jueSeBianMa: 'Code de rôle ',
  jueSeBianMaBu: 'Le code de rôle ne peut pas être vide !',
  zhouRi: 'Dimanche',
  zhouYi: 'Lundi',
  zhouEr: 'Mardi',
  zhouSan: 'Mercredi',
  zhouSi: 'Jeudi',
  zhouWu: 'Vendredi',
  zhouLiu: 'Samedi',
  benCiYanShouShu: 'Quantité reçue',
  benCiYanShouJin: 'Montant reçu ',
  yanShouShenPiJie: 'Étapes d’approbation de réception ',
  yanShouXinXi_2: 'Information de réception ',
  yanShouFuJian: 'Pièces jointes de réception ',
  faHuoShuoMing: 'Description d’expédition ',
  chaKanYiYanShou: 'Voir l’historique de réception ',
  yanShouJiLu: 'Historique de réception ',
  daiYanShouJinE: 'Montant en attente de réception  ',
  shiWuLei: 'Produit Matériel : ',
  qingQueBaoShouDao:
    "Vérifiez si l'article reçu correspond au contrat ou à la demande en termes de quantité, attributs, taille, qualité et délai de livraison, en suivant les normes internes du département concerné.",
  fuWuLei: 'Service : ',
  qingQueBaoFuWu:
    "Vérifiez si le fournisseur a respecté les normes du contrat/demande et les normes internes du département concerné lors de l'exécution du contenu dans les délais impartis.",
  ruanJianLei: 'Logiciel :',
  qingQueBaoNinYi:
    "Assurez-vous d'avoir reçu le logiciel convenu dans le contrat/commande, y compris la quantité de licences achetées/disponibles, le nombre d'utilisateurs autorisés, le dongle d'activation, et que la durée de la licence logicielle correspond au contrat/commande.",
  yanShouYaoQiu: 'Critères de réception  :',
  ruYouYiWenQing:
    "Veuillez contacter l’agent d'achat pour toute question et vérifier la quantité reçue lors de la livraison. La réception en plusieurs parties est possible mais déconseillée sauf en cas de nécessité. ",
  caiGouBuGongGong:
    '< Fiche de demande d’autorisation au département d’approvisionnement - Approbation de réception > ',
  qingAnShiJiLu:
    'Veuillez suivre les étapes de réception conformément au contrat et contacter l’agent d’achat en cas de question !',
  yanShouChengGong: 'Reçu avec succès ',
  queRenShouHuo: 'Confirmer la réception ',
  zuiDuoShangChuanZhang:
    'Le nombre maximum de fichiers autorisés est de 3 et la taille maximale des fichiers est de 100 Mo.',
  yanShouShuoMing: 'Instruction de réception ',
  shangPinXinXi: 'Informations ',
  xingBeiZhu: 'Note de la ligne',
  xingFuJian: 'Pièces jointes de la ligne',
  shenQing: 'Demande ',
  tiJiaoDingDan: 'Soumettre',
  gongYingShangLuYue: 'Commande expédiée',
  dengDaiShouHuo: 'En attente de réception ',
  wanCheng: 'Fini',
  xinJianFaHuoDan: 'Créer un bordereau d’expédition ',
  woDeShenQing: 'Mes demandes ：',
  yinCangLiuChengTu: 'Masquer le diagramme de flux',
  qingShouDaoHuoHou:
    'Veuillez confirmer la réception après avoir reçu le colis et contacter l’agent d’achat en cas de question.',
  yuJiLuYueRi: 'Date d’expédition prévue',
  faHuoFuJian: 'Pièces jointes ',
  shouHuoXinXi_2: 'Informations de livraison ',
  lianXiFangShi: 'Contact',
  dingDanZongE: 'Montant total ',
  xiaDanShiJian: 'Date de commande',
  jiaJi: 'Urgent',
  qingShuRuXunJia_2: 'Veuillez saisir le numéro de demande de devis et le numéro de OA',
  oADanHaoCai: 'Numéro de OA / numéro de commande',
  xuQiuXinXiBu: 'Informations à compléter',
  xunJiaCaoGao: 'Brouillon de demande de devis',
  daiXiaDan: 'Commande en attente ',
  heXiaoXinYongKa: 'Carte de crédit de paiement ',
  keXiaDanShuLiang: 'Quantité disponible',
  keXiaDanJinE: 'Montant disponible',
  xiaDanFangShi: 'Mode de commande',
  gongYingShangBuYi: 'Impossible de créer car le fournisseur est différent',
  gongYingShangLianXi_3: 'Impossible de créer car le contact de fournisseur est différent',
  gongYingShangLianXi_4: 'Impossible de créer car l’adresse courriel est différente',
  shiFouFaSongDing: 'Impossible de créer car le choix d’envoyer le courriel de commande est différent',
  zhangQiBuYiZhi: 'Impossible de créer car le délai de recouvrement est différent',
  jieSuanGongSiBu: 'Impossible de créer car l’entreprise payeuse est différente',
  shouHuoRenBuYi: 'Impossible de créer car le destinataire est différent',
  shouHuoRenYouXiang_2: 'Impossible de créer car l’adresse courriel de destinataire est différente',
  shouHuoGuoJiaBu: 'Impossible de créer car le pays de livraison est différent',
  shouHuoDiQuBu: 'Impossible de créer car la région de livraison est différente',
  shouHuoChengShiBu: 'Impossible de créer car la ville de livraison est différente',
  shouHuoDiZhiBu: 'Impossible de créer car l’adresse de livraison est différente',
  shouHuoYouBianBu: 'Impossible de créer car le code postal de livraison est différent',
  shouHuoRenLianXi_2: 'Impossible de créer car le moyen de contact du destinataire est différent',
  heTongHaoBuYi: 'Impossible de créer car le numéro de contrat est différent ',
  zhiFuXinYongKa: 'Impossible de créer car la carte de crédit est différente',
  shiFouLaiZiDian: 'Impossible de le créer car la provenance (via e-commerce ou pas) est différente',
  chuangJianRenBuYi: 'Impossible de créer car le créateur est différent',
  chuangJianShiBai: 'Impossible de créer ',
  gongYingShangLeiXing: 'Le fournisseur est de petit montant, le montant de la commande',
  chaoGuoXiaoJinE: 'dépasse la limite de',
  deXianZhiQingZuo: '. Veuillez mettre à niveau le fournisseur avant de passer la commande.',
  liJiShengJi: 'Mise à niveau immédiatement ',
  dingDanJinEHan: 'Montant de commande (taxes incluses)',
  jiZhunTiaoKuan: 'Critère essentiel',
  buChongHeTong: 'Contrat supplémentaire  ',
  oADan: 'Demande OA',
  xingCanKaoZongJia_3: 'Prix de référence total de la ligne (taxes incluses)',
  shouHuoDiQuGuo: 'Région de livraison (Pays - Province)',
  qingShuRuOA: 'Veuillez saisir le numéro de demande OA, le nom d’article.',
  oADanHaoXing: 'Numéro de demande OA - numéro de ligne',
  qingShuRuXunJia_3: 'Veuillez saisir le numéro de demande de devis, le numéro de demande OA, le nom d’article.',
  dingDanChuangJianCheng: 'Créé une commande avec succès ',
  daYu_2: '(Plus de',
  xuQianShuHeTong:
    "）, nécessite la signature d'un contrat. Veuillez signer le contrat en temps voulu pour autoriser votre commande. Si vous avez déjà signé un contrat, veuillez en lier.",
  guanLianHeTong: 'Lier un contrat',
  xinJianCaiGouDing: "Créer une commande d'achat",
  shouHuoFangShi: 'Préférence de livraison',
  xiaDanJinE: 'Montant de commande',
  dingDanXingJinE: 'Montant de ligne',
  xinJianDingDan: 'Créer une commande',
  dingDanXingJinE_2: 'Montant de commande de la ligne (hors taxes)',
  dingDanHangJinE_2: 'Montant de commande de la ligne (taxes incluses)',
  feiYongChengDanCheng: 'Centre de coûts',
  yanShouJinE: 'Montant de réception  ',
  oADanHaoDing: 'Numéro de OA / numéro de commande',
  keFaHuoShuLiang: 'Quantité disponible ',
  keFaHuoJinE: 'Montant disponible ',
  benCiFaHuoShu: 'Quantité de cette expédition',
  benCiFaHuoJin: 'Montant de cette expédition',
  faHuoXingBeiZhu: 'Note de ligne d’expédition',
  benDanLuYueJin: 'Montant expédié (taxes incluses)',
  faHuoXinXi: "Informations d'expédition ",
  chuangJianRen: 'Créateur ',
  faHuoLeiXing: 'Type d’expédition ',
  faHuoBeiZhu: "Note d'expédition ",
  zuiDuoShangChuanZhang_2:
    'Le nombre maximum de fichiers autorisés est de 20 et la taille maximale des fichiers est de 100 Mo.',
  mingXiXing: 'Détails ({val1}lignes)',
  heTongPDF: '合同2.pdf',
  yiZaiYanShouZhong: 'Impossible d’annuler pendant le processus de réception  !',
  faHuoDanGuanBi: 'Bordereau d’expédition annulé ',
  guanBiFaHuoDan: 'Annuler le bordereau d’expédition ',
  gaiFaHuoDanBao: 'Ce bordereau d’expédition comprend',
  xingJiangQuanBuGuan: 'lignes qui seront toutes annulées. Êtes-vous sûr de continuer ?',
  guanBiYuanYin: 'Raison d’annulation ',
  faHuoDanXiangQing: 'Détails de bordereau d’expédition {val1}',
  yiGuanBi: 'Annulé',
  faHuoDanFuJian: 'Pièces jointes ',
  faHuoXinXi_2: "Informations d'expédition ({val1})",
  faHuoDanHangHao: 'Numéro de ligne de bordereau d’expédition',
  shiJiFaHuoJin: 'Montant de cette expédition ',
  danJiaHanShui: 'Prix unitaire (taxes incluses)',
  yunFeiHanShui: 'Fret (taxes incluses)',
  baoJiaBeiZhu: 'Note',
  gongYingShangLianXi_5: 'L’adresse courriel de contact du fournisseur ',
  gongYingShangLeiXing_2: 'Type de fournisseur',
  gongYingShangYouXiang: 'Adresse courriel du fournisseur',
  dangQianHeZuoZhuang: 'État actuel de collaboration',
  bianGengXinXi: 'Modifier les informations ',
  muBiaoHeZuoZhuang: 'État de collaboration cible',
  bianGengShuoMing: 'Description de modification ',
  zhunRuXinXi: 'Informations d’admission',
  tuiJianRen: 'Référent',
  tuiJianLiYou: 'Raison de référence ',
  zhunRuShuoMing: 'Description d’entrée',
  danJiaHanShui_2: 'Prix unitaire (taxes incluses)',
  dingBiaoJinEWei: 'Montant attribué (hors taxes)',
  ziDongZhuanPO: 'Transféré automatiquement à PO',
  dingBiaoXinXi: "Avis d'attribution",
  zhongBiao: 'Sélectionné ',
  weiZhong: 'Éliminé ',
  ruKuDanHangHao: 'Fiche d’entreposage - numéro de ligne',
  ruKuZiGuan: 'Opérateur d’entreposage ',
  jiaoYanJieGuo: 'Résultat de vérification ',
  shiBaiYuanYin: 'Raison d’échec ',
  shouHuoDanXiangQing: 'Détails de bordereau de réception',
  faHuoDanHangHao_2: 'Bordereau d’expédition - numéro de ligne',
  weiShuiDanJia: 'Prix unitaire hors taxes',
  faPiaoLeiXing: 'Type de facture',
  yanShouDanHangHao: 'Fiche de réception - numéro de la ligne',
  ruKuXinXi: "Information d'entreposage",
  faHuoDan: 'Bordereau d’expédition ',
  daiYanShouJinE_2: 'Montant en attente de réception  (taxes incluses)',
  faHuoShiJian: 'Date d’expédition ',
  oADanHaoWu: 'Numéro de commande OA, numéro de suivi, numéro d’expédition',
  dingDanHangHao_2: 'Commande - numéro de ligne',
  yanShouJinEHan_2: 'Montant des articles reçus (taxes incluses)',
  shouHuoShiJian: 'Date de réception ',
  oADanHaoShou: 'Numéro de OA / numéro de commande / numéro de commande / numéro d’accrual',
  biaoTi: 'Titre',
  feiYongChengDanBu: 'Centre de coûts',
  shouHuoRenDianHua: 'Numéro de téléphone',
  queDingWanChengXun: 'Êtes-vous sûr d’avoir terminé la demande de devis ?',
  xunJiaYiWanCheng: 'Demande de devis terminée',
  wanChengXunJia: 'Terminer la demande de devis ',
  xuQiuChi: 'Pôle des demandes',
  xunJia: 'Demande de devis ',
  shenQingDanHao: 'ID de demande',
  gongYingShangJianCheng: 'Abréviation du nom de fournisseur ',
  shenPiZhuangTai: 'État d’approbation ',
  qingShuRuGongYing: "Veuillez entrer le nom du fournisseur /nom abrégé/ ID de demande/ID de l'OA",
  heZuoZhuangTai_2: 'État de collaboration :',
  bianGengShuoMing_2: 'Description de modification :',
  shiJian: 'Heure',
  dangQianShaiXuanTiao: 'Aucune donnée ne correspond aux filtres actuels. Veuillez cliquer ici',
  qingKongShaiXuanTiao: 'pour les effacer',
  neiRongBianGeng: 'Modification du contenu',
  bianGengDanHao: 'Modifier l’ID de demande ',
  bianGengNeiRong: 'Modifier le contenu',
  shenPiJieDianMing: "Étapes d'approbation",
  queRenFanHuiShang: 'Êtes-vous sûr de revenir en arrière ?',
  tianXieDeShuJu: 'Les données saisies seront effacées !',
  shangYiBu: 'Retour',
  xiaYiBu: 'Suivant',
  jiChuXinXi: 'Informations clés',
  qingShuRuGongYing_2: 'Veuillez saisir le nom du fournisseur.',
  tongYiSheHuiXin: "Code d'identification unifié de crédit social ",
  qingShuRuTongYi: "Veuillez saisir le code d'identification unifié de crédit social.",
  qingShuRuTI: 'Veuillez saisir un TIN.',
  guanLiYuanXingMing: 'Administrateur',
  qingShuRuGuanLi: 'Veuillez saisir le nom d’administrateur.',
  guanLiYuanYouXiang: 'Adresse courriel d’administrateur',
  qingShuRuGuanLi_2: 'Veuillez saisir l’adresse courriel d’administrateur.',
  qingShuRuXingMing: 'Veuillez saisir le nom complet.',
  zhengJianLeiXing: 'Type de pièce ',
  qingShuRuZhengJian: 'Veuillez saisir un type de pièce.',
  zhengJianHaoMa: 'Numéro de pièce ',
  qingShuRuZhengJian_2: 'Veuillez saisir le numéro de pièce.',
  qingShuRuLianXi: 'Veuillez saisir l’adresse courriel.',
  qiYeXinXi: 'Informations d’entreprise ',
  dangAnJiLu: 'Historique d’archive ',
  dangQianZhanShiNei:
    "L'affichage actuel montre les informations avant les modifications. Veuillez patienter pour les contenus modifiés en attente d'approbation.",
  daiShenHe: 'En attente d’approbation ',
  zhunRuShenQing: 'Demande d’admission ',
  qingShuRuGongYing_3: 'Veuillez saisir le nom du fournisseur / nom abrégé / ID de demande / ID de TIN.',
  fuWuPinLei: 'Catégorie',
  zhunRuShiJian: 'Date d’admission',
  yiRuKu: 'Entreposé',
  weiRuKu: 'Pas entreposé  ',
  zhunRuShenPi: 'Approbation d’admission ',
  qingShuRuGongYing_4: 'Veuillez entrer le nom du fournisseur /nom abrégé/ ID de demande/ID de l’OA.',
  caiGouYuanCaiGou: "Agent d'achat - Superviseur d’achat de niveau 2",
  shouGongFaQi: 'Soumis manuellement ',
  ruVMDM:
    "Si l'étiquette VMDM déclenche une liste noire (source ECC), le processus de mise à niveau ne sera pas autorisé.",
  caiGouYuanFaWu:
    "Agent d'achat - juriste business partenaire - financier business partenaire - superviseur d’achat de niveau 2",
  ruVMDM_2:
    "Si l'étiquette VMDM déclenche une liste noire (source ECC), le processus de mise à niveau ne sera pas autorisé.",
  zhongXuanBingKaiZhan:
    'Une fois sélectionné, une collaboration sera lancée et une mise à jour sera automatiquement appliquée',
  shouCiXiaDanChu: 'La première commande déclenche une des options ',
  nianNeiWeiKaiZhan: 'Pas de collaboration pendant deux ans entraînera une rétrogradation automatique',
  youHeTongQuHe:
    "Avec contrat, prendre la date d'expiration la plus tardive du contrat ; sans contrat mais avec des commandes, prendre la date de la dernière commande ; sans contrat ni commandes, prendre la date d'approbation.",
  caiGouYuanCaiGou_2: "Agent d'achat - Superviseur d’achat de niveau 2 - Directeur d’achat ",
  caiGouYuanFaWu_2:
    "Agent d'achat - Juriste business partenaire - Financier business partenaire - Superviseur d’achat de niveau 2 - Directeur d’achat",
  caiGouYuanCaiGou_3:
    'Approuvé par Agent d’achat - Superviseur d’achat de niveau 2 - Directeur d’achat, copié au Juriste business partenaire - Financier business partenaire - Business partenaire de contrôle interne ',
  yiJinXingXinXi:
    '1. Les informations sont enregistrées mais l’approbation de l\'entreposage n’est pas faite. \n 2. L’entreposage est approuvé/ fournisseur n\'ayant pas collaboré depuis plus de deux ans et n\'ayant pas été marqué comme "élimination" ou "liste noire”．',
  yiTongGuoRuKu:
    'Le fournisseur a été approuvé pour entrer dans l\'entrepôt pour moins de 2 ans et n\'est pas répertorié comme étant en "élimination" ou sur une "liste noire". Un fournisseur de petit montant et de faible risque n\'a pas besoin d\'approbation financière et peut commencer une collaboration rapidement. Le montant de collaboration ne peut pas dépasser 100 000 CNY et chaque commande ne peut pas dépasser 50 000 CNY.',
  yiTongGuoRuKu_2:
    'Le fournisseur a été approuvé pour entrer dans l\'entrepôt pour moins de 2 ans et n\'est pas répertorié comme étant en "élimination" ou sur une "liste noire".',
  zhiZaiJinNianNei:
    "Le fournisseur a collaboré pendant deux ans et n'est pas répertorié comme étant éliminé ou sur liste noire. (mise à jour à [collaboration/petit montant] de [collaboration possibilité/petit montant])",
  zhiZaiJinNianNei_2:
    "Le fournisseur a collaboré pendant deux ans et n'est pas répertorié comme étant éliminé ou sur liste noire.",
  heZuoGuoChengZhong:
    "Le fournisseur a été suspendu par le département d'approvisionnement en raison d'irrégularités survenues pendant la collaboration, telles que des échecs lors d'examens ou des risques de non-conformité au contrat.",
  yuanZeShangYingYong: 'En principe, les fournisseurs doivent être suspendus.',
  buQuFen: 'Pas de distinction',
  muBiaoJiBie: 'Niveau cible',
  shenPiLianLu: 'Flux d’approbation ',
  shengJiangJiChuFa: "Déclenchement d'un trigger point",
  jieDuanShuoMingXiang: 'Détails',
  shengMingZhouQiJi: 'Description de cycle de vie',
  shengMingZhouQiLiu: 'Règles de cycle de vie',
  ziZhiPinLeiYu: 'Veuillez modifier car la catégorie de qualification ne correspond pas à la catégorie de service.',
  tuiJianYuanYin: 'Raison de référence ',
  faDingDaiBiaoRen: 'Représentant légal ',
  gongSiZhuYeWang: "Site web d'entreprise",
  yingYeQiXian: 'Durée de permis d’affaires',
  chengLiRiQi: 'Date de fondation',
  naShuiRenZiZhi: 'Qualification de contribuable',
  shengShi: 'Province/ville',
  quXian: 'District (comté)',
  yingYeZhiZhaoFu: "Pièces jointes de permis d'affaires",
  zhunRuZiLiao: 'Document d’admission',
  dengJiXinXi: 'Enregistrer les informations ',
  fuWuPinLeiNei: 'Contenu de catégorie',
  ziZhiXinXi: 'Informations de qualification ',
  ziZhiMingCheng: 'Nom de qualification ',
  ziZhiWenJian: 'Document de qualification ',
  ziZhiYouXiaoQi: 'Validité de qualification',
  yiCunZaiXiangTong:
    'Ces informations de qualification sont déjà enregistrées, veuillez les modifier avant de les soumettre.',
  tianJiaZiZhi: 'Ajouter des qualifications',
  ziZhiXinXi_2: 'Informations de qualification {val1}',
  tianJia: 'Ajouter',
  lianXiRenXinXi: 'Informations de contact',
  lianXiRenLeiXing: 'Type de contact',
  zhiWu: 'Titre du poste',
  shouJiHao: 'Numéro de téléphone',
  yiCunZaiXiangTong_2: 'Ce contact existe déjà. Veuillez le modifier avant de le soumettre.',
  tianJiaLianXiRen: 'Ajouter un contact',
  lianXiRen_2: 'Contact {val1}',
  yiCunZaiMoRen: 'Un autre compte par défaut existe déjà. Êtes-vous sûr de vouloir le remplacer par le vôtre ?',
  yinHangZhangHaoXin: 'Informations de compte bancaire ',
  zhangHaoMingCheng: 'Compte bancaire ',
  jieSuanBiZhong: 'Monnaie de règlement',
  kaiHuHangMingCheng_2: "Banque d'ouverture",
  zhiHangMingCheng: 'Succursale bancaire',
  fuZhiXinZeng: 'Copier',
  yiCunZaiXiangTong_3: 'Ce compte bancaire existe déjà, veuillez le modifier.',
  tianJiaYinHangXin: 'Ajouter des informations du compte bancaire ',
  yinHangXinXi_2: 'Informations du compte bancaire {val1}',
  lianHangHao: 'Code CNAPS',
  kaiHuHangShengShi: 'Ville et province d’ouverture de compte bancaire',
  yinHangDiZhi: 'Adresse de banque ',
  shouXuFeiChengDan: 'Frais de transaction pris en charge par',
  shiFouWeiMoRen: 'Compte par défaut ',
  caiWuZhuangKuang: 'État financier ',
  nianFen: 'Année ',
  yingYeShouRuWan: 'Revenu (10k)',
  liRunZongEWan: 'Profit (10k) ',
  shiJiaoZiBenWan: 'Capital libéré (10k)  ',
  nianDuShenJiBao: 'Rapport annuel d’audit ',
  caiWuBaoBiao: 'Rapport financier',
  youXiaoQiXian: 'Durée de validation',
  yiCunZaiXiangTong_4: 'L’état financier est déjà prêt, veuillez le modifier.',
  tianJiaCaiWuZhuang: 'Ajouter l’état financier ',
  caiWuZhuangKuang_2: '{val1} État financier ',
  chengGongAnLi: 'Exemple de réussite ',
  anLiMingCheng: "Nom d'exemple",
  keHuMingCheng: 'Nom de client',
  fuWuChanPinFan: 'Services / Produit  ',
  xiangMuGuiMoWan: 'Taille du projet (10k CNY) ',
  xiangMuShiJian: 'Date de projet',
  xiangMuJianJie: 'Profil de projet',
  xiangMuChengGuo: 'Résultats',
  yiCunZaiXiangTong_5: 'Cet exemple existe déjà, veuillez le modifier.',
  tianJiaChengGongAn: 'Ajouter un exemple de réussite ',
  chengGongAnLi_2: '{val1} Exemple de réussite ',
  lianXiRenZhiWei: 'Poste',
  keHuPingJiaTui: 'Évaluation de client / lettre de référence',
  heTongZhengMingHuo: 'Justificatif de contrat ou dossier de projet',
  liYiGuanXiShen: 'Déclaration de relation',
  yuanGongXingMing: "Nom d'employé ",
  yuanGongSuoZaiGong: 'Entreprise',
  guanXi: 'Relation',
  yiCunZaiXiangTong_6: 'Cette relation d’intérêt existe déjà, veuillez la modifier.',
  tianJiaLiYiGuan: 'Ajouter la relation ',
  liYiGuanXi: '{val1} Relation',
  gongYingShangID_2: 'ID de fournisseur :',
  gongYingShangYouXiang_2: 'Adresse courriel du fournisseur :',
  zhuCeGuoJiaDi_2: "Pays/région d'inscription :",
  gongYingShangLeiXing_3: 'Type de fournisseur :',
  qiYeMingCheng: 'Nom d’entreprise',
  dengJiXinXi_2: 'Enregistrer les informations ',
  moRenZhangHao: 'Compte par défaut ',
  ziGuanDaiShou: 'Reçu par responsable d’actifs',
  yuanGongBenRenShou: 'Reçu par demandeur',
  yongHuGuanLi: 'Gestion des utilisateurs',
  xunYuanGang: 'Manager de Sourcing',
  caiGouJingLi: 'Manager d’achat',
  caiGouBuMenFu: 'Directeur du département d’achat',
  caiGouGuiZeGuan: 'Gestion des règlements d’achat',
  caiWu: 'Finance',
  shenJi: 'Audit',
  neiKong: 'Contrôle interne',
  tian_7: '90 jours',
  qiYe: 'Entreprise',
  geRen: 'Individuel',
  juMinShenFenZheng: 'Carte d’identité ',
  gangAoJuMinLai: 'Permis de voyage pour les résidents de Hong Kong et de Macao',
  zhongHuaRenMinGong:
    'Permis de résidence pour les résidents de Hong Kong et de Macao de la République populaire de Chine',
  taiWanJuMinLai: 'Permis de voyage pour les résidents de Taïwan',
  zhongHuaRenMinGong_2: 'Permis de résidence pour les résidents de Taïwan de la République populaire de Chine',
  zhongGuoHuZhao: 'Passeport Chinois ',
  waiGuoHuZhao: 'Passeport étranger ',
  waiGuoRenYongJiu: 'Carte de résident permanent d’un étranger ',
  zhongHuaRenMinGong_3: 'Permis de travail d’un étranger à la République populaire de Chine (catégorie  A)',
  zhongHuaRenMinGong_4: 'Permis de travail d’un étranger à la République populaire de Chine (catégorie  B)',
  zhongHuaRenMinGong_5: 'Permis de travail d’un étranger à la République populaire de Chine (catégorie  C)',
  qiTaGeRenZheng: 'Autres pièces personnelles',
  yiBanNaShuiRen: 'Assujetti ordinaire',
  xiaoGuiMoNaShui: 'Petite et micro contribuable',
  feiZengZhiShuiNa: 'Pas assujettis à la TVA',
  qiTa: 'Autres',
  shangWuDuiJieRen: 'Affaires',
  caiWuDuiJieRen: 'Finance',
  wangYiJiTuan: 'NetEase',
  shuangFang: 'Deux parties',
  shengMingZhouQiBian: 'Modification de cycle de vie',
  zhuZhangHao: 'Compte principal',
  ziZhangHao: 'Sous-compte',
  wuLiuKuaiDi: 'Express logistique',
  feiWuLiuKuaiDi: 'Express non logistique',
  wuXinXi: 'Pas d’informations',
  weiFaHuo: 'Non expédié',
  yiFaHuo: 'Expédié',
  yiQianShou: 'Reçu ',
  yiTuiHuo: 'Retourné',
  peiSongZhong: 'Livraison en cours',
  zengZhiShuiZhuanPiao: 'Facture avec TVA',
  zengZhiShuiPuPiao: 'Facture générale',
  dianZiFaPiaoZhuan: 'Facture électronique (avec TVA)',
  dianZiFaPiaoPu: 'Facture électronique (générale)',
  niMeiYouFangWen: "Vous n'avez pas accès à cette page.",
  chongXinDengLu: 'Se connecter à nouveau',
  fanHuiShouYe: 'Retour à l’accueil',
  ziChanXinXi: 'Informations d’actifs',
  ziChanBianHao: "Code d'actifs",
  pODanHao: 'Code de commande PO',
  qingShuRuWanZheng_2: 'Veuillez saisir le code complet de commande PO',
  daiXuQiuQueRen: 'Confirmation de demande',
  daiXunJia: 'Demande de devis en attente',
  daiXunJiaQueRen: 'Brouillon',
  dingDanXinXiBu: 'Compléter les informations de commande ',
  daiBuChongHeTong_2: 'À compléter les informations de contrat ',
  zhengDanFaHuo: 'Expédier par lots',
  dingDanHangFaHuo: 'Expédier les articles de cette ligne',
  guanLianXinYongKa_2: 'Facture de carte de crédit liée',
  qingShuRuWanZheng_3: "Fournir l'identifiant exact du bon de commande",
  qingXianBuChongXu: 'Complétez les détails de la demande avant de poursuivre',
  queRenTongGuo: 'Approuvé',
  xuQiuYiQueRen: 'Demande confirmée',
  xingCanKaoZongJia_2: 'Prix de référence de la ligne',
  buKeQuXiao: 'Non annulable',
  gaiXuQiuGongXing: "Êtes-vous sûr de vouloir annuler toutes les {val1} lignes de cette demande d'achat ?",
  xuQiuXingQueRen: 'Demande confirmée',
  buTongYiJiPin: 'Demande de devis indisponible dans une catégorie différente',
  xuQiuQueRen: "Approuver la demande d'achat",
  feiYongDaLei: 'Catégorie de dépenses',
  feiYongXiaoLei: 'Sous-catégorie de dépenses',
  xuQiuXinXiQue: "Confirmer les détails de la demande d'achat",
  xuQiuXinXiQue_2: "Vue d'ensemble des détails de la demande d'achat ",
  fangDiChanJiShe: 'Immobilier et installation',
  yuanGongFuLiQi: 'Autres avantages sociaux',
  shiJiShiYongRen_10: 'Impossible de demander le devis car l’utilisateur effectif vient d’un département différent.',
  xunJiaBiaoTi: 'Titre de demande de devis',
  wenXinTiShiShe: 'Remarque : Le contenu [{val1}] concernant l’approbation de CEO sera synchronisé sur POPO.',
  xunJiaBiaoTiHe: 'Titre de demande de devis, description de devis, et Document de devis',
  qingShuRuXunJia:
    "Veuillez saisir le titre de la demande de devis. Le message sera synchronisé sur POPO si cela concerne l'approbation du CEO.",
  qingShuRuHeJia:
    "Veuillez saisir la description de devis. Le message sera synchronisé sur POPO si cela concerne l'approbation du CEO.",
  queDingJinYongYongHu: 'Êtes-vous sûr de désactiver cet utilisateur ?',
  queDingQiYongYongHu: 'Êtes-vous sûr d’activer cet utilisateur ?',
  jinYongYongHuHou: 'L’utilisateur ne pourra pas se connecter à Netbuy après désactivé.',
  zuiHouGengXinRen: 'Dernière mise à jour par',
  lianXiRenXingMing: 'Contact',
  banGongYeWuYong: 'Bureau/affaires',
  iTBuMenZhuan: 'Exclusif au département informatique - Pièces détachées',
  tian_2: 'jour (s)',
  dianJiFaQiP: 'Chattez dans POPO',
  buTongJieSuanZhu: 'Impossible de demander le devis en raison d’une incohérence dans son entreprise de paiement',
  zhongZhi: 'Réinitialiser',
  huiLuQiJian: 'Période de taux de change',
  yuanBiZhong: 'Devise d’origine',
  zheSuanBiZhong: 'Convertir en',
  chaKanXuQiu: 'Afficher les demandes',
  chaKanXunJiaDan: 'Afficher la demande de devis',
  shanChuXunJiaDan: 'Supprimer la demande de devis',
  chaKanDingDan: 'Afficher la commande',
  gengXinDingDan: 'Mettre à jour la commande',
  xinZeng: 'Ajouter',
  jieDanGuiZe: 'Règles de sélectionner la commande',
  rFXShenPi_2: 'Règles d’approbation de la demande de devis',
  teDingBiaoQian: 'Étiquette ',
  suoYouQuanXian: 'Accès complet',
  quanXianPeiZhi: 'Configuration des accès ',
  chuangJianJiaoSe: 'Créer un rôle',
  bianJiJueSe: 'Modifier le rôle',
  jueSeZhuangTai: 'État',
  jueSeMingCheng: 'Nom ',
  jueSeMingChengBu: 'Le nom du rôle ne peut pas être vide !',
  shanChuChengGong: 'Supprimé avec succès ',
  shanChuShiBai: 'Impossible de supprimer',
  bianJiGuiZe: 'Modifier les règles',
  guiZeMingCheng: 'Nom de règle',
  guoJiaLeiXing: 'Pays/type',
  caiGouLeiXing: 'Type ',
  shangPinMingChengPei: "Nom d'article & Attribut & Marque",
  guoJiaDiQu_2: 'Pays/Région',
  buMen: 'Département',
  shenQingRenJiBie: 'Niveau de poste du demandeur',
  shenQingRenXingMing: 'Nom de demandeur',
  shiJiShiYongRen_5: 'Niveau de poste de l’utilisateur effectif',
  shiJiShiYongRen_6: 'Utilisateur effectif',
  jieSuanZhuTi: 'Entreprise payeuse',
  xinJianBiaoQian: 'Créer une étiquette',
  bianJiBiaoQian: 'Modifier l’étiquette ',
  biaoQianMing: 'Nom d’étiquette ',
  biaoQianMingBuDe: 'Veuillez saisir le nom d’étiquette ',
  biaoQianYanSe: 'Couleur de l’étiquette',
  panDuanTiaoJian: 'Critère ',
  xinZengTiaoJian: 'Ajouter un critère ',
  xuHao: 'Numéro ',
  guiZe: 'Règle',
  shenQingRenBuMen_2: 'Département du demandeur : {val1}',
  shenQingRenJiBie_2: 'Niveau de poste du demandeur : {val1}',
  shiJiShiYongRen_7: 'Département de l’utilisateur final : {val1}',
  shiJiShiYongRen_8: 'Niveau de poste de l’utilisateur final : {val1}',
  shiJiShiYongRen_9: 'Utilisateur effectif : {val1}',
  jieSuanZhuTi_2: 'Entreprise payeuse : {val1}',
  gongYingShang_2: 'Fournisseur : {val1}',
  yiXuanZeQuanBu: 'Sélectionné tous les employés',
  yiXuanZeQuanBu_2: 'Sélectionné toutes les entreprises payeuses',
  dangQianMoRenXuan:
    'La sélection par défaut est « Tous les employés », et vous pouvez choisir « Employés sélectionnés ».',
  dangQianMoRenXuan_2:
    'La sélection par défaut est « Toutes les entreprises payantes », et vous pouvez choisir « Entreprises payeuses sélectionnées ».',
  xinZengCaiGouYuan: 'Ajouter un agent d’achat',
  xinZengJieSuanZhu: 'Ajouter une entreprise payeuse',
  xingMing: 'Nom complet',
  gongHao: 'ID d’employé ',
  jieSuanZhuTiMing: 'Entreprise payeuse',
  jieSuanZhuTiI: 'Identifiant de l’entreprise',
  quanBuRenYuan: 'Tous les employés',
  zhiDingRenYuan: 'Employés sélectionnés',
  yiChuCaiGouYuan: 'Supprimer l’agent d’achat',
  quanBuJieSuanZhu: 'Toutes les entreprises payeuses',
  zhiDingJieSuanZhu: 'Entreprises payeuses sélectionnées',
  yiChuJieSuanZhu: 'Supprimer l’entreprise payeuse',
  tianJiaYongHu: 'Ajouter un utilisateur',
  bianJiYongHu: 'Modifier l’utilisateur',
  qingZhengQueTianXie: 'Veuillez fournir les données correctes.',
  fenPeiJueSe: 'Rôle',
  shuJuQuanXian: 'Accès aux données',
  xingMingGongHao: 'Nom/ID d’employé ',
  xuanPinYiWanCheng: 'Produits sélectionnés',
  qingFanHuiOA: 'Retournez à la page de demande OA pour continuer.',
  caiGouDingDanZheng: 'Aperçu de la commande',
  caiGouDingDanMing: 'Détails de la commande',
  xunJiaDanHao: 'Numéro de la demande de devis',
  tiJiaoRiQi: 'Date de soumission',
  tiJiaoKaiShiRi: 'Date de début ',
  dingDanZongEWei: 'Valeur totale (hors taxes)',
  laiYuan: 'Source',
  gengXinZhuTiHe: 'Modifier les entités, les contrats et les notes',
  dingDanHangHao: 'Numéro de ligne de commande',
  baoJiaJinEWei: 'Montant de devis (hors taxes)',
  chuangJianRiQi: 'Date de création',
  chuangJianKaiShiRi: 'Date de début ',
  qiWangDaoHuoDi: 'Adresse de livraison',
  shouHuoLianXiRen: 'Destinataire',
  gaiXingDuiYingDe: 'Êtes-vous sûr de supprimer toute la ligne de demande de devis ?',
  shanChuXunJiaDan_2: 'Demande de devis supprimée',
  zanWu: 'Aucun',
  yanShouDanXiangQing: 'Détail de réception ',
  yanShouDanHao: 'Numéro de réception ',
  yanShouRen: 'Accepteur',
  yanShouShiJian: 'Date de réception ',
  yanShouLeiXing: 'Type de réception ',
  yanShouXinXi: 'Information de réception ',
  oADanHaoXu: 'ID de demande OA - Numéro de ligne',
  caiGouDingDanHao: 'ID de commande - Numéro de ligne',
  jiTiDanHao: 'Numéro de ticket cumulé',
  jiTiZhuangTai: 'État d’accumulation',
  shouQuanQiXianYue: 'Type de permis (mois)',
  yanShouJinEHan: 'Valeur des articles reçus (taxes incluses)',
  biZhong: 'Devise',
  shiFouZiChan: 'Actif ou non',
  caoGao: 'Brouillon',
  tiJiaoJieShuRi: 'Date de fin',
  daiYanShouShiChang: 'Durée de réception ',
  daiYanShouShuLiang: 'Quantité en attente de réception ',
  zuiXinWuLiuXin: 'État de livraison',
  yiShouHuoShuLiang: 'Quantité reçue',
  weiQueRen: 'Confirmation en attente',
  yiTuiHui: 'Retourné',
  weiShenHe: 'Approbation en attente',
  yiShenHe: 'Approuvé ',
  yiRuZhang: 'Compté',
  yiChongXiao: 'Offset',
  caiGouDingDanHao_2: 'ID de commande',
  yanShouRiQi: 'Date de réception ',
  yanShouKaiShiRi: 'Date de début ',
  yanShouJieShuRi: 'Date de fin',
  jiTiRiQi: 'Date de cumul',
  jiTiKaiShiRi: 'Date de début ',
  jiTiJieShuRi: 'Date de fin',
  caiGouDingDanHao_3: 'ID de commande - Numéro de ligne',
  weiBaoQiXianYue: 'Garantie (mois)',
  yiWanCheng: 'Terminé',
  qingShuRuWanZheng: 'ID de demande OA complet',
  shenQingRiQi: 'Date de demande',
  shenQingKaiShiRi: 'Date de début ',
  oADanHangHao_2: 'ID de demande OA - Numéro de ligne',
  renMinBiJinE: 'Montant en CNY (taxes incluses)',
  shouHuoDianHua: 'Numéro de téléphone',
  shouJianRen: 'Destinataire',
  yuanYinJiXuQiu: 'Motif d’achat',
  chanPin: 'Produit',
  chanPinChengBenZhong: 'Centre de coûts',
  buChongXingCanKao: 'Ajoutez le prix de la ligne de référence et la catégorie',
  tingYong: 'Désactivé',
  xingHao: 'Numéro de ligne',
  kaiHuHangMingCheng: 'Succursale bancaire',
  zhangHuMingCheng: 'Compte bancaire',
  yinHangZhangHao: 'Numéro de compte bancaire',
  kaiHuXuKeZheng: 'Permis d’ouverture de compte',
  tiJiaoChengGong: 'Soumis',
  tiJiao: 'Soumettre',
  gongYingShangID: 'ID du fournisseur',
  gongYingShangMingCheng: 'Nom du fournisseur',
  dengBaiShiBianMa: 'Numéro DUNS',
  zhuCeGuoJiaDi: 'Pays/région d’inscription ',
  lianXiRen: 'Contact',
  lianXiYouXiang: 'Adresse courriel',
  chengLiShiJian: 'Date de fondation',
  zhuCeZiBenWan: 'Capital social (10 k)',
  gengXinShiJian: 'Mis à jour à',
  gengXinRen: 'Mis à jour par',
  xiangXiDiZhi: 'Adresse complète ',
  jingYingFanWei: 'Champ d’activité',
  heFaZhuCeZheng: "Attestation d'inscription de l'entreprise",
  zhunRuZiLiaoShang: 'Document d’admission',
  yinHangXinXi: 'Compte bancaire',
  dengBaiShi: 'Numéro DUNS',
  faRenDaiBiaoRen: 'Représentant légal/Personne responsable',
  zuiHouGengXinShi: 'Dernière mise à jour à',
  caoZuoShiBai: 'Échoué',
  xinZengGongYingShang: 'Ajouter un fournisseur',
  zhiChiKuoZhanMing: 'Format pris en charge : pdf, jpg, png, chaque dossier inférieur à 100 Mo',
  shangChuanFuJian: 'Pièces jointes',
  chuangJianChengGong: 'Créé avec succès ',
  qingShuRuZhengQue: 'Veuillez saisir une adresse courriel correcte.',
  qingShuRuZhengQue_2: 'Veuillez saisir un numéro de téléphone portable correct.',
  zhuCeBiZhong: 'Devise',
  tian_3: '15 jours',
  tian_4: '30 jours',
  tian_5: '45 jours',
  tian_6: '60 jours',
  shouHuo: 'Réception ',
  tuiHuo: 'Retourner',
  zongYunFeiHanShui: 'Fret (Taxes incluses) : {val1}',
  dingBiaoJinEHan: 'Montant attribué (Taxes et Fret réparti Inclus)',
  dingBiaoJinEHan_2: 'Montant attribué (Taxes Incluses)',
  yunFei: '(Fret : {val1})',
  yunFei_2: 'Fret',
  zaiGaiBaoJiaDan: 'Fret pour [{val1}] couvert par ce devis',
  zongYunFeiHanShui_2: 'Fret (Taxes incluses)',
  quXiaoXuQiu: 'Annuler la demande',
  xuQiuYiQuXiao: 'Demande annulée',
  hanShui: 'TVA inclus',
  weiShui: 'Hors taxes',
  gongYingShangXiangQing: 'Informations de fournisseur',
  caiGouDingDanXiang: 'Détails de commande {val1}',
  faSongDingDan: 'Envoyer la commande',
  faSongZhi: 'Envoyé à',
  faSongChengGong: 'Envoyé avec succès ',
  quXiaoDingDan: 'Annuler la commande',
  caiGouDingDanYi: 'Commande annulée',
  dingDanHao: 'ID de commande',
  dingDanLaiYuan: 'Source',
  danJuBiaoQian: 'Étiquette',
  dingDanJinE: 'Valeur de commande',
  yuFuKuanShenQing: 'Demande de prépaiement',
  xinYongKaZhangDan: 'Facture de carte de crédit',
  shouHuoXinXi: 'Informations de livraison ',
  guoJiaDiQu: 'Pays/Région',
  shouHuoLianXiDian: 'Numéro de téléphone',
  oADanHangHao: 'Numéro de ligne de demande OA',
  xunJiaDingBiaoXing: 'ID de demande de devis-- Numéro de ligne',
  shouQuanQiXian: 'Type de permis',
  weiBaoQiXian: 'Garantie',
  xuQiuShuLiang: 'Quantité demandée',
  xiaDanShuLiang_2: 'Quantité de commande',
  dingDanHangBiZhong: 'Devise de ligne',
  xingYunFeiHanShui: 'Fret de la ligne (taxes incluses)',
  dingDanHangJinE: 'Montant de la ligne (fret compris)',
  shouHuoShuLiang: 'Quantité reçue',
  shouHuoDanHao: 'Numéro du réception',
  fuJiaXinXi: 'Pièces jointes',
  chengBenZhongXin: 'Centre de coûts',
  chanPinDuan: 'Information de produit',
  wuLiuXinXi: 'Informations logistiques',
  zhangDanID: 'ID de facture',
  shangJiaMingCheng: 'Vendeur',
  jiaoYiShiJian: 'Date de transaction',
  jiaoYiJinE: 'Montant',
  jiaoYiBiZhong: 'Devise',
  xinYongKaBenBi: 'Montant (devise de la carte)',
  zhangDanXiangQing: 'Détails de facturation',
  guanLianXinYongKa: 'Facture de carte de crédit liée ({val1})',
  beiZhuXinXi: 'Remarque',
  caoZuoShiJian: 'Modifié à',
  feiKuaiDiWuLiu: 'Non express',
  wuLiuGongSi: 'Prestataire logistique',
  wuLiuDanHao: 'Numéro de suivi',
  shiJiFaHuoShu: 'Quantité expédiée',
  yuJiDaoHuoRi: 'Délai de livraison estimé',
  faHuoRiQi: "Date d'expédition",
  queDingFaHuoMa: 'Êtes-vous sûr de vouloir expédier ?',
  faHuoShiBaiShi: 'Échec d’expédition, la quantité est insuffisante.',
  yiTiJiaoFaHuo: 'Expédié ',
  faHuo: 'Expédition',
  tianJiaXing: 'Ajouter une ligne',
  mingXiHangHao: 'Numéro de ligne',
  daiFaHuoShuLiang: 'Non expédié/Tout',
  faHuoShiBaiShi_2: 'Échec d’expédition, la quantité d’expédition doit dépasser 0.',
  piLiangFaSong: 'Envoi par lots',
  piLiangFaHuo: 'Expédition par lots',
  queRenFaHuo: 'Confirmation ',
  shiJiFaHuoShu_2: 'Quantité expédiée : {val1}',
  caoZuoRen_2: 'Mis à jour par :',
  bianGengWei: 'Mis à jour pour',
  xuQiuDanXiangQing: 'Détails de demande',
  zhiHangQingKuang: 'État d’avancement ',
  shenQingRen: 'Demandeur',
  shenQingRenYouXiang: 'Adresse courriel',
  shenQingRenBuMen: 'Département ',
  shenQingSuoShuGong: 'Entreprise',
  shenQingRenChengBen: 'Centre de coûts',
  shenQingShiJian: 'Demandé à',
  shiFouDianShang: 'Vous achetez sur un site de commerce électronique ?',
  shiFouBenRenShi: 'Pour usage personnel ?',
  shiJiShiYongRen_4: "Centre de coûts pour l'utilisateur effectif",
  canKaoZongJinE: 'Réf. Montant',
  guoJia: 'Pays',
  diQu: 'Région',
  shouHuoLianXiFang: 'Informations de contact',
  qiWangDaoHuoRi: 'Délai de livraison estimé',
  xuQiuXinXi: 'Détails de demande',
  oAHangHao: 'ID de demande OA — N° de ligne : {val1}',
  shuLiang: 'Quantité',
  xuQiuJiYuanYin: "Motif de l'achat",
  yongTu: 'Usage',
  dengDaiShiChang: 'En attente pour',
  xiaoShi: '{val1} heures',
  xingCanKaoZongJia: 'Référence de ligne Montant (taxes incluses)',
  xuQiuShenPiJie: "Détails de la progression de l'approbation",
  queRen: 'Confirmer',
  jinDu: 'Progrès',
  fanKuiShiJian: 'Mis à jour à',
  tiJiaoFanKui: 'Soumettre',
  tianJiaJinDu: 'Ajouter le feed-back',
  zhuanJiaoCaiGouYuan: 'Réassigner',
  zhuanJiaoChengGong: 'Réassigné',
  dangQianCaiGouYuan: 'Acheteur actuel ',
  xinCaiGouYuan: 'Nouvel acheteur',
  faQiXunJia: 'Soumettre une demande de devis',
  buChongCanKaoJia: 'Vous pouvez soumettre une demande de devis après avoir enregistré tous les prix de référence.',
  queDingYaoFaQi: 'Êtes-vous sûr de soumettre la demande de devis ?',
  quanXuan: 'Tout sélectionner',
  yiXuanXingCaiGou: '{val1} lignes sélectionnées',
  oAHangHao_2: 'Numéro de ligne OA',
  shangPin: 'Article',
  xunJiaXinXi: 'Informations',
  xunJiaDan: 'Demande de devis',
  dingDanXinXi: 'Bon de commande',
  dingDanZhuangTai: 'État de commande',
  yanShouShuLiang: 'Quantité reçue',
  zhiXingMingXi: 'Détail de processus ',
  daiBuChongCanKao: 'Devis en attente (réf. Prix)',
  zhen: 'Vrai',
  jia: 'Faux',
  buQiYong: 'Désactivé',
  youXiao: 'Actif',
  banGongYong: 'Utilisé au bureau',
  iTBeiJi: 'Fournitures informatiques',
  yuanGongFuLiNian: 'Avantages sociaux - Cadeaux pour réunion annuelle ',
  yuanGongFuLiJie: 'Avantages sociaux - Cadeaux fériés',
  huoDongJiangPin: 'Cadeaux événementiels',
  daiBuChongHeTong: 'Contrat en attente',
  daiFaHuo: 'Non expédié',
  daiYanShou: 'En attente de réception',
  buFenYanShou: 'Partiellement reçu',
  yiYanShou: 'Reçu',
  caiGouShenQing: "Demande d'achat OA",
  dianShang: 'Site e-commerce',
  shenPiZhong: 'Approbation en cours',
  shenPiJuJue: 'Refusé',
  shenPiTongGuo: 'Approuvé',
  daiRenLingXinYong: 'Factures de cartes de crédit en attente',
  chaoGuoCanKaoJia: 'Dépassé du prix de référence de {val1}%',
  wan: '10K',
  shiWan: '100K',
  baiWan: 'M',
  qianWan: '10M',
  yi: '100M',
  shiYi: 'B',
  baiYi: '10B',
  qianYi: '100B',
  weiBaoCunNeiRong: 'Le contenu non sauvegardé sera perdu. Voulez-vous toujours quitter cette page ?',
  cheHuiYuanYin: 'Raison du retrait',
  cheHuiQingQiuYi: 'Demande de retrait envoyée, veuillez actualiser plus tard pour vérifier.',
  zhiDaoLe: "J'ai compris",
  quXiaoYuanYin: 'Raison du retrait',
  xingVALDing: 'La quantité de la ligne {val1} : le montant cité ne peut pas être de 0',
  jieDian: 'Nœud',
  shenPiShiJian: 'Date d’approbation',
  caoZuoRen: 'Opérateur ',
  shenPiYiJian: 'Avis',
  rPXShenPi: 'Détails',
  shenPiDanHao: 'ID de l’OA : ',
  shouQi: 'Masquer',
  zhanKai: 'Afficher',
  dingBiaoShuLiang: 'Quantité attribuée : ',
  dingBiaoZongE: 'Montant attribué : ',
  tiShiGaiXunJia:
    'Remarque : le montant dépasse 20,000.00 CNY, il faut donner les tarifs d’au moins trois fournisseurs.',
  dingBiao: 'Attribution',
  zuiYouJia: 'Meilleur prix',
  zanWuBaoJiaXin: 'Aucun devis ',
  qingDianJiXiaFang: 'Veuillez cliquer sur ce bouton pour entrer les informations de devis.',
  liJiBaoJia: 'Ajouter un devis',
  heTongMingXi: 'Détails du contrat',
  heTongBianHao: 'Numéro de contrat',
  heTongMingCheng: 'Nom de contrat',
  qianDingRiQi: 'Date de signature',
  heTongKaiShiRi: 'Date de début',
  heTongZhongZhiRi: 'Date de fin',
  zongJinE: 'Montant total',
  biaoQian: 'Étiquette',
  heJiaShuoMing: 'Évaluation d’attribution',
  baoJiaFuJian: 'Pièces jointes de devis',
  shiJiShiYongRen: 'Utilisateur effectif',
  shiJiShiYongRen_2: 'Département d’utilisateur effectif',
  shiJiShiYongRen_3: 'Adresse courriel d’utilisateur effectif',
  caiGouXuQiuFu: 'Pièces jointes de demande',
  shouHuoRenLianXi: 'Numéro téléphone ',
  caiGouYuanYin: 'Motif',
  dingBiaoZongE_2: 'Montant total attribué',
  yiXunJiaZongShu: 'Renseignés / Tout',
  ruanJian: 'Logiciel',
  yingJian: 'Matériel',
  baoJia: 'Devis {val1}',
  dingBiaoJinE: 'Montant attribué ',
  gongYingShangLianXi: 'Contact',
  gongYingShangLianXi_2: 'Adresse courriel',
  faSongDingDanTong: 'Envoyer une notification',
  shi: 'Oui',
  fou: 'Non',
  xiaZai: 'Télécharger',
  queDingCheHuiShen: 'Voulez-vous retirer votre avis ?',
  cheHuiShenPiCheng: 'Avis retiré',
  cheHuiShenPi: 'Retirer mon avis',
  queDingQuXiaoDing: 'Voulez-vous annuler la décision d’attribution de l’offre ?',
  quXiaoDingBiaoCheng: 'L’attribution de l’offre a été annulée',
  quXiaoDingBiao: 'Annuler l’attribution de l’offre',
  shouHuoRenYouXiang: 'Adresse courriel',
  tiJiaoDingBiaoShi: 'Échec de la soumission d’attribution',
  dingBiaoShuLiangWei: 'La quantité attribuée est de 0, veuillez vérifier avant la soumission',
  woZhiDaoLe: 'J’ai compris',
  xingZhongBiaoShuLiang:
    'La quantité de la ligne {val1} est supérieure à la quantité renseignée, veuillez saisir à nouveau.',
  xingYuFuJinE: 'Montant de l’acompte de la ligne {val1} ne peut pas être 0.',
  queDingYaoTiJiao: 'Voulez-vous soumettre l’attribution de l’offre ?',
  dingBiaoTiJiaoHou:
    'Les étapes de vérification commencera automatiquement une fois le fournisseur aura été choisi, puis une commande d’approvisionnement sera créée également de façon automatique.',
  bianJiXunJiaDan: 'Modifier la demande de devis',
  chuangJianXunJiaDan: 'Créer une demande de devis',
  tiJiaoDingBiao: 'Soumettre',
  xinZengBaoJia: 'Ajouter un devis',
  baoJia_2: 'Ligne {val1} - devis {val2}',
  queDingYaoShanChu: 'Voulez-vous supprimer le devis ?',
  danGeWenJianBu: 'La taille maximale est à 1GB pour chaque document.',
  zhiZhiChiMYi: 'La taille maximale de fichier autorisée est de {val1}.',
  zhiNengShangChuanGe: 'Le nombre maximal de fichiers est de {val1} .',
  xuanZeHeTong: 'Sélectionner un contrat',
  qingXuanZeHeTong: 'Veuillez sélectionner un contrat.',
  shenPiZhongDaiXun_2: 'Approbation en cours / à renseigner',
  yiShenPiDaiXun_2: 'Avis émis / à renseigner',
  shenPiZhongYiXun_2: 'En cours / renseigné',
  shenPiZhongXunJia_2: 'En cours / approbation de demande de devis en cours',
  yiShenPiXunJia_2: 'Avis émis / approbation de demande de devis en cours',
  pUNCH: 'Gestion de la liste blanche et des utilisateurs',
  zhanDian: 'Sites',
  yongHuLieBiao: 'Liste des utilisateurs',
  baiMingDanGongNeng: 'Liste blanche',
  queDingJinYongMa: 'Voulez-vous désactiver la liste blanche ?',
  queDingQiYongMa: 'Voulez-vous activer la liste blanche ?',
  yiJinYong: 'Désactivée',
  yiQiYong: 'Activée',
  qiYong: 'Activer',
  jinYong: 'Désactiver',
  yongHuYouXiang: 'Adresse courriel utilisateur',
  huaMingYouXiang: "Alias d'adresse courriel",
  qingTianXieYongHu: "Veuillez entrer l'adresse courriel utilisateur",
  xinZengYongHu: 'Ajouter un utilisateur',
  zanWuXianXiaBao: 'Pas de justificatif de devis donné hors ligne',
  xianXiaBaoJiaFu: 'Téléchargement du justificatif de devis donné hors ligne',
  shenPiZhongXunJia: 'En cours / approbation de demande de devis en cours',
  yiShenPiXunJia: 'Avis émis / approbation de demande de devis en cours',
  queDingShanChuMa: 'Voulez-vous supprimer cet élément ?',
  shanChu: 'Supprimer',
  liKaiYeMianDang: 'Si vous quittez la page, les modifications ne seront pas enregistrées.',
  ciXiangBiTian: 'Requis',
  quXiao: 'Annuler',
  baoCun: 'Sauvegarder',
  quanBuPinLei: 'Toutes les catégories',
  gengDuoShaiXuan: 'Plus',
  yongHuPeiZhi: 'Paramètres d’utilisateur',
  jueSe: 'Rôle',
  yongHu: 'Utilisateur',
  yeWuPeiZhi: "Éléments d'affaires",
  huiLu: 'Taux de change',
  guiZePeiZhi: 'Règlements',
  caiGouJieDanGui: 'Règlements de réception de commande',
  shenPiGuiZe: 'Règlements d’approbation',
  teShuBiaoQian: 'Étiquettes spéciales',
  gongZuoTai: 'Accueil',
  caiGouXuQiu: "Demande d'achat",
  xunJiaDingBiao: 'Renseignement et attribution',
  caiGouDingDan: 'Commande',
  shouHuoGuanLi: 'Réception',
  gongYingShangGuanLi: 'Fournisseurs',
  xiTongGuanLi: 'Paramètres',
  danHaoBaoKuoV: 'Les références OA {val1} sont incluses.',
  danHaoBaoKuoV_2: 'Les demandes d’un devis {val1} sont incluses.',
  danHaoBaoKuoV_3: 'Les références PO {val1} sont incluses.',
  shangPinMingBaoKuo: 'Les noms des articles PO {val1} sont incluses.',
  qingSouSuoDanHao: 'Veuillez entrer la référence, le nom ou le fournisseur etc.',
  guanLiYuan: 'Administrateur',
  tuiChuDengLu: 'Déconnexion',
  guiZeZhuangTai: 'État',
  manZuYiXiaTiao: 'Les conditions requises pour démarrer une vérification',
  tiaoJian: 'Critères',
  shanChuTiaoJian: 'Supprimer',
  tianJiaTiaoJian: 'Ajouter',
  xinJianShenPiGui: 'Créer une nouvelle règle pour approbation de RFx',
  bianJiShenPiGui: 'Modifier les règles pour approbation de RFx',
  baoCunChengGong: 'Sauvegardé',
  xinJianChengGong: 'Créé',
  xinJianGuiZe: 'Créer une règle',
  bianJi: 'Modifier',
  shenPiGuiZeMing: 'Règlements',
  youXianJi: 'Priorité',
  oAShenPiLian: 'Processus d’OA',
  bianGengQian: 'Avant modification',
  bianGengHou: 'Après modification',
  chaKan: 'Voir',
  bianGengJiLuR: 'Note de modification d’examination Rfx {val1}',
  zanWuBianGengJi: 'Aucun historique de modification',
  qingShuRuGuiZe: 'Nom de règle',
  leiXing: 'Type',
  zhuangTai: 'État',
  bianGengJiLu: 'Historique de modifications',
  caoZuo: 'Opération',
  queDingShiXiaoMa: 'Voulez-vous confirmer la désactivation ?',
  queDingShengXiaoMa: 'Voulez-vous confirmer l’activation ?',
  shiXiao: 'Désactiver ',
  shengXiao: 'Activer',
  yiShiXiao: 'Désactivé ',
  yiShengXiao: 'Activé',
  zanWuShuJu: 'Aucune donnée',
  rFXShenPi: 'Approbation de RFx',
  daYu: 'Supérieur à ',
  xiaoYu: 'Inférieur à ',
  dengYu: 'Égal à',
  daYuDengYu: 'Supérieur ou égal à',
  xiaoYuDengYu: 'Inférieur ou égal à',
  buDengYu: 'Différent de ',
  cunZaiYu: 'Inclus',
  zhengDanJinE: 'Montant total',
  tiJiaoRen: 'Initiateur',
  jieSuanGongSi: 'Entreprise de règlement',
  shouHuoGuoJiaDi: 'Pays / Régions',
  pinLei: 'Catégorie',
  dingBiaoGongYingShang: 'Fournisseur',
  zhiFuFangShi: 'Mode de paiement',
  zhangQi: 'Délai de paiement',
  gongYingShang: 'Fournisseur',
  weiBao: 'Garantie',
  danJiaWeiShui: 'Prix unitaire hors taxes',
  baoJiaBiZhong: 'Devise du devis',
  shuiLu: 'Taux de taxation',
  xiaDanShuLiang: "Quantité d'attribution ",
  hanShuiJinE: 'Montant taxes incluses',
  tian: '{val1} jours',
  yuFuJinE: 'Montant de l’acompte',
  xinYongKaHao: 'Numéro de carte de crédit',
  heTongHao: 'Numéro de contrat',
  beiZhu: 'Notes',
  fuJian: 'Pièces jointes',
  shouQuan: 'Type de permis',
  yongJiu: 'Perpétuel',
  geYue: '{val1} mois ',
  baoJiaXiangQing: 'Informations du devis',
  oADanHao: 'Numéro de commande OA',
  canKaoLianJie: 'Lien de référence',
  shangPinMingCheng: "Nom d'article",
  pinPai: 'Marque',
  peiZhi: 'Paramètres',
  canKaoJia: 'Montant de référence (taxe incluse)',
  canKaoBiZhong: 'Devise de référence',
  shouHuoRen: 'Destinataire',
  shouHuoDiZhi: 'Adresse de livraison',
  youBian: 'Code postal',
  lianXiDianHua: 'Numéro de contact',
  youXiang: 'Adresse courriel',
  daiXunJiaZongShu: 'À renseigner / Total',
  jiBenXinXi: 'Informations générales',
  xunJiaDingBiaoHao: 'Référence de renseignement',
  chuangJianShiJian: 'Date de création',
  tiJiaoShiJian: 'Date de soumission',
  caiGouYuan: 'Agent d’achat',
  caiGouYuanYouXiang: 'Courriel de l’agent',
  canKaoJiaHeJi: 'Montant de prix de référence (taxes incluses)',
  dingBiaoJinEHe: "Montant d'attribution (taxes incluses)",
  jieSuanGongSiBi: 'Devise d’entreprise payeuse',
  shenPiZhongDaiXun: 'Approbation en cours / à renseigner',
  yiShenPiDaiXun: 'Avis émis / à renseigner',
  shenPiZhongYiXun: 'En cours / renseigné',
  daiJiaGeHuiZong: 'En attente de devis',
  yiXiaDan: 'Commande passée',
  yiQuXiao: 'Annulé',
  zhuanZhang: 'Virement bancaire',
  xinYongKa: 'Carte de crédit',
  zhuanZhangYuFu: 'Virement bancaire - acompte',
  wangLuoYiChang: 'Erreur de connexion réseau',
}
