import { type ReactNode, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  Anchor,
  Button,
  Collapse,
  Empty,
  Form,
  Message,
  Upload,
  Tag,
  Link,
  Typography,
  Tooltip,
} from '@bedrock/components'
import ErrorImage from '@bedrock/components/es/Empty/images/small/Error'
import type { TypographyProps } from '@bedrock/components/lib/Typography/interface'
import type { ButtonProps } from '@bedrock/components/lib/Button'
import type { FormItemProps, FormProps } from '@bedrock/components/lib/Form'
import type { UploadFile, BrFile, UploadChangeParam, UploadProps } from '@bedrock/components/lib/Upload/types'
import type { AnchorProps } from '@bedrock/components/lib/Anchor'
import { Eye, Copy } from '@bedrock/icons-react'
import { BEMGenerator, useRCVars, useRCWatch } from '@oahz/neact-utils'
import {
  openFile,
  downloadFile,
  getMoneyNumberSign,
  dataDiff,
  formatDateTime,
  formatMoenyAmount,
  getFileSizeUnit,
} from '@feature/shared'
import I18N from '@feature/i18n'
import './style.less'

const bem = BEMGenerator('ftdv')

export { RollingLoadSelect } from './compontent/rolling-load-select'

export const DataContainer = (props: {
  className?: string
  children?: ReactNode
  anchor?: { id: string; element: ReactNode }[]
  scrollOffsetTop?: number
  getAnchorScroller?: AnchorProps['getScrollContainer']
}) => {
  // scrollOffsetTop 在NetBuy站点上，就是Card的paddingTop，在Supplier站点上是站点头+页面头+Card的paddingTop
  const { className, anchor, scrollOffsetTop = 32, getAnchorScroller, children } = props
  return (
    <div className={bem('container', [className])}>
      {anchor && (
        <DataAnchor offsetTop={scrollOffsetTop} getScrollContainer={getAnchorScroller}>
          {anchor}
        </DataAnchor>
      )}
      {children}
    </div>
  )
}

export const DataAnchor = (
  props: Omit<AnchorProps, 'children'> & { children?: { id: string; element: ReactNode }[] }
) => {
  const { children, className, ...rest } = props

  if (!children) return null

  return (
    <Anchor className={bem('anchor', [className])} height={600} useCaret={false} {...rest}>
      {children?.map(v => (
        <Anchor.Link key={v.id} href={`#${v.id}`} title={v.element} />
      ))}
    </Anchor>
  )
}

export const DataCard = (props: {
  id?: string
  className?: string
  title?: ReactNode
  action?: ReactNode
  children?: ReactNode
  collapsable?: boolean
}) => {
  const { id, className, title, action, children, collapsable } = props

  if (collapsable) {
    return (
      <Collapse className={bem('card', [className])} accordion bordered={false} defaultActiveKey={[1]} pure>
        <Collapse.Panel
          id={id}
          key="1"
          className={bem('card-collapse-panel')}
          header={
            title && (
              <div className={bem('card-header')} onClick={e => e.stopPropagation()}>
                <div className={bem('card-title')}>{title}</div>
                {action && <div className={bem('card-action')}>{action}</div>}
              </div>
            )
          }>
          <div className={bem('card-body')}>{children}</div>
        </Collapse.Panel>
      </Collapse>
    )
  }

  return (
    <div id={id} className={bem('card', [className])}>
      {title && (
        <div className={bem('card-header')}>
          <div className={bem('card-title')}>{title}</div>
          {action && <div className={bem('card-action')}>{action}</div>}
        </div>
      )}
      <div className={bem('card-body')}>{children}</div>
    </div>
  )
}

export const DataGrid = (props: { className?: string; children: ReactNode }) => {
  const { className, children } = props
  return <div className={bem('dg', [className])}>{children}</div>
}

export const DataBlock = (props: {
  title: ReactNode
  icon?: ReactNode
  extra?: ReactNode
  sublevel?: boolean
  children?: ReactNode
}) => {
  const { title, icon, extra, sublevel = false, children } = props
  return (
    <div className={bem('db', { sublevel })}>
      <div className={bem('db-header')}>
        <div className={bem('db-title')}>
          {icon}
          {title}
        </div>
        {extra}
      </div>
      {children}
    </div>
  )
}

export const DataItem = (props: {
  className?: string
  w100?: boolean
  label: ReactNode
  value?: ReactNode
  children?: ReactNode
}) => {
  const { className, w100 = false, label, value, children } = props
  return (
    <div className={bem('di', { w100 }, [className])}>
      <div className={bem('di-label')}>{label}</div>
      <div className={bem('di-value')}>{(children || value) ?? '-'}</div>
    </div>
  )
}

export const DataForm = (props: FormProps) => {
  const { className, ...rest } = props
  return <Form className={bem('fm', [className])} layout="horizontal" autoComplete="off" {...rest} />
}

export const DataFormItem = (
  props: FormItemProps & {
    w100?: boolean
    w102?: boolean
    h100?: boolean
    disableLabel?: boolean
    children?: ReactNode
  }
) => {
  const {
    className,
    w100 = false,
    w102 = false,
    h100 = false,
    disableLabel = false,
    required,
    rules = [],
    validateTrigger,
    ...rest
  } = props
  return (
    <Form.Item
      className={bem('fm-item', { w100, w102, h100, disableLabel }, [className])}
      validateFirst
      required={required}
      rules={[
        // 这bedrock组件库Input和Select触发校验的交互不一样，就这样吧
        ...(required
          ? [{ required: true, message: I18N.auto.ciXiangBiTian, ...(validateTrigger ? { validateTrigger } : {}) }]
          : []),
        ...rules,
      ]}
      {...rest}
    />
  )
}

export const AsyncButton = (props: Omit<ButtonProps, 'loading' | 'onClick'> & { onClick?: () => Promise<void> }) => {
  const { onClick, ...rest } = props
  const [loading, setLoading] = useState(false)

  return (
    <Button
      onClick={async () => {
        try {
          setLoading(true)
          await onClick?.()
        } finally {
          setLoading(false)
        }
      }}
      loading={loading}
      {...rest}
    />
  )
}

const createPreviewIconAsUploadProp = (getUrl: (v: UploadFile) => Promise<string | undefined>) => {
  return {
    renderIcon: (v: UploadFile) =>
      // 只有图片和PDF文件才显示预览按钮
      /(png|jpe?g|pdf)$/.test(v.name || '') && (
        <Button
          type="text-subtle"
          size="xSmall"
          icon={<Eye />}
          onClick={async () => {
            const url = await getUrl(v)
            url && openFile(url)
          }}
        />
      ),
  }
}

const createOnDownloadAsUploadProp = (getUrl: (v: UploadFile) => Promise<string | undefined>) => {
  return {
    onDownload: async (v: UploadFile) => {
      const url = await getUrl(v)
      url && downloadFile(url, v.name)
    },
  }
}

export type FilePreviewAndDownloadServices = {
  getPreviewUrl?: (data: { dataId: string; fileId: number }) => Promise<string | undefined>
  getDownloadUrl?: (data: { dataId: string; fileId: number }) => Promise<string | undefined>
}
export type FileRequestUploadDto = {
  /** 上传地址 */
  uploadUrl?: string
  /** 完成令牌 */
  token?: string
  /** 额外头部 */
  extraHeaders?: {
    [key: string]: any
  }
}
export type FileCompleteUploadDto = {
  /** 文件地址 */
  url?: string
  /** 文件id */
  id?: number
  /** 文件名 */
  fileName?: string
  /** 文件大小 */
  fileSize?: number
}
export type FileUploadServices = {
  requestUploadFile: (v: {
    fileName?: string
    fileSize?: number
    id?: string
  }) => Promise<FileRequestUploadDto | undefined>
  completeUploadFile: (v: { token?: string }) => Promise<FileCompleteUploadDto | undefined>
}

const FILE_MAX_SIZE = 1024 * 1024 * 1024 * 1
export type UploadFileObject = { id: number; name: string; size: number }
export type FileUploadProps = Omit<UploadProps, 'onChange'> & {
  dataId: string
  defaultValue?: Partial<UploadFileObject> | Partial<UploadFileObject>[]
  onChange?: (v: UploadFileObject | UploadFileObject[]) => void
}
export const FileUpload = (props: FileUploadProps & FileUploadServices & FilePreviewAndDownloadServices) => {
  const {
    className,
    dataId,
    fileSizeLimit = FILE_MAX_SIZE,
    defaultValue = [],
    onChange,
    requestUploadFile,
    completeUploadFile,
    getPreviewUrl,
    getDownloadUrl,
    ...restProps
  } = props
  const vars = useRCVars(() => {
    const _defaultValue = (Array.isArray(defaultValue) ? defaultValue : [defaultValue]).filter(Boolean)
    const defaultFileList = _defaultValue.map<UploadFile>(v => ({ uid: `${v.id}`, status: 'done', ...v }))
    const fileMap = defaultFileList.reduce((r, v) => ({ ...r, [v.uid]: v }), {} as Record<string, UploadFile>)
    return {
      defaultFileList,
      fileMap: fileMap as Record<string, UploadFile & { id: number; token: string; completed: boolean }>,
    }
  })

  const getAction = async (file: BrFile) => {
    const uploadInfo = await requestUploadFile({
      id: dataId,
      fileName: file.name,
      fileSize: file.size,
    })
    const { token, uploadUrl } = uploadInfo || {}

    if (!uploadUrl) throw new Error('UploadUrl is null')

    vars.fileMap[file.uid] = { uid: file.uid, id: 0, name: file.name, size: file.size, token: token!, completed: false }

    return uploadUrl
  }

  const doChange = async (v: UploadChangeParam) => {
    // 找出上传成功的文件
    const succeedFiles = v.fileList.filter(t => t.status === 'done').map(t => vars.fileMap[t.uid])

    // 还没有通知filepicker已完成上传任务的，需要通知一下
    for (let i = 0, file; i < succeedFiles.length; i++) {
      file = succeedFiles[i]
      if (!file.completed && file.token) {
        file.completed = true
        const res = await completeUploadFile({ token: file.token })
        res && (file.id = res.id!)
      }
    }

    const changedFiles = succeedFiles.map(v => ({ id: v.id, name: v.name!, size: v.size! }))
    // 通知上层当前上传成功的文件信息
    onChange?.(restProps.limit === 1 ? changedFiles[0] : changedFiles)
  }

  const onError = (e: Error) => {
    Message.warn(
      e.message === 'Size Limit'
        ? I18N.template(I18N.auto.zhiZhiChiMYi, { val1: getFileSizeUnit(fileSizeLimit) })
        : e.message === 'Exceed Limit'
        ? I18N.template(I18N.auto.zhiNengShangChuanGe, { val1: restProps.limit })
        : e.message
    )
  }

  return (
    <Upload
      className={bem('upload', [className])}
      action={getAction}
      urlPath={() => ''}
      // s3上传得Blob或者File对象包装一下，后者可以定义名称
      beforeUpload={v => new File([v], v.name)}
      format="raw"
      method="PUT"
      defaultFileList={vars.defaultFileList}
      tip={I18N.auto.danGeWenJianXiao}
      fileSizeLimit={fileSizeLimit}
      showRemoveIconWhenError
      onError={onError}
      onChange={doChange}
      {...(getPreviewUrl
        ? createPreviewIconAsUploadProp(v => getPreviewUrl({ dataId, fileId: vars.fileMap[v.uid].id }))
        : {})}
      {...(getDownloadUrl
        ? createOnDownloadAsUploadProp(v => getDownloadUrl({ dataId, fileId: vars.fileMap[v.uid].id }))
        : {})}
      {...restProps}
    />
  )
}

export type DataAttachmentsProps = {
  className?: string
  dataId: string
  attachments?: { id?: number; name?: string; size?: number; url?: string }[]
  emptyText?: string
}
export const DataAttachments = (props: DataAttachmentsProps & FilePreviewAndDownloadServices) => {
  const { className, dataId, attachments: propAttachments, emptyText, getPreviewUrl, getDownloadUrl } = props

  const attachments = propAttachments?.filter(v => v.id || v.url)

  if (!attachments?.length) {
    return emptyText ? <Empty imageNode={<ErrorImage />} text={emptyText} /> : <>-</>
  }

  return (
    <>
      {attachments.map(v => (
        <Upload
          key={v.id}
          className={bem('attachment', [className])}
          fileList={[
            {
              uid: `${v.id}`,
              status: 'done',
              name: v.name,
              size: v.size,
            },
          ]}
          limit={1}
          showRemoveIcon={false}
          {...(getPreviewUrl ? createPreviewIconAsUploadProp(() => getPreviewUrl({ dataId, fileId: v.id! })) : {})}
          {...(getDownloadUrl ? createOnDownloadAsUploadProp(() => getDownloadUrl({ dataId, fileId: v.id! })) : {})}
        />
      ))}
    </>
  )
}

export { TimeCountDown } from './compontent/time-count-down'

export const DataLink = (props: {
  className?: string
  href?: string
  name?: ReactNode
  children?: ReactNode
  underline?: boolean
}) => {
  const { className, href, name, children, underline = true } = props
  if (!href) return <>{name || children}</>

  return (
    <Link className={bem('link', { underline }, [className])} type="primary" href={href} target="_blank">
      {name || children || href}
    </Link>
  )
}

export const ValueCopyView = (props: {
  className?: string
  value?: string | number
  children?: ReactNode
  light?: boolean
}) => {
  const { className, value, children, light = false } = props
  if (!value) return <>-</>
  return (
    <div className={bem('copy', { light: light }, [className])}>
      {children || value}
      <CopyToClipboard
        text={value}
        onCopy={() => {
          Message.success(I18N.auto.fuZhiChengGong)
        }}>
        <Copy />
      </CopyToClipboard>
    </div>
  )
}

export const CompanyView = (props: {
  companyName?: string
  companyCode?: string
  isSettlement?: boolean
  className?: string
  typeTitle?: string
}) => {
  const { companyName, companyCode, isSettlement = false, typeTitle, className } = props
  if (!companyName) return <>-</>
  return (
    <div className={bem('comv', [className])}>
      <div className={bem('comv-name')}>{companyName}</div>
      {companyCode && (
        <Tooltip
          title={I18N.template(I18N.auto.zhuCeDiWei, {
            val1: typeTitle ? typeTitle : isSettlement ? I18N.auto.jieSuanGongSi : I18N.auto.gongYingShang,
            val2: companyCode,
          })}>
          <div className={bem('comv-code')}>{companyCode}</div>
        </Tooltip>
      )}
    </div>
  )
}

export const getMoneyText = (v?: MoneyDto) => {
  // 金额数值要千分位格式化
  return v && `${`${v.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
export type MoneyDto = {
  /** 金额 */
  amount?: number
  currency?: string
  /** 币种符号 */
  currencySymbol?: string
}
export const DataMoney = (props: {
  className?: string
  value?: MoneyDto | number
  highlight?: boolean
  size?: 'xsmall' | 'small' | 'default' | 'large'
  fontWeight?: 'normal' | 'bold' | 'extraBold'
  centerLine?: boolean
  showSign?: boolean
}) => {
  const {
    className,
    value,
    highlight = true,
    fontWeight,
    centerLine = false,
    size = 'default',
    showSign = true,
  } = props
  const weight = highlight && !fontWeight ? 'weight-bold' : `weight-${fontWeight || 'normal'}`
  const [sign, setSign] = useState('')

  const currency = (v: typeof value) => {
    return typeof value !== 'number' && value?.currency ? `${value?.currency} ` : ''
  }

  const normalize = (v: typeof value) => {
    return typeof value === 'number' ? value : formatMoenyAmount(value)
  }

  useRCWatch(() => {
    if (typeof value === 'number' || !value?.amount) {
      setSign('')
      return
    }
    return setSign(getMoneyNumberSign(value.amount))
  }, [value])

  if (value === undefined) return <>-</>

  return (
    <div className={bem('money', [className])}>
      <div
        className={bem('money-value', 'money-currency', `money-value-${weight}`, { highlight, line: centerLine }, [
          bem(`money-value-${size}`),
        ])}>
        {currency(value)}
      </div>
      <div className={bem('money-c')}>
        <div className={bem('money-row')}>
          <div
            className={bem('money-value', `money-value-${weight}`, { highlight, line: centerLine }, [
              bem(`money-value-${size}`),
            ])}>
            {normalize(value)}
          </div>
        </div>
        {!!sign && showSign && (
          <div className={bem('money-tip', `money-tip-${weight}`, { highlight })}>
            <Tag className={bem('money-sign')} text={sign} color="light-grey" size="xSmall" />
          </div>
        )}
      </div>
    </div>
  )
}

export const ShowCutOffTime = (props: {
  targetTime?: string
  value?: number
  unit?: string
  format?: string
  className?: string
  prefix?: ReactNode
  stuffix?: ReactNode
}) => {
  const { targetTime, value = 24, unit, format, className, prefix, stuffix } = props
  const time = formatDateTime(targetTime, format)
  const isEnd = dataDiff(targetTime, unit, format) <= value
  return (
    <div className={bem('cut-off', [className], { timeWarning: isEnd })}>
      {prefix}
      <div className={bem('cut-off-time')}>{time}</div>
      {stuffix}
    </div>
  )
}

export const SupplierTags = (props: {
  className?: string
  value?: {
    tagId?: string
    tagLevel?: number
    label?: string
  }[]
}) => {
  const { className, value } = props
  return (
    <div className={bem('tags', [className])}>
      {value?.map(v => (
        <span key={v.tagId} className={bem('tag', `tag-${v.tagLevel}`)}>
          {v.label}
        </span>
      ))}
    </div>
  )
}

// 税率小数百分比计算是会精度问题，如：0.07 * 100 = 7.000000001，所以这里以正则字符串转换一下
// 如: 0.01 -> 1% 0.1 -> 10% 0.124 -> 12.4%
export const getTaxRateText = (v?: number) => {
  return v !== undefined ? `${+`${v}00`.replace(/^0\.(\d\d)/, '$1.')}%` : v
}

export const DataTaxRate = (props: { value?: number }) => {
  return <>{getTaxRateText(props.value)}</>
}

export const CommitmentContainer = (props: {
  leftExtra?: string | ReactNode
  rightExtra?: string | ReactNode
  children?: ReactNode
}) => {
  const { leftExtra, rightExtra, children } = props

  return (
    <div className={bem('cc')}>
      {(leftExtra || rightExtra) && (
        <div className={bem('cc-header')}>
          <div>{leftExtra}</div>
          <div>{rightExtra}</div>
        </div>
      )}
      <div className={bem('cc-content')}>{children}</div>
    </div>
  )
}

export const CategoryWithPath = (props: { value?: string; pathName?: string; paragraph?: true | TypographyProps }) => {
  const { value, pathName, paragraph } = props
  if (!value) return <div>{'-'}</div>
  const children = paragraph ? (
    <Typography.Paragraph
      title=""
      ellipsis={{
        rows: 1,
        showTooltip: false,
        cssEllipsis: true,
        ...(paragraph === true ? {} : paragraph),
      }}>
      {value}
    </Typography.Paragraph>
  ) : (
    value
  )
  return <Tooltip title={pathName}>{children}</Tooltip>
}

export const ListMoneyView = (props: { value?: MoneyDto; className?: string }) => {
  const { value, className } = props
  if (props.value === undefined) return <>-</>
  const money = value?.amount !== undefined ? formatMoenyAmount(value) : ''
  return <div className={bem('lmv', [className])}>{`${value?.currency || ''} ${money}`}</div>
}

type TagDto = {
  /** 标签id */
  tagId?: number
  /** 标签名称 */
  name?: string
  /** 颜色-色值，例如：#D6404D */
  colour?: string
}
// netbuy的系统标签
export const SystemTags = (props: { className?: string; value?: TagDto[]; wrap?: boolean }) => {
  const { className, value: propValue, wrap = false } = props
  const value = propValue?.filter(Boolean)
  if (!value?.length) return <>-</>
  return (
    value && (
      <div className={bem('nb-tags', { wrap }, [className])}>
        {value.map(v => (
          <div className={bem('nb-tags-item')} key={v.tagId} style={{ background: v.colour }}>
            {v.name}
          </div>
        ))}
      </div>
    )
  )
}
