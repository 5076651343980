import { useState } from 'react'
import { DataOfResponse, createUri, createEnum } from '@oahz/neact-openapi'
import { CommitmentContainer, TimeCountDown } from '@feature/views'
import { useManualService, useRoute, useRouter } from '@oahz/neact'
import { BEMGenerator, useRCMount, useRCComputed } from '@oahz/neact-utils'
import { Download, Delete, CloseCircle, Upload, Clock } from '@bedrock/icons-react'
import NoFile from '@bedrock/components/es/Empty/images/large/NoFile'
import { Checkbox, Message, Modal, Link, Empty, Button } from '@bedrock/components'
import { formatUtcTimeZone } from '@feature/shared'
import { PageContainer, PageLoading, MainContainer } from '@/components/page-view'
import { AsyncButton } from '@/components/data-view'
import { QuotationUpload } from '@/components/quotation'
import { apiService, bizEnums, defs } from '@/services'
import './style.less'

const bem = BEMGenerator('pseto')

export default () => {
  const { query } = useRoute()
  const { push } = useRouter()
  const [confirmationLetterAttachment, setConfirmationLetterAttachment] = useState<defs.StorageObjectCommand>()
  const [confidentialityAgreementAttachment, setConfidentialityAgreementAttachment] =
    useState<defs.StorageObjectCommand>()
  const [isAgree, setIsAgree] = useState(false)

  const { data, loading, request } = useManualService(apiService.quotationTenderTenderDetail)

  const downFile = async (file?: defs.StorageObjectDto) => {
    if (!file) return Message.error('未找到文件信息')
    const href = await apiService.quotationQuotationGetAttachmentDownloadUrl({ fileId: file.id! })
    href && window.open(href)
  }

  const quotationStatus = bizEnums.QuotationStatusEnum.pick(data?.status!) //当前报价状态

  const statusIcon = () => {
    switch (data?.status) {
      case bizEnums.QuotationStatusEnum.ABANDONED_BID:
        return <Delete />
      case bizEnums.QuotationStatusEnum.NOT_BID:
        return <CloseCircle />
      case bizEnums.QuotationStatusEnum.WAIT_RESPOND_TENDER:
        return <Clock />
      default:
        return <></>
    }
  }

  useRCMount(() => query.id && request({ id: query.id }))

  const abandon = async () => {
    Modal.confirm({
      title: '您即将弃标，一旦提交，将被视为正式且不可更改。您确定要继续提交吗？',
      onOk: async () => {
        await apiService.quotationTenderDiscardedTender({ id: query.id })
        Message.success('弃标成功')
        request({ id: query.id })
      },
    })
  }

  const onSubmit = async () => {
    await apiService.quotationTenderParticipateTender(
      { id: query.id },
      {
        isAgree: true,
        confirmationLetterAttachment,
        confidentialityAgreementAttachment,
      }
    )
    push(`/tender/detail?id=${query.id}`)
  }

  const canSubmit = useRCComputed(() => {
    let _res = isAgree
    if (data?.sendConfirmationLetter) _res = _res && !!confirmationLetterAttachment
    if (data?.sendConfidentialityAgreement) _res = _res && !!confidentialityAgreementAttachment
    return _res
  }, [data, confirmationLetterAttachment, confidentialityAgreementAttachment, isAgree])

  return (
    <PageContainer className={bem()} htmlTitle={'评估'}>
      {data?.status === bizEnums.QuotationDisplayTenderStatusEnum.WAIT_RESPOND_TENDER || (
        <header className={bem('tool', { [quotationStatus?.value!]: true })}>
          <div className={bem('tool-title')}>
            {statusIcon()}
            {quotationStatus?.name!}
          </div>
        </header>
      )}
      <MainContainer
        className={bem('main', {
          empty: !(data?.status === bizEnums.QuotationDisplayTenderStatusEnum.WAIT_RESPOND_TENDER),
        })}>
        <CommitmentContainer
          className={bem('main-container')}
          leftExtra={
            <span className={bem('main-icon')}>
              {statusIcon()}
              {bizEnums.QuotationDisplayTenderStatusEnum.pick(data?.status)?.name}
            </span>
          }>
          <div className={bem('header')}>
            <div className={bem('header-title')}>{data?.title}</div>
            <div className={bem('header-range')}>
              <div>投标开始时间：{formatUtcTimeZone(data?.beginAt)}</div>
              <div>投标结束时间：{formatUtcTimeZone(data?.endAt)}</div>
            </div>
            {data?.status === bizEnums.QuotationDisplayTenderStatusEnum.WAIT_RESPOND_TENDER && (
              <TimeCountDown className={bem('header-end')} type={'light'} targetDate={data?.endAt} label={'距结束'} />
            )}
          </div>
          {data?.status === bizEnums.QuotationDisplayTenderStatusEnum.WAIT_RESPOND_TENDER ? (
            <>
              <div className={bem('content')}>
                <p>
                  <span className={bem('content-rfxId')}>招标事件号【{data?.rfxId}】</span>供应商报价、投标须知：
                </p>
                <p>一、本平台发布的供应商比选项目，由网易负责监督管理，并对其发布内容的真实性、合规性负责；</p>
                <p>
                  二、受邀且有意参与本平台报价、投标的供应商，在满足项目需求的前提下，在规定的时间内提交项目报价，并对报价内容承担责任，不得恶意报价、围标；
                </p>
                <p>
                  三、报价需要列明是否含税、含运费等所有相关费用、开具符合要求的发票，并且明确产品的交期、报价有效期等必要信息；
                </p>
                <p>
                  四、如涉及竞价，允许连续报价，竞价排名按照单价高低排名，系统显示排名第一不代表中标，在竞价截止时间前，若出现新的报价，系统将自动触发延时继续竞价；
                </p>
                <p>五、如涉及向网易支付投标或履约保证金，需要按时完成缴纳，具体金额以项目通知为准；</p>
                <p>
                  六、如涉及恶意报价、围标、串标等行为，供应商将被列入不良记录名单，将通告并封禁平台帐号，不得参于网易任何采购项目；
                </p>
                <p>七、网易有权根据项目具体情况，决定是否重新进行报价，或议价洽谈；</p>
                <p>
                  八、报价结束后半小时为项目复核期，若有系统故障等导致竞价结果与实际情况不符，请在复核期内书面提交澄清，可发送至邮箱：it_purchase@service.netease.com；
                </p>
                <p className={bem('content-complaint')}>
                  以上规则最终解释权归网易所有，若有疑问请及时联系对应项目对接人；
                  若操作过程中有发现违规操作，可向网易投诉，投诉渠道：
                  <Link
                    className={bem('content-complaint-link')}
                    type="primary"
                    href="https://jubao.163.com"
                    target="_blank">
                    https://jubao.163.com
                  </Link>
                </p>
                {data?.sendConfirmationLetter && (
                  <div className={bem('upload')}>
                    <div className={bem('upload-t')}>
                      <div className={bem('upload-t-l')}>
                        <span>*</span>应标确认函签署
                      </div>
                      <AsyncButton
                        type="text-subtle-primary"
                        onClick={() => downFile(data?.confirmationLetterTemplate)}
                        size="small"
                        icon={<Download />}>
                        下载应标确认模版
                      </AsyncButton>
                    </div>
                    <QuotationUpload
                      limit={1}
                      className={bem('upload-btn', { hidden: !!confirmationLetterAttachment })}
                      onChange={file => setConfirmationLetterAttachment(file)}
                      fileSizeLimit={1024 * 1024 * 1024 * 1}
                      tip={'单个文件小于1GB'}
                    />
                  </div>
                )}
                {data?.sendConfidentialityAgreement && (
                  <div className={bem('upload')}>
                    <div className={bem('upload-t')}>
                      <div className={bem('upload-t-l')}>
                        <span>*</span>保密协议签署
                      </div>
                      <AsyncButton
                        type="text-subtle-primary"
                        onClick={() => downFile(data?.confidentialityAgreementTemplate)}
                        size="small"
                        icon={<Download />}>
                        下载保密协议模版
                      </AsyncButton>
                    </div>
                    <QuotationUpload
                      className={bem('upload-btn', { hidden: !!confidentialityAgreementAttachment })}
                      onChange={file => setConfidentialityAgreementAttachment(file)}
                      limit={1}
                      fileSizeLimit={1024 * 1024 * 1024 * 1}
                      tip={'单个文件小于1GB'}
                    />
                  </div>
                )}
              </div>
              <div className={bem('footer')}>
                <Checkbox
                  value={isAgree}
                  onChange={e => {
                    setIsAgree(e.target.checked)
                  }}>
                  我已阅读并同意全部应答条件
                </Checkbox>

                <AsyncButton type="primary" onClick={onSubmit} disabled={!canSubmit}>
                  参与投标
                </AsyncButton>

                <AsyncButton danger onClick={abandon}>
                  弃标
                </AsyncButton>
              </div>
            </>
          ) : (
            <div className={bem('empty')}>
              <Empty
                className={bem('empty')}
                imageNode={<NoFile />}
                size={'xSmall'}
                text={<span className={bem('empty-text')}>{'暂无招标信息'}</span>}
              />
            </div>
          )}
        </CommitmentContainer>
      </MainContainer>
    </PageContainer>
  )
}
